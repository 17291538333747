<template>
  <!-- App.vue -->
  <v-app>
    <v-app-bar class="elevation-0 white" app>
      <v-btn text color="white" onclick="window.history.back()">
        <font-awesome-icon
          :icon="['fas', 'arrow-left']"
          class="fa-x black--text mr-2"
        />
        retour
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn icon @click="dialogSearch = true">
        <font-awesome-icon :icon="['fas', 'search']" class="fa-x black--text" />
      </v-btn>
    </v-app-bar>
    <!-- Sizes your content based upon application components -->

    <v-main class="bg py-5" style="background-color:rgb(235, 236, 239)">
      <transition name="slide-fade">
        <div v-if="show">
          <section class="bg-col-news">
            <div
              style="height: 100%;width: 100%;background: rgba(11,18,81,0.66);padding: 80px 0px;"
            >
              <div class="container py-0">
                <div class="row">
                  <div
                    class="col-12 d-flex justify-content-center align-items-center flex-column"
                    style="text-align: center;"
                  >
                    <h1 class="white--text display-2 font-weight-bold pt-5">
                      ACTIVITIES
                    </h1>
                    <p
                      class="d-md-none d-lg-flex"
                      style="color: rgb(224,224,224);font-size: 22px;"
                    >
                      <!-- <br />Jeunes retraités actifs ou centenaires dépendants,
                      la Ville doit pouvoir répondre à leurs besoins de loisirs,
                      daide sociale, de santé ou de logement.<br /><br /> -->
                    </p>

                    <a
                      class="btn btn-primary btn-xl"
                      role="button"
                      href="#services"
                      style="background: white;padding: 5px 20px;color: rgb(11,18,81);font-size: 16px;"
                      >Contactez le concepteur
                      <font-awesome-icon
                        :icon="['fas', 'arrow-right']"
                        class="fa-x"
                        style="color: rgb(11,18,81);"
                    /></a>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section
            id="portfolio"
            class="content-section"
            style="padding: 10px 0px;"
          >
            <div class="container py-5">
              <div class="row d-flex">
                <div
                  class="col-6 col-sm-10 col-md-6 col-lg-6 col-xl-4 col-xxl-4"
                  style="padding: 10px;"
                  v-for="(x, k) in activities"
                  :key="k"
                >
                  <a class="portfolio-item" @click="openNews(x)">
                    <div
                      class="caption"
                      style="background: rgba(11,18,81,0.66);"
                    >
                      <div class="caption-content">
                        <h2 style="font-size: 20.8px;">
                          <strong>{{ x.title.slice(0, 30) }}</strong
                          ><br />
                        </h2>
                        <p class="mb-0">
                          {{ x.paragraph.slice(0, 20) }}
                        </p>
                        <span
                          style="color: rgb(207,207,207);font-size: 13px;background: rgba(244,71,107,0.5);"
                          >{{ x.date }}</span
                        >
                      </div>
                    </div>
                    <v-img
                      class="img-fluid"
                      :src="x.image"
                      style="height: 350px;width: 100%;"
                    />
                  </a>

                  <v-divider class="grey lighten-2"></v-divider>
                </div>
              </div>
            </div>
          </section>
          <span class="d-none headline">{{ salaSearch }}</span>
        </div>
      </transition>
    </v-main>

    <v-dialog v-model="dialogSearch" max-width="400">
      <v-card class="p-3">
        <v-text-field label="Recherche" v-model="search" @click="search = ''" />
        <v-btn class="white--text" color="red" small>Fermer</v-btn>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="dialogWait" max-width="200">
      <v-card class="text-center py-3">
        <v-progress-circular
          :size="30"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <span class="ml-2">Please Wait</span>
      </v-card>
    </v-dialog>
    <div class="d-none">{{ ouvrirTransition }}</div>
  </v-app>
</template>

<script>
// import ServiceApi from "../ServicesApi.js";
import firebase from "firebase";
import db from "../../db.js";

export default {
  data() {
    return {
      email: "",
      show: false,
      password: "",
      dialog: false,
      dialogWait: false,
      dialogSearch: "",
      search: "",
      place: "",
      news: [],
      activities: [],
      journal: [
        {
          title: "",
          image: "",
          paragraph: ""
        }
      ]
    };
  },
  created() {
    var user = firebase.auth().currentUser;
    if (
      this.$store.state.ngayi.email == "" ||
      this.$store.state.ngayi.email == undefined ||
      user.email == "" ||
      user.email == undefined
    )
      this.$router.push("/menu");

    var that = this;
    /********************************************************/
    db.collection("activites").onSnapshot(snapshot => {
      that.news = [];
      snapshot.forEach(doc => {
        console.log("ok ok ok");
        that.news.push({
          id: doc.data().id,
          title: doc.data().title,
          image: doc.data().image,
          myImage: "",
          auteur: "",
          paragraph: doc.data().paragraph,
          date: new Date(doc.data().cretedAd.toDate()).toDateString(),
          heure:
            " at " + new Date(doc.data().cretedAd.toDate()).toLocaleTimeString()
        });
      });
    });
    //     /*******************************************************/
  },
  computed: {
    salaSearch() {
      this.rechercher();
      return 0;
    },
    ouvrirTransition() {
      this.leTemps();
      return false;
    }
  },

  methods: {
    rechercher() {
      this.activities = [];
      this.news.forEach(element => {
        if (element.title.toLowerCase().includes(this.search.toLowerCase())) {
          this.activities.push({
            originalID: element.originalID,
            id: element.id,
            title: element.title,
            image: element.image,
            myImage: element.myImage,
            auteur: element.auteur,
            paragraph: element.paragraph,
            date: element.date,
            cretedAd: element.cretedAd
          });
        }
      });
    },
    openNews(x) {
      this.$store.state.news = x;
      this.$router.push("/activites_details");
    },
    leTemps() {
      setTimeout(() => {
        this.changedValue();
        return false;
      }, 100);
      return 0;
    },
    changedValue() {
      this.show = true;
      return false;
    }
  }
};
</script>

<style scoped>
.bg-col-news {
  background: url("../../images/news.jpg") center / cover no-repeat;
}
</style>
