<template>
  <!-- App.vue -->
  <v-app>
    <v-app-bar class="elevation-0 white" app>
      <v-btn
        text
        color="white"
        onclick="window.history.back()"
        v-if="part == 0"
      >
        <font-awesome-icon
          :icon="['fas', 'arrow-left']"
          class="fa-x black--text mr-2"
        />
        retour
      </v-btn>
      <v-btn text color="white" @click="part = 0" v-else>
        <font-awesome-icon
          :icon="['fas', 'arrow-left']"
          class="fa-x black--text mr-2"
        />
        retour
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn text class="black--text" @click="part = 0" v-if="part == 1">
        <font-awesome-icon
          :icon="['fas', 'bars']"
          class="fa-x mr-2 black--text"
        />
        News
      </v-btn>
      <v-btn
        icon
        @click="
          dialogSearch = true;
          part = 1;
        "
      >
        <font-awesome-icon :icon="['fas', 'search']" class="fa-x black--text" />
      </v-btn>

      <!-- v-if="ajouter" -->
      <v-btn icon @click="dialog = true">
        <font-awesome-icon :icon="['fas', 'plus']" class="fa-x black--text" />
      </v-btn>
      <v-btn icon @click="dialogIT = true" v-if="WaKongo">
        <font-awesome-icon
          :icon="['fas', 'book-dead']"
          class="fa-x black--text"
        />
      </v-btn>
    </v-app-bar>
    <!-- Sizes your content based upon application components -->

    <v-main class="bg py-5" style="background-color: rgba(235, 236, 239);">
      <transition name="slide-fade">
        <div v-if="show">
          <section class="bg-col-news">
            <div
              style="height: 100%;width: 100%;background: rgba(11,18,81,0.66);padding: 80px 0px;"
            >
              <div class="container py-0">
                <div class="row">
                  <div
                    class="col-12 d-flex justify-content-center align-items-center flex-column"
                    style="text-align: center;"
                  >
                    <h1 class="white--text display-2 font-weight-bold pt-5">
                      ACTUALITES
                    </h1>
                    <p
                      class="d-md-none d-lg-flex"
                      style="color: rgb(224,224,224);font-size: 22px;"
                    >
                      <br />Jeunes retraités actifs ou centenaires dépendants,
                      la Ville doit pouvoir répondre à leurs besoins de loisirs,
                      d’aide sociale, de santé ou de logement.<br /><br />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div
            class="py-5"
            data-aos="fade"
            data-aos-duration="1000"
            style="heights: 100vh;"
            v-if="part == 0"
          >
            <div
              class="d-flex flex-column justify-content-around align-items-center py-5"
              style="background-color: rgba(0,0,0,.0);height: 100%;"
            >
              <div class="container text-center">
                <h3 class="text-center py-5 mb-5 black--text">Categories</h3>

                <v-simple-table class="bg-transparent">
                  <template v-slot:default>
                    <tbody>
                      <tr
                        v-for="(x, k) in categories"
                        :key="k"
                        @click="fongola(x.name)"
                      >
                        <td class="text-left">
                          {{ x.name }}
                        </td>
                        <td class="text-left title">
                          {{ x.nbre }}
                        </td>
                        <td class="text-left">
                          <font-awesome-icon
                            :icon="['fas', 'chevron-right']"
                            class="fa-x black--text"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </div>
          </div>

          <section
            v-else
            id="portfolio"
            class="content-section"
            style="padding: 0px;"
          >
            <div class="container py-5">
              <div class="row d-flex">
                <div
                  v-for="(x, k) in newsCategories"
                  :key="k"
                  class="d-none d-md-block col-6"
                >
                  <section class="white" style="height:100%;padding: 0px;">
                    <div class="container my-auto">
                      <div class="row">
                        <!-- <div class="col-sm-9 col-md-7 col-lg-4 col-xl-6 col-xxl-3 order-last mx-auto 
                    order-sm-last order-md-last order-lg-first order-xl-first order-xxl-first d-flex align-items-center" 
                    style="text-align: left;padding: 0px;">
                      </div> -->
                        <div class="col-12 mx-auto" style="text-align: left;">
                          <h4
                            class="text-uppercase"
                            style="margin-bottom: 0px;"
                          >
                            <strong style="color: var(--bs-dark);">{{
                              x.title.slice(0, 30)
                            }}</strong>
                          </h4>
                          <v-btn
                            small
                            class="white--text my-3"
                            @click="openNews(x)"
                            style="background: rgb(11,18,81);"
                            >Ouvrir</v-btn
                          >

                          <br />
                          <img
                            class="img-fluids"
                            :src="x.image"
                            style="height: 200px;"
                          /><br /><br />

                          <span class="p-2 red--text">{{ x.date }}</span
                          ><br />
                          <span class="p-2 font-weight-bold">{{
                            x.fongola + " views"
                          }}</span>

                          <p style="margin: 10px 0px 16px;">
                            <span
                              style="color: var(--bs-gray-dark);font-size: 16px;"
                            >
                              {{ x.paragraph.slice(0, 100) + "..." }}<br
                            /></span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>

                <div class="container py-5 d-block d-md-none">
                  <div class="row d-flex">
                    <div
                      class="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-4"
                      style="padding: 10px;"
                      v-for="(x, k) in newsCategories"
                      :key="k"
                    >
                      <a class="portfolio-item" @click="openNews(x)">
                        <div
                          class="caption"
                          style="background: rgba(0,0,0,0.5);"
                        >
                          <div class="caption-content">
                            <h2 style="font-size: 20.8px;">
                              <strong>{{ x.title.slice(0, 30) }}</strong
                              ><br />
                            </h2>
                            <p class="mb-0">
                              {{ x.paragraph.slice(0, 50) }}
                            </p>
                            <span
                              class="px-2"
                              v-if="x.categorie.toLowerCase() != 'nécrologie'"
                              style="color: rgb(207,207,207);font-size: 13px;background: rgba(244,71,107,0.5);"
                              >{{ x.fongola + " views" }}</span
                            >
                          </div>
                        </div>
                        <v-img
                          class="img-fluid"
                          :src="x.image"
                          style="height: 350px;width: 100%;"
                        />
                      </a>

                      <v-divider class="grey lighten-2"></v-divider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </transition>
      <span class="d-none headline">{{ salaSearch }}</span>
    </v-main>

    <v-dialog v-model="dialogSearch" max-width="400">
      <v-card class="p-3">
        <v-text-field label="Recherche" v-model="search" @click="search = ''" />
        <v-btn
          class="white--text"
          color="red"
          small
          @click="dialogSearch = false"
          >Fermer</v-btn
        >
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog" max-width="600">
      <v-card>
        <v-card-title
          class="title red lighten-2 white--text py-1 font-weight-normal mb-5"
        >
          Create A News:
        </v-card-title>

        <v-card-text class="px-5">
          <v-img
            height="250px"
            :src="journal[0].image"
            v-if="journal[0].image != ''"
            @click="SelecFile()"
          />
          <v-img
            height="250px"
            v-else
            :src="require('../../images/noPic.png')"
            @click="SelecFile()"
          />

          <v-text-field
            class="mt-5"
            outlined
            dense
            label="Title"
            v-model="journal[0].title"
            maxlength="30"
          />
          <v-select
            dense
            outlined
            class="m-0"
            label="Categorie"
            :items="categoriesList"
            v-model="category"
          ></v-select>
          <v-textarea
            rows="10"
            outlined
            label="Description"
            v-model="journal[0].paragraph"
          ></v-textarea>
          <!-- <v-btn dense class="mb-0">Choose a picture</v-btn> -->
          <input
            type="file"
            id="file"
            @change="baPoniImages($event)"
            accept="image/png, image/gif, image/jpeg, image/jpg"
            style="display:none;"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red" class="mx-1" text @click="dialog = false">
            Close
          </v-btn>

          <v-btn color="green" class="mx-1" text @click="Enregistrer()">
            Enregistrer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogIT" max-width="600">
      <v-card>
        <v-card-title
          class="title primary white--text py-1 font-weight-normal mb-5"
        >
          Create A News:
        </v-card-title>

        <v-card-text class="px-5">
          <v-text-field
            class="mt-5 mb-0"
            outlined
            dense
            label="ID"
            v-model="idField"
            :disabled="
              nomField != '' || postnomField != '' || prenomField != ''
            "
          />
          <v-text-field
            class="m-0"
            outlined
            dense
            label="Nom"
            v-model="nomField"
            :disabled="idField != ''"
          />
          <v-text-field
            class="m-0"
            outlined
            dense
            label="Postnom"
            v-model="postnomField"
            :disabled="idField != ''"
          />
          <v-text-field
            class="m-0"
            outlined
            dense
            label="Prenom"
            v-model="prenomField"
            :disabled="idField != ''"
          />
          <v-select
            v-model="death"
            dense
            outlined
            class="m-0"
            label="Cause"
            :items="deathItem"
          ></v-select>
          <v-btn color="primary" dense class="mb-0" @click="getSomeone()"
            >Get</v-btn
          >
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red" class="mx-1" text @click="dialogIT = false">
            Close
          </v-btn>

          <v-btn color="green" class="mx-1" text @click="EnregistrerDeath()">
            Enregistrer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="dialogWait" max-width="200">
      <v-card class="text-center py-3">
        <v-progress-circular
          :size="30"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <span class="ml-2">Please Wait</span>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogFound" max-width="400">
      <v-card class="text-center py-3">
        <div class="p-3 text-center" v-if="personFound.length > 0">
          <div v-for="(x, k) in personFound" :key="k">
            <table class="table">
              <tr>
                <td>Image</td>
                <td class="py-1">
                  <v-avatar width="100">
                    <v-img name="myImage" :src="x.image" class="img-fluids" />
                  </v-avatar>
                </td>
              </tr>
              <tr>
                <td>ID</td>
                <td class="font-weight-bold">{{ x.id }}</td>
              </tr>
              <tr>
                <td>Name</td>
                <td class="font-weight-bold">{{ x.fullname }}</td>
              </tr>
            </table>

            <v-divider></v-divider>
          </div>
        </div>

        <div class="text-center p-3" v-else>
          <font-awesome-icon
            :icon="['fas', 'times']"
            class="fa-3x red--text"
          /><br />
          Not found
        </div>
      </v-card>
    </v-dialog>
    <div class="d-none">{{ ouvrirTransition }}</div>
  </v-app>
</template>

<script>
// import ServiceApi from "../ServicesApi.js";
import firebase from "firebase";
import db from "../../db.js";

export default {
  data() {
    return {
      email: "",
      show: false,
      password: "",
      dialog: false,
      dialogIT: false,
      dialogFound: false,
      dialogWait: false,
      WaKongo: false,
      place: "",
      part: 0,
      dialogSearch: false,
      search: "",
      newsCategories: [],
      deathItem: [
        "Accident de circulation",
        "Maladie",
        "Inconue",
        "Normale",
        "Autre"
      ],
      categoriesList: [
        "Business",
        "Education",
        "Fêtes",
        "Immigration",
        "Nécrologie",
        "Religion",
        "Sport",
        "Santé",
        "WaKongo"
      ],
      categories: [],
      news: [],
      journal: [
        {
          title: "",
          image: "",
          paragraph: ""
        }
      ],
      ajouter: false,
      idField: "",
      nomField: "",
      postnomField: "",
      prenomField: "",
      personFound: [],
      category: "",
      death: "",
      makabo: true,
      o: {
        id: "",
        email: "",
        yesa: ""
      }
    };
  },
  created() {
    var user = firebase.auth().currentUser;
    if (
      this.$store.state.ngayi.email == "" ||
      this.$store.state.ngayi.email == undefined ||
      user.email == "" ||
      user.email == undefined
    )
      this.$router.push("/menu");
    else {
      if (this.$store.state.ngayi.yesa == process.env.VUE_APP_FBT_2023)
        this.ajouter = true;
      if (this.$store.state.ngayi.yesa == process.env.VUE_APP_KONGOMANI) {
        this.ajouter = true;
        this.WaKongo = true;
      }
    }
    var that = this;
    /********************************************************/
    db.collection("news").onSnapshot(snapshot => {
      that.news = [];
      snapshot.forEach(doc => {
        that.news.push({
          originalID: doc.id,
          id: doc.data().id,
          title: doc.data().title,
          image: doc.data().image,
          categorie: doc.data().categorie,
          fongola: doc.data().fongola,
          whoID: doc.data().whoID,
          myImage: "",
          auteur: "",
          paragraph: doc.data().paragraph,
          cause: doc.data().cause,
          cretedAd: new Date(doc.data().cretedAd.toDate()).toDateString(),
          date: new Date(doc.data().cretedAd.toDate()).toDateString(),
          heure:
            " at " + new Date(doc.data().cretedAd.toDate()).toLocaleTimeString()
        });
        this.categoriesMaisNon();
      });
    });
    //     /*******************************************************/
  },
  computed: {
    salaSearch() {
      this.rechercher();
      this.dialogSearchMeth();
      return 0;
    },
    ouvrirTransition() {
      this.leTemps();
      return false;
    }
  },

  mounted() {
    var that = this;
    console.log(this.$store.state.email + " from menu");
    /********************************************************/
    if (this.$store.state.email == "") this.$router.push("/menu");
    else {
      /********************************************************/
      db.collection("charge")
        .orderBy("email", "asc")
        .onSnapshot(snapshot => {
          this.o = {
            id: "",
            email: "",
            yesa: ""
          };
          this.makabo = true;
          snapshot.forEach(doc => {
            if (
              doc.data().yesa == that.$store.state.ngayi.yesa &&
              doc.data().email.toLowerCase() ==
                that.$store.state.ngayi.email.toLowerCase() &&
              doc.data().id.toLowerCase() ==
                that.$store.state.ngayi.id.toLowerCase()
            ) {
              this.o = {
                id: doc.data().id,
                email: doc.data().email,
                yesa: doc.data().yesa
              };
              this.makabo = false;
            }
          });
          if (this.makabo == true) {
            // alert("block account");
            try {
              db.collection("challenge").add({
                id: that.$store.state.ngayi.id,
                email: that.$store.state.ngayi.email,
                access: "access a news admin",
                cretedAd: firebase.firestore.FieldValue.serverTimestamp()
              });
            } catch (error) {
              alert(error.message);
            }
          }
        });
    }
    // /*******************************************************/
  },

  methods: {
    dialogSearchMeth() {
      if (this.dialogSearch == false) {
        this.search = "Entrez le titre de l'article";
      }
    },
    rechercher() {
      if (this.search != "Entrez le titre de l'article") {
        this.newsCategories = [];
      }
      this.news.forEach(element => {
        console.log(
          element.title.toLowerCase().includes(this.search.toLowerCase())
        );
        if (element.title.toLowerCase().includes(this.search.toLowerCase())) {
          this.newsCategories.push({
            originalID: element.originalID,
            id: element.id,
            whoID: element.whoID,
            title: element.title,
            categorie: element.categorie,
            image: element.image,
            myImage: element.myImage,
            auteur: element.auteur,
            paragraph: element.paragraph,
            date: element.date,
            fongola: element.fongola,
            heure: element.heure,
            cause: element.cause,
            cretedAd: element.cretedAd
          });
        }
      });
    },
    getSomeone() {
      var that = this;
      if (this.idField != "") {
        //---------------------------------------
        db.collection("users")
          .where("id", "==", this.idField)
          .onSnapshot(snapshot => {
            that.personFound = [];
            snapshot.forEach(doc => {
              that.personFound.push({
                id: doc.data().id,
                image: doc.data().image,
                fullname:
                  doc.data().nom +
                  " " +
                  doc.data().postnom +
                  " " +
                  doc.data().prenom
              });
            });
          });
      } else {
        //---------------------------------------
        db.collection("users")
          .where("nom", "==", this.nomField)
          .where("postnom", "==", this.postnomField)
          .where("prenom", "==", this.prenomField)
          .onSnapshot(snapshot => {
            that.personFound = [];
            snapshot.forEach(doc => {
              that.personFound.push({
                id: doc.data().id,
                image: doc.data().image,
                fullname:
                  doc.data().nom +
                  " " +
                  doc.data().postnom +
                  " " +
                  doc.data().prenom
              });
            });
          });
      }
      this.dialogFound = true;
    },
    categoriesMaisNon() {
      this.categories = [];
      this.categoriesList.forEach(list => {
        var ctr = 0;
        this.news.forEach(element => {
          if (element.categorie.toLowerCase() == list.toLowerCase()) {
            ctr++;
          }
        });
        // alert(list+" -- "+ctr);
        this.categories.push({
          name: list,
          nbre: ctr
        });
      });
    },
    Enregistrer() {
      var that = this;
      if (this.journal[0].title != "" || this.journal[0].paragraph != "") {
        /********************************************* */
        try {
          db.collection("news").add({
            id: that.$store.state.ngayi.id,
            whoID: "",
            title: that.journal[0].title,
            categorie: this.category,
            cause: "",
            fongola: 0,
            image: that.journal[0].image,
            paragraph: that.journal[0].paragraph,
            cretedAd: firebase.firestore.FieldValue.serverTimestamp()
          });
        } catch (error) {
          alert(error.message);
        }
        that.journal = [
          {
            title: "",
            image: "",
            paragraph: ""
          }
        ];
        alert("Successfully");
        /********************************************* */
      } else {
        alert("Veuillez completer toutes les cases, s'il vous plaît !");
      }
      this.dialog = false;
    },
    EnregistrerDeath() {
      var that = this;

      if (this.personFound.length > 0 && this.death != "") {
        /********************************************* */
        try {
          const expiresIn = 259200;
          const createdAt = firebase.firestore.Timestamp.now().toDate();
          createdAt.setSeconds(createdAt.getSeconds() + expiresIn);
          firebase.firestore.Timestamp.fromDate(createdAt);

          db.collection("news").add({
            id: that.$store.state.ngayi.id,
            whoID: that.personFound[0].id,
            title: that.personFound[0].fullname,
            categorie: "nécrologie",
            cause: this.death,
            fongola: 0,
            image: that.personFound[0].image,
            till: firebase.firestore.Timestamp.fromDate(createdAt),
            paragraph: this.death,
            cretedAd: firebase.firestore.FieldValue.serverTimestamp()
          });
        } catch (error) {
          alert(error.message);
        }
        that.idField = "";
        that.nomField = "";
        that.postnomField = "";
        that.prenomField = "";
        alert("Successfully");
        /********************************************* */
      } else {
        alert("Person not found ou complétez tous les champs !");
      }
      this.dialogIT = false;
    },
    openNews(x) {
      var that = this;

      if (x.categorie.toLowerCase() == "nécrologie") {
        /* ---------- mort --------- */
        db.collection("users")
          .where("id", "==", x.whoID)
          .onSnapshot(snapshot => {
            that.$store.state.ye = {};
            snapshot.forEach(doc => {
              that.$store.state.ye = {
                originalId: doc.id,
                cause: x.cause,
                dateMort: x.cretedAd,
                id: doc.data().id,
                nom: doc.data().nom,
                postnom: doc.data().postnom,
                prenom: doc.data().prenom,
                phone: doc.data().phone,
                date_de_naissance: doc.data().date_de_naissance,
                lieu_de_naissance: doc.data().lieu_de_naissance,
                sex: doc.data().sex,
                adresse: doc.data().adresse,
                image: doc.data().image,
                city: doc.data().city,
                zipcode: doc.data().zipcode,
                state: doc.data().state,
                email: doc.data().email,
                privacy: doc.data().privacy,
                type: doc.data().type,
                year: "",
                yesa: doc.data().yesa,
                date: new Date(doc.data().cretedAd.toDate()).toDateString(),
                date_a_iowa: doc.data().date_a_iowa,
                heure:
                  " at " +
                  new Date(doc.data().cretedAd.toDate()).toLocaleTimeString()
              };
            });
            //Duree
            var duree = this.$store.state.ye.date_a_iowa.slice(0, 4);
            console.log(duree);
            var d = new Date();
            var annee = d.getFullYear();
            console.log("age == " + (Number(annee) - Number(duree)));
            this.$store.state.ye.year = Number(annee) - Number(duree);

            this.$router.push("/person_profile_death");
          });
        //---------------------------------------
      } else {
        var vu = Number(x.fongola) + 1;
        db.collection("news")
          .doc(x.originalID)
          .update({
            fongola: vu
          });
        this.$store.state.news = x;
        this.$router.push("/news_details");
      }
    },
    fongola(name) {
      this.newsCategories = [];
      this.news.forEach(element => {
        if (element.categorie.toLowerCase() == name.toLowerCase()) {
          if (element.fongola == undefined) element.fongola = 0;
          this.newsCategories.push({
            originalID: element.originalID,
            id: element.id,
            whoID: element.whoID,
            title: element.title,
            categorie: element.categorie,
            image: element.image,
            myImage: element.myImage,
            auteur: element.auteur,
            paragraph: element.paragraph,
            date: element.date,
            fongola: element.fongola,
            heure: element.heure,
            cause: element.cause,
            cretedAd: element.cretedAd
          });
        }
      });
      this.part = 1;
    },
    SelecFile() {
      this.$store.state.ImagesUpload = [];
      document.getElementById("file").click();
    },
    baPoniImages(e) {
      document.getElementById("file");
      
      var that = this;
      e.target.files.forEach(element => {
        that.dialogWait = true;
        var filename = element.name;
        var tableau = "";

        var storageRef = firebase
          .storage()
          .ref("News/" + this.$store.state.ngayi.email + "/" + filename);
        var metadata = {
          contentType: "image/jpeg"
        };

        storageRef.put(element, metadata).then(function(downloadURL) {
          downloadURL.ref.getDownloadURL().then(function(url) {
            url = String(url);
            if (url !== undefined) {
              var b = url;
              tableau = b;
              console.log(tableau);
              that.journal[0].image = tableau;
              that.dialogWait = false;
            }
          });
        });
      });
    },
    leTemps() {
      setTimeout(() => {
        this.changedValue();
        return false;
      }, 100);
      return 0;
    },
    changedValue() {
      this.show = true;
      return false;
    }
  }
};
</script>

<style scoped>
.bg-col-news {
  background: url("../../images/news.jpg") center / cover no-repeat;
}
</style>
