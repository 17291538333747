<template>
    <div class="bg-secondary d-flex align-items-center justify-center" style="min-height: 100vh;">
        <v-row class="pt-5" justify="center">

            <v-col cols="12" lg="8" class="white">
                <v-progress-linear v-model="knowledge" color="primary darken-4" height="35">
                    <strong class="white--text">{{knowledge}}%</strong>
                </v-progress-linear>
            </v-col>

            <v-col cols="12" lg="8" class="white d-flex align-items-center justify-center p-0" style="height:150px;">

                <div class="d-flex align-items-center justify-center flex-column p-0 w-100" style="height:100%">
                    <h3 style="color: rgba(0,0,0,.79);" class="text-center">
                        Delivery address?
                    </h3>
                    <span>Adresse de livraison</span>
                </div>
            </v-col>

            <v-col cols="12" lg="8" class="bg px-5 white">
                <v-card height="100%" class="bg-transparent elevation-0 pb-5 px-5">
                    <v-simple-table>
                        <template v-slot:default>
                            <tbody>
                                <!-- Address -->
                                <tr>
                                    <td class="py-2 black--text">Address:<br /><span
                                            class="grey--text">(Addresse)</span></td>
                                    <td>
                                        <v-textarea v-model="adresse" label="Address"></v-textarea>
                                    </td>
                                </tr>
                                <!-- Zip code -->
                                <tr>
                                    <td class="py-2 black--text">Zip Codde:<br /><span class="grey--text">(Code postal)</span>
                                    </td>
                                    <td>
                                        <v-text-field label="Zip code" v-model="zipcode" />
                                    </td>
                                </tr>
                                <!-- City -->
                                <tr>
                                    <td class="py-2 black--text">City:<br /><span class="grey--text">(Ville)</span></td>
                                    <td>
                                        <v-select :items="cities" label="Cities" v-model="city" />
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>

                    <div class="pt-5">
                    </div>
                    <div class="text-center">
                        <v-btn large class="m-1" color="red white--text" href="#/setMyAccount">Retour</v-btn>
                        <v-btn large class="m-1" color="success" @click="postUsers()">Pay Now</v-btn>

                    </div>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import db from "../../db.js";

    export default {
        data() {
            return {
                knowledge: 97,
                prenom: "",
                postnom: "",
                nom: "",
                phone: "",
                email: "",
                city: "",
                sex: "",
                cities: [
                    "Cedar Rapids",
                    "Coralville",
                    "Iowa City",
                    "North Liberty",
                    "Tiffin",
                    "Autres"
                ],
                adresse: "",
                zipcode: ""
            }
        },
        created() {
            this.setUp();
            setTimeout(() => {
                this.setUp();
            }, 2000);
        },
        methods: {
            setUp() {
                this.city = this.$store.state.ngayi.city;
                this.adresse = this.$store.state.ngayi.adresse;
                this.zipcode = this.$store.state.ngayi.zipcode;
            },
            postUsers() {
                var that = this;
                db.collection("users")
                    .doc(that.$store.state.ngayi.originalId)
                    .update({
                        adresse: that.adresse,
                        zipcode: that.zipcode,
                        city: that.city
                    });
                window.open("https://pay.wakongo.com/comco-member-card");
                
            }

        }
    }
</script>

<style>

</style>