<template>
  <!-- App.vue -->
  <v-app>
    <!-- 
    <v-btn @click="getDataBase_value()">database</v-btn>
    <h1 class="red--text">{{laValeur}}</h1> -->

    <!-- Sizes your content based upon application components -->
    <v-main class="bg-blue p-0">
      <div class="p-0 m-0" style="background: rgba(0,0,0,.79);height: 100%;">
        <!-- <a href='mailto:person1@domain.com,person2@domain.com?subject="papa' class="display-4">Email Us</a> -->
        <!-- <span class="p-3 red white--text">{{$store.state.death}}</span> -->
        <!-- <v-btn @click="FongolaDeathDialog(true)">CLick</v-btn> -->

        <router-view></router-view>
        <!-- <iframe height="100%" width="100%" src="https://poynt.godaddy.com/checkout/c1731c11-3f51-4201-a93f-b77e1fbf5dab/Starting-fee?fbclid=IwAR3uON0k5BGs2ApnoWLmvfrDR5OmTr6GF55uSAmF-75zjbtV2OkiSxpH9mY"
        ></iframe> -->
      </div>
    </v-main>

    <v-snackbar v-model="$store.state.snackBar" color="primary">
      {{ $store.state.snackBarTitle }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="$store.state.snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="$store.state.dialogErreur" max-width="450">
      <v-card>
        <v-card-title class="title red--text font-weight-normal">
          Error:
        </v-card-title>

        <v-card-text>
          <h1 class="title black--text">
            {{ $store.state.erreur }}
          </h1>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            small
            color="red"
            text
            @click="$store.state.dialogErreur = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="$store.state.dialogQuestion" max-width="450">
      <v-card>
        <v-card-title class="title warning--text font-weight-normal">
          Warning:
        </v-card-title>

        <v-card-text>
          <h1 class="title black--text">
            {{ $store.state.question }}
          </h1>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            small
            color="red"
            text
            @click="
              $store.state.dialogAnswer = 'non';
              $store.state.dialogQuestion = false;
            "
          >
            Cancel
          </v-btn>

          <v-btn
            small
            color="green"
            text
            @click="
              $store.state.dialogAnswer = 'yes';
              $store.state.dialogQuestion = false;
            "
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogChat" persistent max-width="100%">
      <v-card class="vh-100">
        <v-card-title class="title red--text font-weight-normal">
          Error:
        </v-card-title>

        <v-divider></v-divider>
        <v-card-text>
          <div class="text-center">
            <img
              :src="require('./images/myLogo.png')"
              class="w-25"
            /><br /><br />
            <h5 class="font-weight-bold">
              Votre compte a été bloquer à cause des activités illégales.
            </h5>
            <br />
            <v-btn href="tel:3195193008" color="red" class="white--text">
              Contacter le consultant
            </v-btn>
            <v-divider></v-divider>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="$store.state.dialogDeath" persistent max-width="600px">
      <v-card>
        <v-card-title class="title red--text font-weight-normal">
          Communiqué Nécrologique:
        </v-card-title>

        <v-divider></v-divider>
        <v-card-text>
          <v-row justify="center">
            <v-col cols="12">
              <h4>
                La Communauté Congolaise de Iowa dans les comtés de Johnson et
                Linn a la profonde douleur d'annoncer aux amis et connaissances
                la mort de:
              </h4>
            </v-col>

            <v-col cols="12">
              <v-simple-table class="bg-transparent">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Image
                      </th>
                      <th class="text-left">
                        Noms
                      </th>
                      <th class="text-left">
                        Cause
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(x, k) in $store.state.death"
                      :key="k"
                      @click="openPeople(x.id)"
                    >
                      <td>
                        <v-avatar>
                          <img :src="x.image" />
                        </v-avatar>
                      </td>
                      <td class="py-5">
                        <h6 class="font-weight-bold">
                          {{ x.title }}
                        </h6>
                      </td>
                      <td>
                        <h6 class="font-weight-normal">
                          {{ x.cause }}
                        </h6>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <div class="w-100 text-right p-3">
            <v-btn
              small
              color="red"
              class="white--text"
              @click="$store.state.dialogDeath = false"
              >Fermer</v-btn
            >
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <span class="d-none">{{ chatNotification }}</span>
    <span class="d-none">{{ deathNotification }}</span>
  </v-app>
</template>

<script>
import db from "./db.js";
import firebase from "firebase";
import sound from "./sounds/wrong.wav";

export default {
  data: () => ({
    items: [],
    family: [],
    death: [],
    dialogChat: false,
    audio: null
  }),
  computed: {
    chatNotification() {
      if (this.$store.state.salange.length > 0) this.FongolaDialog(true);
      else this.FongolaDialog(false);
      return 0;
    },
    deathNotification() {
      if (this.$store.state.death.length > 0) this.FongolaDeathDialog(true);
      else this.FongolaDeathDialog(false);
      return 0;
    }
  },
  created() {
    this.requestMethod();
    this.audio = new Audio(sound);
    // this.chatNotifications();
    this.$store.state.part = this.getCookie("pro");
    this.$store.state.email = this.getCookie("mutuYango");
    this.$store.state.psw = this.getCookie("codeYango");
    console.log("== " + this.$store.state.email);
    console.log("== " + this.$store.state.psw);
    
      // alert(this.$store.state.email);
      // if (this.$store.state.email == "" || this.$store.state.email == undefined || this.$store.state.email == "undefined") {
      //     this.$router.push("/login");
      // }else{
      //   // alert("here");
      //     this.$router.push("/menu");
      // }

    // this.login(); // OpenLater
  },
  methods: {
    FongolaDialog(value) {
      this.dialogChat = value;
    },
    FongolaDeathDialog(value) {
      if (value == true) {
        this.audio.play();
      }
      setTimeout(() => {
        this.$store.state.dialogDeath = value;
      }, 2000);
    },
    requestMethod() {
      try {
        db.collection("Request").onSnapshot(snapshot => {
          snapshot.forEach(doc => {
            /*************************** */
            console.log(doc);
            /************************** */
          });
        });
      } catch (error) {
        console.log(error);
      }
    },
    login() {
      var that = this;
      // setTimeout(() => {
      if (this.$store.state.email != "" || this.$store.state.psw != "") {
        this.challenge();
        // alert("success");
        firebase
          .auth()
          .signInWithEmailAndPassword(
            that.$store.state.email,
            that.$store.state.psw
          )
          .then(
            elt => {
              console.log(elt.user.email);
              this.deathNotifications();
              // this.getFamilyRequest();
              try {
                that.$router.push("/menu");
              } catch (error) {
                console.log(error.message);
              }
            },
            error => {
              alert(error.message);
              // that.$router.push("/login");
            }
          );
      } else {
        // alert("nope");

        console.log("vide");
        that.$router.push("/login");
      }
      // }, 5000);
    },
    getCookie(cname) {
      var name = cname + "=";
      var ca = document.cookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    /*******************************************************/
    challenge() {
      var that = this;
      /********************************************************/
      db.collection("challenge")
        .where("email", "==", that.$store.state.email)
        .onSnapshot(snapshot => {
          that.$store.state.salange = [];
          snapshot.forEach(doc => {
            that.$store.state.salange = [
              {
                id: doc.data().id,
                email: doc.data().email,
                cretedAd: doc.data().cretedAd
              }
            ];
          });

          // alert(this.$store.state.salange.length + ' --');
        });
    },
    chatNotifications() {
      var that = this;
      try {
        db.collection("chatNotifications")
          .where("email", "==", that.$store.state.email)
          .onSnapshot(snapshot => {
            that.$store.state.chatNotifications = [];
            snapshot.forEach(doc => {
              /*************************** */
              that.$store.state.chatNotifications = [
                {
                  id: doc.data().id,
                  email: doc.data().email,
                  message: doc.data().message,
                  ladate: doc.data().ladate,
                  cretedAd: doc.data().cretedAd
                }
              ];
              /************************** */
            });
          });
      } catch (error) {
        console.log(error);
      }
    },
    deathNotifications() {
      var that = this;
      var ladate = new Date().toISOString();
      try {
        db.collection("news")
          .where("categorie", "==", "nécrologie")
          .onSnapshot(snapshot => {
            that.$store.state.death = [];
            snapshot.forEach(doc => {
              /*************************** */
              var till = new Date(doc.data().till.toDate())
                .toISOString()
                .slice(0, 10);
              console.log("-------------------------------------------------");
              if (till >= ladate.slice(0, 10)) {
                console.log(doc.data().title);
                that.$store.state.death.push({
                  id: doc.data().whoID,
                  title: doc.data().title,
                  cause: doc.data().cause,
                  image: doc.data().image,
                  till: till.slice(0, 10),
                  cretedAd: new Date(doc.data().cretedAd.toDate()).toISOString()
                });
              }
              /************************** */
            });
          });
      } catch (error) {
        console.log(error);
      }
    },
    openPeople(id) {
      var that = this;
      /* ---------- mort --------- */
      db.collection("users")
        .where("id", "==", id)
        .onSnapshot(snapshot => {
          that.$store.state.ye = {};
          snapshot.forEach(doc => {
            that.$store.state.ye = {
              originalId: doc.id,
              id: doc.data().id,
              nom: doc.data().nom,
              postnom: doc.data().postnom,
              prenom: doc.data().prenom,
              phone: doc.data().phone,
              date_de_naissance: doc.data().date_de_naissance,
              lieu_de_naissance: doc.data().lieu_de_naissance,
              sex: doc.data().sex,
              adresse: doc.data().adresse,
              image: doc.data().image,
              city: doc.data().city,
              zipcode: doc.data().zipcode,
              state: doc.data().state,
              email: doc.data().email,
              privacy: doc.data().privacy,
              type: doc.data().type,
              year: "",
              yesa: doc.data().yesa,
              date: new Date(doc.data().cretedAd.toDate()).toDateString(),
              date_a_iowa: doc.data().date_a_iowa,
              heure:
                " at " +
                new Date(doc.data().cretedAd.toDate()).toLocaleTimeString()
            };
          });
          //Duree
          var duree = this.$store.state.ye.date_a_iowa.slice(0, 4);
          console.log(duree);
          var d = new Date();
          var annee = d.getFullYear();
          console.log("age == " + (Number(annee) - Number(duree)));
          this.$store.state.ye.year = Number(annee) - Number(duree);

          this.$store.state.dialogDeath = false;
          this.$router.push("/person_profile_death");
        });
      //---------------------------------------
    }
  }
};
</script>

<style lang="scss">
.bg {
  background-color: rgb(35, 36, 39);
}
.bg-blue {
  /* background: #000; */
  background: #2962ff;
}
.subg {
  background-color: #444;
}
.titre {
  color: rgb(236, 236, 236);
}
.subtitre {
  color: rgb(212, 212, 212);
}
.couleur {
  color: #2962ff;
}
.titpurple {
  color: #00e396;
}
.erreur {
  color: #ff5252;
}

.bg-col-image {
  background: url("./images/pexels-eberhard-grossgasteiger-2098427.jpg") center /
    cover no-repeat;
}
.slide-fade-enter-active {
  transition: all 0.2s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(200px);
  opacity: 0;
}
</style>
