<template>
  <v-app>
    <v-main class="bg-blue">
      <div style="height: 100vh;">
        <div
          class="d-flex flex-column justify-content-around align-items-center"
          style="background-color: rgba(0,0,0,.79);height: 100%;"
        >
          <div class="container text-center"></div>
          <div class="container text-center p-0">
            <img :src="require('../images/myLogo.png')" class="w-25" />
          </div>
          <div class="container text-center p-0">
            <h3 class="display-1 white--text">waKongo</h3>
            <h3 class="overline white--text">version 1.0</h3>
          </div>
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "Home",
  mounted() {
    // this.$router.push("/login");
  }
};
</script>
