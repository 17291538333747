<template>
  <!-- App.vue -->
  <v-app>
    <v-app-bar class="elevation-0 white" app>
      <v-btn text color="white" onclick="window.history.back()">
        <font-awesome-icon
          :icon="['fas', 'chevron-left']"
          class="fa-x black--text mr-2"
        />
      </v-btn>
      <v-spacer></v-spacer>
      <!-- <h4 class="black--text">My Request</h4> -->
      <v-spacer></v-spacer>
      <v-btn icon @click="dialog = true">
        <font-awesome-icon :icon="['fas', 'plus']" class="fa-x black--text" />
      </v-btn>
      <!-- <v-btn icon @click="postDocuments()">
        <font-awesome-icon :icon="['fas', 'save']" class="fa-x black--text" />
      </v-btn> -->
    </v-app-bar>
    <!-- Sizes your content based upon application components -->

    <v-main class="main p-0 py-5" style="background-color:rgb(235, 236, 239)">
      <transition name="slide-fade">
        <div v-if="show">
          <section class="bg-col-image">
            <div
              style="height: 100%;width: 100%;background: rgba(11,18,81,0.66);padding: 80px 0px 60px;"
            >
              <div class="container py-0">
                <div class="row">
                  <div
                    class="col-12 d-flex justify-content-center align-items-center flex-column"
                    style="text-align: center;"
                  >
                    <h1
                      class="white--text display-1 font-weight-bold pt-5 text-uppercase"
                    >
                      Mes besoins
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div class="container py-5">
            <!-- <span class="black--text">{{ documentsPick }}</span>
        <span class="red--text">{{ assistance }}</span> -->
            <v-row justify="center">
              <!-- <v-col class="w-100 text-center">
            <v-avatar size="150">
              <v-img :src="$store.state.ngayi.image" class="img-fluids" /> </v-avatar
            ><br />
            <h5 class="mt-2 subblack--text">{{ $store.state.ngayi.email }}</h5>
            <v-divider class="grey"></v-divider>
          </v-col> -->

              <v-col cols="12" class="py-2 px-5">
                <v-simple-table class="bg-transparent">
                  <template v-slot:default>
                    <tbody>
                      <!-- Prénom -->
                      <tr
                        v-for="(x, k) in documentsPick"
                        :key="k"
                        @dblclick="cancelProfile(x.id, 'job')"
                      >
                        <td class="py-2"><h3>{{ x.name }}</h3></td>
                        <!-- <td class="py-2 text-right">
                          {{ "($" + x.price + ")" }}
                        </td> -->
                        <td>
                          <v-btn
                            icon
                            @click="
                              dialogQuestion = true;
                              pick = x.name;
                            "
                          >
                            <font-awesome-icon
                              :icon="['fas', 'trash']"
                              class="fa-2x red--text"
                            />
                          </v-btn>
                        </td>
                      </tr>
                      <!-- <tr>
                        <td class="title black--text"><b>Total</b></td>
                        <td class="title black--text text-right">
                          <b>{{ "$" + total }}</b>
                        </td>
                      </tr> -->
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </div>
        </div>
      </transition>
    </v-main>

    <v-dialog persistent v-model="dialogWait" max-width="200">
      <v-card class="text-center py-3">
        <v-progress-circular
          :size="30"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <span class="ml-2">Please Wait</span>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="dialogQuestion" max-width="400">
      <v-card class="text-center py-3">
        <h6 class="py-5">Voulez-vous supprimer {{ pick }} ?</h6>
        <v-btn small color="success" class="elevation-0 m-2" @click="quiter()"
          >Oui</v-btn
        >
        <v-btn
          small
          color="red white--text"
          class="elevation-0 m-2"
          @click="dialogQuestion = false"
          >Non</v-btn
        >
      </v-card>
    </v-dialog>

    <!-- Documents -->
    <v-dialog persistents v-model="dialog" max-width="800">
      <v-card class="text-center py-3">
        <h5 class="text-center">What assistance do you need?</h5>
        <v-card-text>
          <div>
            <v-list shaped>
              <v-list-item-group v-model="documentsPick" multiple>
                <template v-for="(item, i) in mydocuments">
                  <v-divider v-if="!item" :key="`divider-${i}`"></v-divider>

                  <v-list-item
                    v-else
                    :key="`item-${i}`"
                    :value="item"
                    active-class="primary white--text"
                  >
                    <template v-slot:default="{ active }">
                      <v-list-item-content>
                        <v-list-item-title class="h4"
                          v-text="item.name"
                        ></v-list-item-title>
                      </v-list-item-content>

                      <!-- <v-list-item-content>
                        <v-list-item-title
                          v-text="'$' + item.price"
                        ></v-list-item-title>
                      </v-list-item-content> -->

                      <v-list-item-action>
                        <v-checkbox
                          :input-value="active"
                          color="primary darken-2"
                        ></v-checkbox>
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                </template>
              </v-list-item-group>
            </v-list>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn small color="red" text @click="dialog = false">
            Fermer
          </v-btn>
          <v-btn small color="green" text @click="postDocuments()">
            Ajouter
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="d-none">{{ ouvrirTransition }}</div>
  </v-app>
</template>

<script>
// import ServiceApi from "../ServicesApi.js";
import firebase from "firebase";
import db from "../../db.js";

export default {
  data() {
    return {
      email: "kerene@example.com",
      show: false,
      password: "plus1234",
      dialogWait: false,
      dialog: false,
      dialogQuestion: false,
      documentsPick: [],
      pick: "",
      mydocuments: [
        {
          name: "Green Card",
          price: 0
        },
        {
          name: "ID Card",
          price: 0
        },
        {
          name: "Driver License",
          price: 0
        },
        {
          name: "Social Security",
          price: 0
        },
        {
          name: "Work Permit",
          price: 0
        },
        {
          name: "Passport",
          price: 0
        },
        {
          name: "Lease",
          price: 0
        },
        {
          name: "Refugee",
          price: 0
        },
        {
          name: "Host Family",
          price: 0
        },
        {
          name: "Job application",
          price: 0
        },
        {
          name: "Translate",
          price: 0
        },
        {
          name: "Food Stamps",
          price: 0
        },
        {
          name: "School",
          price: 0
        },
        {
          name: "Health",
          price: 0
        },
        {
          name: "Assurances",
          price: 0
        },
        {
          name: "Police",
          price: 0
        }
      ],
      tab: ["1", "2", "3", "4", "5"],

      place: "",
      total: 0,
      montre: 1,
      assistance: [],
      switch: false,
      nani: {
        fullname: "=",
        src: "",
        email: "",
        phone: "",
        request: []
      },
      assistanceID: ""
    };
  },
  created() {
    var user = firebase.auth().currentUser;
    if (
      this.$store.state.ngayi.email == "" ||
      this.$store.state.ngayi.email == undefined ||
      user.email == "" ||
      user.email == undefined
    )
      this.$router.push("/menu");
  },
  mounted() {
    var that = this;
    /***************************************/
    that.documentsPick = [];
    db.collection("assistance")
      .where("id", "==", that.$store.state.ngayi.id)
      .onSnapshot(snapshot => {
        that.assistance = [];
        snapshot.forEach(doc => {
          // if (doc.data().id == this.$store.state.ngayi.id) {
          that.assistanceID = doc.id;
          that.assistance.push({
            originalId: doc.id,
            id: doc.data().id,
            fullname: doc.data().fullname,
            phone: doc.data().phone,
            email: doc.data().email,
            request: doc.data().request
          });
          that.documentsPick = doc.data().request;
          // }
        });
        that.calculerTotal();
      });
    /***************************************/
  },
  methods: {
    calculerTotal() {
      this.total = 0;
      this.documentsPick.forEach(element => {
        this.total = -(-this.total - Number(element.price));
      });
    },
    postDocuments() {
      try {
        db.collection("assistance")
          .doc(this.assistanceID)
          .delete();
      } catch (error) {
        console.log(error.message);
      }

      var that = this;
      /****************************************************/
      db.collection("assistance").add({
        fullname:
          that.$store.state.ngayi.nom +
          " " +
          that.$store.state.ngayi.postnom +
          " " +
          that.$store.state.ngayi.prenom,
        email: that.$store.state.ngayi.email,
        phone: that.$store.state.ngayi.phone,
        id: that.$store.state.ngayi.id,
        image: that.$store.state.ngayi.image,
        request: that.documentsPick,
        cretedAd: firebase.firestore.FieldValue.serverTimestamp()
      });
      that.dialog = false;
      /****************************************************/
    },
    quiter() {
      var tab = this.documentsPick;
      for (let i = 0; i < this.documentsPick.length; i++) {
        var element = this.documentsPick[i];
        console.log(element.name + "==" + this.pick);
        console.log(element.name == this.pick);
        if (element.name == this.pick) {
          console.log("---" + i + "---");
          console.log("before");
          console.log(tab);
          this.documentsPick.splice(i, 1);
          console.log("after");
          console.log(tab);
        }
      }
      this.documentsPick = tab;
      this.dialogQuestion = false;
      this.postDocuments();
    },
    leTemps() {
      setTimeout(() => {
        this.changedValue();
        return false;
      }, 100);
      return 0;
    },
    changedValue() {
      this.show = true;
      return false;
    }
  },
  computed: {
    ouvrirTransition() {
      this.leTemps();
      return false;
    }
  }
};
</script>
