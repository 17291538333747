<template>
  <!-- App.vue -->
  <v-app>
    <v-app-bar class="elevation-0" app style="background-color:rgb(35, 36, 39)">
      <v-btn text color="white" onclick="window.history.back()">
        <font-awesome-icon
          :icon="['fas', 'arrow-left']"
          class="fa-x white--text mr-2"
        />
        retour
      </v-btn>
    </v-app-bar>
    <!-- Sizes your content based upon application components -->

    <v-main class="p-0 bg">
      <transition name="slide-fade">
        <div v-if="show">
          <section
            id="portfolio"
            class="content-section"
            style="padding: 10px 0px;"
          >
            <div class="container py-5">
              <!-- {{videos}} -->
              <div
                class="row p-0 py-5 d-flex justify-content-center justify-content-sm-center justify-content-md-center justify-content-lg-start justify-content-xl-start justify-content-xxl-start"
              >
                <!-- Title -->
                <div class="col-12 text-center">
                  <h2 class="display-2 d-none d-md-block titre">
                    {{ videos.title }}
                  </h2>
                  <h2 class="display-1 d-block d-md-none titre">
                    {{ videos.title }}
                  </h2>
                  <span class="overline subtitre">{{ videos.date }}</span
                  ><br />
                  <v-btn
                    small
                    color="red darken-4"
                    class="white--text"
                    :href="'https://www.youtube.com/embed/' + videos.video"
                    target="_blank"
                  >
                    <font-awesome-icon
                      :icon="['fab', 'youtube']"
                      class="fa-x white--text mr-2"
                    />Open on youtube
                  </v-btn>
                </div>

                <!-- Video -->
                <div class="col-12 text-left my-5 w-100">
                  <iframe
                    width="100%"
                    height="350"
                    :src="'https://www.youtube.com/embed/' + videos.video"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowfullscreen
                  ></iframe>
                </div>

                <!-- Paragraph -->
                <div class="col-12 text-left my-5">
                  <p
                    class="subtitle pl-5 subtitre"
                    style="white-space: pre-line"
                  >
                    {{ videos.paragraph }}
                  </p>
                </div>
              </div>
            </div>
          </section>

          <!-- container -->
          <div class="container">
            <div class="mb-2 px-5">
              <span class="title subtitre">{{ "By " + videos.auteur }}</span>
            </div>
            <div v-if="videos.myImage">
              <img
                class="img-fluid px-5"
                :src="videos.myImage"
                style="height:200px"
              />
            </div>
          </div>
        </div>
      </transition>
    </v-main>

    <v-dialog persistent v-model="dialogWait" max-width="200">
      <v-card class="text-center py-3">
        <v-progress-circular
          :size="30"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <span class="ml-2">Please Wait</span>
      </v-card>
    </v-dialog>
    <div class="d-none">{{ ouvrirTransition }}</div>
  </v-app>
</template>

<script>
import db from "../../db.js";
import firebase from "firebase";

export default {
  data() {
    return {
      email: "kerene@example.com",
      show: false,
      password: "plus1234",
      dialog: false,
      place: "",
      videos: [],
      dialogWait: true
    };
  },
  created() {
    var user = firebase.auth().currentUser;
    if (
      this.$store.state.ngayi.email == "" ||
      this.$store.state.ngayi.email == undefined ||
      user.email == "" ||
      user.email == undefined
    )
      this.$router.push("/menu");

    setTimeout(() => {
      this.dialogWait = false;
    }, 3000);

    this.videos = this.$store.state.videos;
    var auteur = this.$store.state.videos.id;
    db.collection("users").onSnapshot(snapshot => {
      snapshot.forEach(doc => {
        if (doc.data().id == auteur) {
          console.log(doc.data().id == auteur);
          this.videos.myImage = doc.data().image;
          this.videos.auteur =
            doc.data().nom + " " + doc.data().postnom + " " + doc.data().prenom;
        }
      });
    });
  },
  methods: {
    leTemps() {
      setTimeout(() => {
        this.changedValue();
        return false;
      }, 100);
      return 0;
    },
    changedValue() {
      this.show = true;
      return false;
    }
  },
  computed: {
    ouvrirTransition() {
      this.leTemps();
      return false;
    }
  }
};
</script>
