<template>
  <!-- App.vue -->
  <v-app>
    <v-main class="bg-blues bg-img p-0">
      <transition name="slide-fade">
        <div v-if="show">
          <div class="bg-blue" style="height: 100vh;" v-if="part == 0">
            <div class="d-flex flex-column justify-content-around align-items-center"
              style="background-color: rgba(0,0,0,.79);height: 100%;">
              <div class="container text-center"></div>
              <div class="container text-center p-0 d-none d-md-block w-100">
                <img :src="require('../../images/myLogo.png')" class="w-25" />
              </div>
              <div class="container text-center p-0 d-block d-md-none w-100">
                <img :src="require('../../images/myLogo.png')" class="w-50" />
              </div>
              <div class="container text-center p-0">
                <h3 class="display-1 white--text">waKongo</h3>
                <h3 class="overline white--text">version 1.0</h3>
              </div>
            </div>
          </div>

          <!-- Mobile -->
          <div v-else class="main bg-blue p-0" style="background: rgba(0,0,0,.79);height: 100%;">
            <!-- d-flex flex-column justify-content-around align-items-center -->
            <section style="height: 100vh;">
              <div class="row p-0" style="height: 100%;">
                <div class="col-12 col-lg-8 bg-col-img p-0 d-none d-md-block" data-aos="slide-right"
                  data-aos-duration="1000" data-aos-delay="1000">
                  <div class="d-flex flex-column justify-content-center align-items-start"
                    style="height: 100%;width: 100%;background: rgba(11,18,81,0.66);padding: 0px 25px;">
                    <div class="container">
                      <h1 style="font-size: 61px;font-family: Amethysta, serif;color: rgb(255,255,255);">
                        Ensemble on change tout !
                      </h1>
                      <p class="d-md-none d-lg-flex" style="color: rgb(224,224,224);font-size: 22px;">
                        <!-- <br />Jeunes retraités actifs ou centenaires dépendants,
                        la Ville doit pouvoir répondre à leurs besoins de
                        loisirs, d’aide sociale, de santé ou de logement. Aux
                        Sorinières, des services pour les aînés existent, mais
                        n’est-il pas temps de passer à la vitesse supérieure
                        ?<br /><br /> -->
                      </p>

                      <a class="btn btn-primary btn-xl" role="button" href="tel:319-519-3008"
                        style="background: white;padding: 10px 20px;color: rgb(11,18,81);font-size: 20px;">Contactez le comité directeur
                        <font-awesome-icon :icon="['fas', 'arrow-right']" class="fa-x" style="color: rgb(11,18,81);" />
                        </a>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-md-12 col-lg-4 p-0 bg-blue d-flex" data-aos="slide-left" data-aos-duration="1000"
                  data-aos-delay="1000" style="text-align: center;">
                  <div class="menu" style="background: rgba(0,0,0,.79); height:100%; width:100%">
                    <!-- Photo Side -->
                    <section class="p-0">
                      <div class="container py-0">
                        <div class="row p-0 pt-5">
                          <div class="col-md-3 d-flex justify-content-center align-items-center"
                            style="text-align: center;">
                            <img class="img-fluid" style="width:80px" :src="$store.state.ngayi.image" />
                          </div>
                          <div class="col-md-9 d-flex flex-column align-items-center p-0">
                            <div class="d-flex flex-column justify-content-center"
                              style="height: 100%;width: 100%;background: var(--bs-gray-200);">
                              <div class="container text-md-left">
                                <h1 class="headline white--text" style="font-family: Amethysta, serif;">
                                  {{
                                    $store.state.ngayi.nom +
                                      " " +
                                      $store.state.ngayi.postnom +
                                      " " +
                                      $store.state.ngayi.prenom
                                  }}
                                </h1>
                                <p class="grey--text">
                                  {{ $store.state.ngayi.id }}
                                </p>
                              </div>
                            </div>
                          </div>
                          <v-divider class="grey"></v-divider>
                        </div>
                      </div>
                    </section>
                    <!-- Photo Side end -->

                    <div class="container text-left px-5">
                      <v-list dark dense class="px-5 bg-transparent">
                        <v-list-item v-for="(x, k) in leMenu" :key="k" :href="x.link" class="p-0">
                          <v-list-item-avatar>
                            <v-badge color="red" overlap :content="nbreChat" v-if="nbreChat > 0 && x.explication == 0">
                              <font-awesome-icon :icon="x.icon" class="fa-2x grey--text" />
                            </v-badge>
                            <font-awesome-icon :icon="x.icon" class="fa-x grey--text"
                              v-else-if="nbreChat > 0 && x.explication != 0" />
                            <font-awesome-icon :icon="x.icon" class="fa-x grey--text" v-else-if="nbreChat == 0" />
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <v-list-item-title class="subtitle-1 grey--text" v-text="x.name"></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>

                      <!-- <v-divider class="grey"></v-divider> -->
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </transition>
    </v-main>

    <v-footer app class="p-0" v-if="part == 1">
      <v-tabs v-model="tab" background-color="#232427" centered dark fixed-tabs bottom icons>
        <v-tabs-slider></v-tabs-slider>

        <v-tab href="tel:3195193008">
          <font-awesome-icon :icon="['fas', 'phone']" class="fa-x white--text" />
        </v-tab>

        <v-tab @click="open('settings')">
          <font-awesome-icon :icon="['fas', 'cog']" class="fa-x white--text" />
        </v-tab>

        <!-- <v-tab href="#tab-4">
        <font-awesome-icon :icon="['fas','cog']" class="fa-2x grey--text" />
      </v-tab> -->
      </v-tabs>
    </v-footer>

    <v-dialog persistent v-model="dialogWait" max-width="200">
      <v-card class="text-center py-3">
        <v-progress-circular :size="30" color="primary" indeterminate></v-progress-circular>
        <span class="ml-2">Please Wait</span>
      </v-card>
    </v-dialog>


    <v-dialog persistent v-model="dialogTerms" max-width="700" max-height="200">
      <v-card class="text-center py-3 grey lighten-2">
        <h4 class="text-center my-4">Terms and Conditions</h4>
        <div>

          <div>
            <Terms />
          </div>

          <div class="my-3">

            <v-btn small color="red" @click="logout()" class="white--text m-1 elevation-0">
              I do not agree
            </v-btn>

            <v-btn small class="white--text m-1 elevation-0 p-3" @click="dialogPhoto = true;" color="green">
              I agree
            </v-btn>

          </div>
        </div>

      </v-card>
    </v-dialog>


    <v-dialog persistent v-model="dialogPhoto" max-width="500">
      <v-card class="text-center py-3 grey lighten-2">
        <h4 class="text-center my-4">Mettez une photo de profil</h4>
        <h5 class="text-center my-4 red--text">(En Format Passport)</h5>
        <!-- Photo -->
        <div>
          <img @click="SelecFile()" name="myImage" :src="$store.state.ngayi.image" class="img-fluids curseur"
            width="200px" />

          <input type="file" id="file" @change="baPoniImages($event)"
            accept="image/png, image/gif, image/jpeg, image/jpg" style="display:none;" />

          <div class="my-3">
            <v-btn small class="white--text m-1 elevation-0 p-3" @click="SelecFile()"
              style="background-color: rgb(11,18,81);">
              Cliquer ici
            </v-btn>

            <v-btn small color="red" class="white--text m-1 elevation-0" @click="dialogPhoto = false">
              Pas maintenant
            </v-btn>
          </div>
        </div>

      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogValid" persistent max-width="400">
      <v-card class="py-5 px-3">
        <v-card-title class="title red--text font-weight-normal">
          Message:
        </v-card-title>

        <v-divider></v-divider>
        <v-card-text>
          <div class="text-center">
            <img :src="require('../../images/myLogo.png')" class="w-25" /><br /><br />
            <h5 class="font-weight-bold">
              Votre compte est en cours de validation.
            </h5>
            <br />
            <v-btn href="tel:3195193008" color="primary" class="white--text m-1">
              Contactez le consultant
            </v-btn>
            <v-btn @click="logout()" color="red" class="white--text m-1">
              Deconnection
            </v-btn>
            <v-divider></v-divider>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div class="d-none">{{ ouvrirTransition }}</div>
    <div class="d-none">{{ timersMe }}</div>
  </v-app>
</template>

<script>
  // import ServiceApi from "../ServicesApi.js";
  import db from "../../db.js";
  import firebase from "firebase";

  export default {
    data() {
      return {
        email: "",
        show: false,
        kadena: "",
        password: "",
        erreur: "",
        tab: null,
        dialogErreur: false,
        dialogWait: true,
        dialogValid: false,
        dialogPhoto: false,
        dialogTerms: false,
        place: "",
        part: 1,
        model: 0,
        colors: ["primary", "secondary", "yellow darken-2", "red", "orange"],
        o: {
          id: "",
          email: "",
          yesa: "",
          type: ""
        },
        leMenu: [
          // {
          //   name: "Mon Profile",
          //   icon: ["fas", "user-tie"],
          //   link: "#/profil",
          //   explication:
          //     "Pour communiquer avec le comité directeur sur votre besoin"
          // },
          {
            name: "Actualités",
            icon: ["fas", "newspaper"],
            link: "#/news",
            explication:
              "Pour communiquer avec le comité directeur sur votre besoin"
          },
          // {
          //   name: "Activités",
          //   icon: ["fas", "newspaper"],
          //   link: "#/activites",
          //   explication:
          //     "Pour communiquer avec le comité directeur sur votre besoin"
          // },
          {
            name: "Démographie",
            icon: ["fas", "users"],
            link: "#/demographie",
            explication: "Pour communiquer avec le comité directeur sur votre besoin"
          },
          // {
          //   name: "Famille",
          //   icon: ["fas", "user-plus"],
          //   link: "#/link_with_family",
          //   explication:
          //     "Pour communiquer avec le comité directeur sur votre besoin"
          // },
          // {
          //   name: "Finance",
          //   icon: ["fas", "dollar-sign"],
          //   link: "#/finances",
          //   explication:
          //     "Pour communiquer avec le comité directeur sur votre besoin"
          // },
          {
            name: "Assistance",
            icon: ["fas", "hands-helping"],
            link: "#/assistance",
            explication: "Pour communiquer avec le comité directeur sur votre besoin"
          },
          // {
          //   name: "Business",
          //   icon: ["fas", "briefcase"],
          //   link: "#/business",
          //   explication:
          //     "Pour communiquer avec le comité directeur sur votre besoin"
          // },
          // {
          //   name: "Chat",
          //   icon: ["fas", "envelope"],
          //   link: "#/chat_ouverture",
          //   explication: "0"
          // },
          // {
          //   name: "Vidéos",
          //   icon: ["fas", "video"],
          //   link: "#/videos",
          //   explication:
          //     "Pour communiquer avec le comité directeur sur votre besoin"
          // }
        ],
        chat: [],
        nbreChat: 0,
        days: [
          "01",
          "02",
          "03",
          "04",
          "05",
          "06",
          "07",
          "08",
          "09",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23",
          "24",
          "25",
          "26",
          "27",
          "28",
          "29",
          "30",
          "31"
        ],
        moi: [
          "January",
          "February",
          "Mars",
          "Avril",
          "Mai",
          "Juin",
          "Juillet",
          "Août",
          "Septembre",
          "Octembre",
          "novembre",
          "Decembre"
        ],
        ididiModel: "",
        dayModel: "",
        moiModel: "",
        codeModel: "",
        photo: true,
        photoMis: false
      };
    },
    created() {
      this.getMessages();
      this.$store.state.part = this.getCookie("pro");
      this.part = this.$store.state.part;

      var that = this;
      // this.dialogWait = true;
      this.$store.state.email = this.getCookie("mutuYango");
      this.$store.state.password = this.getCookie("codeYango");

      this.login();

      // console.log(this.$store.state.email + " from menu");
      // alert(this.$store.state.email + " email");
      that.$store.state.ngayi.originalId = undefined;
      /********************************************************/
      // var user = {email: "papa"};
      // alert(user.email + " avant");
      // try {
      //   user = firebase.auth().currentUser;
      //   alert(user.email + " firebase");
      // } catch (error) {
      //   user.email = "";
      //   alert(error.message);
      // }
      if (this.$store.state.email == "" || this.$store.state.email == undefined) {
        try {
          this.$router.push("/login");
          // alert(this.$store.state.email);
        } catch (error) {
          alert(error.message);
        }
      } else {
        /********************************************************/
        /********************************************************/
        try {
          var pos = that.leMenu
            .map(function (e) {
              return e.name;
            })
            .indexOf("Chat");

          db.collection("kadena")
            .where("email", "==", that.$store.state.email)
            .onSnapshot(snapshot => {
              that.kadena = [];
              snapshot.forEach(doc => {
                that.kadena.push({
                  email: doc.data().email
                });
              });
              if (that.kadena.length > 0) that.leMenu.splice(pos, 1);
            });
        } catch (error) {
          alert(error.message);
        }
        /********************************************************/
        db.collection("charge")
          .where("email", "==", that.$store.state.email)
          .onSnapshot(snapshot => {
            this.o = {
              id: "",
              email: "",
              yesa: "",
              type: ""
            };
            snapshot.forEach(doc => {
              this.o = {
                id: doc.data().id,
                email: doc.data().email,
                yesa: doc.data().yesa,
                type: doc.data().type
              };
            });
          });

          
        /********************************************************/
        setTimeout(() => {
          db.collection("users")
            .where("email", "==", that.$store.state.email)
            .onSnapshot(snapshot => {
              that.$store.state.ngayi = [];
              snapshot.forEach(doc => {
                that.$store.state.ngayi = {
                  originalId: doc.id,
                  id: doc.data().id,
                  nom: doc.data().nom,
                  postnom: doc.data().postnom,
                  prenom: doc.data().prenom,
                  phone: doc.data().phone,
                  date_de_naissance: doc.data().date_de_naissance,
                  lieu_de_naissance: doc.data().lieu_de_naissance,
                  sex: doc.data().sex,
                  adresse: doc.data().adresse,
                  image: doc.data().image,
                  city: doc.data().city,
                  zipcode: doc.data().zipcode,
                  state: doc.data().state,
                  email: doc.data().email,
                  privacy: doc.data().privacy,
                  type: doc.data().type,
                  year: "",
                  yesa: doc.data().yesa,
                  // date: new Date(doc.data().cretedAd.toDate()).toDateString(),
                  date_a_iowa: doc.data().date_a_iowa,
                  // heure:
                  //   " at " +
                  //   new Date(doc.data().cretedAd.toDate()).toLocaleTimeString()
                };
                // console.log(this.o);

                if (
                  this.o.yesa == that.$store.state.ngayi.yesa &&
                  this.o.email.toLowerCase() ==
                  that.$store.state.ngayi.email.toLowerCase() &&
                  this.o.id.toLowerCase() ==
                  that.$store.state.ngayi.id.toLowerCase()
                ) {
                  // that.dialogWait = false;
                  // that.part = 1;
                  // alert(this.o.type);
                  if (this.o.type == "FB") {
                    this.validate(0);
                    this.$router.push("/menu_pre");
                  } else if (this.o.type == "MI") {
                    this.validate(0);
                    this.$router.push("/menu_pro");
                  } else if (this.o.type == "nkumu") {
                    this.validate(0);
                    this.$router.push("/menu_it");
                  }
                } else {
                  that.dialogWait = false;
                  this.validate(1);
                  that.part = 1;
                }
                // this.getFamilyRequest();
              });
              // console.log(that.$store.state.ngayi);
              if (that.$store.state.ngayi.originalId == undefined) {
                that.$store.state.ngayi.email = "";
                // alert("you not find");
                this.dialogValid = true;
                // this.$router.push("/login");
              }
              // else alert("wtf");
            });
        }, 2000);
      }
      this.challenge();
      // /*******************************************************/
    },

    methods: {
      login(){

        var that = this;
        firebase
        .auth()
        .signInWithEmailAndPassword(that.$store.state.email, that.$store.state.password)
        .then(
          elt => {
            // console.log(elt.user.email);
            // console.log(elt.user.password);
            var a = elt;
            a = "";
            console.log(a);
          },
          error => {
            that.$store.state.password = "";
            console.log(error.message);
          }
        );
      },
      getMessages() {
        var that = this;
        /********************************************************/
        db.collection("chat")
          .orderBy("cretedAd", "asc")
          .onSnapshot(snapshot => {
            that.chat = [];
            snapshot.forEach(doc => {
              that.chat.push({
                message: doc.data().message,
                fullname: doc.data().fullname,
                image: doc.data().image,
                ladate: new Date(doc.data().cretedAd.toDate()).toDateString(),
                heure: " at " +
                  new Date(doc.data().cretedAd.toDate()).toLocaleTimeString()
              });
            });
            // console.log("this.chat up");
            that.tiaSikoyo();
          });
        ///*******************************************************/
      },
      tiaSikoyo() {
        var items = [];
        var d = new Date();
        var auzuidui = d.toDateString();
        this.chat.forEach(element => {
          if (element.ladate == auzuidui) {
            items.push({
              message: element.message,
              fullname: element.fullname,
              image: element.image,
              ladate: element.ladate + element.heure
            });
          }
        });
        this.nbreChat = items.length;
        // alert(items.length);
        // console.log(this.items);
      },
      /********************************** */
      validate(value) {
        var d = new Date();
        d.setTime(d.getTime() + 5 * (365 * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = "pro" + "=" + value + ";" + expires + ";";
      },
      getCookie(cname) {
        var name = cname + "=";
        var ca = document.cookie.split(";");
        for (var i = 0; i < ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) == " ") {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
      },

      /*******************************************************/
      getFamilyRequest() {
        var that = this;
        /********************************************************/
        db.collection("familyPreparation").onSnapshot(snapshot => {
          that.$store.state.familyNotification = [];
          snapshot.forEach(doc => {
            // console.log(
            //   doc.data().to_email + " == " + that.$store.state.ngayi.email
            // );
            // console.log(doc.data().to_email == that.$store.state.ngayi.email);
            if (doc.data().to_email == that.$store.state.ngayi.email) {
              that.$store.state.familyNotification.push({
                id: doc.id,
                from_email: doc.data().from_email,
                from_name: doc.data().from_name,
                from_image: doc.data().from_image,
                from_relation: doc.data().from_relation,
                to_email: doc.data().to_email,
                to_name: doc.data().to_name,
                to_image: doc.data().to_image,
                to_relation: doc.data().to_relation
              });
            }
          });
        });
      },
      /*******************************************************/
      challenge() {
        var that = this;
        /********************************************************/
        db.collection("challenge").onSnapshot(snapshot => {
          that.$store.state.salange = [];
          snapshot.forEach(doc => {
            if (doc.data().email == that.$store.state.email) {
              that.$store.state.salange = [{
                id: doc.data().id,
                email: doc.data().email,
                cretedAd: doc.data().cretedAd
              }];
            }
          });
        });
      },
      open(value) {
        if (value == "settings") this.$router.push("/settings");
      },
      logout() {
        var that = this;
        firebase
          .auth()
          .signOut()
          .then(function () {
            // Sign-out successful.
            document.cookie = "mutuYango=" + ";";
            document.cookie = "codeYango=" + ";";

            //Reinstallation
            that.$store.state.ngayi = {};
            that.$store.state.ye = {};
            that.$store.state.email = "";
            that.$store.state.psw = "";
            that.$store.state.news = {};
            that.$store.state.city = {};
            that.$store.state.users = [];
            that.$store.state.familyNotification = [];
            that.$store.state.family = [];
            that.$store.state.roommate = [];

            that.$router.push("/login");
          })
          .catch(function (error) {
            // An error happened.
            alert(error.message);
          });
      },
      leTemps() {
        setTimeout(() => {
          this.changedValue();
          return false;
        }, 100);
        return 0;
      },
      changedValue() {
        this.show = true;
        return false;
      },
      checkMe() {
        // if first time
        if (this.$store.state.ngayi.type == "e") this.dialogTerms = true;
        else this.dialogTerms = false;
      },
      SelecFile() {
        this.$store.state.ImagesUpload = [];
        document.getElementById("file").click();
      },
      baPoniImages(e) {
        document.getElementById("file");
        
        var that = this;
        e.target.files.forEach(element => {
          that.dialogWait = true;
          // var filename = element.name;
          var tableau = "";

          var storageRef = firebase
            .storage()
            .ref("Pictures/" + this.$store.state.ngayi.email + "/" + "profil");
          var metadata = {
            contentType: "image/jpeg"
          };

          storageRef.put(element, metadata).then(function (downloadURL) {
            downloadURL.ref.getDownloadURL().then(function (url) {
              url = String(url);
              if (url !== undefined) {
                var b = url;
                tableau = b;
                // console.log(tableau);
                that.dialogWait = false;
                that.photo = false;
                /****************************/
                db.collection("users")
                  .doc(that.$store.state.ngayi.originalId)
                  .update({
                    image: tableau
                  });
                /*************************/
                that.$store.state.ngayi.image = tableau;
                that.kangaDial();
              }
            });
          });
        });
      },
      kangaDial() {
        var that = this;
        /****************************/
        db.collection("users")
          .doc(that.$store.state.ngayi.originalId)
          .update({
            type: "agree"
          });
        /*************************/
        that.$store.state.ngayi.type = "";
        that.dialogPhoto = false;
        this.dialogWait = true;

        setTimeout(() => {
          this.dialogWait = false;
        }, 2000);
      },
      doFirst() {
        this.ididiModel = Number(this.ididiModel) - 9517538426543210;
        this.codeModel = Number(this.codeModel) - 951;
        this.didi();
      },
      didi() {
        var that = this;
        try {
          // alert(that.$store.state.ngayi.originalId);
          db.collection("users")
            .doc(that.$store.state.ngayi.originalId)
            .update({
              day: that.dayModel,
              mois: that.moiModel,
              access: that.codeModel,
              didi: that.ididiModel,
              type: ""
            });
          this.dialogPhoto = false;
        } catch (error) {
          alert(error.message);
          alert(
            "Une erreur s'est produite. Veuillez appeler le +1(319)-519-3008"
          );
          that.codeModel = "";
          that.ididiModel = "";
        }
      }
    },
    components: {
      Terms: () => import("./Terms.vue")
    },
    computed: {
      ouvrirTransition() {
        this.leTemps();
        return false;
      },
      timersMe() {
        this.checkMe();
        return 0;
      }
    }
  };
</script>

<style scoped>
  .bg-img {
    background: url("../../images/pexels-magda-ehlers-2660262.jpg") center / contain no-repeat,
      #151515;
  }

  .bg-col-img {
    background: url("../../images/pexels-askar-abayev-6190867.jpg") center / cover no-repeat;
  }

  .card {
    cursor: pointer;
    background-color: rgb(236, 236, 236);
    border-radius: 25px;
  }

  .card:hover {
    background-color: rgb(218, 211, 250);
  }

  .menu {
    padding: 0px 0px 100px 0px;
  }
</style>