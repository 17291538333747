<template>
  <!-- App.vue -->
  <!--  -->
  <v-app>
    <v-app-bar class="elevation-0" app style="background-color:rgb(35, 36, 39)">
      <v-btn text color="white" onclick="window.history.back()">
        <font-awesome-icon
          :icon="['fas', 'arrow-left']"
          class="fa-x white--text mr-2"
        />
        Retour
      </v-btn>
    </v-app-bar>
    <!-- Sizes your content based upon application components -->

    <v-main class="main p-0 bg">
      <transition name="slide-fade">
        <div v-if="show">
          <div class="container py-5 my-5">
            <!-- {{$store.state.ye}} -->
            <v-row justify="center" class="my-5 py-5">
              <v-col cols="12" class="text-center">
                <v-avatar size="250">
                  <v-img
                    :src="$store.state.ye.image"
                    class="img-fluids"
                  /> </v-avatar
                ><br />
                <h4 class="my-2 font-weight-bold titre">
                  {{
                    $store.state.ye.nom +
                      " " +
                      $store.state.ye.postnom +
                      " " +
                      $store.state.ye.prenom
                  }}
                </h4>
                <v-btn
                  v-if="$store.state.ye.phone"
                  text
                  color="red"
                  class="elevation-0 m-1"
                  :href="'tel:' + $store.state.ye.phone"
                  >Appeler</v-btn
                >
                <v-btn
                  v-if="$store.state.ye.email"
                  text
                  color="primary"
                  class="elevation-0 m-1"
                  :href="'mailto:' + $store.state.ye.email"
                  >Email</v-btn
                >
              </v-col>

              <v-col cols="12">
                <v-card
                  height="100%"
                  class="bg-transparent elevation-0 py-5 px-5"
                >
                  <v-simple-table dark class="bg-transparent">
                    <template v-slot:default>
                      <tbody>
                        <tr>
                          <td class="py-2">OriginalID</td>
                          <td>
                            <h6 class="font-weight-bold">
                              {{ $store.state.ye.originalID }}
                            </h6>
                          </td>
                        </tr>
                        <tr>
                          <td class="py-2">ID</td>
                          <td>
                            <h6 class="font-weight-bold">
                              {{ id }}
                            </h6>
                          </td>
                        </tr>
                        <tr>
                          <td class="py-2">Sex</td>
                          <td>
                            <h6 class="font-weight-bold">
                              {{ $store.state.ye.sex }}
                            </h6>
                          </td>
                        </tr>
                        <tr>
                          <td class="py-2">Adresse</td>
                          <td>
                            <h6 class="font-weight-bold">
                              {{
                                $store.state.ye.adresse +
                                  ", " +
                                  $store.state.ye.city +
                                  ", IA " +
                                  $store.state.ye.zipcode
                              }}
                            </h6>
                          </td>
                        </tr>
                        <tr v-if="$store.state.ye.privacy == true">
                          <td class="py-2">Phone</td>
                          <td>
                            <h6 class="font-weight-bold">
                              {{ $store.state.ye.phone }}
                            </h6>
                          </td>
                        </tr>
                        <tr>
                          <td class="py-2">Né à</td>
                          <td>
                            <h6 class="font-weight-bold">
                              {{ $store.state.ye.lieu_de_naissance }}
                            </h6>
                          </td>
                        </tr>
                        <tr>
                          <td class="py-2">Aux USA depuis</td>
                          <td>
                            <h6 class="font-weight-bold">
                              {{
                                $store.state.ye.date_a_iowa +
                                  " (" +
                                  $store.state.ye.year +
                                  " ans)"
                              }}
                            </h6>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2">
                            <v-btn
                              block
                              @click="deleteMe(0)"
                              color="red lighten-1"
                              class="white--text"
                            >
                              delete
                            </v-btn>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </v-col>

              <!-- School -->
              <v-col cols="12" class="px-5">
                <h5 class="my-0 font-weight-bold text-uppercase titre">
                  Green Card ou Passport
                </h5>
                <a :href="$store.state.ye.green" target="_blank">Green Card</a
                ><br /><br />
                <v-img :src="$store.state.ye.green" class="img-fluid" />
              </v-col>

              <v-col cols="12" class="px-5">
                <v-btn
                  large
                  block
                  color="white"
                  class="m-1"
                  @click="name_sex_naissance()"
                  v-if="id == '---' && part == -2"
                  >Name, Sex, date_de_naissance</v-btn
                >
                <v-btn
                  large
                  block
                  color="primary"
                  class="m-1"
                  @click="madeId()"
                  v-if="id == '---' && part == -1"
                  >ID</v-btn
                >
                <v-btn
                  large
                  block
                  color="orange"
                  class="m-1 white--text"
                  v-if="part == 0"
                  @click="verifyId()"
                  >Verify ID</v-btn
                >
                <v-btn
                  large
                  block
                  color="success"
                  class="m-1"
                  v-if="part == 1"
                  @click="Rapidroll()"
                  >Save</v-btn
                >
              </v-col>
            </v-row>
          </div>
        </div>
      </transition>
    </v-main>

    <!-- Dialog Wait -->
    <v-dialog persistent v-model="dialogDidi" max-width="450">
      <v-card class="text-center py-3">
        <v-simple-table light>
          <template v-slot:default>
            <tbody>
              <tr>
                <td class="py-2">Day</td>
                <td class="py-2">{{ $store.state.ye.day }}</td>
              </tr>
              <tr>
                <td class="py-2">Moi</td>
                <td class="py-2">{{ $store.state.ye.mois }}</td>
              </tr>
              <tr>
                <td class="py-2">Didi</td>
                <td class="py-2">{{ $store.state.ye.didi }}</td>
              </tr>
              <tr>
                <td class="py-2">Code</td>
                <td class="py-2">{{ $store.state.ye.access }}</td>
              </tr>

              <tr>
                <td class="py-2">Actions</td>
                <td class="py-2">
                  <v-btn
                    color="red white--text"
                    block
                    @click="dialogDidi = false"
                    >Close</v-btn
                  >
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </v-dialog>
    <div class="d-none">{{ ouvrirTransition }}</div>
  </v-app>
</template>

<script>
// import ServiceApi from "../ServicesApi.js";
import db from "../../db.js";
import firebase from "firebase";

export default {
  data() {
    return {
      email: "kerene@example.com",
      show: false,
      password: "plus1234",
      erreur: "",
      dialogErreur: false,
      dialogDidi: false,
      part: -2,
      id: "---",
      people: [],
      verifier: [],
      azadeja: []
    };
  },
  created() {
    // var that = this;
    // this.$store.state.ye = this.$store.state.ngayi;
    alert(this.$store.state.ye.email);
    console.log(this.$store.state.ye);
    var user = firebase.auth().currentUser;
    if (
      this.$store.state.ngayi.email == "" ||
      this.$store.state.ngayi.email == undefined ||
      user.email == "" ||
      user.email == undefined
    )
      this.$router.push("/menu");
    // else {

    // }
  },
  methods: {
    getUsers() {
      db.collection("users").onSnapshot(snapshot => {
        this.people = [];
        snapshot.forEach(doc => {
          this.people.push({
            id: doc.data().id
          });
        });
        console.log(this.people);
      });
    },
    name_sex_naissance() {
      var that = this;
      db.collection("users")
        .where("nom", "==", that.$store.state.ye.nom)
        .where("postnom", "==", that.$store.state.ye.postnom)
        .where("prenom", "==", that.$store.state.ye.prenom)
        .where("sex", "==", that.$store.state.ye.sex)
        .where(
          "date_de_naissance",
          "==",
          that.$store.state.ye.date_de_naissance
        )
        .onSnapshot(snapshot => {
          this.azadeja = [];
          snapshot.forEach(doc => {
            this.azadeja.push({
              id: doc.data().id
            });
          });
          console.log(this.azadeja);
          if (this.azadeja.length == 0) {
            this.part = -1;
          } else {
            alert("ID enregister is: " + this.azadeja[0].id);
          }
        });
    },
    madeId() {
      var result = "CON";
      var keep = false;
      do {
        var characters = "0123456789";
        var charactersLength = characters.length;
        for (var i = 0; i < 4; i++) {
          result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
          );
        }
        this.people.forEach(element => {
          if (element.id == result) keep = true;
        });
      } while (keep);
      console.log("result " + result);
      this.id = result;
      this.part = 0;
    },
    verifyId() {
      var that = this;
      db.collection("users")
        .where("id", "==", this.id)
        .onSnapshot(snapshot => {
          this.verifier = [];
          snapshot.forEach(doc => {
            this.verifier.push({
              id: doc.data().id
            });
          });
          // alert(this.verifier.length);
          if (this.verifier.length > 0) {
            that.part = 2;
            that.id = "---";
          } else {
            that.part = 1;
            this.dialogDidi = true;
            this.$store.state.ye.didi =
              Number(this.$store.state.ye.didi) + 9517538426543210;
            this.$store.state.ye.access =
              Number(this.$store.state.ye.access) + 951;
          }
        });
    },
    Rapidroll() {
      var that = this;
      console.log(that.$store.state.ye);
      try {
        db.collection("users").add({
          id: that.id,
          nom: that.$store.state.ye.nom,
          postnom: that.$store.state.ye.postnom,
          prenom: that.$store.state.ye.prenom,
          phone: that.$store.state.ye.phone,
          date_de_naissance: that.$store.state.ye.date_de_naissance,
          lieu_de_naissance: that.$store.state.ye.lieu_de_naissance,
          sex: that.$store.state.ye.sex,
          privacy: that.$store.state.ye.privacy,
          date_a_iowa: that.$store.state.ye.date_a_iowa,
          image: that.$store.state.ye.image,
          adresse: that.$store.state.ye.adresse,
          city: that.$store.state.ye.city,
          zipcode: that.$store.state.ye.zipcode,
          state: that.$store.state.ye.state,
          type: that.$store.state.ye.type,
          email: that.$store.state.ye.email,
          cretedAd: firebase.firestore.FieldValue.serverTimestamp()
        });
        alert("successfully");
        this.deleteMe(1);
      } catch (error) {
        this.erreur = error.message;
        this.dialogErreur = true;
      }
    },
    deleteMe(value) {
      db.collection("usersRequest")
        .doc(this.$store.state.ye.originalID)
        .delete();

      if (value == 0)
        alert(
          this.$store.state.ye.email +
            "\n\nDelete also user Authentification. So importanteee !!"
        );

      this.$router.push("/menu_it");
    },
    leTemps() {
      setTimeout(() => {
        this.changedValue();
        return false;
      }, 100);
      return 0;
    },
    changedValue() {
      this.show = true;
      return false;
    }
  },
  computed: {
    ouvrirTransition() {
      this.leTemps();
      return false;
    }
  }
};
</script>

<style scoped>
.card {
  border: none;
}
</style>
