<template>
  <!-- App.vue -->
  <v-app style="background-color:#dfe7f1">
    <v-app-bar app style="background-color:#214a80">
      <v-btn icon onclick="window.history.back()">
        <font-awesome-icon
          :icon="['fas', 'arrow-left']"
          class="fa-x white--text"
        />
      </v-btn>
      <v-spacer></v-spacer>
      <h4 class="white--text">Search</h4>
      <v-spacer></v-spacer>
      <v-btn icon href="#/menu">
        <font-awesome-icon :icon="['fas', 'bars']" class="fa-x white--text" />
      </v-btn>
      <v-btn icon @click="dialog4 = true">
        <font-awesome-icon :icon="['fas', 'search']" class="fa-x white--text" />
      </v-btn>
    </v-app-bar>
    <!-- Sizes your content based upon application components -->

    <v-main class="main p-0">
      <transition name="slide-fade">
        <div v-if="show">
          <div class="container" v-if="montrer == 1">
            <!-- {{people}} -->
            <div
              class="p-3 mt-5 headline text-center grey--text"
              v-if="showPerson"
            >
              <span>Cette personne n'existe pas</span><br />
              <font-awesome-icon
                :icon="['fas', 'exclamation-circle']"
                class="fa-2x grey--text"
              />
            </div>

            <v-row justify="center">
              <v-col
                cols="8"
                md="4"
                lg="3"
                class="text-center"
                v-for="(x, k) in peopleSearch"
                :key="k"
              >
                <v-card class="p-3 elevation-0" style="height:100%">
                  <v-card-text class="p-0">
                    <v-avatar size="100">
                      <v-img :src="x.image" class="img-fluid" /> </v-avatar
                    ><br />
                    <h6 class="my-2 font-weight-bold">{{ x.fullname }}</h6>
                    <br />
                    <v-btn color="primary" @click="pona(x)">Profil</v-btn>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </div>
      </transition>
    </v-main>

    <v-dialog persistent v-model="dialogWait" max-width="200">
      <v-card class="text-center py-3">
        <v-progress-circular
          :size="30"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <span class="ml-2">Please Wait</span>
      </v-card>
    </v-dialog>

    <!-- Dialog 4 -->
    <v-dialog v-model="dialog4" max-width="400">
      <v-card class="elevation-0 px-5 py-5" v-if="par == 'rien'">
        <div class="w-100 text-center">
          <span class="headline">Recherche</span><br /><br />
          <v-btn
            large
            blocks
            class="elevation-0 white--text m-1"
            color="orange"
            @click="par = 'id'"
            >Par ID</v-btn
          >
          <v-btn
            large
            blocks
            class="elevation-0 white--text m-1"
            color="orange"
            @click="par = 'nom'"
            >Par Noms</v-btn
          >
        </div>
      </v-card>

      <!-- ID  -->
      <v-card class="elevation-0 px-5 py-5" v-else-if="par == 'id'">
        <v-btn icon @click="par = 'rien'">
          <font-awesome-icon
            :icon="['fas', 'arrow-left']"
            class="fa-x black--text"
        /></v-btn>
        <div class="w-100 text-center">
          <span class="headline">Recherche par ID</span>
        </div>
        <v-text-field
          @keypress.enter="chercher(0)"
          v-model="koluka[0].id"
          label="ID Number"
        />
        <v-btn
          class="elevation-0 white--text"
          color="orange"
          :disabled="koluka[0].id == ''"
          @click="chercher(0)"
          >Chercher</v-btn
        >
      </v-card>

      <!-- Noms  -->
      <v-card class="elevation-0 px-5 py-5" v-else-if="par == 'nom'">
        <v-btn icon @click="par = 'rien'">
          <font-awesome-icon
            :icon="['fas', 'arrow-left']"
            class="fa-x black--text"
        /></v-btn>
        <div class="w-100 text-center">
          <span class="headline">Recherche par noms</span>
        </div>

        <v-text-field
          @keypress.enter="chercher(1)"
          v-model="koluka[0].nom"
          label="Nom"
        />
        <v-text-field
          @keypress.enter="chercher(1)"
          v-model="koluka[0].postnom"
          label="Postnom"
        />
        <v-text-field
          @keypress.enter="chercher(1)"
          v-model="koluka[0].prenom"
          label="Prenom"
        />

        <v-btn
          class="elevation-0 white--text"
          :disabled="koluka[0].nom == '' || koluka[0].prenom == ''"
          color="orange"
          @click="chercher(1)"
          >Chercher</v-btn
        >
      </v-card>
    </v-dialog>
    <div class="d-none">{{ ouvrirTransition }}</div>
  </v-app>
</template>

<script>
// import ServiceApi from "../ServicesApi.js";
import db from "../../db.js";
import firebase from "firebase";

export default {
  data() {
    return {
      email: "kerene@example.com",
      show: false,
      password: "plus1234",
      erreur: "",
      showPerson: false,
      par: "rien",
      koluka: [
        {
          id: "",
          nom: "",
          postnom: "",
          prenom: ""
        }
      ],
      place: "",
      valeur: 50,
      search: "",
      dialog: false,
      dialog2: false,
      dialog3: false,
      dialog4: true,
      roomid: "",
      dialogWait: false,
      nombreEnfant: 1,
      peopleSearch: [],

      enfants: [
        {
          nom: "",
          postnom: "",
          prenom: "",
          sex: "",
          lieu_de_naissance: "",
          date_de_naissance: "",
          adresse: "",
          city: "",
          zipcode: "",
          date_a_iowa: ""
        }
      ],
      people: [],
      naponi: [],
      roomate: [],
      montrer: 1,
      male:
        "https://firebasestorage.googleapis.com/v0/b/databaste-51ebd.appspot.com/o/Pictures%2F3.jpg?alt=media&token=29d212de-77cc-4385-9600-d58c22342678",
      female:
        "https://firebasestorage.googleapis.com/v0/b/databaste-51ebd.appspot.com/o/Pictures%2F4.jpg?alt=media&token=bf53b876-a8c8-497b-88cd-c67465a5e5e0"
    };
  },
  created() {
    var user = firebase.auth().currentUser;
    if (
      this.$store.state.ngayi.email == "" ||
      this.$store.state.ngayi.email == undefined ||
      user.email == "" ||
      user.email == undefined
    )
      this.$router.push("/menu");
  },
  mounted() {
    this.$store.state.roomate = [];

    /***************************************/
    db.collection("users").onSnapshot(snapshot => {
      this.people = [];
      snapshot.forEach(doc => {
        // console.log(doc.id);
        this.people.push({
          id: doc.data().id,
          nom: doc.data().nom,
          postnom: doc.data().postnom,
          prenom: doc.data().prenom,
          phone: doc.data().phone,
          date_de_naissance: doc.data().date_de_naissance,
          date_a_iowa: doc.data().date_a_iowa,
          lieu_de_naissance: doc.data().lieu_de_naissance,
          sex: doc.data().sex,
          adresse: doc.data().adresse,
          image: doc.data().image,
          city: doc.data().city,
          zipcode: doc.data().zipcode,
          state: doc.data().state,
          email: doc.data().email,
          privacy: doc.data().privacy,
          type: doc.data().type,
          year: "",
          date: new Date(doc.data().cretedAd.toDate()).toDateString(),
          heure:
            " at " + new Date(doc.data().cretedAd.toDate()).toLocaleTimeString()
        });
      });
    });
  },
  methods: {
    pona(people) {
      console.log(people);
      var duree = people.date_a_iowa.slice(0, 4);
      console.log(duree);
      var d = new Date();
      var annee = d.getFullYear();
      console.log("age == " + (Number(annee) - Number(duree)));
      this.$store.state.ye = people;
      this.$store.state.ye.year = Number(annee) - Number(duree);
      this.$router.push("/person_profile");
    },
    chercher(value) {
      console.log(this.people);
      this.peopleSearch = [];
      var keepId = false;
      var keepNom = false;
      // par ID
      if (value == 0) {
        this.people.forEach(element => {
          if (element.id.toLowerCase() == this.koluka[0].id.toLowerCase()) {
            console.log("yes");
            keepId = true;
            this.showPerson = false;
            this.peopleSearch.push({
              id: element.id,
              image: element.image,
              fullname:
                element.nom + " " + element.postnom + " " + element.prenom,
              phone: element.phone,
              date_de_naissance: element.date_de_naissance,
              date_a_iowa: element.date_a_iowa,
              lieu_de_naissance: element.lieu_de_naissance,
              sex: element.sex,
              adresse: element.adresse,
              city: element.city,
              zipcode: element.zipcode,
              state: element.state,
              email: element.email,
              privacy: element.privacy
            });
          }
        });
        console.log(this.peopleSearch);
        if (keepId == false) this.showPerson = true;
      }
      // par Noms
      else {
        this.people.forEach(element => {
          if (
            element.nom.toLowerCase() == this.koluka[0].nom.toLowerCase() &&
            element.postnom.toLowerCase() ==
              this.koluka[0].postnom.toLowerCase() &&
            element.prenom.toLowerCase() == this.koluka[0].prenom.toLowerCase()
          ) {
            keepNom = true;
            this.showPerson = false;
            this.peopleSearch.push({
              id: element.id,
              image: element.image,
              fullname:
                element.nom + " " + element.postnom + " " + element.prenom
            });
          }
        });
        console.log(this.peopleSearch);
        if (keepNom == false) this.showPerson = true;
      }
      this.dialog4 = false;
      this.koluka = [
        {
          id: "",
          nom: "",
          postnom: "",
          prenom: ""
        }
      ];
      this.par = "rien";
      console.log("test");
    },
    leTemps() {
      setTimeout(() => {
        this.changedValue();
        return false;
      }, 100);
      return 0;
    },
    changedValue() {
      this.show = true;
      return false;
    }
  },
  computed: {
    ouvrirTransition() {
      this.leTemps();
      return false;
    }
  }
};
</script>

<style scoped>
.card:hover {
  border: 2px solid rgb(4, 61, 134);
  cursor: pointer;
}
</style>
