<template>
  <!-- App.vue -->
  <v-app>
    <!-- Sizes your content based upon application components -->

    <v-main class="bg-blue p-0">
      <h1 class="white--text">
        Contactez le comité directeur. Supprimer ba console.log, OPEN LOGIN() in App
      </h1>
      <!-- SM  -->
      <div
        class="main px-2 d-flex flex-column justify-content-around align-items-center"
        style="background: rgba(0,0,0,.79);height: 100%;"
      >
        <div class="container m-0 p-0">
          <v-list subheader two-line class="bg-transparent">
            <v-list-item>
              <v-list-item-avatar class="d-block d-md-none mr-3">
                <img class="img-fluid" :src="$store.state.ngayi.image" />
              </v-list-item-avatar>

              <v-list-item-avatar class="d-none d-md-block mr-3">
                <img class="img-fluid" :src="$store.state.ngayi.image" />
              </v-list-item-avatar>

              <v-list-item-content class="d-block d-md-none">
                <v-list-item-title
                  class="titre"
                  v-text="
                    $store.state.ngayi.nom +
                      ' ' +
                      $store.state.ngayi.postnom +
                      ' ' +
                      $store.state.ngayi.prenom
                  "
                ></v-list-item-title>

                <v-list-item-subtitle
                  class="subtitle-2 grey--text"
                  v-text="$store.state.ngayi.id"
                ></v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-content class="d-none d-md-block">
                <v-list-item-title
                  class="title titre"
                  v-text="
                    $store.state.ngayi.nom +
                      ' ' +
                      $store.state.ngayi.postnom +
                      ' ' +
                      $store.state.ngayi.prenom
                  "
                ></v-list-item-title>

                <v-list-item-subtitle
                  class="subtitle-2 grey--text"
                  v-text="$store.state.ngayi.id"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider class="grey"></v-divider>
        </div>

        <div class="container">
          <v-list dark denses class="bg-transparent">
            <v-list-item
              v-for="(x, k) in leMenu"
              :key="k"
              :href="x.link"
              class="p-0"
            >
              <v-list-item-avatar>
                <v-badge
                  color="red"
                  overlap
                  :content="nbreChat"
                  v-if="nbreChat > 0 && x.explication == 0"
                >
                  <font-awesome-icon :icon="x.icon" class="fa-x grey--text" />
                </v-badge>
                <font-awesome-icon
                  :icon="x.icon"
                  class="fa-x grey--text"
                  v-else-if="nbreChat > 0 && x.explication != 0"
                />
                <font-awesome-icon
                  :icon="x.icon"
                  class="fa-x grey--text"
                  v-else-if="nbreChat == 0"
                />
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title
                  class="title"
                  v-text="x.name + ' -- ' + request.length"
                  v-if="x.name == 'Request'"
                >
                </v-list-item-title>

                <v-list-item-title
                  class="subtitre"
                  v-text="x.name"
                  v-else
                ></v-list-item-title>
                <!-- <v-list-item-subtitle v-text="folder.subtitle"></v-list-item-subtitle> -->
              </v-list-item-content>
              <!-- 
        <v-list-item-action>
          <v-btn icon>
            <v-icon color="grey lighten-1">mdi-information</v-icon>
          </v-btn>
        </v-list-item-action> -->
            </v-list-item>
          </v-list>

          <v-divider class="grey"></v-divider>
        </div>
      </div>
    </v-main>

    <v-footer app class="p-0">
      <v-tabs
        v-model="tab"
        background-color="#232427"
        centered
        dark
        fixed-tabs
        bottom
        icons
      >
        <v-tabs-slider></v-tabs-slider>

        <v-tab href="#tab-1">
          <font-awesome-icon :icon="['fas', 'home']" class="fa-x white--text" />
        </v-tab>

        <v-tab href="#tab-2">
          <font-awesome-icon
            :icon="['fas', 'phone']"
            class="fa-x white--text"
          />
        </v-tab>

        <v-tab @click="open('settings')">
          <font-awesome-icon :icon="['fas', 'cog']" class="fa-x white--text" />
        </v-tab>

        <!-- <v-tab href="#tab-4">
        <font-awesome-icon :icon="['fas','cog']" class="fa-2x grey--text" />
      </v-tab> -->
      </v-tabs>
    </v-footer>

    <v-dialog persistent v-model="dialogWait" max-width="200">
      <v-card class="text-center py-3">
        <v-progress-circular
          :size="30"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <span class="ml-2">Please Wait</span>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
// import ServiceApi from "../ServicesApi.js";
import db from "../../db.js";
import firebase from "firebase";

export default {
  data() {
    return {
      email: "",
      password: "",
      erreur: "",
      dialogErreur: false,
      dialogWait: false,
      place: "",
      tab: null,
      o: {
        id: "",
        email: "",
        yesa: ""
      },
      leMenu: [
        {
          name: "Mon Profile",
          icon: ["fas", "user-tie"],
          link: "#/profil",
          explication: "1"
        },
        {
          name: "Actualités",
          icon: ["fas", "newspaper"],
          link: "#/news_adm",
          explication: "1"
        },
        {
          name: "Activités",
          icon: ["fas", "newspaper"],
          link: "#/activites_adm",
          explication: "1"
        },
        {
          name: "Finance",
          icon: ["fas", "dollar-sign"],
          link: "#/finance",
          explication: "1"
        },
        {
          name: "Démographie",
          icon: ["fas", "users"],
          link: "#/demographie",
          explication: "1"
        },
        {
          name: "Population",
          icon: ["fas", "users"],
          link: "#/tout_le_monde",
          explication: "1"
        },
        {
          name: "Famille",
          icon: ["fas", "user-plus"],
          link: "#/link_with_family",
          explication: "1"
        },
        {
          name: "Assistance",
          icon: ["fas", "hands-helping"],
          link: "#/assistance_pro",
          explication: "1"
        },
        {
          name: "Business",
          icon: ["fas", "briefcase"],
          link: "#/business",
          explication:
            "Pour communiquer avec le comité directeur sur votre besoin"
        },
        {
          name: "Request",
          icon: ["fas", "hands-helping"],
          link: "#/request",
          explication: "1"
        },
        {
          name: "Signaler",
          icon: ["fas", "envelope"],
          link: "#/signaler",
          explication: "1"
        },
        {
          name: "Excel",
          icon: ["fas", "file-excel"],
          link: "#/excel",
          explication: "1"
        },
        {
          name: "Tracking",
          icon: ["fas", "hands-helping"],
          link: "#/tracking",
          explication: "1"
        },
        {
          name: "Entrez",
          icon: ["fas", "pencil"],
          link: "#/entrez",
          explication: "1"
        },
        {
          name: "Hacking",
          icon: ["fas", "hands-helping"],
          link: "#/tracking",
          explication: "1"
        },
        {
          name: "Chat ",
          icon: ["fas", "envelope"],
          link: "#/chat_ouverture",
          explication: "0"
        },
        {
          name: "Vidéos ",
          icon: ["fas", "video"],
          link: "#/videos_adm",
          explication: "1"
        }
      ],
      makabo: true,
      chat: [],
      request: [],
      nbreChat: 0
    };
  },
  created() {
    this.$store.state.email = this.getCookie("mutuYango");
    var user = firebase.auth().currentUser;
    if (
      this.$store.state.ngayi.email == "" ||
      this.$store.state.ngayi.email == undefined ||
      user.email == "" ||
      user.email == undefined
    )
      this.$router.push("/menu");
    else {
      if (this.$store.state.ngayi.yesa == process.env.VUE_APP_KONGOMANI)
        console.log("ok ok ok");
    }
  },
  mounted() {
    this.getMessages();
    this.getRequest();
    var that = this;
    /********************************************************/
    if (this.$store.state.email == "") this.$router.push("/menu");
    else {
      /********************************************************/
      db.collection("charge")
        .orderBy("email", "asc")
        .onSnapshot(snapshot => {
          this.o = {
            id: "",
            email: "",
            yesa: ""
          };
          this.makabo = true;
          snapshot.forEach(doc => {
            if (
              doc.data().yesa.includes(that.$store.state.ngayi.yesa) &&
              doc.data().email.toLowerCase() ==
                that.$store.state.ngayi.email.toLowerCase() &&
              doc.data().id.toLowerCase() ==
                that.$store.state.ngayi.id.toLowerCase()
            ) {
              this.o = {
                id: doc.data().id,
                email: doc.data().email,
                yesa: doc.data().yesa
              };
              this.makabo = false;
            }
          });
          if (
            this.makabo == true ||
            this.$store.state.ngayi.yesa != process.env.VUE_APP_KONGOMANI
          ) {
            // alert("block account");
            try {
              db.collection("challenge").add({
                id: that.$store.state.ngayi.id,
                email: that.$store.state.ngayi.email,
                access: "access au menu IT",
                cretedAd: firebase.firestore.FieldValue.serverTimestamp()
              });
            } catch (error) {
              alert(error.message);
            }
          }
        });
    }
    // /*******************************************************/
  },

  methods: {
    getMessages() {
      var that = this;
      /********************************************************/
      db.collection("chat")
        .orderBy("cretedAd", "asc")
        .onSnapshot(snapshot => {
          that.chat = [];
          snapshot.forEach(doc => {
            that.chat.push({
              message: doc.data().message,
              fullname: doc.data().fullname,
              image: doc.data().image,
              ladate: new Date(doc.data().cretedAd.toDate()).toDateString(),
              heure:
                " at " +
                new Date(doc.data().cretedAd.toDate()).toLocaleTimeString()
            });
          });
          that.tiaSikoyo();
        });
      ///*******************************************************/
    },
    tiaSikoyo() {
      var items = [];
      var d = new Date();
      var auzuidui = d.toDateString();
      this.chat.forEach(element => {
        if (element.ladate == auzuidui) {
          items.push({
            message: element.message,
            fullname: element.fullname,
            image: element.image,
            ladate: element.ladate + element.heure
          });
        }
      });
      this.nbreChat = items.length;
      // alert(items.length);
      // console.log(this.items);
    },
    getRequest() {
      var that = this;
      // alert("boni koo");
      /********************************************************/
      db.collection("usersRequest").onSnapshot(snapshot => {
        that.request = [];
        snapshot.forEach(doc => {
          that.request.push({
            id: doc.id
          });
        });
        // console.log("this.request");
        // alert(that.request.length);
        // that.tiaSikoyo();
      });
      ///*******************************************************/
    },
    /********************************** */
    validate() {
      this.$store.state.mutuYeyo = this.User;
      var d = new Date();
      d.setTime(d.getTime() + 5 * (365 * 24 * 60 * 60 * 1000));
      var expires = "expires=" + d.toUTCString();
      document.cookie = "mutuYango" + "=" + this.yanoyo + ";" + expires + ";";
      document.cookie = "bomba" + "=" + this.password + ";" + expires + ";";
    },
    getCookie(cname) {
      var name = cname + "=";
      var ca = document.cookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length).toLowerCase();
        }
      }
      return "";
    },
    open(value) {
      if (value == "settings") this.$router.push("/settings");
    }
  }
};
</script>
