<template>
  <!-- App.vue -->
  <v-app>
    <v-app-bar app class="elevation-0 white">
      <v-btn text color="white" onclick="window.history.back()">
        <font-awesome-icon
          :icon="['fas', 'arrow-left']"
          class="fa-x black--text mr-2"
        />
        retour
      </v-btn>
    </v-app-bar>
    <!-- Sizes your content based upon application components -->

    <v-main class="py-5" style="background-color:rgb(235, 236, 239)">
      <transition name="slide-fade">
        <div v-if="show">
          <section class="bg-col-image">
            <div
              style="height: 100%;width: 100%;background: rgba(11,18,81,0.66);padding: 40px 0px;"
            >
              <div class="container py-0">
                <div class="row">
                  <div
                    class="col-12 d-flex justify-content-center align-items-center flex-column"
                    style="text-align: center;"
                  >
                    <h1 class="white--text display-2 font-weight-bold pt-5">
                      {{ business.name }}
                    </h1>
                    <!-- Image -->
                    <div v-if="business.image_1 != ''">
                      <br />
                      <img
                        class="img-fluid d-none d-md-block"
                        :src="business.image_1"
                        style="height:400px"
                      />
                      <img
                        class="img-fluid d-block d-md-none"
                        style="max-height:400px"
                        :src="business.image_1"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div class="container">
            <v-simple-table class="bg-transparent py-5">
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td class="font-weight-bold">Nom:</td>
                    <td>{{ business.name }}</td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">Tel:</td>
                    <td>{{ business.tel }}</td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">Email:</td>
                    <td>{{ business.email }}</td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">Address:</td>
                    <td>{{ business.adresse }}</td>
                  </tr>
                  <tr v-if="business.link != ''">
                    <td class="font-weight-bold">Site web:</td>
                    <td>
                      <v-btn
                        class="white--text"
                        style="background: rgb(11,18,81);"
                        small
                        :href="business.link"
                        target="_blank"
                        >Ouvrir</v-btn
                      >
                    </td>
                  </tr>
                  <tr>
                    <td class="font-weight-bold">Descriptions:</td>
                    <td>{{ business.descriptions }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>

            <div class="mt-5 pt-5">
              <h4>Images</h4>
              <br />
              <div class="row">
                <!-- col 2 -->
                <div
                  class="col-12 col-md-4 grey lighten-2"
                  v-if="business.image_2"
                >
                  <img class="img-fluid" :src="business.image_2" />
                </div>
                <!-- col 3 -->
                <div
                  class="col-12 col-md-4 grey lighten-2"
                  v-if="business.image_3"
                >
                  <img class="img-fluid" :src="business.image_3" />
                </div>
              </div>
            </div>
          </div>

          <!-- container -->
          <div class="container">
            <div class="mb-2 px-5">
              <span class="title black--text">{{
                "Par " + business.auteur
              }}</span>
            </div>
            <div v-if="business.myImage">
              <img
                class="img-fluid px-5"
                :src="business.myImage"
                style="height:200px"
              />
            </div>
            <div v-else>
              <img
                class="img-fluid px-5"
                :src="require('../../images/unknow.jpg')"
                style="height:200px"
              />
            </div>
          </div>
        </div>
      </transition>
    </v-main>
    <div class="d-none">{{ ouvrirTransition }}</div>
  </v-app>
</template>

<script>
import firebase from "firebase";
import db from "../../db.js";

export default {
  data() {
    return {
      email: "kerene@example.com",
      show: false,
      password: "plus1234",
      dialog: false,
      place: "",
      business: []
    };
  },
  created() {
    var that = this;
    var user = firebase.auth().currentUser;
    if (
      this.$store.state.ngayi.email == "" ||
      this.$store.state.ngayi.email == undefined ||
      user.email == "" ||
      user.email == undefined
    )
      this.$router.push("/menu");

    this.business = [];
    var auteur = this.$store.state.businesses.whoID;
    var img = "";
    var nom = "";
    db.collection("users")
      .where("id", "==", auteur)
      .onSnapshot(snapshot => {
        snapshot.forEach(doc => {
          img = doc.data().image;
          nom =
            doc.data().nom + " " + doc.data().postnom + " " + doc.data().prenom;
        });
        that.business = that.$store.state.businesses;
        that.business.myImage = img;
        that.business.auteur = nom;
        console.log("that.business");
        console.log(that.business);
      });
  },
  methods: {
    leTemps() {
      setTimeout(() => {
        this.changedValue();
        return false;
      }, 100);
      return 0;
    },
    changedValue() {
      this.show = true;
      return false;
    }
  },
  computed: {
    ouvrirTransition() {
      this.leTemps();
      return false;
    }
  }
};
</script>
