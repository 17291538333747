import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../components/Home.vue")
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../components/Login.vue")
  },
  {
    path: "/settings",
    name: "Settings",
    component: () => import("../components/Settings.vue")
  },
  // {
  //   path: "/signup",
  //   name: "signup",
  //   component: () => import("../components/Signup.vue")
  // },
  // {
  //   path: "/signupbefore",
  //   name: "signupbefore",
  //   component: () => import("../components/Signupbefore.vue")
  // },
  {
    path: "/menu",
    name: "menu",
    component: () => import("../components/users/Menu.vue")
  },
  {
    path: "/terms_and_condition",
    name: "terms",
    component: () => import("../components/users/Terms.vue")
  },
  {
    path: "/menu_pro",
    name: "menu_pro",
    component: () => import("../components/users/Menu_pro.vue")
  },
  {
    path: "/menu_pre",
    name: "menu_pre",
    component: () => import("../components/users/Menu_pre.vue")
  },
  {
    path: "/menu_it",
    name: "menu_it",
    component: () => import("../components/users/Menu_IT.vue")
  },
  {
    path: "/news",
    name: "news",
    component: () => import("../components/users/News.vue")
  },
  {
    path: "/videos",
    name: "videos",
    component: () => import("../components/users/Video.vue")
  },
  {
    path: "/entrez",
    name: "entrez",
    component: () => import("../components/users/Entrez.vue")
  },
  {
    path: "/demographie",
    name: "demographie",
    component: () => import("../components/users/Demographie.vue")
  },
  {
    path: "/demographiep",
    name: "demographiep",
    component: () => import("../components/users/Demographie_pro.vue")
  },
  {
    path: "/demographie_city",
    name: "demographie_city",
    component: () => import("../components/users/Demographie_city.vue")
  },
  {
    path: "/people_from_city",
    name: "people_from_city",
    component: () => import("../components/users/People_from_city.vue")
  },
  {
    path: "/person_profile",
    name: "person_profile",
    component: () => import("../components/users/Persone_Profil.vue")
  },
  {
    path: "/business",
    name: "Business",
    component: () => import("../components/users/Business.vue")
  },
  {
    path: "/excel",
    name: "Excel",
    component: () => import("../components/users/Excel.vue")
  },
  {
    path: "/business_details",
    name: "Business Details",
    component: () => import("../components/users/Business_details.vue")
  },
  {
    path: "/person_profile_death",
    name: "person_profile_death",
    component: () => import("../components/users/Persone_Profil_death.vue")
  },
  {
    path: "/link_with_family",
    name: "link_with_family",
    component: () => import("../components/users/Link_with_family.vue")
  },
  {
    path: "/finance",
    name: "finance",
    component: () => import("../components/users/Finance.vue")
  },
  {
    path: "/finances",
    name: "finances",
    component: () => import("../components/users/Finances.vue")
  },
  {
    path: "/news_details",
    name: "news_details",
    component: () => import("../components/users/News_details.vue")
  },
  {
    path: "/news_adm",
    name: "news_adm",
    component: () => import("../components/admin/News.vue")
  },
  {
    path: "/videos_adm",
    name: "videos_adm",
    component: () => import("../components/admin/Video.vue")
  },
  {
    path: "/videos_details",
    name: "videos_details",
    component: () => import("../components/users/Video_details.vue")
  },
  {
    path: "/activites",
    name: "activites",
    component: () => import("../components/users/Activités.vue")
  },
  {
    path: "/activites_adm",
    name: "activites_adm",
    component: () => import("../components/admin/Activités.vue")
  },
  {
    path: "/activites_details",
    name: "activites_details",
    component: () => import("../components/users/Activités_details.vue")
  },
  {
    path: "/profil",
    name: "profil",
    component: () => import("../components/users/Profile.vue")
  },
  {
    path: "/tout_le_monde",
    name: "tout_le_monde",
    component: () => import("../components/users/Tout_le_monde.vue")
  },
  {
    path: "/updateProfile",
    name: "updateProfile",
    component: () => import("../components/users/UpdateProfile.vue")
  },
  {
    path: "/cjmgbEssJpxTTycUeGk0rp1BqmiIvMqmjWLxAP/u7BU=",
    name: "info",
    component: () => import("../components/users/GetProfile.vue")
  },
  {
    path: "/creation",
    name: "creation",
    component: () => import("../components/users/Creation.vue")
  },
  {
    path: "/ajouter",
    name: "ajouter",
    component: () => import("../components/users/Ajouter.vue")
  },
  {
    path: "/family",
    name: "family",
    component: () => import("../components/users/Family.vue")
  },
  {
    path: "/roommate",
    name: "roommate",
    component: () => import("../components/users/Roomate.vue")
  },
  {
    path: "/payment",
    name: "payment",
    component: () => import("../components/users/Payment.vue")
  },
  {
    path: "/assistance",
    name: "assistance",
    component: () => import("../components/users/Assistance.vue")
  },
  {
    path: "/assistance_pro",
    name: "assistance_pro",
    component: () => import("../components/users/Assistance_pro.vue")
  },
  {
    path: "/luka",
    name: "luka",
    component: () => import("../components/users/Search.vue")
  },
  {
    path: "/chat",
    name: "chat",
    component: () => import("../components/users/Chat.vue")
  },
  {
    path: "/chat_ouverture",
    name: "chat_ouverture",
    component: () => import("../components/users/Chat_ouverture.vue")
  },
  {
    path: "/request",
    name: "request",
    component: () => import("../components/users/Request.vue")
  },
  {
    path: "/voir_plus",
    name: "voir_plus",
    component: () => import("../components/users/Voir_plus.vue")
  },
  {
    path: "/signaler",
    name: "Signaler",
    component: () => import("../components/users/Signaler.vue")
  },
  {
    path: "/setMyProfile",
    name: "setMyProfile",
    component: () => import("../components/users/_Profile.vue")
  },
  {
    path: "/setMyInfo",
    name: "setMyInfo",
    component: () => import("../components/users/_Infos.vue")
  },
  {
    path: "/setMySchool",
    name: "setMySchool",
    component: () => import("../components/users/_School.vue")
  },
  {
    path: "/setMyJob",
    name: "setMyJob",
    component: () => import("../components/users/_Job.vue")
  },
  {
    path: "/setMyAccount",
    name: "setMyAccount",
    component: () => import("../components/users/_Account.vue")
  },
  {
    path: "/setMyFinish",
    name: "setMyFinish",
    component: () => import("../components/users/_Finish.vue")
  },
  {
    path: "/setMyAddress",
    name: "setMyAddress",
    component: () => import("../components/users/_Address.vue")
  }
];

const router = new VueRouter({
  routes
});

export default router;
