<template>
    <div class="row bg-light d-flex align-items-center justify-center" style="min-height: 100vh;">


        <!-- Message -->
        <v-col cols="12" class="mt-5 py-5 text-center">

            <v-progress-linear v-model="knowledge" color="success" height="35">
                <strong>100%</strong>
            </v-progress-linear>
            
            <font-awesome-icon :icon="['fa', 'thumbs-up']" class="fa-4x my-5" />
            <h3>
                <b>{{ $store.state.ngayi.prenom + " "+$store.state.ngayi.nom+" "+$store.state.ngayi.postnom }}</b>
            </h3>
            <h5>Merci pour votre participation!</h5>
        </v-col>

    </div>
</template>

<script>
    export default {
        data() {
            return {
                knowledge: 100
            }
        },
        created(){
            // setTimeout(() => {
            // }, 1000);
        }
    }
</script>

<style>

</style>