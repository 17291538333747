<template>
  <!-- App.vue -->
  <v-app>
    <v-app-bar class="elevation-0 white" app>
      <v-btn text color="white" onclick="window.history.back()">
        <font-awesome-icon
          :icon="['fas', 'chevron-left']"
          class="fa-x black--text mr-2"
        />
      </v-btn>
      <v-spacer></v-spacer>
      <!-- <h4 class="black--text">Démographie</h4> -->
      <v-spacer></v-spacer>
    </v-app-bar>
    <!-- Sizes your content based upon application components -->

    <v-main
      class="main p-0 d-print-none"
      style="background-color:rgb(235, 236, 239)"
    >
      <transition name="slide-fade">
        <div v-if="show">
          <div class="py-5" style="background: rgba(0,0,0,.0);height: 100%;">
            <section class="bg-col-image">
              <div
                style="height: 100%;width: 100%;background: rgba(11,18,81,0.66);padding: 80px 0px;"
              >
                <div class="container py-0">
                  <div class="row">
                    <div
                      class="col-12 d-flex justify-content-center align-items-center flex-column"
                      style="text-align: center;"
                    >
                      <h3
                        class="white--text font-weight-bold pt-5 text-uppercase"
                      >
                        Total des Congolais enrôlés <br />
                        (Johnson & Linn Counties)
                      </h3>
                      <br />
                      <number
                        class="display-3 white--text font-weight-bold"
                        ref="number1"
                        :from="0"
                        :to="users.length"
                        :format="theFormat"
                        :duration="0.5"
                        :delay="0"
                        easing="Power1.easeOut"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <div class="container pt-5">
              <div class="w-100 text-center my-5">
                <!-- <h4 class="black--text">Démographie</h4> -->
              </div>
              <v-row justify="center" class="my-5">
                
                <!------------------------ Male ------------------------>
                <v-col cols="6" md="6" lg="4">
                  <v-card
                    height="100%"
                    class="elevation-0 p-0 px-3 white py-5 d-flex justify-center align-items-center flex-column bg-transparent"
                  >
                  <font-awesome-icon
                    :icon="['fas', 'male']"
                    class="fa-3x black--text m-2"
                  />
                    <h5>Hommes</h5>
                    <h3 class="px-5">{{ ho +"/"+ users.length}}</h3>
                    <div class="mt-5">
                    <apexchart
                      class="red--text"
                      type="radialBar"
                      :options="chartOptions"
                      :series="seriesHomme"
                    ></apexchart>
                    </div>
                  </v-card>
                </v-col>
                
                <!------------------------ Female ------------------------>
                <v-col cols="6" md="6" lg="4">
                  <v-card
                    height="100%"
                    class="elevation-0 p-0 px-3 white py-5 d-flex justify-center align-items-center flex-column bg-transparent"
                  >
                  <font-awesome-icon
                    :icon="['fas', 'female']"
                    class="fa-3x black--text m-2"
                  />
                    <h5>Femmes</h5>
                    <h3 class="px-5">{{ fe +"/"+ users.length}}</h3>
                    <div class="mt-5">
                    <apexchart
                      class="red--text"
                      type="radialBar"
                      :options="chartOptionsFemme"
                      :series="seriesFemme"
                    ></apexchart>
                    </div>
                  </v-card>
                </v-col>
                
                <!------------------------ Kid ------------------------>
                <v-col cols="6" md="6" lg="4">
                  <v-card
                    height="100%"
                    class="elevation-0 p-0 px-3 white py-5 d-flex justify-center align-items-center flex-column bg-transparent"
                  >
                  <font-awesome-icon
                    :icon="['fa', 'child']"
                    class="fa-3x black--text m-2"
                  />
                    <h5>Enfants</h5>
                    <h3 class="px-5">{{ ch +"/"+ users.length}}</h3>
                    <div class="mt-5">
                    <apexchart
                      class="red--text"
                      type="radialBar"
                      :options="chartOptionsChild"
                      :series="seriesChild"
                    ></apexchart>
                    </div>
                  </v-card>
                </v-col>

                <!------------------------ Ont du travail ------------------------>
                <v-col cols="6" md="6" lg="4">
                  <v-card
                    height="100%"
                    class="elevation-0 p-0 px-3 white py-5 d-flex justify-center align-items-center flex-column bg-transparent"
                  >
                  <font-awesome-icon
                    :icon="['fas', 'briefcase']"
                    class="fa-3x black--text m-2"
                  />
                    <h5>Employés</h5>
                    <h3 class="px-5">{{ ha +"/"+ users.length}}</h3>
                    <div class="mt-5">
                    <apexchart
                      class="red--text"
                      type="radialBar"
                      :options="chartOptionsJob"
                      :series="seriesJob"
                    ></apexchart>
                    </div>
                  </v-card>
                </v-col>

                <!------------------------ Unemployment ------------------------>
                <v-col cols="6" md="6" lg="4">
                  <v-card
                    height="100%"
                    class="elevation-0 p-0 px-3 white py-5 d-flex justify-center align-items-center flex-column bg-transparent"
                  >
                  <font-awesome-icon
                    :icon="['fas', 'times-circle']"
                    class="fa-3x black--text m-2"
                  />
                    <h5>Non-Employés</h5>
                    <h3 class="px-5">{{ un +"/"+ users.length}}</h3>
                    <div class="mt-5">
                    <apexchart
                      class="red--text"
                      type="radialBar"
                      :options="chartOptionsUnemployment"
                      :series="seriesUnemployment"
                    ></apexchart>
                    </div>
                  </v-card>
                </v-col>

                <!------------------------ Vont à l'école ------------------------>
                <v-col cols="6" md="6" lg="4">
                  <v-card
                    height="100%"
                    class="elevation-0 p-0 px-3 white py-5 d-flex justify-center align-items-center flex-column bg-transparent"
                  >
                  <font-awesome-icon
                    :icon="['fas', 'graduation-cap']"
                    class="fa-3x black--text m-2"
                  />
                    <h5>Etudiants</h5>
                    <h3 class="px-5">{{ en +"/"+ users.length}}</h3>
                    <div class="mt-5">
                    <apexchart
                      class="red--text"
                      type="radialBar"
                      :options="chartOptionsSchool"
                      :series="seriesSchool"
                    ></apexchart>
                    </div>
                  </v-card>
                </v-col>

                <v-col cols="12">
                  <v-divider></v-divider>
                  <section class="bg-col-images whites">
                    <div
                      style="height: 100%;width: 100%;backgrounds: rgba(11,18,81,0.66);padding: 20px 0px;"
                    >
                      <div class="container py-0">
                        <div class="row">
                          <div
                            class="col-12 d-flex justify-content-center align-items-center flex-column"
                            style="text-align: center;"
                          >
                            <h2
                              class="text-center mt-5 black--text text-uppercase"
                            >
                              Statistiques par ville
                            </h2>
                            <h5 class="text-center mb-5 black--text">
                              Cliquez sur une ville pour en savoir plus
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </v-col>

                <v-col cols="12" class="py-0 px-5 white">
                  <v-card height="100%" class="elevation-0 py-5 bg-transparent">
                    <!-- <h2 class="text-center mt-5 black--text text-uppercase">
                  Statistiques par ville
                </h2>
                <h5 class="text-center mb-5 black--text">
                  Cliquez sur une ville pour en savoir plus
                </h5> -->

                    <v-simple-table class="bg-transparent">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              City name
                            </th>
                            <th class="text-left">
                              Pourcentage
                            </th>
                            <th class="text-left">
                              Total
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(x, k) in cities"
                            :key="k"
                            @click="openCity(x)"
                            >
                            <td>
                              <h6 class="font-weight-bold text-capitalize">
                                {{ x.name }}
                              </h6>
                            </td>
                            <td class="py-5">
                              <v-progress-linear
                                :color="x.color"
                                height="20"
                                :value="x.pourcentage"
                              >
                                <template v-slot:default="{ value }">
                                  <strong>{{ value + "%" }}</strong>
                                </template>
                              </v-progress-linear>
                            </td>
                            <td>
                              <h6 class="font-weight-normal title">
                                {{ x.total }}
                              </h6>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card>
                </v-col>

                <v-col cols="12" class="d-nones d-md-block white py-0">
                  <v-card height="100%" class="elevation-0 py-5 bg-transparent">
                    <apexchart
                      type="bar"
                      labels="asdksad"
                      height="350"
                      class="w-100"
                      :options="chartOptionsCollum"
                      :series="seriesCollum"
                    >
                    </apexchart>
                  </v-card>
                </v-col>

                <!-- <v-col cols="12" lg="6" class="black py-5 px-5"></v-col> -->
              </v-row>
            </div>
          </div>
        </div>
      </transition>
    </v-main>
    <div class="d-none">{{ ouvrirTransition }}</div>
  </v-app>
</template>

<script>
// import ServiceApi from "../ServicesApi.js";
// import firebase from "firebase";
import db from "../../db.js";
export default {
  data() {
    return {
      ho: "",
      fe: "",
      ha: "",
      un: "",
      en: "",
      ch: "",
      email: "",
      show: false,
      password: "",
      erreur: "",
      dialogErreur: false,
      place: "",
      valeur: 50,
      users: [],
      color: [
        "yellow darken-3",
        "primary ligtenen-3",
        "red lighten-2",
        "green darken-1",
        "deep-purple lighten-1",
        "pink darken-1",
        "yellow darken-3",
        "primary ligtenen-3",
        "red lighten-2",
        "green darken-1",
        "orange lighten-2",
        "pink darken-1",
        "yellow darken-3",
        "primary ligtenen-3",
        "red lighten-2",
        "green darken-1",
        "orange lighten-2",
        "pink darken-1"
      ],

      people: [],
      schools: [],
      jobs: [],
      H: 0,
      F: 0,
      age: 0,
      cities: [],
      //   AppCHart
      seriesFemme: [0],
      chartOptionsFemme: {
        chart: {
          type: "radialBar",
          offsetY: -20,
          sparkline: {
            enabled: true
          }
        },
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            track: {
              background: "#CCCCCC",
              strokeWidth: "97%",
              margin: 5, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                color: "white",
                opacity: 1,
                blur: 2
              }
            },
            dataLabels: {
              name: {
                show: false
              },
              value: {
                offsetY: -2,
                fontSize: "20px",
                color: "#000000"
              }
            }
          }
        },
        grid: {
          padding: {
            top: -10
          }
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "light",
            type: "horizontal",
            shadeIntensity: 0.5,
            gradientToColors: ["#9E77F2"],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 0,
            stops: [100, 100, 100, 100]
          }
        },

        labels: ["Average Results"]
      },

      seriesHomme: [0],
      chartOptions: {
        chart: {
          type: "radialBar",
          offsetY: -20,
          sparkline: {
            enabled: true
          }
        },
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            track: {
              background: "#CCCCCC",
              strokeWidth: "97%",
              margin: 5, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                color: "white",
                opacity: 1,
                blur: 2
              }
            },
            dataLabels: {
              name: {
                show: false
              },
              value: {
                offsetY: -2,
                fontSize: "20px",
                color: "#000000"
              }
            }
          }
        },
        grid: {
          padding: {
            top: -10
          }
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "light",
            type: "horizontal",
            shadeIntensity: 0.5,
            gradientToColors: ["#F9A825"],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 0,
            stops: [100, 100, 100, 100]
          }
        },

        labels: ["Average Results"]
      },
      // end AppChart

      colors: [
        "#F9A825",
        "#0091EA",
        "#E57373",
        "#43A047",
        "#FFB74D",
        "#D81B60",
        "#7E57C2",
        "#7E57C2"
      ],

      seriesCollum: [
        {
          data: []
        }
      ],

      chartOptionsCollum: {
        chart: {
          height: 350,
          type: "bar",
          events: {
            click: function(chart, w, e) {
              var a = chart;
              a = e;
              a = w;
              a = "";
              console.log(a);
            }
          }
        },
        colors: [
          "#F9A825",
          "#0091EA",
          "#E57373",
          "#43A047",
          "#7E57C2",
          "#D81B60",
          "#7E57C2",
          "#7E57C2"
        ],
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true
          }
        },
        dataLabels: {
          enabled: true
        },
        legend: {
          show: true
        },
        xaxis: {
          categories: [
            "Cedar rapids",
            "Coralville",
            "Iowa city",
            "North liberty",
            "Tiffin"
          ],
          labels: {
            style: {
              colors: "#000000",
              fontSize: "12px"
            }
          },
          title: {
            text: "COMCO - Johnson & Linn Counties",
            style: {
              colors: "#000000",
              fontSize: "20px",
              padding: "20px 0px"
            }
          }
        },
        yaxis: {
          labels: {
            style: {
              colors: "#000000",
              fontSize: "12px"
            }
          },
          title: {
            text: "Iowa, USA",
            style: {
              colors: "#000000",
              fontSize: "15px",
              padding: "0px 20px"
            }
          }
        }
      },

      // Job
      seriesJob: [],
      chartOptionsJob: {
        chart: {
          type: "radialBar",
          offsetY: -20,
          sparkline: {
            enabled: true
          }
        },
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            track: {
              background: "#CCCCCC",
              strokeWidth: "97%",
              margin: 5, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                color: "#999",
                opacity: 1,
                blur: 2
              }
            },
            dataLabels: {
              name: {
                show: false
              },
              value: {
                offsetY: -2,
                fontSize: "20px",
                color: "#000000"
              }
            }
          }
        },
        grid: {
          padding: {
            top: -10
          }
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "light",
            type: "horizontal",
            shadeIntensity: 0.5,
            gradientToColors: ["#EAE900"],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 0,
            stops: [100, 100, 100, 100]
          }
        },
        labels: ["Average Results"]
      },

      // Unemployment
      seriesUnemployment: [],
      chartOptionsUnemployment: {
        chart: {
          type: "radialBar",
          offsetY: -20,
          sparkline: {
            enabled: true
          }
        },
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            track: {
              background: "#CCCCCC",
              strokeWidth: "97%",
              margin: 5, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                color: "#999",
                opacity: 1,
                blur: 2
              }
            },
            dataLabels: {
              name: {
                show: false
              },
              value: {
                offsetY: -2,
                fontSize: "20px",
                color: "#000000"
              }
            }
          }
        },
        grid: {
          padding: {
            top: -10
          }
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "light",
            type: "horizontal",
            shadeIntensity: 0.5,
            gradientToColors: ["#E57373"],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 0,
            stops: [100, 100, 100, 100]
          }
        },
        labels: ["Average Results"]
      },

      // School
      seriesSchool: [],
      chartOptionsSchool: {
        chart: {
          type: "radialBar",
          offsetY: -20,
          sparkline: {
            enabled: true
          }
        },
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            track: {
              background: "#CCCCCC",
              strokeWidth: "97%",
              margin: 5, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                color: "#999",
                opacity: 1,
                blur: 2
              }
            },
            dataLabels: {
              name: {
                show: false
              },
              value: {
                offsetY: -2,
                fontSize: "20px",
                color: "#000000"
              }
            }
          }
        },
        grid: {
          padding: {
            top: -10
          }
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "light",
            type: "horizontal",
            shadeIntensity: 0.5,
            gradientToColors: ["#86cdff"],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 0,
            stops: [100, 100, 100, 100]
          }
        },
        labels: ["Average Results"]
      },

      // Child
      seriesChild: [],
      chartOptionsChild: {
        chart: {
          type: "radialBar",
          offsetY: -20,
          sparkline: {
            enabled: true
          }
        },
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            track: {
              background: "#CCCCCC",
              strokeWidth: "97%",
              margin: 5, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                color: "#999",
                opacity: 1,
                blur: 2
              }
            },
            dataLabels: {
              name: {
                show: false
              },
              value: {
                offsetY: -2,
                fontSize: "20px",
                color: "#000000"
              }
            }
          }
        },
        grid: {
          padding: {
            top: -10
          }
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "light",
            type: "horizontal",
            shadeIntensity: 0.5,
            gradientToColors: ["#489925"],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 0,
            stops: [100, 100, 100, 100]
          }
        },
        labels: ["Average Results"]
      }
    };
  },
  created() {
    // var user = firebase.auth().currentUser;
    // if (
    //   this.$store.state.ngayi.email == "" ||
    //   this.$store.state.ngayi.email == undefined ||
    //   user.email == "" ||
    //   user.email == undefined
    // )
    //   this.$router.push("/menu");
  },
  mounted() {
    var that = this;
    /***************************************/
    db.collection("users").onSnapshot(snapshot => {
      that.users = [];
      snapshot.forEach(doc => {
        that.users.push({
          id: doc.data().id,
          // date_de_naissance: doc.data().date_de_naissance,
          sex: doc.data().sex,
          city: doc.data().city,
          // zipcode: doc.data().zipcode,
          // state: doc.data().state,
          year: "",
          // date: new Date(doc.data().cretedAd.toDate()).toDateString(),
          // heure:
          //   " at " + new Date(doc.data().cretedAd.toDate()).toLocaleTimeString()
        });
      });
      // console.log("tab");
      // console.log(that.users);
      that.cities = [];
      that.compter();
    });
    /***************************************/
  },
  computed: {
    ouvrirTransition() {
      this.leTemps();
      return false;
    }
  },

  methods: {
    analyticsGoogle(category, action, label){
      this.$ga.event(category, action, label, 1)
    },
    compter() {
      this.$store.state.users = this.users;
      var ctr = 0;
      this.seriesCollum = [];
      this.seriesChild = [];
      var data = [];
      this.cities = [];

      this.chartOptionsCollum.xaxis.categories = [
        ["1", "0"],
        ["3", "0"],
        ["5", "0"],
        "7",
        ["8", "0"],
        ["10", "11"]
      ];

      // "davenport",
      // "columbus junction",
      // "des moines",
      // "muscantine",
      // "ottumwa",
      // "sioux city",
      // "tama",
      // "waterloo",
      var cities = [
        "cedar rapids",
        "coralville",
        "iowa city",
        "north liberty",
        "tiffin"
      ];
      for (let index = 0; index < cities.length; index++) {
        ctr = 0;
        for (let i = 0; i < this.users.length; i++) {
          if(this.users[i].city){
            if (this.users[i].city.toLowerCase() == cities[index].toLowerCase()) {
              ctr++;
            }
          }
        }
        var val = 0;
        if ((ctr / this.users.length) * 100 != "Infinity")
          val = (ctr * 100) / this.users.length;
        var obj = {
          name: cities[index],
          color: this.color[index],
          pourcentage: val.toFixed(2),
          total: ctr
        };

        // data
        data.push(ctr);

        this.cities.push(obj);
      }

      //Enter data as object
      // console.log("data");
      // console.log(data);
      var objData = {
        data: data
      };
      this.seriesCollum.push(objData);

      ///******************************************** */
      var H = 0;
      var F = 0;

      
      for (let i = 0; i < this.users.length; i++) {
        
        //Age
        // var age = this.users[i].date_de_naissance.slice(0, 4);
        // var d = new Date();
        // var annee = d.getFullYear();
        // console.log("age == " + (Number(annee) - Number(age)));
        
        //If kid
        // if (Number(annee) - Number(age) < 18) {
          // this.age++;
        // }else{
          //Sort by sex
          if(this.users[i].sex){
            if (this.users[i].sex.toLowerCase() == "f") F++;
            else H++;
          }
        // }

      }
      this.seriesHomme = [];
      this.seriesFemme = [];
      this.seriesHomme.push((H / this.users.length) * 100);
      this.seriesFemme.push((F / this.users.length) * 100);
      this.ho = H;
      this.fe = F;
      this.seriesHomme[0] = this.seriesHomme[0].toFixed(1);
      this.seriesFemme[0] = this.seriesFemme[0].toFixed(1);

      console.log("ouside");
      // this.ch = this.age;
      // var agePourcentage = (this.age * 100) / this.users.length;
      // this.seriesChild.push(agePourcentage);
      // this.seriesChild[0] = this.seriesChild[0].toFixed(1);

      this.ch = 0;
      var agePourcentage = (0 * 100) / this.users.length;
      this.seriesChild.push(agePourcentage);
      this.seriesChild[0] = this.seriesChild[0].toFixed(1);

      
      this.getSchool();
      this.getJob();
    },

    theFormat(number) {
      return number.toFixed(0);
    },
    openCity(city) {
      this.$store.state.city = city;
      var lelabel = "Open stats for "+city.name;
      console.log(lelabel);
      this.analyticsGoogle("Demographie", lelabel, "Demography label");
      this.$router.push("/demographie_city");
    },

    /********************************** */
    validate() {
      this.$store.state.mutuYeyo = this.User;
      var d = new Date();
      d.setTime(d.getTime() + 5 * (365 * 24 * 60 * 60 * 1000));
      var expires = "expires=" + d.toUTCString();
      document.cookie = "mutuYango" + "=" + this.yanoyo + ";" + expires + ";";
      document.cookie = "bomba" + "=" + this.password + ";" + expires + ";";
    },

    getSchool() {
      var that = this;
      this.seriesSchool = [];
      /********************************************************/
      db.collection("school")
        .where("school", "==", true)
        .onSnapshot(snapshot => {
          var ctr = 0;
          snapshot.forEach(doc => {
            that.seriesSchool = [];
              var a = doc.id;
              // console.log("SCHOOOOOOOOOOOL");
              a = "";    
              console.log(a);
              ctr++;
          });
          that.seriesSchool.push((ctr * 100) / that.users.length);
          try {
            
            that.seriesSchool[0] = that.seriesSchool[0].toFixed(1);
          } catch (error) {
            console.log(error);
          }
          that.en = ctr;
        });
    },
    getJob() {
      var that = this;
      this.seriesJob = [];
      /********************************************************/
      db.collection("job")
        .where("job", "==", true)
        .onSnapshot(snapshot => {
          var ctr = 0;
          snapshot.forEach(doc => {
            that.seriesJob = [];
            that.seriesUnemployment = [];
              var a = doc.id;
              a = "";
              console.log(a);

            // if (doc.data().email != email) {
            //   // Age
            //   var age = doc.data().date_de_naissance;
            //   var d = new Date();
            //   var annee = d.getFullYear();
            //   if (Number(annee) - Number(age) > 17) ctr++;
            // }
            // email = doc.data().email;
            ctr++;
          });

          // alert(this.age);
          // var numbre = Number(this.users.length) - Number(this.age);
          var numbre = Number(this.users.length);
          // alert(numbre);
          var valeur = (ctr * 100) / numbre;
          that.ha = ctr;
          that.un = that.users.length - ctr;
          var Unemployment = 100 - Number(valeur);
          that.seriesJob.push(valeur);
          that.seriesJob[0] = that.seriesJob[0].toFixed(1);
          that.seriesUnemployment.push(Unemployment);
          that.seriesUnemployment[0] = that.seriesUnemployment[0].toFixed(1);
        });
    },
    leTemps() {
      setTimeout(() => {
        this.changedValue();
        return false;
      }, 100);
      return 0;
    },
    changedValue() {
      this.show = true;
      return false;
    }
  }
};
</script>
