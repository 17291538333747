<template>
    <v-app>
        <v-main class="d-flex align-items-center grey lighten-3 pb-5">
            <v-container class="text-center">

                <v-progress-linear v-model="knowledge" :color="couleur" height="25" v-if="knowledge>0">
                    <strong>{{ Math.ceil(knowledge) }}%</strong>
                </v-progress-linear>

                <v-row class="d-flex justify-center blues darken-4">

                    <!-- Terms -->
                    <v-col cols="12">
                        <Terms />
                        <div class="mt-3">
                            <v-btn class="m-1" color="success" href="#/setMyInfo">I agree
                            
                            </v-btn>
                        </div>
                    </v-col>

                </v-row>
            </v-container>
        </v-main>
        <v-dialog persistent v-model="dialogWait" max-width="200">
            <v-card class="text-center py-3">
                <v-progress-circular :size="30" color="primary" indeterminate></v-progress-circular>
                <span class="ml-2">Please wait ...</span>
            </v-card>
        </v-dialog>
    </v-app> 
</template>

<script>
    import db from "../../db.js";
    import firebase from "firebase";

    export default {
        data() {
            return {
                users: [],
                dialogWait: false,
                part: 0,
                knowledge: 0,
                couleur: "",
                show: 'password',
                
                
                loading: false,
                password: '',
                cfrPassword: '',
                rules: {
                min: v => v.length >= 8 || 'Min 8 characters',
                emailMatch: () => (`The email and password you entered don't match`),
                },
            }
        },
        components: {
            Terms: () => import("./Terms.vue")
        },
        created() {
            this.login();
        },
        methods: {
            login() {

                
                document.cookie = "mutuYango=" + ";";
                document.cookie = "codeYango=" + ";";
                 
                if (this.$store.state.ngayi.id == null || this.$store.state.ngayi.id == "")
                    this.$router.push("/");
                else {
                    var that = this;
                    that.dialogWait = true;
                    firebase
                        .auth()
                        .signInWithEmailAndPassword(that.$store.state.provi.email, that.$store.state.provi.password)
                        .then(
                            elt => {
                                var a = elt;
                                a = "";
                                console.log(a);

                                that.$store.state.part = 0;
                                setTimeout(() => {
                                    that.dialogWait = false;
                                    this.getUser();
                                }, 2000);
                            },
                            error => {
                                // that.erreur = error.message;
                                that.dialogWait = false;
                                alert(error.message);
                                this.$router.push("/");
                            }
                        );
                }
            },
            getUser() {
                var that = this;
                db.collection("users")
                    .where("id", "==", that.$store.state.ngayi.id)
                    .onSnapshot(snapshot => {
                        that.$store.state.ngayi = [];
                        snapshot.forEach(doc => {
                            that.$store.state.ngayi = {
                                originalId: doc.id,
                                id: doc.data().id,
                                nom: doc.data().nom,
                                postnom: doc.data().postnom,
                                prenom: doc.data().prenom,
                                phone: doc.data().phone,
                                date_de_naissance: doc.data().date_de_naissance,
                                lieu_de_naissance: doc.data().lieu_de_naissance,
                                sex: doc.data().sex,
                                adresse: doc.data().adresse,
                                image: doc.data().image,
                                city: doc.data().city,
                                zipcode: doc.data().zipcode,
                                state: doc.data().state,
                                email: doc.data().email,
                                privacy: doc.data().privacy,
                                type: doc.data().type,
                                year: "",
                                yesa: doc.data().yesa,
                                date_a_iowa: doc.data().date_a_iowa
                            };
                        });
                    });
                    
            }
        }
    }
</script>

<style>

</style>