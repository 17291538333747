<template>
    <div class="bg-secondary d-flex align-items-center justify-center" style="min-height: 100vh;">
        <section class="white">
            <v-progress-linear v-model="knowledge" color="primary darken-3" style="color:white" height="35">
                    <strong>{{knowledge}}%</strong>
                </v-progress-linear>

            <h3 style="color: rgba(0,0,0,.79);padding-top:50px;" class="text-center">
                        Changer la photo de profile
                    </h3>
                    <p class="red--text px-5">Veuillez cliquer sur "{{  label }}" pour enregistrer votre photo et puis appuyez sur "Continuer"</p>
            <div style="height: 100%;width: 100%;padding: 20px 0px;">
                <div class="container py-0">
                    <div class="row">
                        <div class="col-12  d-flex flex-column justify-content-center align-items-center"
                            style="text-align: center;">
                            <img @click="SelecFile()" name="myImage" :src="$store.state.ngayi.image"
                                class="img-fluids curseur" height="350" />

                            <input type="file" id="file" @change="baPoniImages($event)"
                                accept="image/png, image/gif, image/jpeg, image/jpg" style="display:none;" />

                            <v-btn large class="black white--text elevation-s0 mt-5" @click="SelecFile()">
                                {{ label }}
                            </v-btn>
                        </div>
                    </div>
                </div>
            </div>

                <div class="text-center py-5">
                        <v-btn large class="mt-5 mx-2" color="red white--text" href="#/setMySchool">Retour</v-btn>
                        <v-btn large class="mt-5" color="primary"  href="#/setMyAccount">
                            <!-- v-if="!$store.state.misImage==true" -->
                            Continuer
                        </v-btn>
                </div>
        </section>
        <v-dialog persistent v-model="dialogWait" max-width="200">
            <v-card class="text-center py-3">
                <v-progress-circular :size="30" color="primary" indeterminate></v-progress-circular>
                <span class="ml-2">Please wait ...</span>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import db from "../../db.js";
    import firebase from "firebase";

    export default {
        data() {
            return {
                label: "Choisir une photo",
                dialogWait: false,
                knowledge: 75
            }
        },
        created() {
            // this.getUser();
        },
        methods: {
            SelecFile() {
                this.$store.state.ImagesUpload = [];
                document.getElementById("file").click();
            },
            baPoniImages(e) {
                document.getElementById("file");

                var that = this;
                e.target.files.forEach(element => {
                    that.dialogWait = true;
                    // var filename = element.name;
                    var tableau = "";

                    var storageRef = firebase
                        .storage()
                        .ref("Pictures/" + this.$store.state.ngayi.email + "/" + "profil");
                    var metadata = {
                        contentType: "image/jpeg"
                    };

                    storageRef.put(element, metadata).then(function (downloadURL) {
                        downloadURL.ref.getDownloadURL().then(function (url) {
                            url = String(url);
                            if (url !== undefined) {
                                var b = url;
                                tableau = b;
                                console.log(tableau);
                                that.dialogWait = false;
                                /****************************/
                                db.collection("users")
                                    .doc(that.$store.state.ngayi.originalId)
                                    .update({
                                        image: tableau
                                    });
                                /*************************/
                                that.$store.state.ngayi.image = tableau;
                                that.$store.state.misImage = true;
                                that.label = "Prendre une autre image" 
                                console.log(tableau);
                            }
                        });
                    });
                });
            },
        }
    }
</script>

<style>

</style>