<template>
  <!-- App.vue -->
  <!--  -->
  <v-app>
    <v-app-bar class="elevation-0" app style="background-color:rgb(35, 36, 39)">
      <v-btn text color="white" onclick="window.history.back()">
        <font-awesome-icon
          :icon="['fas', 'arrow-left']"
          class="fa-x mr-2"
        />
        Retour
      </v-btn>
    </v-app-bar>
    <!-- Sizes your content based upon application components -->

    <v-main class="main p-0 bg" style="background-color:rgb(235, 236, 239)">
      <transition name="slide-fade">
        <div v-if="show">
          <div class="container py-5 my-5">
            <!-- {{$store.state.ye}} -->
            <v-row justify="center" class="my-5 py-5">
              <v-col cols="12" class="text-center">
                <!-- <v-avatar size="250"> -->
                  <img
                    :src="$store.state.ye.image"
                    class="img-fluid" max-height="400"
                  /> 
                  <!-- </v-avatar> -->
                <br />
                <h4 class="my-2 font-weight-bold ">
                  {{ $store.state.ye.nom + " " + $store.state.ye.prenom  + " " + $store.state.ye.postnom }}
                </h4>
                <v-btn
                  v-if="$store.state.ye.phone"
                  text
                  color="red lighten-1"
                  class="elevation-0 m-1"
                  :href="'tel:' + $store.state.ye.phone"
                  >Appeler</v-btn
                >
                <v-btn
                  v-if="$store.state.ye.email"
                  text
                  color="primary"
                  class="elevation-0 m-1"
                  :href="'mailto:' + $store.state.ye.email"
                  >Email</v-btn
                >
                <v-divider class="grey"></v-divider>
              </v-col>

              <v-col cols="12">
                <v-card
                  height="100%"
                  class="bg-transparent elevation-0 py-5 px-5"
                >
                  <v-simple-table class="bg-transparent">
                    <template v-slot:default>
                      <tbody>
                        <tr>
                          <td class="py-2"><h5>ID</h5></td>
                          <td>
                            <h6 class="font-weight-bold">
                              {{ $store.state.ye.id }}
                            </h6>
                          </td>
                        </tr>
                        <tr>
                          <td class="py-2"><h5>Nom</h5></td>
                          <td>
                            <h6 class="font-weight-bold">
                              <v-text-field v-model="$store.state.ye.nom"  />
                            </h6>
                          </td>
                        </tr>
                        <tr>
                          <td class="py-2"><h5>Postnom</h5></td>
                          <td>
                            <h6 class="font-weight-bold">
                              <v-text-field v-model="$store.state.ye.postnom"  />
                            </h6>
                          </td>
                        </tr>
                        <tr>
                          <td class="py-2"><h5>Prenom</h5></td>
                          <td>
                            <h6 class="font-weight-bold">
                              <v-text-field v-model="$store.state.ye.prenom"  />
                            </h6>
                          </td>
                        </tr>
                        <tr>
                          <td class="py-2"><h5>Phone</h5></td>
                          <td>
                            <h6 class="font-weight-bold">
                              <v-text-field v-model="$store.state.ye.phone"  />
                            </h6>
                          </td>
                        </tr>
                        <tr>
                          <td class="py-2"><h5>Sex</h5></td>
                          <td>
                            <h5 class="font-weight-bold">
                              <v-text-field v-model="$store.state.ye.sex"  />
                            </h5>
                          </td>
                        </tr>
                        <!-- <tr>
                          <td class="py-2">Adresse</td>
                          <td>
                            <h6 class="font-weight-bold">
                              {{
                                $store.state.ye.adresse +
                                  ", " +
                                  $store.state.ye.city +
                                  ", IA " +
                                  $store.state.ye.zipcode
                              }}
                            </h6>
                          </td>
                        </tr> -->
                        <!-- <tr v-if="$store.state.ye.privacy == true">
                          <td class="py-2"><h5>Phone</h5></td>
                          <td>
                            <h5 class="font-weight-bold">
                              {{ $store.state.ye.phone }}
                            </h5>
                          </td>
                        </tr> -->
                        <!-- <tr>
                          <td class="py-2">Né à</td>
                          <td>
                            <h6 class="font-weight-bold">
                              {{ $store.state.ye.lieu_de_naissance }}
                            </h6>
                          </td>
                        </tr> -->
                        <tr>
                          <td class="py-2"><h5>Member since</h5></td>
                          <td>
                            <h6 class="font-weight-bold">
                              {{$store.state.ye.date}}
                            </h6>
                          </td>
                        </tr>
                        <tr>
                          <td class="py-2"><h5>Year</h5></td>
                          <td>
                            <h6 class="font-weight-bold">
                              {{$store.state.ye.year}}
                            </h6>
                          </td>
                        </tr>
                        <tr>
                          <td class="py-2"><h5>Action</h5></td>
                          <td>
                            <v-btn color="primary" @click="editUserInfo()">Edit user</v-btn>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </v-col>

              <!-- School -->
                <v-col cols="12" class="px-5">
                  <div>
                    <h5 class="my-0 font-weight-bold text-uppercase ">
                      Is
                      <span v-if="$store.state.ye.sex=='F'"> she</span><span v-else> he</span> 
                      a student?
                    </h5>
                  </div>
                  <div class="my-3">
                    <v-btn color="green" rounded class="m-1" @click="updateSchool(true)">Yes</v-btn>
                    <v-btn color="red" class="m-1" rounded @click="updateSchool(false)">No</v-btn>
                  </div>

                  <v-card v-if="schools.length>0" class="elevation-0 bg-transparent">
                    <h5 class="my-5 py-2 px-5 green--text" v-if="schools[0].kelasi == true">
                      Yes,
                      <span v-if="$store.state.ye.sex=='F'"> she</span><span v-else> he</span> 
                      is a student?
                    </h5>
                    <h5 class="my-5 py-2 px-5 red--text" v-else>
                      No,
                      <span v-if="$store.state.ye.sex=='F'"> she</span><span v-else> he</span> 
                      is not a student?
                    </h5>
                  </v-card>
                  <v-card v-else class="elevation-0 bg-transparent">
                    <h5 class="my-5 py-2 px-5 red--text">
                      No,
                      <span v-if="$store.state.ye.sex=='F'"> she</span><span v-else> he</span> 
                      is not a student?
                    </h5>
                  </v-card>

                  <v-divider class="grey"></v-divider>

                </v-col>

              <!-- Job -->

                <v-col cols="12" class="px-5">
                  <div>
                    <h5 class="my-0 font-weight-bold text-uppercase ">
                      Does
                      <span v-if="$store.state.ye.sex=='F'"> she</span><span v-else> he</span> 
                      work?
                    </h5>
                  </div>
                  <div class="my-3">
                    <v-btn color="green" rounded class="m-1" @click="updateJob(true)">Yes</v-btn>
                    <v-btn color="red" class="m-1" rounded @click="updateJob(false)">No</v-btn>
                  </div>

                  <v-card v-if="jobs.length>0" class="elevation-0 bg-transparent">
                    <h5 class="my-5 py-2 px-5 green--text" v-if="jobs[0].musala == true">
                      Yes,
                      <span v-if="$store.state.ye.sex=='F'"> she</span><span v-else> he</span> 
                      is currently working
                    </h5>
                    <h5 class="my-5 py-2 px-5 red--text" v-else>
                      No,
                      <span v-if="$store.state.ye.sex=='F'"> she</span><span v-else> he</span> 
                      is not working
                    </h5>
                  </v-card>
                  <v-card v-else class="elevation-0 bg-transparent">
                    <h5 class="my-5 py-2 px-5 red--text">
                      No,
                      <span v-if="$store.state.ye.sex=='F'"> she</span><span v-else> he</span> 
                      is not working
                    </h5>
                  </v-card>

                  <v-divider class="grey"></v-divider>

                </v-col>

              <!-- Business -->
              <v-col cols="12" class="px-5">
                <h5 class="my-0 font-weight-bold text-uppercase ">
                  Business
                </h5>

                <v-row>
                  <v-col cols="12" lg="10" class="px-5">
                    <v-card
                      height="100%"
                      class="bg-transparent elevation-0 pb-5 px-5"
                      v-if="businesses.length > 0"
                    >
                      <v-simple-table class="bg-transparent">
                        <template v-slot:default>
                          <tbody>
                            <tr v-for="(x, k) in businesses" :key="k">
                              <!-- <td class="py-2">{{k+1}}</td> -->
                              <td class="py-2">{{ x.name }}</td>
                              <td class="py-2">{{ x.adresse }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card>

                    <div class="my-0 py-0 text-center" v-else>
                      <h4 class="sub">-- No business --</h4>
                    </div>
                  </v-col>
                </v-row>
              </v-col>

              <!-- Automobile -->
              <!-- <v-col cols="12" class="px-5">
                <h5 class="my-0 font-weight-bold text-uppercase ">
                  Automobile ({{ automobiles.length }})
                </h5>
                <v-row>
                  <v-col cols="12" lg="10" class="px-5">
                    <v-card
                      height="100%"
                      class="bg-transparent elevation-0 pb-5 px-5"
                      v-if="automobiles.length > 0"
                    >
                      <v-row>
                        <v-col
                          cols="12"
                          md="4"
                          class="card bg-transparent p-1"
                          v-for="(x, k) in automobiles"
                          :key="k"
                        >
                          <div class="media grey darken-3 p-3">
                            <font-awesome-icon
                              v-if="x.color"
                              :icon="['fas', 'car']"
                              :class="x.color + ' fa-3x align-self-center'"
                            />
                            <div class="media-body ml-5 pl-5">
                              <h4 class="">{{ x.name }}</h4>
                              <h6 class="sub">{{ x.model }}</h6>
                              <span class="sub">{{ x.year }}</span>
                            </div>
                          </div>
                        </v-col>
                      </v-row>
                    </v-card>

                    <div class="my-0 py-0 text-center" v-else>
                      <h4 class="sub">-- No automobile --</h4>
                    </div>
                  </v-col>
                </v-row>
              </v-col> -->

              <!-- Assurance -->
              <v-col cols="12" class="px-5">
                <h5 class="my-0 font-weight-bold text-uppercase ">
                  Assurances
                </h5>
                <v-row>
                  <v-col cols="12" lg="10" class="px-5">
                    <v-card
                      v-if="assurances.length > 0"
                      class="bg-transparent elevation-0"
                      max-width="500"
                    >
                      <v-list shaped :disabled="true" class="bg-transparent">
                        <v-list-item-group v-model="insurancesPick" multiple>
                          <template v-for="(item, i) in insurances">
                            <v-divider
                              v-if="!item"
                              :key="`divider-${i}`"
                            ></v-divider>

                            <v-list-item
                              v-else
                              :key="`item-${i}`"
                              :value="item"
                              active-class="green lighten-1"
                              class="my-3"
                            >
                              <template v-slot:default="{ active }">
                                <v-list-item-content>
                                  <v-list-item-title
                                    v-text="item"
                                  ></v-list-item-title>
                                </v-list-item-content>

                                <v-list-item-action>
                                  <v-checkbox
                                    :input-value="active"
                                    color="primary darken-2"
                                  ></v-checkbox>
                                </v-list-item-action>
                              </template>
                            </v-list-item>
                          </template>
                        </v-list-item-group>
                      </v-list>
                    </v-card>
                    <div class="my-0 py-0 text-center" v-else>
                      <h4 class="sub">-- No assurance --</h4>
                    </div>
                  </v-col>
                </v-row>
              </v-col>

              <!-- Documents -->
              <v-col cols="12" class="px-5">
                <h5 class="my-0 font-weight-bold text-uppercase ">
                  Documents
                </h5>
                <v-row>
                  <v-col cols="12" lg="10" class="px-5">
                    <v-card
                      v-if="documents.length > 0"
                      class="bg-transparent elevation-0"
                      max-width="500"
                    >
                      <v-list shaped :disabled="true" class="bg-transparent">
                        <v-list-item-group v-model="documentsPick" multiple>
                          <template v-for="(item, i) in mydocuments">
                            <v-divider
                              v-if="!item"
                              :key="`divider-${i}`"
                            ></v-divider>

                            <v-list-item
                              v-else
                              :key="`item-${i}`"
                              :value="item"
                              active-class="grey darken-3"
                            >
                              <template v-slot:default="{ active }">
                                <v-list-item-content>
                                  <v-list-item-title
                                    class="white--text"
                                    v-text="item"
                                  ></v-list-item-title>
                                </v-list-item-content>

                                <v-list-item-action>
                                  <v-checkbox
                                    :input-value="active"
                                    color="primary darken-2"
                                  ></v-checkbox>
                                </v-list-item-action>
                              </template>
                            </v-list-item>
                          </template>
                        </v-list-item-group>
                      </v-list>
                    </v-card>

                    <div class="my-0 py-0 text-center" v-else>
                      <h4 class="sub">-- No document --</h4>
                    </div>
                  </v-col>
                </v-row>
              </v-col>

              
              <!-- Emergency Contact -->
              <v-col cols="12" class="px-5">
                <v-divider class="grey"></v-divider>
                <!-- {{ emergency }} -->
                  <div>
                    <h5 class="my-0 font-weight-bold text-uppercase ">
                      Emergency contact
                    </h5>
                  </div>
                  <div class="my-3" v-if="emergency.length<=0">
                    <v-btn color="green lighten-1" rounded class="m-1" @click="dialogEmergency=true;">Update</v-btn>
                  </div>
                  <div class="my-3" v-else>
                    <v-btn color="green lighten-1" rounded class="m-1" @click="recherche=emergency[0].emergency_id; dialogEmergency=true;">Update</v-btn>
                  </div>

                  <div v-if="emergency.length>0">
                    <h4 class="my-5 py-2 px-5 font-weight-bold green--text">
                        {{ emergency[0].emergency_id }}
                    </h4>
                  </div>
                  <div v-else>
                    <h4 class="my-5 py-2 px-5 font-weight-bold red--text">
                      Nobody
                    </h4>
                  </div>

                  <v-divider class="grey"></v-divider>

                </v-col>
            </v-row>

            
          </div>
        </div>
      </transition>
    </v-main>

    <v-dialog v-model="dialogEmergency" max-width="800">
      <v-card class="white elevation-0">
        <v-card-title>
          <v-text-field v-model="recherche" append-icon="mdi-magnify" label="Search" single-line hide-details>
          </v-text-field>
        </v-card-title>
        <v-data-table dense class="bg-transparent" :items-per-page="-1" :headers="headers" :items="users"
          :search="recherche">
          <template v-slot:item.image="{ item }">
            <div class="py-1">
              <v-avatar>
                <v-img :src="item.image" :alt="'Image de ' + item.fullname" class="img-fluid"></v-img>
              </v-avatar>
            </div>
          </template>
          <template v-slot:item.setting="{ item }">
            <span class="d-none">{{ item.setting }}</span>
            <v-btn small color="blue accent-3" class="white--text" @click="emergency_id = item.id;updateEmergency();dialogEmergency=false;">Select</v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-dialog>

    <div class="d-none">{{ ouvrirTransition }}</div>
  </v-app>
</template>

<script>
// import ServiceApi from "../ServicesApi.js";
import db from "../../db.js";
import firebase from "firebase";

export default {
  data() {
    return {
      email: "kerene@example.com",
      show: false,
      password: "plus1234",
      erreur: "",
      dialogErreur: false,
      place: "",
      valeur: 50,
      // family: [],
      // roommate: [],
      // mesRoommate: [],
      schools: [],
      jobs: [],
      emergency: [],
      dialogEmergency: false,
      emergency_id: "CON001",
      headers: [
        { text: "Image", value: "image" },
        { text: "Fullname", value: "fullname" },
        { text: "ID", value: "id" },
        { text: "Phone", value: "phone" },
        { text: "Action", value: "setting" }
      ],
      recherche: "",
      users: [],
      businesses: [],
      automobiles: [],
      familyName: "",
      familyId: "",
      assurances: [],
      documents: [],
      mydocuments: [
        "Green Card",
        "ID Card",
        "Driver License",
        "Social Security",
        "Work Permit",
        "Passport",
        "Lease"
      ],
      insurancesPick: [],
      documentsPick: [],
      insurances: [
        "Life Insurance",
        "Car Insurance",
        "Health Insurance",
        "Home Insurance"
      ],
      makabo: true,
      o: {
        id: "",
        email: "",
        yesa: ""
      }
    };
  },
  created() {
    var that = this;
    this.$store.state.family = [];
    // this.$store.state.ye = this.$store.state.ngayi;
    var user = firebase.auth().currentUser;
    if (
      this.$store.state.ngayi.email == "" ||
      this.$store.state.ngayi.email == undefined ||
      user.email == "" ||
      user.email == undefined
    )
      this.$router.push("/menu");
    else {
      /********************************************************/
      db.collection("users").onSnapshot(snapshot => {
        that.users = [];
        snapshot.forEach(doc => {
          that.users.push({
            id: doc.data().id,
            image: doc.data().image,
            phone: doc.data().phone,
            fullname:
              doc.data().nom +
              " " +
              doc.data().postnom +
              " " +
              doc.data().prenom
          });
        });
      });
      /*******************************************************/
      /********************************************************/
      // that.$store.state.family = [];
      /********************************************************/
      // db.collection("family")
      //   .where("id", "==", that.$store.state.ye.id)
      //   .onSnapshot(snapshot => {
      //     that.$store.state.family = [];
      //     snapshot.forEach(doc => {
      //       that.$store.state.family.push({
      //         id: doc.data().familyId,
      //         name: doc.data().familyName
      //       });
      //     });
      //     console.log(that.$store.state.family);
      //     this.getFamily();
      //   });
      /*******************************************************/

      /********************************************************/
      // that.$store.state.roommate = [];
      // /********************************************************/
      // db.collection("roommate")
      //   .where("id", "==", that.$store.state.ye.id)
      //   .onSnapshot(snapshot => {
      //     that.roommate = [];
      //     that.roomId = "";
      //     snapshot.forEach(doc => {
      //       console.log(doc.id);
      //       that.roomId = doc.data().roomid;
      //       that.$store.state.roommate = doc.data().roomid;
      //       that.mesRoommate.push({
      //         id: doc.id
      //       });
      //     });
      //     this.getRoomate();
      //   });
      /*******************************************************/

      this.getEmergency();
      this.getSchool();
      this.getJob();
      this.getBusiness();
      // this.getAuto();
      this.getAssurance();
      // this.getDocument();
    }
  },

  mounted() {
    var that = this;
    console.log(this.$store.state.email + " from menu");
    /********************************************************/
    if (this.$store.state.email == "") this.$router.push("/menu");
    else {
      /********************************************************/
      if (
        this.$store.state.ngayi.yesa == process.env.VUE_APP_FBT_2023 ||
        this.$store.state.ngayi.yesa == process.env.VUE_APP_KONGOMANI
      )
        console.log("ok ok ok");
      else {
        // alert("block account");
        try {
          db.collection("challenge").add({
            id: that.$store.state.ngayi.id,
            email: that.$store.state.ngayi.email,
            access: "access a news admin",
            cretedAd: firebase.firestore.FieldValue.serverTimestamp()
          });
        } catch (error) {
          alert(error.message);
        }
      }
    }
    // /*******************************************************/
  },
  methods: {
    theFormat(number) {
      return number.toFixed(0);
    },
    /*******************************************************/
    /*******************************************************/
    // getFamily() {
    //   var that = this;
    //   that.family = [];
    //   var uniqstate = this.$store.state.family.filter(
    //     (v, i, a) => a.findIndex(t => t.id === v.id) === i
    //   );
    //   this.$store.state.family = uniqstate;

    //   this.$store.state.family.forEach(element => {
    //     console.log(element.id);
    //     /********************************************************/
    //     db.collection("family")
    //       .where("familyId", "==", element.id)
    //       .onSnapshot(snapshot => {
    //         snapshot.forEach(doc => {
    //           console.log(doc.id);
    //           that.family.push({
    //             originVariable: doc.id,
    //             id: doc.data().id,
    //             familyId: doc.data().familyId,
    //             fullname: "",
    //             image: "",
    //             phone: "",
    //             email: ""
    //           });
    //         });
    //         var uniq = this.family.filter(
    //           (v, i, a) =>
    //             a.findIndex(t => t.id === v.id && t.familyId === v.familyId) ===
    //             i
    //         );
    //         this.family = uniq;
    //         console.log("family");
    //         console.log(this.family);
    //         console.log("family");
    //         that.prendUsers();
    //       });
    //     /*******************************************************/
    //   });
    // },
    /*******************************************************/
    // getRoomate() {
    //   var that = this;

    //   // alert(this.$store.state.roommate);
    //   /********************************************************/
    //   db.collection("roommate")
    //     .where("roomid", "==", that.roomId)
    //     .onSnapshot(snapshot => {
    //       that.roommate = [];
    //       snapshot.forEach(doc => {
    //         that.roommate.push({
    //           originVariable: doc.id,
    //           id: doc.data().id,
    //           fullname: "",
    //           image: "",
    //           phone: "",
    //           email: ""
    //         });
    //       });
    //       that.prendUsersForRoom();
    //     });
    //   /*******************************************************/
    // },
    // prendUsers() {
    //   for (let i = 0; i < this.family.length; i++) {
    //     var familleId = this.family[i].id;
    //     this.users.forEach(element => {
    //       if (familleId == element.id) {
    //         this.family[i].fullname = element.fullname;
    //         this.family[i].image = element.image;
    //         this.family[i].phone = element.phone;
    //         this.family[i].email = element.email;
    //       }
    //     });
    //   }

    //   // var tab = this.family;

    //   // for (let i = 0; i < tab.length; i++) {
    //   //   if(tab[i].id == this.$store.state.ngayi.id){
    //   //     this.family.splice(i,1);
    //   //   }
    //   // }
    //   // this.family = tab;
    // },
    // prendUsersForRoom() {
    //   for (let i = 0; i < this.roommate.length; i++) {
    //     var roomId = this.roommate[i].id;
    //     this.users.forEach(element => {
    //       if (roomId == element.id) {
    //         this.roommate[i].fullname = element.fullname;
    //         this.roommate[i].image = element.image;
    //         this.roommate[i].phone = element.phone;
    //         this.roommate[i].email = element.email;
    //       }
    //     });
    //   }
    //   var uniqstate = this.roommate.filter(
    //     (v, i, a) => a.findIndex(t => t.id === v.id) === i
    //   );
    //   this.roommate = uniqstate;
    // },

    /****************************************** */
    getEmergency() {
      var that = this;
      /********************************************************/
      db.collection("emergency")
        .where("id", "==", that.$store.state.ye.id)
        .onSnapshot(snapshot => {
          that.emergency = [];
          snapshot.forEach(doc => {
            that.emergency = [{
              docID: doc.id,
              id: doc.data().id,
              emergency_id: doc.data().emergency_id
            }];
          });
        });
    },
    /***************************************** */
    getSchool() {
      var that = this;
      /********************************************************/
      db.collection("school")
        .where("email", "==", that.$store.state.ye.email)
        .onSnapshot(snapshot => {
          that.schools = [{id:"", kelasi:false}];
          snapshot.forEach(doc => {
            that.schools = [{
              id: doc.id,
              kelasi: doc.data().school
            }];
          });
        });
    },
    getJob() {
      var that = this;
      /********************************************************/
      db.collection("job")
        .where("email", "==", that.$store.state.ye.email)
        .onSnapshot(snapshot => {
          that.jobs = [{id:"", musala:false}];
          snapshot.forEach(doc => {
            that.jobs = [{
              id: doc.id,
              musala: doc.data().job
            }];
          });
        });
    },

    /*******POST */
    /****************************************************/
    postEmergency() {
      var that = this;
        db.collection("emergency").add({
          id: that.$store.state.ye.id,
          emergency_id: this.emergency_id,
          cretedAd: firebase.firestore.FieldValue.serverTimestamp()
        });
    },
    /****************************************************/
    postSchool(value) {
      var that = this;
        db.collection("school").add({
          email: that.$store.state.ye.email,
          city: that.$store.state.ye.city,
          school: value,
          id: that.$store.state.ye.id,
          cretedAd: firebase.firestore.FieldValue.serverTimestamp()
        });
    },
    /****************************************************/
    postJob(value) {
      var that = this;
        db.collection("job").add({
          email: that.$store.state.ye.email,
          city: that.$store.state.ye.city,
          job: value,
          cretedAd: firebase.firestore.FieldValue.serverTimestamp()
        });
    },
    /****************************************************/

    /*******UPDATE */
    /****************************************************/
    updateEmergency(){
      var that = this;
      if(that.emergency.length>0){
          db.collection("emergency")
            .doc(that.emergency[0].docID)
            .update({
              emergency_id: this.emergency_id
            });
      }else{
          that.postEmergency();
        }
    },
    /****************************************************/
    updateSchool(value){
      var that = this;
      if(that.schools.length>0){
        if(that.schools[0].id!=""&&that.schools[0].id!=undefined){
          db.collection("school")
            .doc(that.schools[0].id)
            .update({
              school: value
            });
        }else{
          that.postSchool(value);
        }
      }
    },
    /****************************************************/
    updateJob(value){
      var that = this;
      if(that.jobs.length>0){
        if(that.jobs[0].id!=""&&that.jobs[0].id!=undefined){
          db.collection("job")
            .doc(that.jobs[0].id)
            .update({
              job: value
            });
        }else{
          that.postJob(value);
        }
      }
    },
    /****************************************************/

    /***************************************** */
    /***************************************** */





    // getSchool() {
    //   var that = this;
    //   this.part = "school";
    //   /********************************************************/
    //   db.collection("school").onSnapshot(snapshot => {
    //     that.schools = [];
    //     snapshot.forEach(doc => {
    //       if (doc.data().email == that.$store.state.ye.email) {
    //         that.schools.push({
    //           id: doc.id,
    //           date: doc.data().date,
    //           name: doc.data().name,
    //           email: doc.data().email
    //         });
    //       }
    //     });
    //     console.log(that.schools);
    //   });
    // },
    // getJob() {
    //   var that = this;
    //   this.part = "job";
    //   /********************************************************/
    //   db.collection("job").onSnapshot(snapshot => {
    //     that.jobs = [];
    //     snapshot.forEach(doc => {
    //       if (doc.data().email == that.$store.state.ye.email) {
    //         that.jobs.push({
    //           id: doc.id,
    //           name: doc.data().name,
    //           date: doc.data().date,
    //           email: doc.data().email
    //         });
    //       }
    //     });
    //     console.log(that.jobs);
    //   });
    // },



    getBusiness() {
      var that = this;
      this.part = "business";
      /********************************************************/
      db.collection("business")
      .where("email", "==", that.$store.state.ngayi.email)
      .onSnapshot(snapshot => {
        that.businesses = [];
        snapshot.forEach(doc => {
            that.businesses.push({
              id: doc.id,
              name: doc.data().name,
              adresse: doc.data().adresse,
              email: doc.data().email
            });
        });
        console.log(that.businesses);
      });
    },
    // getAuto() {
    //   var that = this;
    //   this.part = "automobile";
    //   /********************************************************/
    //   db.collection("automobile").onSnapshot(snapshot => {
    //     that.automobiles = [];
    //     snapshot.forEach(doc => {
    //       if (doc.data().email == that.$store.state.ye.email) {
    //         that.automobiles.push({
    //           id: doc.id,
    //           name: doc.data().name,
    //           model: doc.data().model,
    //           color: doc.data().color,
    //           year: doc.data().year,
    //           email: doc.data().email
    //         });
    //       }
    //     });
    //     console.log(that.automobiles);
    //   });
    // },
    getAssurance() {
      var that = this;
      this.part = "assurance";
      /********************************************************/
      db.collection("assurance")
      .where("email", "==", that.$store.state.ye.email)
      .onSnapshot(snapshot => {
        that.assurances = [];
        snapshot.forEach(doc => {
            that.assurances.push({
              id: doc.id,
              name: doc.data().name,
              email: doc.data().email
            });
        });
        try {
          that.insurancesPick = this.assurances[0].name;
        } catch (error) {
          that.insurancesPick = [];
        }
        console.log(that.assurances);
      });
    },
    getDocument() {
      var that = this;
      this.part = "document";
      /********************************************************/
      db.collection("document")
      .where("email", "==", that.$store.state.ngayi.email)
      .onSnapshot(snapshot => {
        that.documents = [];
        snapshot.forEach(doc => {
            that.documents.push({
              id: doc.id,
              name: doc.data().name,
              email: doc.data().email
            });
        });
        try {
          that.documentsPick = this.documents[0].name;
        } catch (error) {
          that.documentsPick = [];
        }
        console.log(that.documents);
      });
    },
    editUserInfo(){
      var that = this;
      db.collection("users")
        .doc(that.$store.state.ye.originalId)
        .update({
          nom: that.$store.state.ye.nom,
          postnom: that.$store.state.ye.postnom,
          prenom: that.$store.state.ye.prenom,
          sex: that.$store.state.ye.sex,
          phone: that.$store.state.ye.phone
        });
      alert("Successfully");
    },

    /********************************** */
    validate() {
      this.$store.state.mutuYeyo = this.User;
      var d = new Date();
      d.setTime(d.getTime() + 5 * (365 * 24 * 60 * 60 * 1000));
      var expires = "expires=" + d.toUTCString();
      document.cookie = "mutuYango" + "=" + this.yanoyo + ";" + expires + ";";
      document.cookie = "bomba" + "=" + this.password + ";" + expires + ";";
    },
    leTemps() {
      setTimeout(() => {
        this.changedValue();
        return false;
      }, 100);
      return 0;
    },
    changedValue() {
      this.show = true;
      return false;
    }
  },
  computed: {
    ouvrirTransition() {
      this.leTemps();
      return false;
    }
  }
};
</script>

<style scoped>
.card {
  border: none;
}
</style>
