<template>
  <!-- App.vue -->
  <v-app>
    <v-app-bar class="elevation-0" app>
      <v-btn text color="white" onclick="window.history.back()">
        <font-awesome-icon
          :icon="['fas', 'arrow-left']"
          class="fa-x black--text mr-2"
        />
      </v-btn>
      <!-- <v-spacer></v-spacer>
      <h4 class="black--text">Creation</h4> -->
      <v-spacer></v-spacer>
    </v-app-bar>
    <!-- Sizes your content based upon application components -->

    <v-main class="main p-0" style="background-color:rgb(235, 236, 239)">
      <transition name="slide-fade">
        <div v-if="show">
          <section class="bg-col-image">
            <div
              style="height: 100%;width: 100%;background: rgba(11,18,81,0.66);padding: 80px 0px 60px;"
            >
              <div class="container py-0">
                <div class="row">
                  <div
                    class="col-12 d-flex justify-content-center align-items-center flex-column"
                    style="text-align: center;"
                  >
                    <h1
                      class="white--text display-1 font-weight-bold pt-5 text-uppercase"
                    >
                      Création d'une famille
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div class="container py-5">
            <v-row>
              <v-col cols="12" md="8" lg="6" class="py-5">
                <form>
                  <span class="my-5 title font-weight-normal black--text"
                    >Quel est le nom de la famille?</span
                  >
                  <input
                    class="form-control mt-5 mb-3 subtitle-2 font-weight-normal"
                    type="text"
                    name="Name"
                    placeholder="Name"
                    v-model="familyName"
                    style="border-radius: 10px;color: black;"
                  />

                  <span
                    class="py-1 px-3 my-0 white red--text"
                    v-if="
                      familyName.toLowerCase().includes('famille') ||
                        familyName.toLowerCase().includes('famile') ||
                        familyName.toLowerCase().includes('family')
                    "
                  >
                    Le nom de la famille ne doit pas contenir le mot "Famille" </span
                  ><br /><br />

                  <v-btn
                    :disabled="
                      familyName == '' ||
                        familyName.toLowerCase().includes('famille') ||
                        familyName.toLowerCase().includes('famile') ||
                        familyName.toLowerCase().includes('family')
                    "
                    small
                    color="orange black--text"
                    class="elevation-0"
                    @click="createId()"
                    >Commencer</v-btn
                  >
                </form>
              </v-col>
            </v-row>
          </div>
        </div>
      </transition>
    </v-main>
    <div class="d-none">{{ ouvrirTransition }}</div>
  </v-app>
</template>

<script>
import firebase from "firebase";
import db from "../../db.js";
// import ServiceApi from "../ServicesApi.js";
export default {
  data() {
    return {
      familyName: "",
      show: false,
      family: []
    };
  },
  created() {
    var user = firebase.auth().currentUser;
    if (
      this.$store.state.ngayi.email == "" ||
      this.$store.state.ngayi.email == undefined ||
      user.email == "" ||
      user.email == undefined
    )
      this.$router.push("/menu");
    else this.getFamily();
  },
  computed: {
    ouvrirTransition() {
      this.leTemps();
      return false;
    }
  },

  methods: {
    leTemps() {
      setTimeout(() => {
        this.changedValue();
        return false;
      }, 100);
      return 0;
    },
    changedValue() {
      this.show = true;
      return false;
    },
    /*******************************************************/
    getFamily() {
      var that = this;
      /********************************************************/
      db.collection("family").onSnapshot(snapshot => {
        that.family = [];
        snapshot.forEach(doc => {
          that.family.push({
            originVariable: doc.id
          });
        });
      });
      /*******************************************************/
    },
    createId() {
      var result = "FAM";
      var keep = false;

      do {
        var characters =
          "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        var charactersLength = characters.length;
        for (var i = 0; i < 40; i++) {
          result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
          );
        }
        this.family.forEach(element => {
          if (element.originVariable == result) keep = true;
        });
      } while (keep);
      this.familyId = result;
      // alert(this.familyId);
      this.$store.state.familyId = this.familyId;
      this.$store.state.familyName = this.familyName;
      this.dialog4 = true;
      this.montrer = 1;
      this.$router.push("/family");
    }
  }
};
</script>

<style scoped>
.card:hover {
  border: 2px solid rgb(0, 110, 255);
  cursor: pointer;
}
</style>
