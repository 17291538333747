<template>
  <!-- App.vue -->
  <v-app>
    <v-app-bar class="elevation-0 white" app>
      <v-btn text color="white" onclick="window.history.back()">
        <font-awesome-icon
          :icon="['fas', 'arrow-left']"
          class="fa-x black--text mr-2"
        />
        retour
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn icon>
        <font-awesome-icon :icon="['fas', 'search']" class="fa-x black--text" />
      </v-btn>
      <v-btn icon @click="dialog = true" v-if="ajouter">
        <font-awesome-icon :icon="['fas', 'plus']" class="fa-x black--text" />
      </v-btn>
    </v-app-bar>
    <!-- Sizes your content based upon application components -->

    <v-main class="bg py-5" style="background-color:rgb(235, 236, 239)">
      <transition name="slide-fade">
        <div v-if="show">
          <section class="bg-col-news">
            <div
              style="height: 100%;width: 100%;background: rgba(11,18,81,0.66);padding: 80px 0px;"
            >
              <div class="container py-0">
                <div class="row">
                  <div
                    class="col-12 d-flex justify-content-center align-items-center flex-column"
                    style="text-align: center;"
                  >
                    <h1 class="white--text display-2 font-weight-bold pt-5">
                      ACTIVITIES
                    </h1>
                    <p
                      class="d-md-none d-lg-flex"
                      style="color: rgb(224,224,224);font-size: 22px;"
                    >
                      <br />Jeunes retraités actifs ou centenaires dépendants,
                      la Ville doit pouvoir répondre à leurs besoins de loisirs,
                      daide sociale, de santé ou de logement.<br /><br />
                    </p>

                    <a
                      class="btn btn-primary btn-xl"
                      role="button"
                      href="#services"
                      style="background: white;padding: 5px 20px;color: rgb(11,18,81);font-size: 16px;"
                      >Contactez le concepteur
                      <font-awesome-icon
                        :icon="['fas', 'arrow-right']"
                        class="fa-x"
                        style="color: rgb(11,18,81);"
                    /></a>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section
            id="portfolio"
            class="content-section"
            style="padding: 10px 0px;"
          >
            <div class="container py-5">
              <div class="row d-flex">
                <div
                  class="col-6 col-sm-10 col-md-6 col-lg-6 col-xl-4 col-xxl-4"
                  style="padding: 10px;"
                  v-for="(x, k) in news"
                  :key="k"
                >
                  <a class="portfolio-item" @click="openNews(x)">
                    <div
                      class="caption"
                      style="background: rgba(11,18,81,0.66);"
                    >
                      <div class="caption-content">
                        <h2 style="font-size: 20.8px;">
                          <strong>{{ x.title.slice(0, 30) }}</strong
                          ><br />
                        </h2>
                        <p class="mb-0">
                          {{ x.paragraph.slice(0, 20) }}
                        </p>
                        <span
                          style="color: rgb(207,207,207);font-size: 13px;background: rgba(244,71,107,0.5);"
                          >{{ x.date }}</span
                        >
                      </div>
                    </div>
                    <v-img
                      class="img-fluid"
                      :src="x.image"
                      style="height: 350px;width: 100%;"
                    />
                  </a>

                  <v-divider class="grey lighten-2"></v-divider>
                </div>
              </div>
            </div>
          </section>
        </div>
      </transition>
    </v-main>

    <v-dialog v-model="dialog" max-width="600">
      <v-card>
        <v-card-title
          class="title red lighten-2 black--text py-1 font-weight-normal mb-5"
        >
          Create An Activity:
        </v-card-title>

        <v-card-text class="px-5">
          <v-img
            height="250px"
            :src="journal[0].image"
            v-if="journal[0].image != ''"
            @click="SelecFile()"
          />
          <v-img
            height="250px"
            v-else
            :src="require('../../images/noPic.png')"
            @click="SelecFile()"
          />

          <v-text-field
            class="mt-5"
            outlined
            dense
            label="Title (30 charactères)"
            v-model="journal[0].title"
            maxlength="30"
          />
          <v-textarea
            rows="10"
            outlined
            label="Description"
            v-model="journal[0].paragraph"
          ></v-textarea>
          <!-- <v-btn dense class="mb-0">Choose a picture</v-btn> -->
          <input
            type="file"
            id="file"
            @change="baPoniImages($event)"
            multiple
            style="display:none;"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red" class="mx-1" text @click="dialog = false">
            Close
          </v-btn>

          <v-btn color="green" class="mx-1" text @click="Enregistrer()">
            Enregistrer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="dialogWait" max-width="200">
      <v-card class="text-center py-3">
        <v-progress-circular
          :size="30"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <span class="ml-2">Please Wait</span>
      </v-card>
    </v-dialog>
    <div class="d-none">{{ ouvrirTransition }}</div>
  </v-app>
</template>

<script>
// import ServiceApi from "../ServicesApi.js";
import firebase from "firebase";
import db from "../../db.js";

export default {
  data() {
    return {
      email: "",
      show: false,
      password: "",
      dialog: false,
      dialogWait: false,
      place: "",
      news: [],
      journal: [
        {
          title: "",
          image: "",
          paragraph: ""
        }
      ],
      ajouter: false,
      makabo: true,
      o: {
        id: "",
        email: "",
        yesa: ""
      }
    };
  },
  created() {
    var user = firebase.auth().currentUser;
    if (
      this.$store.state.ngayi.email == "" ||
      this.$store.state.ngayi.email == undefined ||
      user.email == "" ||
      user.email == undefined
    )
      this.$router.push("/menu");
    else {
      if (
        this.$store.state.ngayi.yesa == process.env.VUE_APP_FBT_2023 ||
        this.$store.state.ngayi.yesa == process.env.VUE_APP_KONGOMANI
      )
        this.ajouter = true;
    }
    var that = this;
    /********************************************************/
    db.collection("activites").onSnapshot(snapshot => {
      that.news = [];
      snapshot.forEach(doc => {
        console.log("ok ok ok");
        that.news.push({
          id: doc.data().id,
          title: doc.data().title,
          image: doc.data().image,
          myImage: "",
          auteur: "",
          paragraph: doc.data().paragraph,
          date: new Date(doc.data().cretedAd.toDate()).toDateString(),
          heure:
            " at " + new Date(doc.data().cretedAd.toDate()).toLocaleTimeString()
        });
      });
    });
    //     /*******************************************************/
  },

  methods: {
    Enregistrer() {
      var that = this;
      if (this.journal[0].title != "" || this.journal[0].paragraph != "") {
        /********************************************* */
        try {
          db.collection("activites").add({
            id: that.$store.state.ngayi.id,
            title: that.journal[0].title,
            image: that.journal[0].image,
            paragraph: that.journal[0].paragraph,
            cretedAd: firebase.firestore.FieldValue.serverTimestamp()
          });
        } catch (error) {
          alert(error.message);
        }
        that.journal = [
          {
            title: "",
            image: "",
            paragraph: ""
          }
        ];
        alert("Successfully");
        /********************************************* */
      } else {
        alert("Veuillez completer toutes les cases, s'il vous plaît !");
      }
      this.dialog = false;
    },
    openNews(x) {
      this.$store.state.news = x;
      this.$router.push("/activites_details");
    },
    SelecFile() {
      this.$store.state.ImagesUpload = [];
      document.getElementById("file").click();
    },
    baPoniImages(e) {
      document.getElementById("file");
      
      var that = this;
      e.target.files.forEach(element => {
        that.dialogWait = true;
        var filename = element.name;
        var tableau = "";

        var storageRef = firebase
          .storage()
          .ref("Activites/" + this.$store.state.ngayi.email + "/" + filename);
        var metadata = {
          contentType: "image/jpeg"
        };

        storageRef.put(element, metadata).then(function(downloadURL) {
          downloadURL.ref.getDownloadURL().then(function(url) {
            url = String(url);
            if (url !== undefined) {
              var b = url;
              tableau = b;
              console.log(tableau);
              that.journal[0].image = tableau;
              that.dialogWait = false;
            }
          });
        });
      });
    },
    leTemps() {
      setTimeout(() => {
        this.changedValue();
        return false;
      }, 100);
      return 0;
    },
    changedValue() {
      this.show = true;
      return false;
    }
  },
  computed: {
    ouvrirTransition() {
      this.leTemps();
      return false;
    }
  }
};
</script>
