<template>
    <div class="bg-secondary d-flex align-items-center" style="min-height: 100vh;">
        <!-- School -->
        <v-row class="py-5" justify="center" v-if="part == 'school'">

            <v-col cols="12" lg="10" class="white">
                <v-progress-linear v-model="knowledge" color="primary darken-1" height="35">
                    <strong>{{knowledge}}%</strong>
                </v-progress-linear>
            </v-col>

            <v-col cols="12" lg="10" class="bg px-5 py-5 white">
                <div>
                    <h3 style="color: rgba(0,0,0,.79);" class="text-center font-weight-bold">
                        Do you go to school?
                    </h3>
                </div>
                <div class="text-center my-3">
                    <v-btn color="success" rounded class="m-1" @click="updateSchool(true)">Yes</v-btn>
                    <v-btn color="red" class="white--text m-1" rounded @click="updateSchool(false)">No</v-btn>
                </div>

                <v-divider class="grey"></v-divider>
                <!-- {{schools}} -->
                <v-card v-if="schools.length>0" class="elevation-0 font-weight-bold">
                    <div class="my-5 py-2 text-center green--text" v-if="schools[0].kelasi == true">
                        You are enrolled in school
                    </div>
                    <div class="my-5 py-2 text-center red--text" v-else>
                        You don't go to school
                    </div>
                </v-card>
                <v-card v-else class="elevation-0">
                    <div class="my-5 py-2 text-center red--text">
                        You don't go to school
                    </div>
                </v-card>

                <div class="text-center">
                        <v-btn large class="mt-5 mx-2" color="red white--text" href="#/setMyJob">Retour</v-btn>
                        <v-btn large class="mt-5" color="primary" href="#/setMyProfile">
                            Continuer
                        </v-btn>
                </div>

                <v-divider class="grey"></v-divider>

            </v-col>
        </v-row>
    </div>
</template>

<script>
    import firebase from "firebase";
    import db from "../../db.js";

    export default {
        data() {
            return {
                schools: [],
                jobs: [],
                knowledge: 60
            }
        },
        created(){
            this.getSchool();
        },
        methods: {
            getSchool() {
                var that = this;
                this.part = "school";
                /********************************************************/
                db.collection("school")
                    .where("email", "==", that.$store.state.ngayi.email)
                    .onSnapshot(snapshot => {
                        that.schools = [{
                            id: "",
                            kelasi: false
                        }];
                        snapshot.forEach(doc => {
                            that.schools = [{
                                id: doc.id,
                                kelasi: doc.data().school
                            }];
                        });
                    });
            },
            /****************************************************/
            updateSchool(value) {
                var that = this;
                if (that.schools.length > 0) {
                    if (that.schools[0].id != "" && that.schools[0].id != undefined) {
                        db.collection("school")
                            .doc(that.schools[0].id)
                            .update({
                                school: value
                            });
                    } else {
                        that.postSchool(value);
                    }
                }
            },
            postSchool(value) {
                var that = this;
                db.collection("school").add({
                    email: that.$store.state.ngayi.email,
                    city: that.$store.state.ngayi.city,
                    school: value,
                    id: that.$store.state.ngayi.id,
                    cretedAd: firebase.firestore.FieldValue.serverTimestamp()
                });
            }
        }
    }
</script>

<style>

</style>