<template>
  <!-- App.vue -->
  <v-app>
    <!-- Sizes your content based upon application components -->

    <v-main class="bg-img p-0">
      <transition name="slide-fade">
        <div v-if="show">
          <div style="background: rgba(0,0,0,.79);height: 100%;">
            <section style="height: 100vh;">
              <div class="row p-0" style="height: 100%;">
                <div
                  class="col-12 col-lg-8 bg-col-img p-0 d-none d-md-block"
                  data-aos="slide-right"
                  data-aos-duration="1000"
                  data-aos-delay="1000"
                >
                  <div
                    class="d-flex flex-column justify-content-center align-items-start"
                    style="height: 100%;width: 100%;background: rgba(11,18,81,0.66);padding: 0px 25px;"
                  >
                    <div class="container">
                      <h1
                        style="font-size: 61px;font-family: Amethysta, serif;color: rgb(255,255,255);"
                      >
                        Ensemble on change tout !
                      </h1>
                      <p
                        class="d-md-none d-lg-flex"
                        style="color: rgb(224,224,224);font-size: 22px;"
                      >
                        <!-- <br />Jeunes retraités actifs ou centenaires dépendants,
                        la Ville doit pouvoir répondre à leurs besoins de
                        loisirs, d’aide sociale, de santé ou de logement. Aux
                        Sorinières, des services pour les aînés existent, mais
                        n’est-il pas temps de passer à la vitesse supérieure
                        ?<br /><br /> -->
                      </p>

                      <a
                        class="btn btn-primary btn-xl"
                        role="button"
                        href="#services"
                        style="background: white;padding: 10px 20px;color: rgb(11,18,81);font-size: 20px;"
                        >Contactez le concepteur
                        <font-awesome-icon
                          :icon="['fas', 'arrow-right']"
                          class="fa-x"
                          style="color: rgb(11,18,81);"
                      /></a>
                    </div>
                  </div>
                </div>

                <div
                  class="col-12 col-md-12 col-lg-4 p-0 bg-blue d-flex"
                  data-aos="slide-left"
                  data-aos-duration="1000"
                  data-aos-delay="1000"
                  style="text-align: center;"
                >
                  <div
                    class="menu"
                    style="background: rgba(0,0,0,.79); height:100%; width:100%"
                  >
                    <!-- Photo Side -->
                    <section class="p-0">
                      <div class="container py-0">
                        <div class="row p-0 pt-5">
                          <div
                            class="col-md-3 d-flex justify-content-center align-items-center"
                            style="text-align: center;"
                          >
                            <img
                              class="img-fluid"
                              style="width:80px"
                              :src="$store.state.ngayi.image"
                            />
                          </div>
                          <div
                            class="col-md-9 d-flex flex-column align-items-center p-0"
                          >
                            <div
                              class="d-flex flex-column justify-content-center"
                              style="height: 100%;width: 100%;background: var(--bs-gray-200);"
                            >
                              <div class="container text-md-left">
                                <h1
                                  class="headline white--text"
                                  style="font-family: Amethysta, serif;"
                                >
                                  {{
                                    $store.state.ngayi.nom +
                                      " " +
                                      $store.state.ngayi.postnom +
                                      " " +
                                      $store.state.ngayi.prenom
                                  }}
                                </h1>
                                <p class="grey--text">
                                  {{ $store.state.ngayi.id }}
                                </p>
                              </div>
                            </div>
                          </div>
                          <v-divider class="grey"></v-divider>
                        </div>
                      </div>
                    </section>
                    <!-- Photo Side end -->

                    <div class="container text-left px-5">
                      <v-list dark dense class="px-5 bg-transparent">
                        <v-list-item
                          v-for="(x, k) in leMenu"
                          :key="k"
                          :href="x.link"
                          class="p-0"
                        >
                          <v-list-item-avatar>
                            <v-badge
                              color="red"
                              overlap
                              :content="nbreChat"
                              v-if="nbreChat > 0 && x.explication == 0"
                            >
                              <font-awesome-icon
                                :icon="x.icon"
                                class="fa-x grey--text"
                              />
                            </v-badge>
                            <font-awesome-icon
                              :icon="x.icon"
                              class="fa-x grey--text"
                              v-else-if="nbreChat > 0 && x.explication != 0"
                            />
                            <font-awesome-icon
                              :icon="x.icon"
                              class="fa-x grey--text"
                              v-else-if="nbreChat == 0"
                            />
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <v-list-item-title
                              class="subtitle-1 grey--text"
                              v-text="x.name"
                            ></v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>

                      <!-- <v-divider class="grey"></v-divider> -->
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </transition>
    </v-main>

    <v-footer app class="p-0">
      <v-tabs
        v-model="tab"
        background-color="#232427"
        centered
        dark
        fixed-tabs
        bottom
        icons
      >
        <v-tabs-slider></v-tabs-slider>

        <v-tab href="#tab-1">
          <font-awesome-icon :icon="['fas', 'home']" class="fa-x white--text" />
        </v-tab>

        <v-tab href="#tab-2">
          <font-awesome-icon
            :icon="['fas', 'phone']"
            class="fa-x white--text"
          />
        </v-tab>

        <v-tab @click="open('settings')">
          <font-awesome-icon :icon="['fas', 'cog']" class="fa-x white--text" />
        </v-tab>

        <!-- <v-tab href="#tab-4">
        <font-awesome-icon :icon="['fas','cog']" class="fa-2x grey--text" />
      </v-tab> -->
      </v-tabs>
    </v-footer>

    <v-dialog persistent v-model="dialogWait" max-width="200">
      <v-card class="text-center py-3">
        <v-progress-circular
          :size="30"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <span class="ml-2">Please Wait</span>
      </v-card>
    </v-dialog>
    <div class="d-none">{{ ouvrirTransition }}</div>
  </v-app>
</template>

<script>
// import ServiceApi from "../ServicesApi.js";
import db from "../../db.js";
import firebase from "firebase";

export default {
  data() {
    return {
      email: "",
      show: false,
      password: "",
      erreur: "",
      dialogErreur: false,
      dialogWait: false,
      place: "",
      tab: null,
      o: {
        id: "",
        email: "",
        yesa: ""
      },
      leMenu: [
        {
          name: "Mon Profile",
          icon: ["fas", "user-tie"],
          link: "#/profil",
          explication: "1"
        },
        {
          name: "Actualités",
          icon: ["fas", "newspaper"],
          link: "#/news_adm",
          explication: "1"
        },
        {
          name: "Activités",
          icon: ["fas", "newspaper"],
          link: "#/activites_adm",
          explication: "1"
        },
        // {
        //   name: "Finance",
        //   icon: ["fas", "dollar-sign"],
        //   link: "#/finance",
        //   explication: "1"
        // },
        {
          name: "Démographie",
          icon: ["fas", "users"],
          link: "#/demographiep",
          explication: "1"
        },
        // {
        //   name: "Famille",
        //   icon: ["fas", "user-plus"],
        //   link: "#/link_with_family",
        //   explication: "1"
        // },
        {
          name: "Assistance ",
          icon: ["fas", "hands-helping"],
          link: "#/assistance_pro",
          explication: "1"
        },
        {
          name: "Business",
          icon: ["fas", "briefcase"],
          link: "#/business",
          explication:
            "Pour communiquer avec le comité directeur sur votre besoin"
        },
        // {
        //   name: "Chat ",
        //   icon: ["fas", "envelope"],
        //   link: "#/chat_ouverture",
        //   explication: "0"
        // },
        {
          name: "Vidéos ",
          icon: ["fas", "video"],
          link: "#/videos",
          explication: "1"
        }
      ],
      chat: [],
      nbreChat: 0,
      makabo: true
    };
  },
  created() {
    this.$store.state.email = this.getCookie("mutuYango");
    var user = firebase.auth().currentUser;
    if (
      this.$store.state.ngayi.email == "" ||
      this.$store.state.ngayi.email == undefined ||
      user.email == "" ||
      user.email == undefined
    )
      this.$router.push("/menu");
  },
  mounted() {
    this.getMessages();
    var that = this;
    console.log(this.$store.state.email + " from menu");
    /********************************************************/
    if (this.$store.state.email == "") this.$router.push("/menu");
    else {
      /********************************************************/
      db.collection("charge")
        .orderBy("email", "asc")
        .onSnapshot(snapshot => {
          this.o = {
            id: "",
            email: "",
            yesa: ""
          };
          this.makabo = true;
          snapshot.forEach(doc => {
            if (
              doc.data().yesa == that.$store.state.ngayi.yesa &&
              doc.data().email.toLowerCase() ==
                that.$store.state.ngayi.email.toLowerCase() &&
              doc.data().id.toLowerCase() ==
                that.$store.state.ngayi.id.toLowerCase()
            ) {
              this.o = {
                id: doc.data().id,
                email: doc.data().email,
                yesa: doc.data().yesa
              };
              this.makabo = false;
            }
          });
          if (this.makabo == true) {
            alert("block account");
            try {
              db.collection("challenge").add({
                id: that.$store.state.ngayi.id,
                email: that.$store.state.ngayi.email,
                access: "access au menu pro",
                cretedAd: firebase.firestore.FieldValue.serverTimestamp()
              });
            } catch (error) {
              alert(error.message);
            }
          }
        });
    }
    // /*******************************************************/
  },

  methods: {
    getMessages() {
      var that = this;
      /********************************************************/
      db.collection("chat")
        .orderBy("cretedAd", "asc")
        .onSnapshot(snapshot => {
          that.chat = [];
          snapshot.forEach(doc => {
            that.chat.push({
              message: doc.data().message,
              fullname: doc.data().fullname,
              image: doc.data().image,
              ladate: new Date(doc.data().cretedAd.toDate()).toDateString(),
              heure:
                " at " +
                new Date(doc.data().cretedAd.toDate()).toLocaleTimeString()
            });
          });
          console.log("this.chat up");
          that.tiaSikoyo();
        });
      ///*******************************************************/
    },
    tiaSikoyo() {
      var items = [];
      var d = new Date();
      var auzuidui = d.toDateString();
      this.chat.forEach(element => {
        if (element.ladate == auzuidui) {
          items.push({
            message: element.message,
            fullname: element.fullname,
            image: element.image,
            ladate: element.ladate + element.heure
          });
        }
      });
      this.nbreChat = items.length;
      // alert(items.length);
      console.log(this.items);
    },
    /********************************** */
    validate() {
      this.$store.state.mutuYeyo = this.User;
      var d = new Date();
      d.setTime(d.getTime() + 5 * (365 * 24 * 60 * 60 * 1000));
      var expires = "expires=" + d.toUTCString();
      document.cookie = "mutuYango" + "=" + this.yanoyo + ";" + expires + ";";
      document.cookie = "bomba" + "=" + this.password + ";" + expires + ";";
    },
    getCookie(cname) {
      var name = cname + "=";
      var ca = document.cookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length).toLowerCase();
        }
      }
      return "";
    },

    /*******************************************************/
    getFamilyRequest() {
      var that = this;
      /********************************************************/
      db.collection("familyPreparation").onSnapshot(snapshot => {
        that.$store.state.familyNotification = [];
        snapshot.forEach(doc => {
          console.log(
            doc.data().to_email + " == " + that.$store.state.ngayi.email
          );
          console.log(doc.data().to_email == that.$store.state.ngayi.email);
          if (doc.data().to_email == that.$store.state.ngayi.email) {
            that.$store.state.familyNotification.push({
              id: doc.id,
              from_email: doc.data().from_email,
              from_name: doc.data().from_name,
              from_image: doc.data().from_image,
              from_relation: doc.data().from_relation,
              to_email: doc.data().to_email,
              to_name: doc.data().to_name,
              to_image: doc.data().to_image,
              to_relation: doc.data().to_relation
            });
          }
        });
      });
    },
    /*******************************************************/
    open(value) {
      if (value == "settings") this.$router.push("/settings");
    },
    leTemps() {
      setTimeout(() => {
        this.changedValue();
        return false;
      }, 100);
      return 0;
    },
    changedValue() {
      this.show = true;
      return false;
    }
  },
  computed: {
    ouvrirTransition() {
      this.leTemps();
      return false;
    }
  }
};
</script>

<style scoped>
.bg-img {
  background: url("../../images/pexels-magda-ehlers-2660262.jpg") center /
      contain no-repeat,
    #151515;
}

.bg-col-img {
  background: url("../../images/pexels-askar-abayev-6190867.jpg") center / cover
    no-repeat;
}

.card {
  cursor: pointer;
  background-color: rgb(236, 236, 236);
  border-radius: 25px;
}

.card:hover {
  background-color: rgb(218, 211, 250);
}

.menu {
  padding: 0px 0px 100px 0px;
}
</style>
