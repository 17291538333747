<template>
  <!-- App.vue -->
  <v-app>
    <v-app-bar class="elevation-0 white" app>
      <v-btn
        text
        color="white"
        onclick="window.history.back()"
        v-if="part == 0"
      >
        <font-awesome-icon
          :icon="['fas', 'chevron-left']"
          class="fa-x black--text mr-2"
        />
      </v-btn>
      <v-btn text color="white" @click="part = 0" v-else>
        <font-awesome-icon
          :icon="['fas', 'chevron-left']"
          class="fa-x black--text mr-2"
        />
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn text class="black--text" @click="part = 0" v-if="part == 1">
        <font-awesome-icon
          :icon="['fas', 'bars']"
          class="fa-x mr-2 black--text"
        />
        Videos
      </v-btn>
      <v-btn icon v-if="part != 0">
        <font-awesome-icon :icon="['fas', 'search']" class="fa-x black--text" />
      </v-btn>
    </v-app-bar>
    <!-- Sizes your content based upon application components -->

    <v-main class="p-0 py-5" style="background-color:rgb(235, 236, 239)">
      <transition name="slide-fade">
        <div v-if="show">
          <section class="bg-col-image">
            <div
              style="height: 100%;width: 100%;background: rgba(11,18,81,0.66);padding: 80px 0px 60px;"
            >
              <div class="container py-0">
                <div class="row">
                  <div
                    class="col-12 d-flex justify-content-center align-items-center flex-column"
                    style="text-align: center;"
                  >
                    <h1
                      class="white--text display-1 font-weight-bold pt-5 text-uppercase"
                    >
                      Videos
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div
            class="py-5"
            data-aos="fade"
            data-aos-duration="1000"
            style="heights: 100vh;"
            v-if="part == 0"
          >
            <div
              class="d-flex flex-column justify-content-around align-items-center py-5"
              style="background-color: rgba(0,0,0,.0);height: 100%;"
            >
              <div class="container text-center">
                <v-simple-table class="bg-transparent">
                  <template v-slot:default>
                    <tbody>
                      <tr
                        v-for="(x, k) in categories"
                        :key="k"
                        @click="fongola(x.name)"
                      >
                        <td class="text-left">
                          {{ x.name }}
                        </td>
                        <td class="text-left title">
                          {{ x.nbre }}
                        </td>
                        <td class="text-left">
                          <font-awesome-icon
                            :icon="['fas', 'chevron-right']"
                            class="fa-x grey--text"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </div>
          </div>

          <section
            id="portfolio"
            class="content-section"
            style="padding: 10px 0px;"
            v-else
          >
            <div class="container py-5">
              <!-- v-for="(y,o) in 3" :key="o" -->
              <div class="row d-flex">
                <div
                  class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4"
                  style="padding: 10px;"
                  v-for="(x, k) in newsCategories"
                  :key="k"
                >
                  <!-- :href="x.video" target="_blank" -->
                  <a
                    class="portfolio-item"
                    :href="'https://www.youtube.com/embed/' + x.video"
                    target="_blank"
                  >
                    <div class="caption" style="background: rgba(0,0,0,0.5);">
                      <div class="caption-content">
                        <h2 style="font-size: 20.8px;">
                          <strong>{{ x.title.slice(0, 30) }}</strong
                          ><br />
                        </h2>
                        <span
                          style="color: rgb(207,207,207);font-size: 13px;background: rgba(244,71,107,0.5);"
                          >{{ x.date }}</span
                        >
                      </div>
                    </div>
                    <v-img
                      class="img-fluid"
                      :src="x.image"
                      style="height: 350px;width: 100%;"
                    />
                  </a>

                  <v-divider class="grey lighten-2"></v-divider>
                </div>
              </div>
            </div>
          </section>
        </div>
      </transition>
    </v-main>
    <div class="d-none">{{ ouvrirTransition }}</div>
  </v-app>
</template>

<script>
// import ServiceApi from "../ServicesApi.js";
import firebase from "firebase";
import db from "../../db.js";

export default {
  data() {
    return {
      email: "",
      show: false,
      password: "",
      dialog: false,
      place: "",
      videos: [],
      part: 0,
      newsCategories: [],
      categoriesList: [
        "Business",
        "Education",
        "Fêtes",
        "Immigration",
        "Nécrologie",
        "Religion",
        "Sport",
        "Santé",
        "WaKongo"
      ],
      categories: [],
      journal: [
        {
          title: "",
          image: "",
          video: ""
        }
      ]
    };
  },
  created() {
    var user = firebase.auth().currentUser;
    if (
      this.$store.state.ngayi.email == "" ||
      this.$store.state.ngayi.email == undefined ||
      user.email == "" ||
      user.email == undefined
    )
      this.$router.push("/menu");

    var that = this;
    /********************************************************/
    db.collection("videos").onSnapshot(snapshot => {
      that.videos = [];
      snapshot.forEach(doc => {
        that.videos.push({
          id: doc.data().id,
          title: doc.data().title,
          image: doc.data().image,
          paragraph: doc.data().paragraph,
          categorie: doc.data().categorie,
          myImage: "",
          auteur: "",
          video: doc.data().video,
          date: new Date(doc.data().cretedAd.toDate()).toDateString(),
          heure:
            " at " + new Date(doc.data().cretedAd.toDate()).toLocaleTimeString()
        });
        this.categoriesMaisNon();
      });
    });
    //     /*******************************************************/
  },

  methods: {
    categoriesMaisNon() {
      this.categories = [];
      this.categoriesList.forEach(list => {
        var ctr = 0;
        this.videos.forEach(element => {
          if (element.categorie.toLowerCase() == list.toLowerCase()) {
            ctr++;
          }
        });
        // alert(list+" -- "+ctr);
        this.categories.push({
          name: list,
          nbre: ctr
        });
      });
    },

    openVideos(x) {
      this.$store.state.videos = x;
      alert(x.video);
      // this.$router.push("/videos_details");
    },
    fongola(name) {
      this.newsCategories = [];
      this.videos.forEach(element => {
        if (element.categorie == name.toLowerCase()) {
          this.newsCategories.push({
            id: element.id,
            title: element.title,
            image: element.image,
            myImage: element.myImage,
            auteur: element.auteur,
            video: element.video,
            paragraph: element.paragraph,
            date: element.date,
            heure: element.heure
          });
        }
      });
      this.part = 1;
    },
    leTemps() {
      setTimeout(() => {
        this.changedValue();
        return false;
      }, 100);
      return 0;
    },
    changedValue() {
      this.show = true;
      return false;
    }
  },
  computed: {
    ouvrirTransition() {
      this.leTemps();
      return false;
    }
  }
};
</script>
