<template>
  <!-- App.vue -->
  <v-app>
    <v-app-bar class="elevation-0" app style="background-color:rgb(35, 36, 39)">
      <v-btn text color="white" onclick="window.history.back()">
        <font-awesome-icon
          :icon="['fas', 'arrow-left']"
          class="fa-x white--text mr-2"
        />
        retour
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn text class="white--text">
        <font-awesome-icon
          :icon="['fas', 'bars']"
          class="fa-x mr-2 white--text"
        />
        Video
      </v-btn>
      <v-btn icon>
        <font-awesome-icon :icon="['fas', 'search']" class="fa-x white--text" />
      </v-btn>
      <v-btn icon @click="dialog = true">
        <font-awesome-icon :icon="['fas', 'plus']" class="fa-x white--text" />
      </v-btn>
    </v-app-bar>
    <!-- Sizes your content based upon application components -->

    <v-main class="bg py-5">
      <transition name="slide-fade">
        <div v-if="show">
          <section
            id="portfolio"
            class="content-section"
            style="padding: 10px 0px;"
          >
            <div class="container py-5">
              <div class="row d-flex">
                <div
                  class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-4 col-xxl-4"
                  style="padding: 10px;"
                  v-for="(x, k) in videos"
                  :key="k"
                >
                  <a class="portfolio-item" @click="openNews(x)">
                    <div class="caption" style="background: rgba(0,0,0,0.5);">
                      <div class="caption-content">
                        <h2 style="font-size: 20.8px;">
                          <strong>{{ x.title.slice(0, 30) }}</strong
                          ><br />
                        </h2>
                        <span
                          style="color: rgb(207,207,207);font-size: 13px;background: rgba(244,71,107,0.5);"
                          >{{ x.date }}</span
                        >
                      </div>
                    </div>
                    <v-img
                      class="img-fluid"
                      :src="x.image"
                      style="height: 350px;width: 100%;"
                    />
                  </a>

                  <v-divider class="grey lighten-2"></v-divider>
                </div>
              </div>
            </div>
          </section>
        </div>
      </transition>
    </v-main>

    <v-dialog v-model="dialog" max-width="600">
      <v-card>
        <v-card-title
          class="title red lighten-2 white--text py-1 font-weight-normal mb-5"
        >
          Update a Video:
        </v-card-title>

        <v-card-text class="px-5">
          <v-img
            height="250px"
            :src="journal[0].image"
            v-if="journal[0].image != ''"
            @click="SelecFile()"
          />
          <v-img
            height="250px"
            v-else
            :src="require('../../images/noPic.png')"
            @click="SelecFile()"
          />

          <v-text-field
            class="mt-5"
            outlined
            dense
            label="Title"
            v-model="journal[0].title"
          />
          <v-select
            dense
            v-model="journal[0].categorie"
            :items="categories"
            label="Categories"
          />
          <v-text-field
            class="mt-5"
            outlined
            dense
            label="Link"
            v-model="journal[0].video"
          />

          <input
            type="file"
            id="file"
            @change="baPoniImages($event)"
            multiple
            style="display:none;"
          />

          <v-textarea
            rows="10"
            outlined
            label="Description"
            v-model="journal[0].paragraph"
          ></v-textarea>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red" class="mx-1" text @click="dialog = false">
            Close
          </v-btn>

          <v-btn color="green" class="mx-1" text @click="Enregistrer()">
            Enregistrer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="dialogWait" max-width="200">
      <v-card class="text-center py-3">
        <v-progress-circular
          :size="30"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <span class="ml-2">Please Wait</span>
      </v-card>
    </v-dialog>
    <div class="d-none">{{ ouvrirTransition }}</div>
  </v-app>
</template>

<script>
// import ServiceApi from "../ServicesApi.js";
import firebase from "firebase";
import db from "../../db.js";

export default {
  data() {
    return {
      email: "",
      show: false,
      password: "",
      dialog: false,
      dialogWait: false,
      place: "",
      videos: [],
      journal: [
        {
          title: "",
          image: "",
          video: ""
        }
      ],
      categories: [
        "nécrologie",
        "immigration",
        "fêtes",
        "waKongo",
        "business",
        "education",
        "sport",
        "religion",
        "travaux"
      ],
      makabo: true,
      o: {
        id: "",
        email: "",
        yesa: ""
      }
    };
  },
  created() {
    var user = firebase.auth().currentUser;
    if (
      this.$store.state.ngayi.email == "" ||
      this.$store.state.ngayi.email == undefined ||
      user.email == "" ||
      user.email == undefined
    )
      this.$router.push("/menu");

    var that = this;
    /********************************************************/
    db.collection("videos").onSnapshot(snapshot => {
      that.videos = [];
      snapshot.forEach(doc => {
        that.videos.push({
          id: doc.data().id,
          title: doc.data().title,
          image: doc.data().image,
          paragraph: doc.data().paragraph,
          categorie: doc.data().categorie,
          myImage: "",
          auteur: "",
          video: doc.data().video,
          date: new Date(doc.data().cretedAd.toDate()).toDateString(),
          heure:
            " at " + new Date(doc.data().cretedAd.toDate()).toLocaleTimeString()
        });
      });
    });
    //     /*******************************************************/
  },
  mounted() {
    var that = this;
    console.log(this.$store.state.email + " from menu");
    /********************************************************/
    if (this.$store.state.email == "") this.$router.push("/menu");
    else {
      /********************************************************/
      db.collection("charge")
        .orderBy("email", "asc")
        .onSnapshot(snapshot => {
          this.o = {
            id: "",
            email: "",
            yesa: ""
          };
          this.makabo = true;
          snapshot.forEach(doc => {
            if (
              doc.data().yesa == that.$store.state.ngayi.yesa &&
              doc.data().email.toLowerCase() ==
                that.$store.state.ngayi.email.toLowerCase() &&
              doc.data().id.toLowerCase() ==
                that.$store.state.ngayi.id.toLowerCase()
            ) {
              this.o = {
                id: doc.data().id,
                email: doc.data().email,
                yesa: doc.data().yesa
              };
              this.makabo = false;
            }
          });
          if (
            this.makabo == true ||
            this.$store.state.ngayi.yesa != process.env.VUE_APP_KONGOMANI
          ) {
            // alert("block account");
            try {
              db.collection("challenge").add({
                id: that.$store.state.ngayi.id,
                email: that.$store.state.ngayi.email,
                access: "access au menu pro",
                cretedAd: firebase.firestore.FieldValue.serverTimestamp()
              });
            } catch (error) {
              alert(error.message);
            }
          }
        });
    }
    // /*******************************************************/
  },

  methods: {
    Enregistrer() {
      var that = this;
      if (this.journal[0].title != "" || this.journal[0].video != "") {
        /********************************************* */
        try {
          db.collection("videos").add({
            id: that.$store.state.ngayi.id,
            // myImage: that.$store.state.ngayi.image,
            title: that.journal[0].title,
            image: that.journal[0].image,
            video: that.journal[0].video,
            paragraph: that.journal[0].paragraph,
            categorie: that.journal[0].categorie,
            cretedAd: firebase.firestore.FieldValue.serverTimestamp()
          });
        } catch (error) {
          alert(error.message);
        }
        that.journal = [
          {
            title: "",
            image: "",
            paragraph: ""
          }
        ];
        alert("Successfully");
        /********************************************* */
      } else {
        alert("Veuillez completer toutes les cases, s'il vous plaît !");
      }
      this.dialog = false;
    },
    openNews(x) {
      this.$store.state.videos = x;
      this.$router.push("/videos_details");
    },
    SelecFile() {
      this.$store.state.ImagesUpload = [];
      document.getElementById("file").click();
    },
    baPoniImages(e) {
      document.getElementById("file");
      
      var that = this;
      e.target.files.forEach(element => {
        that.dialogWait = true;
        var filename = element.name;
        var tableau = "";

        var storageRef = firebase
          .storage()
          .ref("videos/" + this.$store.state.ngayi.email + "/" + filename);
        var metadata = {
          contentType: "image/jpeg"
        };

        storageRef.put(element, metadata).then(function(downloadURL) {
          downloadURL.ref.getDownloadURL().then(function(url) {
            url = String(url);
            if (url !== undefined) {
              var b = url;
              tableau = b;
              console.log(tableau);
              that.journal[0].image = tableau;
              that.dialogWait = false;
            }
          });
        });
      });
    },
    leTemps() {
      setTimeout(() => {
        this.changedValue();
        return false;
      }, 100);
      return 0;
    },
    changedValue() {
      this.show = true;
      return false;
    }
  },
  computed: {
    ouvrirTransition() {
      this.leTemps();
      return false;
    }
  }
};
</script>
