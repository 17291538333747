<template>
  <v-app>
    <v-app-bar
      dense
      app
      style="background-color:rgb(35, 36, 39);"
      class="elevation-0 blue darken-3"
    >
      <v-btn class="white--text" text href="#/menu">
        <font-awesome-icon
          :icon="['fas', 'chevron-left']"
          class="fa-x white--text mr-2"
        />
      </v-btn>
      <v-spacer></v-spacer>
      <v-avatar size="30">
        <img :src="require('../../images/myLogo4.png')" class="img-fluid" />
      </v-avatar>
    </v-app-bar>
    <v-main class="grey lighten-3 px-0 py-5">
      <transition name="slide-fade">
        <div v-if="show">
          <div class="my-5 message" style="height:100%; padding: 0px 0px 90px;">
            <!-- Message -->
            <v-list three-line class="grey lighten-3">
              <template v-for="(item, index) in items">
                <v-subheader
                  v-if="item.header"
                  :key="item.header"
                  v-text="item.header"
                ></v-subheader>

                <v-divider
                  v-else-if="item.divider"
                  :key="index"
                  :inset="item.inset"
                ></v-divider>

                <v-list-item v-else :key="index">
                  <v-list-item-avatar size="60">
                    <v-img :src="item.image"></v-img>
                  </v-list-item-avatar>

                  <!-- Bango -->
                  <!-- {{item.id+' == '+$store.state.ngayi.id}} -->
                  <v-list-item-content
                    v-if="item.id == $store.state.ngayi.id"
                    class="grey lighten-2 px-3"
                  >
                    <v-list-item-title
                      v-html="item.fullname"
                      class="font-weight-bold"
                    ></v-list-item-title>
                    <v-list-item-subtitle
                      v-html="item.ladate"
                      class="primary--text"
                    ></v-list-item-subtitle>
                    <p
                      class="pl-5 pt-3 m-0"
                      style="white-space: pre-line"
                      v-if="item.signaler == false"
                    >
                      {{ item.message }}
                    </p>
                    <p
                      class="pl-5 py-3 m-0 mt-2 red lighten-4"
                      style="white-space: pre-line"
                      v-else
                    >
                      {{ item.message }}
                      <v-divider class="p-0 mb-0"></v-divider>
                      <br />
                      <span class="m-0 p-0 overline"
                        >Ce message a été signaler</span
                      >
                    </p>
                  </v-list-item-content>

                  <!-- Ngayi -->
                  <v-list-item-content v-else class="px-3">
                    <v-list-item-title
                      v-html="item.fullname"
                      class="font-weight-bold"
                    ></v-list-item-title>
                    <v-list-item-subtitle
                      v-html="item.ladate"
                      class="primary--text"
                    ></v-list-item-subtitle>
                    <div class="p-0">
                      <v-btn
                        small
                        text
                        color="red"
                        class="white--text"
                        @click="
                          leMesage = item;
                          dialogQuestion = true;
                        "
                        >Signaler</v-btn
                      >
                    </div>
                    <p
                      class="pl-5 pt-3 m-0"
                      style="white-space: pre-line"
                      v-if="item.signaler == false"
                    >
                      {{ item.message }}
                    </p>
                    <p
                      class="pl-5 py-3 m-0 mt-2 red lighten-4"
                      style="white-space: pre-line"
                      v-else
                    >
                      {{ item.message }}
                      <v-divider class="p-0 mb-0"></v-divider>
                      <br />
                      <span class="m-0 p-0 overline"
                        >Ce message a été signaler</span
                      >
                    </p>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
            <!-- End Message -->
          </div>
        </div>
      </transition>
    </v-main>

    <v-footer dense app class="px-1 pt-2 pb-0 m-0 blue darken-3">
      <v-textarea
        rows="2"
        dark
        dense
        v-model="message"
        filled
        clear-icon="mdi-close-circle"
        clearable
        label="Message"
        type="text"
      >
        <!-- @keypress.enter="postMessages()" -->
      </v-textarea>
      <!-- <v-text-field dense outlined label="Message" />
      <v-spacer></v-spacer> -->
      <v-btn
        icons
        text
        color="success"
        v-if="message != ''"
        @click="postMessages()"
      >
        <font-awesome-icon
          :icon="['fas', 'caret-square-right']"
          class="fa-2x green--text mr-2"
        />
      </v-btn>
    </v-footer>

    <v-dialog persistent v-model="dialogQuestion" max-width="400">
      <v-card class="text-center py-3 px-3">
        <h6 class="py-5">Voulez-vous signaler ce message ?</h6>
        <v-btn small color="success" class="elevation-0 m-2" @click="Signaler()"
          >Oui</v-btn
        >
        <v-btn
          small
          color="red white--text"
          class="elevation-0 m-2"
          @click="dialogQuestion = false"
          >Non</v-btn
        >
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="dialogErreur" max-width="400">
      <v-card class="text-center py-3 px-5">
        <h5 class="py-5">{{ messError }}</h5>

        <v-btn
          small
          color="red white--text"
          class="elevation-0 m-2"
          @click="dialogErreur = false"
          >Désolé</v-btn
        >
      </v-card>
    </v-dialog>
    <div class="d-none">{{ ouvrirTransition }}</div>
  </v-app>
</template>

<script>
import firebase from "firebase";
import db from "../../db.js";

export default {
  name: "Home",
  data() {
    return {
      password: "Password",
      show: false,
      message: "",
      items: [],
      people: [],
      dialogQuestion: false,
      messError: "",
      dialogErreur: false,
      leMesage: {},
      chat: [],
      auzuidui: "",
      restrictionsPolitique: [
        "lamuka",
        "UDPS",
        "Fatshi",
        "Beton",
        "Béton",
        "Martin Fayulu",
        "télé Tshangu",
        "Congofrance",
        "Bosolo na Politik",
        "Bosolo na politique",
        "Bosolo na politic",
        "Bosolo bwa politik",
        "Bosolo bwa politique",
        "Bosolo bwa politic",
        "assemble nationale",
        "assemblé nationale",
        "assemblée nationale",
        "Felix Tshisekedi",
        "Felix Tshilombo",
        "Boketshu",
        "Boketshu Wayambo",
        "Commandant Esso",
        "Taliban",
        "Combattant",
        "Resistant",
        "politique",
        "parlement débout",
        "parlement debout",
        "Kamerhe",
        "Bemba",
        "Katumbi",
        "Jean Marc Kabund",
        "Jean Kabund",
        "Augustin Kabuya",
        "Pasteur guilit",
        "Adolf Muzito",
        "parlement",
        "Daniel safu",
        "Eliezer ndeko",
        "Kabila",
        "PPRD",
        "MLC",
        "AMP",
        "authorité morale",
        "drapeau du zaire",
        "drapeau du zaïre",
        "concert à paris",
        "Joe Biden",
        "Donal Trump",
        "Trump",
        "FCC Cash",
        "FCC-Cash",
        "FCC",
        "Denise Nyakeru",
        "Gisele Mpela",
        "Rwandais",
        "Paul kagame",
        "tutshi",
        "tutsi",
        "Musseveni",
        "Union Africaine",
        "SADEC",
        "coup d'etat",
        "Marche pacifique",
        "Soulevement populaire",
        "police congolaise",
        "general kasongo",
        "generale kasongo",
        "générale kasongo",
        "général kasongo",
        "Mboso kodia",
        "Peter Nkumu"
      ],
      restrictionsOffensif: [
        "zoba",
        "liboma",
        "libolo",
        "likata",
        "masoko",
        "lifuni",
        "makunza",
        "likunza",
        "lisoko",
        "makata",
        "imbecile",
        "imbécile",
        "idiot",
        "negre",
        "nègre",
        "tufi na yo",
        "con",
        "fuck",
        "bitch",
        "shit",
        "asshole",
        "motherfucker",
        "dumbass",
        "dumb ass",
        "fucking",
        "f***",
        "f*ck",
        "f**k"
      ]
    };
  },
  created() {
    var user = firebase.auth().currentUser;
    if (
      this.$store.state.ngayi.email == "" ||
      this.$store.state.ngayi.email == undefined ||
      user.email == "" ||
      user.email == undefined
    )
      this.$router.push("/menu");

    /********************************************************/
    try {
      var that = this;
      var kadena = [];
      db.collection("kadena")
        .where("email", "==", that.$store.state.email)
        .onSnapshot(snapshot => {
          kadena = [];
          snapshot.forEach(doc => {
            kadena.push({
              email: doc.data().email
            });
          });
          if (kadena.length > 0) that.blurMe();
        });
    } catch (error) {
      alert(error.message);
    }
    /********************************************************/
  },
  mounted() {
    var d = new Date();
    this.auzuidui = d.toDateString();
    this.getMessages();
  },
  methods: {
    getMessages() {
      var that = this;
      /********************************************************/
      db.collection("chat")
        .orderBy("cretedAd", "asc")
        .onSnapshot(snapshot => {
          that.chat = [];
          snapshot.forEach(doc => {
            that.chat.push({
              originalID: doc.id,
              id: doc.data().id,
              email: doc.data().email,
              message: doc.data().message,
              fullname: doc.data().fullname,
              signaler: doc.data().signaler,
              image: doc.data().image,
              ladate: new Date(doc.data().cretedAd.toDate()).toDateString(),
              heure:
                " at " +
                new Date(doc.data().cretedAd.toDate()).toLocaleTimeString()
            });
          });
          console.log(this.chat);
          console.log("this.chat up");
          that.zuaPeople();
        });
      ///*******************************************************/
    },

    postMessages() {
      var accessPolitique = true;
      var accessOffensif = true;
      this.restrictionsPolitique.forEach(element => {
        if (this.message.toLowerCase().includes(element.toLowerCase()))
          accessPolitique = false;
      });

      this.restrictionsOffensif.forEach(element => {
        if (this.message.toLowerCase().includes(element.toLowerCase()))
          accessOffensif = false;
      });

      if (this.message != "") {
        if (accessPolitique == true && accessOffensif == true) {
          var that = this;
          try {
            db.collection("chat").add({
              image: that.$store.state.ngayi.image,
              email: that.$store.state.ngayi.email,
              id: that.$store.state.ngayi.id,
              signaler: false,
              fullname:
                that.$store.state.ngayi.nom +
                " " +
                that.$store.state.ngayi.postnom +
                " " +
                that.$store.state.ngayi.prenom,
              message: that.message,
              cretedAd: firebase.firestore.FieldValue.serverTimestamp()
            });
            that.message = "";
          } catch (error) {
            alert(error.message);
          }
        } else {
          if (accessPolitique == false && accessOffensif == true) {
            this.messError =
              "Vous ne pouvez pas publier un message politique !";
          } else if (accessPolitique == true && accessOffensif == false) {
            this.messError =
              "Votre message est offensif. Par conséquent, il ne peut pas être publié";
          } else if (accessPolitique == false && accessOffensif == false) {
            this.messError =
              "Votre message est offensif et politique. Il ne peut pas être publié";
          }
          this.dialogErreur = true;
        }
      }
    },
    tiaSikoyo() {
      this.items = [];
      this.items.push({ header: "Today" });

      this.chat.forEach(element => {
        if (element.ladate == this.auzuidui) {
          this.items.push({
            originalID: element.originalID,
            id: element.id,
            email: element.email,
            signaler: element.signaler,
            message: element.message,
            fullname: element.fullname,
            image: element.image,
            ladate: element.ladate + element.heure
          });
          this.items.push({ divider: true, inset: true });
        }
      });
      console.log(this.items);
    },
    zuaPeople() {
      var that = this;
      /***************************************/
      db.collection("users").onSnapshot(snapshot => {
        that.people = [];
        snapshot.forEach(doc => {
          that.people.push({
            id: doc.data().id,
            image: doc.data().image,
            email: doc.data().email
          });
        });
        console.log("tab");
        this.tiaImage();
      });
      /***************************************/
    },
    tiaImage() {
      this.chat.forEach(chat => {
        this.people.forEach(element => {
          if (element.id.toLowerCase() == chat.id.toLowerCase()) {
            chat.image = element.image;
          }
        });
      });
      this.tiaSikoyo();
    },
    Signaler() {
      console.log(this.leMesage);
      try {
        db.collection("signaler").add({
          messageID: this.leMesage.originalID,
          image: this.leMesage.image,
          parImage: this.$store.state.ngayi.image,
          id: this.leMesage.id,
          parId: this.$store.state.ngayi.id,
          email: this.leMesage.email,
          fullname: this.leMesage.fullname,
          parFullname:
            this.$store.state.ngayi.nom +
            " " +
            this.$store.state.ngayi.postnom +
            " " +
            this.$store.state.ngayi.prenom +
            " ",
          message: this.leMesage.message,
          ladate: this.leMesage.ladate,
          cretedAd: firebase.firestore.FieldValue.serverTimestamp()
        });
        alert("Successfully");
        this.dialogQuestion = false;
        this.notifyMe();
      } catch (error) {
        alert(error.message);
      }
    },
    notifyMe() {
      try {
        /****************************/
        db.collection("chat")
          .doc(this.leMesage.originalID)
          .update({
            signaler: true
          });
        /*************************/
      } catch (error) {
        alert(error.message);
      }
    },
    blurMe() {
      var that = this;
      try {
        db.collection("challenge").add({
          id: that.$store.state.ngayi.id,
          email: that.$store.state.ngayi.email,
          access: "access au chat",
          cretedAd: firebase.firestore.FieldValue.serverTimestamp()
        });
        this.$router.push("/");
      } catch (error) {
        alert(error.message);
      }
    },
    leTemps() {
      setTimeout(() => {
        this.changedValue();
        return false;
      }, 100);
      return 0;
    },
    changedValue() {
      this.show = true;
      return false;
    }
  },
  computed: {
    ouvrirTransition() {
      this.leTemps();
      return false;
    }
  }
};
</script>

<style scoped>
.message {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;
}
</style>
