<template>
  <!-- App.vue -->
  <v-app>
    <v-main class="bg">
      <div
        class="bg-blue"
        data-aos="fade"
        data-aos-duration="1000"
        style="height: 100vh;"
        v-if="part == 0"
      >
        <div
          class="d-flex flex-column justify-content-around align-items-center"
          style="background-color: rgba(0,0,0,.79);height: 100%;"
        >
          <div class="container text-center"></div>
          <div class="container text-center p-0 d-none d-md-block w-100">
            <img
              :src="require('../images/myLogo.png')"
              class="w-25"
              data-aos="flip-up"
              data-aos-duration="1000"
            />
          </div>
          <div class="container text-center p-0 d-block d-md-none w-100">
            <img
              :src="require('../images/myLogo.png')"
              class="w-50"
              data-aos="flip-up"
              data-aos-duration="1000"
            />
          </div>
          <div class="container text-center p-0">
            <h3 class="display-1 white--text">waKongo</h3>
            <h3 class="overline white--text">version 1.0</h3>
          </div>
        </div>
      </div>

      <div
        data-aos="fade"
        data-aos-duration="1000"
        style="height: 100vh;"
        v-else
      >
        <div
          class="d-flex flex-column justify-content-around align-items-center"
          style="background: rgba(0,0,0,0);height: 100%;"
        >
          <div class="container text-center">
            <img :src="require('../images/myLogo.png')" class="w-25" />

            <h1 data-aos="slide-left" data-aos-duration="1000" class="titre">
              Connexion<br />
            </h1>

            <!-- <div class="text-center">
              <v-btn
                dense
                text
                href="#/signupbefore"
                class="grey--text overline"
                >Créer un nouveau compte</v-btn
              >
            </div> -->
          </div>
          <div class="container">
            <div class="row d-flex justify-content-center">
              <div class="col-sm-10 col-md-9 col-lg-6 col-xl-5 col-xxl-5">
                <div
                  class="row d-flex justify-content-center align-items-center"
                >
                  <div
                    class="col-10 col-sm-8 col-md-8 col-lg-9"
                    style="text-align: center;padding: 7px;"
                  >
                    <!-- <form> -->
                    <input
                      class="form-control"
                      type="text"
                      name="Email"
                      placeholder="Email"
                      v-model="email"
                      style="border-radius: 10px;background: rgba(0,0,0,0); color:white;"
                      @keypress.enter="login()"
                    />
                    <!-- </form> -->
                  </div>
                  <div
                    class="col-10 col-sm-8 col-md-8 col-lg-9"
                    style="text-align: center;padding: 7px;"
                  >
                    <!-- <form> -->
                    <input
                      class="form-control"
                      type="password"
                      v-model="password"
                      style="border-radius: 10px;background: rgba(0,0,0,0); color:white;"
                      placeholder="Mot de passe"
                      name="Password"
                      @keypress.enter="login()"
                    />
                    <!-- </form> -->
                  </div>
                  <div class="col-8 col-sm-5 col-md-5 col-lg-4 mt-5">
                    <v-btn
                      color="#2962ff"
                      class="elevation-0 white--text"
                      block
                      @click="login()"
                      >Se Connecter</v-btn
                    >
                  </div>
                  <!-- <div
                    class="col-12 col-md-12 mt-2"
                    style="text-align: center;"
                  >
                    <a
                      class="grey--text"
                      href="#/signup"
                      style="font-size: 12px;"
                      >J'ai oublié mon mot de passe<br
                    /></a>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-main>
    <v-dialog persistent v-model="dialogWait" max-width="200">
      <v-card class="text-center py-3">
        <v-progress-circular
          :size="30"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <span class="ml-2">Connexion</span>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import firebase from "firebase";
// import ServiceApi from "../ServicesApi.js";

// import db from "./db.js";

export default {
  data() {
    return {
      email: "",
      password: "",
      erreur: "",
      dialogErreur: false,
      dialogWait: false,
      query: "",
      part: 0
    };
  },
  mounted() {
    setTimeout(() => {
      this.part = 1;
    }, 5000);
  },
  methods: {
    login() {
      var that = this;
      that.dialogWait = true;
      document.cookie = "mutuYango=" + ";";
      document.cookie = "codeYango=" + ";";
      that.$store.state.familyNotification = [];
      firebase
        .auth()
        .signInWithEmailAndPassword(that.email, that.password)
        .then(
          elt => {
            // console.log(elt.user.email);
            // console.log(elt.user.password);
            var a = elt;
            a = "";
            console.log(a);

            var d = new Date();
            d.setTime(d.getTime() + 5 * (365 * 24 * 60 * 60 * 1000));
            var expires = "expires=" + d.toUTCString();
            document.cookie = "mutuYango=" + that.email + ";" + expires + ";";
            document.cookie =
              "codeYango=" + that.password + ";" + expires + ";";
            that.$store.state.part = 0;
            setTimeout(() => {
              that.$router.push("/menu");
            }, 2000);
          },
          error => {
            // that.erreur = error.message;
            that.dialogWait = false;
            alert(error.message);
          }
        );
    }
  }
};
</script>
