<template>
  <!-- App.vue -->
  <!--  -->
  <v-app>
    <v-app-bar class="elevation-0" app>
      <v-btn text color="white" href="#/menu">
        <font-awesome-icon
          :icon="['fas', 'chevron-left']"
          class="fa-x black--text mr-2"
        />
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn text small class="black--text" href="#/creation">
        <font-awesome-icon
          :icon="['far', 'play-circle']"
          class="fa-x black--text mr-1"
        />
        Créer
      </v-btn>
      <v-btn text small class="black--text" href="#/ajouter">
        <font-awesome-icon
          :icon="['fas', 'plus']"
          class="fa-x black--text mr-1"
        />
        Ajouter
      </v-btn>
    </v-app-bar>
    <!-- Sizes your content based upon application components -->

    <v-main class="main p-0 py-5" style="background-color:rgb(235, 236, 239)">
      <transition name="slide-fade">
        <div v-if="show">
          <section class="bg-col-image">
            <div
              style="height: 100%;width: 100%;background: rgba(11,18,81,0.66);padding: 80px 0px;"
            >
              <div class="container py-0">
                <div class="row">
                  <div
                    class="col-12 d-flex justify-content-center align-items-center flex-column"
                    style="text-align: center;"
                  >
                    <h1
                      class="white--text display-2 font-weight-bold pt-5 text-uppercase"
                    >
                      Famille
                    </h1>
                    <p
                      class="d-md-none d-lg-flex"
                      style="color: rgb(224,224,224);font-size: 22px;"
                    >
                      <!-- <br />Jeunes retraités actifs ou centenaires dépendants,
                      la Ville doit pouvoir répondre à leurs besoins de loisirs,
                      daide sociale, de santé ou de logement.<br /><br /> -->
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <!-- <div class="py-5" style="background: rgba(0,0,0,.79);height: 100%;"> -->
          <div class="container my-5">
            <!-- {{$store.state.family}}
          <span class="red--text">{{family}}</span> -->
            <!-- <span class="red--text">{{yeyo}}</span> -->
            <!-- Family -->
            <div v-if="$store.state.family.length > 0">
              <v-row
                class="py-5"
                v-for="(y, kf) in $store.state.family"
                :key="kf"
              >
                <v-col cols="12" class="px-5">
                  <v-divider class="grey"></v-divider>
                  <h5
                    class="black--text my-2 black--text font-weight-bold text-uppercase"
                  >
                    {{ y.name }}
                  </h5>

                  <!-- People -->
                  <v-simple-table class="bg-transparent">
                    <template v-slot:default>
                      <tbody>
                        <tr
                          v-for="(x, k) in family"
                          :key="k"
                          @click="estcekenga(x)"
                        >
                          <td class="py-2" v-if="y.id == x.familyId">
                            <v-avatar>
                              <v-img :src="x.image" class="img-fluid" />
                            </v-avatar>
                          </td>
                          <td class="py-2" v-if="y.id == x.familyId">
                            <h6 class="my-2 font-weight-bold black--text">
                              {{ x.fullname }}
                            </h6>
                          </td>
                          <td class="text-right py-2" v-if="y.id == x.familyId">
                            <font-awesome-icon
                              :icon="['fas', 'chevron-right']"
                              class="fa-x black--text"
                              v-if="x.id == y.who"
                            />
                            <font-awesome-icon
                              :icon="['fas', 'lock']"
                              class="fa-x grey--text"
                              v-else
                            />
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <!-- end People -->
                </v-col>
              </v-row>
            </div>

            <div class="my-5 grey lighten-2 p-3 text-center h4" v-else>
              Aucune Famile
            </div>

            <!-- Roomate -->
            <v-row class="py-5" v-if="roommate.length > 0">
              <!-- <span class="black--text">{{mesRoommate}}</span> -->
              <v-col cols="12" class="px-5">
                <h5 class="my-2 black--text font-weight-bold text-uppercase">
                  Roomate(s):
                </h5>

                <!-- People -->
                <v-row>
                  <v-col
                    cols="6"
                    md="4"
                    lg="3"
                    class="text-center"
                    v-for="(x, k) in roommate"
                    :key="k"
                  >
                    <v-card
                      class="p-3 elevation-0 grey lighten-2"
                      style="height:100%"
                    >
                      <v-card-text class="p-0">
                        <v-avatar size="100">
                          <v-img :src="x.image" class="img-fluid" /> </v-avatar
                        ><br />
                        <h6 class="my-2 font-weight-bold black--text">
                          {{ x.fullname }}
                        </h6>
                        <!-- <span>{{ x.relation }}</span> -->
                      </v-card-text>
                      <v-card-actions class="p-0">
                        <div class="text-center w-100">
                          <v-btn
                            x-small
                            color="red white--text"
                            class="elevation-0"
                            @click="
                              dialogQuestion = true;
                              originVariable = x.originVariable;
                              toWho = 'room';
                            "
                            v-if="x.id == $store.state.ngayi.id"
                            >Quitter</v-btn
                          >
                          <v-btn
                            x-small
                            color="success"
                            class="elevation-0 m-1"
                            :href="'tel:' + x.phone"
                            v-if="x.id != $store.state.ngayi.id && x.phone"
                            >Appeler</v-btn
                          >
                          <v-btn
                            x-small
                            color="primary"
                            class="elevation-0 m-1"
                            :href="'mailto:' + x.email"
                            v-if="x.id != $store.state.ngayi.id && x.email"
                            >Email</v-btn
                          >
                        </div>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
                <!-- end People -->
              </v-col>
            </v-row>

            <div class="my-5 grey lighten-2 p-3 text-center h4" v-else>
              Aucun Roomate
            </div>
          </div>
        </div>
      </transition>
      <!-- </div> -->
    </v-main>

    <v-dialog v-model="dialog" class="p-0" max-width="600">
      <v-card height="100%" class="white">
        <v-card-title
          class="title primary black--text py-1 font-weight-normal mb-5"
        >
          Add a family member:
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <font-awesome-icon
              :icon="['fas', 'times']"
              class="fa-x black--text"
            />
          </v-btn>
        </v-card-title>

        <v-card-text class="px-5">
          <v-text-field class="mt-5" dense label="Search" v-model="search" />
          <v-list dense>
            <v-subheader>People</v-subheader>
            <v-list-item-group color="primary">
              <v-list-item
                v-for="(item, i) in usersPeople"
                :key="i"
                class="py-2"
                @click="
                  yeyo[0].fullname = item.fullname;
                  yeyo[0].image = item.image;
                  dialogRelation = true;
                  yeyo[0].email = item.email;
                "
              >
                <v-avatar>
                  <v-img :src="item.image" />
                </v-avatar>
                <v-list-item-content class="px-5">
                  <v-list-item-title
                    class="font-weight-bold"
                    v-text="item.fullname"
                  ></v-list-item-title>
                  <span style="font-size:.8em;">{{ item.email }}</span>
                </v-list-item-content>
                <v-btn
                  class="d-none d-lg-block"
                  small
                  color="primary"
                  @click="
                    yeyo[0].fullname = item.fullname;
                    yeyo[0].image = item.image;
                    dialogRelation = true;
                    yeyo[0].email = item.email;
                  "
                >
                  + Ajouter</v-btn
                >
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogRelation" persistent class="p-0" max-width="400">
      <v-card height="100%" class="white">
        <v-card-text class="px-5">
          <v-list dense v-if="$store.state.ngayi.sex == 'F'">
            <v-subheader class="subtitle-2 mt-5"
              >How do you describe your relation withes
              {{ yeyo[0].fullname + " ?" }}
            </v-subheader>
            <v-list-item-group color="primary" v-model="yeyo[0].relation">
              <v-list-item v-for="(item, i) in tipeF" :key="i" class="py-1">
                <v-list-item-content class="px-5">
                  <v-list-item-title v-text="item"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <!-- homme -->

          <v-list dense v-if="$store.state.ngayi.sex == 'M'">
            <v-subheader class="subtitle-2 mt-5"
              >How do you describe your relation withes
              {{ yeyo[0].fullname + " ?" }}
            </v-subheader>
            <v-list-item-group color="primary" v-model="yeyo[0].relation">
              <v-list-item v-for="(item, i) in tipeH" :key="i" class="py-1">
                <v-list-item-content class="px-5">
                  <v-list-item-title v-text="item"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>

        <v-card-actions class="px-5">
          <v-btn
            small
            @click="dialogRelation = false"
            color="red lighten-2 black--text"
            >Cancel</v-btn
          >
          <v-btn small @click="createFamily()" color="primary">Valider</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="dialogQuestion" max-width="400">
      <v-card class="text-center py-3">
        <h6 class="py-5" v-if="toWho == 'fami'">
          Voulez-vous quitter la famille ?
        </h6>
        <h6 class="py-5" v-else>Voulez-vous quitter ce(s) roomate(s) ?</h6>
        <v-btn
          small
          color="success"
          v-if="toWho == 'fami'"
          class="elevation-0 m-2"
          @click="quiter()"
          >Oui</v-btn
        >
        <v-btn
          small
          color="success"
          v-else
          class="elevation-0 m-2"
          @click="quiterRoom()"
          >Oui</v-btn
        >
        <v-btn
          small
          color="red white--text"
          class="elevation-0 m-2"
          @click="dialogQuestion = false"
          >Non</v-btn
        >
      </v-card>
    </v-dialog>
    <div class="d-none">{{ ouvrirTransition }}</div>
  </v-app>
</template>

<script>
// import ServiceApi from "../ServicesApi.js";
import firebase from "firebase";
import db from "../../db.js";

export default {
  data() {
    return {
      email: "kerene@example.com",
      show: false,
      password: "plus1234",
      dialog: false,
      dialogQuestion: false,
      originVariable: "",
      toWho: "",
      dialogRelation: false,
      tipeH: [
        "Brother",
        "Dad",
        "Daughter",
        "Host",
        "Mom",
        "Sister",
        "Son",
        "Wife"
      ],
      tipeF: [
        "Brother",
        "Dad",
        "Daughter",
        "Husband",
        "Host",
        "Mom",
        "Sister",
        "Son"
      ],
      checkbox: "",
      search: "",
      yeyo: [
        {
          fullname: "",
          image: "",
          relation: ""
        }
      ],
      valeur: 50,
      users: [],
      family: [],
      roommate: [],
      mesRoommate: [],
      familyId: "",
      familyName: ""
    };
  },
  created() {
    this.$store.state.family = [];
    var user = firebase.auth().currentUser;
    if (
      this.$store.state.ngayi.email == "" ||
      this.$store.state.ngayi.email == undefined ||
      user.email == "" ||
      user.email == undefined
    )
      this.$router.push("/menu");
    else {
      var that = this;

      /********************************************************/
      that.$store.state.family = [];
      /********************************************************/
      db.collection("family")
        .where("id", "==", that.$store.state.ngayi.id)
        .onSnapshot(snapshot => {
          that.$store.state.family = [];
          // that.familyId = "";
          // that.familyName = "";
          snapshot.forEach(doc => {
            // that.familyName = doc.data().familyName;
            // that.familyId = doc.data().familyId;
            that.$store.state.family.push({
              id: doc.data().familyId,
              name: doc.data().familyName,
              who: doc.data().id
            });
          });
          console.log(that.$store.state.family);
          this.getFamily();
        });
      /*******************************************************/

      /********************************************************/
      that.$store.state.roommate = [];
      /********************************************************/

      db.collection("roommate")
        .where("id", "==", that.$store.state.ngayi.id)
        .onSnapshot(snapshot => {
          that.roommate = [];
          that.roomId = "";
          snapshot.forEach(doc => {
            console.log(doc.id);
            that.roomId = doc.data().roomid;
            that.$store.state.roommate = doc.data().roomid;
            that.mesRoommate.push({
              id: doc.id
            });
          });
          this.getRoomate();
        });
      /*******************************************************/
      this.getUsers();
    }
  },
  computed: {
    usersPeople() {
      var tab = [];
      this.users.forEach(element => {
        if (
          element.fullname.toLowerCase().includes(this.search.toLowerCase())
        ) {
          tab.push({
            id: element.id,
            image: element.image,
            fullname: element.fullname,
            email: element.email
          });
        }
      });
      return tab;
    },
    ouvrirTransition() {
      this.leTemps();
      return false;
    }
  },
  methods: {
    getUsers() {
      var that = this;
      /********************************************************/
      db.collection("users").onSnapshot(snapshot => {
        that.users = [];
        snapshot.forEach(doc => {
          that.users.push({
            id: doc.data().id,
            image: doc.data().image,
            fullname:
              doc.data().nom +
              " " +
              doc.data().postnom +
              " " +
              doc.data().prenom,
            email: doc.data().email,
            phone: doc.data().phone
          });
        });
      });
    },
    /*******************************************************/
    getFamily() {
      var that = this;
      that.family = [];
      var uniqstate = this.$store.state.family.filter(
        (v, i, a) => a.findIndex(t => t.id === v.id) === i
      );
      this.$store.state.family = uniqstate;

      this.$store.state.family.forEach(element => {
        console.log(element.id);
        /********************************************************/
        db.collection("family")
          .where("familyId", "==", element.id)
          .onSnapshot(snapshot => {
            snapshot.forEach(doc => {
              console.log(doc.id);
              that.family.push({
                originVariable: doc.id,
                id: doc.data().id,
                familyId: doc.data().familyId,
                fullname: "",
                image: "",
                phone: "",
                email: ""
              });
            });
            var uniq = this.family.filter(
              (v, i, a) =>
                a.findIndex(t => t.id === v.id && t.familyId === v.familyId) ===
                i
            );
            this.family = uniq;
            console.log("family");
            console.log(this.family);
            console.log("family");
            that.prendUsers();
          });
        /*******************************************************/
      });
    },
    /*******************************************************/
    getRoomate() {
      var that = this;

      // alert(this.$store.state.roommate);
      /********************************************************/
      db.collection("roommate")
        .where("roomid", "==", that.roomId)
        .onSnapshot(snapshot => {
          that.roommate = [];
          snapshot.forEach(doc => {
            that.roommate.push({
              originVariable: doc.id,
              id: doc.data().id,
              fullname: "",
              image: "",
              phone: "",
              email: ""
            });
          });
          that.prendUsersForRoom();
        });
      /*******************************************************/
    },
    prendUsers() {
      for (let i = 0; i < this.family.length; i++) {
        var familleId = this.family[i].id;
        this.users.forEach(element => {
          if (familleId == element.id) {
            this.family[i].fullname = element.fullname;
            this.family[i].image = element.image;
            this.family[i].phone = element.phone;
            this.family[i].email = element.email;
          }
        });
      }

      // var tab = this.family;

      // for (let i = 0; i < tab.length; i++) {
      //   if(tab[i].id == this.$store.state.ngayi.id){
      //     this.family.splice(i,1);
      //   }
      // }
      // this.family = tab;
    },
    prendUsersForRoom() {
      for (let i = 0; i < this.roommate.length; i++) {
        var roomId = this.roommate[i].id;
        this.users.forEach(element => {
          if (roomId == element.id) {
            this.roommate[i].fullname = element.fullname;
            this.roommate[i].image = element.image;
            this.roommate[i].phone = element.phone;
            this.roommate[i].email = element.email;
          }
        });
      }
      var uniqstate = this.roommate.filter(
        (v, i, a) => a.findIndex(t => t.id === v.id) === i
      );
      this.roommate = uniqstate;
    },
    quiter() {
      // alert(this.originVariable);
      db.collection("family")
        .doc(this.originVariable)
        .delete();
      this.dialogQuestion = false;
    },
    quiterRoom() {
      this.mesRoommate.forEach(element => {
        db.collection("roommate")
          .doc(element.id)
          .delete();
      });
      alert("Successfully!");
      this.dialogQuestion = false;
    },
    estcekenga(x) {
      // alert(x.originVariable);
      if (x.id == this.$store.state.ngayi.id) {
        this.toWho = "fami";
        this.originVariable = x.originVariable;
        this.dialogQuestion = true;
      }
    },
    leTemps() {
      setTimeout(() => {
        this.changedValue();
        return false;
      }, 100);
      return 0;
    },
    changedValue() {
      this.show = true;
      return false;
    }
  }
};
</script>
