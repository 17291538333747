import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // Setup
    ngayi: {},
    ye: {},
    email: "",
    psw: "",
    part: 1,
    news: {},
    videos: {},
    city: {},
    businesses: {},

    users: [],
    salange: [],
    chatNotifications: [],
    death: [],
    family: [],
    familyId: "",
    familyName: "",
    roommate: "",
    // erreur
    erreur: "",
    dialogErreur: false,
    provi:{
      email: "congolese@gmail.com",
      password: "comco2023"
    },

    // questions
    dialogAnswer: "no",
    dialogQuestion: false,
    dialogDeath: false,
    question: "",
    states: [
      "Alabama",
      "Alaska",
      "American Samoa",
      "Arizona",
      "Arkansas",
      "California",
      "Colorado",
      "Connecticut",
      "Delaware",
      "District of Columbia",
      "Federated States of Micronesia",
      "Florida",
      "Georgia",
      "Guam",
      "Hawaii",
      "Idaho",
      "Illinois",
      "Indiana",
      "Iowa",
      "Kansas",
      "Kentucky",
      "Louisiana",
      "Maine",
      "Marshall Islands",
      "Maryland",
      "Massachusetts",
      "Michigan",
      "Minnesota",
      "Mississippi",
      "Missouri",
      "Montana",
      "Nebraska",
      "Nevada",
      "New Hampshire",
      "New Jersey",
      "New Mexico",
      "New York",
      "North Carolina",
      "North Dakota",
      "Northern Mariana Islands",
      "Ohio",
      "Oklahoma",
      "Oregon",
      "Palau",
      "Pennsylvania",
      "Puerto Rico",
      "Rhode Island",
      "South Carolina",
      "South Dakota",
      "Tennessee",
      "Texas",
      "Utah",
      "Vermont",
      "Virgin Island",
      "Virginia",
      "Washington",
      "West Virginia",
      "Wisconsin",
      "Wyoming"
    ],

    folder: "",
    // message
    snackBar: false,
    snackBarTitle: "",
    misImage: false,

    jour: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],

    languages: [
      "Arabic",
      "English",
      "French",
      "Portuguese",
      "Spanish",
      "Swahili"
    ],
    idMaker: ""
  },
  mutations: {},
  actions: {},
  modules: {}
});
