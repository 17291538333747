<template>
  <!-- App.vue -->
  <v-app>
    <v-app-bar app class="elevation-0 white">
      <v-btn text color="white" onclick="window.history.back()">
        <font-awesome-icon
          :icon="['fas', 'arrow-left']"
          class="fa-x black--text mr-2"
        />
        retour
      </v-btn>
    </v-app-bar>
    <!-- Sizes your content based upon application components -->

    <v-main class="py-5" style="background-color:rgb(235, 236, 239)">
      <transition name="slide-fade">
        <div v-if="show">
          <section class="bg-col-image">
            <div
              style="height: 100%;width: 100%;background: rgba(11,18,81,0.66);padding: 80px 0px;"
            >
              <div class="container py-0">
                <div class="row">
                  <div
                    class="col-12 d-flex justify-content-center align-items-center flex-column"
                    style="text-align: center;"
                  >
                    <h1 class="white--text display-2 font-weight-bold pt-5">
                      {{ news.title }}
                    </h1>
                    <br />
                    <span class="title white--text">{{ news.date }}</span>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section
            id="portfolio"
            class="content-section"
            style="padding: 50px 0px;"
          >
            <div class="container py-5">
              <div class="row d-flex">
                <!-- Image -->
                <div class="col-12 d-flex justify-center" v-if="news.image">
                  <img
                    class="img-fluid d-none d-md-block"
                    :src="news.image"
                    style="height:400px"
                  />
                  <img class="img-fluid d-block d-md-none" :src="news.image" />
                </div>
                <!-- Paragraph -->
                <div class="col-12 text-left my-5">
                  <p
                    class="subtitle pl-5 black--text"
                    style="white-space: pre-line"
                  >
                    {{ news.paragraph }}
                  </p>
                </div>
              </div>
            </div>
          </section>

          <!-- container -->
          <div class="container">
            <div class="mb-2 px-5">
              <span class="title black--text">{{ "Par " + news.auteur }}</span>
            </div>
            <div v-if="news.myImage">
              <img
                class="img-fluid px-5"
                :src="news.myImage"
                style="height:200px"
              />
            </div>
            <div v-else>
              <img
                class="img-fluid px-5"
                :src="require('../../images/unknow.jpg')"
                style="height:200px"
              />
            </div>
          </div>
        </div>
      </transition>
    </v-main>
    <div class="d-none">{{ ouvrirTransition }}</div>
  </v-app>
</template>

<script>
import firebase from "firebase";
import db from "../../db.js";

export default {
  data() {
    return {
      email: "kerene@example.com",
      show: false,
      password: "plus1234",
      dialog: false,
      place: "",
      news: []
    };
  },
  created() {
    var user = firebase.auth().currentUser;
    if (
      this.$store.state.ngayi.email == "" ||
      this.$store.state.ngayi.email == undefined ||
      user.email == "" ||
      user.email == undefined
    )
      this.$router.push("/menu");

    this.news = this.$store.state.news;
    var auteur = this.$store.state.news.id;
    db.collection("users").onSnapshot(snapshot => {
      snapshot.forEach(doc => {
        if (doc.data().id == auteur) {
          console.log(doc.data().id == auteur);
          this.news.myImage = doc.data().image;
          this.news.auteur =
            doc.data().nom + " " + doc.data().postnom + " " + doc.data().prenom;
        }
      });
    });
  },
  methods: {
    leTemps() {
      setTimeout(() => {
        this.changedValue();
        return false;
      }, 100);
      return 0;
    },
    changedValue() {
      this.show = true;
      return false;
    }
  },
  computed: {
    ouvrirTransition() {
      this.leTemps();
      return false;
    }
  }
};
</script>
