<template>
  <!-- App.vue -->
  <!--  -->
  <v-app>
    <v-app-bar class="elevation-0" app style="background-color:rgb(35, 36, 39)">
      <v-btn text color="white" onclick="window.history.back()">
        <font-awesome-icon
          :icon="['fas', 'arrow-left']"
          class="fa-x white--text mr-2"
        />
        Retour
      </v-btn>
    </v-app-bar>
    <!-- Sizes your content based upon application components -->

    <v-main class="main p-0 bg">
      <transition name="slide-fade">
        <div v-if="show">
          <div class="container py-5 my-5">
            <!-- {{$store.state.ye}} -->
            <v-row justify="center" class="my-5 py-5">
              <v-col cols="12" class="text-center">
                <v-avatar size="250">
                  <v-img
                    :src="$store.state.ye.image"
                    class="img-fluids"
                  /> </v-avatar
                ><br />
                <h4 class="my-2 font-weight-bold titre">
                  {{
                    $store.state.ye.nom +
                      " " +
                      $store.state.ye.postnom +
                      " " +
                      $store.state.ye.prenom
                  }}
                </h4>
                <v-divider class="grey"></v-divider>
              </v-col>

              <v-col cols="12">
                <v-card
                  height="100%"
                  class="bg-transparent elevation-0 py-5 px-5"
                >
                  <v-simple-table dark class="bg-transparent">
                    <template v-slot:default>
                      <tbody>
                        <tr v-if="$store.state.ye.cause">
                          <td class="py-2">Cause du décès</td>
                          <td>
                            <h6
                              class="font-weight-bold p-2 title white primary--text"
                            >
                              {{ $store.state.ye.cause }}
                            </h6>
                          </td>
                        </tr>
                        <tr v-if="$store.state.ye.dateMort">
                          <td class="py-2">Date</td>
                          <td>
                            <h6
                              class="font-weight-bold p-2 title white red--text"
                            >
                              {{ $store.state.ye.dateMort }}
                            </h6>
                          </td>
                        </tr>
                        <tr>
                          <td class="py-2">ID</td>
                          <td>
                            <h6 class="font-weight-bold">
                              {{ $store.state.ye.id }}
                            </h6>
                          </td>
                        </tr>
                        <tr>
                          <td class="py-2">Sex</td>
                          <td>
                            <h6 class="font-weight-bold">
                              {{ $store.state.ye.sex }}
                            </h6>
                          </td>
                        </tr>
                        <tr>
                          <td class="py-2">Adresse</td>
                          <td>
                            <h6 class="font-weight-bold">
                              {{
                                $store.state.ye.adresse +
                                  ", " +
                                  $store.state.ye.city +
                                  ", IA " +
                                  $store.state.ye.zipcode
                              }}
                            </h6>
                          </td>
                        </tr>
                        <tr v-if="$store.state.ye.privacy == true">
                          <td class="py-2">Phone</td>
                          <td>
                            <h6 class="font-weight-bold">
                              {{ $store.state.ye.phone }}
                            </h6>
                          </td>
                        </tr>
                        <tr>
                          <td class="py-2">Né à</td>
                          <td>
                            <h6 class="font-weight-bold">
                              {{ $store.state.ye.lieu_de_naissance }}
                            </h6>
                          </td>
                        </tr>
                        <tr>
                          <td class="py-2">Aux USA depuis</td>
                          <td>
                            <h6 class="font-weight-bold">
                              {{
                                $store.state.ye.date_a_iowa +
                                  " (" +
                                  $store.state.ye.year +
                                  " ans)"
                              }}
                            </h6>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </v-col>

              <!-- School -->
              <v-col cols="12" class="px-5">
                <h5 class="my-0 font-weight-bold text-uppercase titre">
                  School
                </h5>

                <v-row>
                  <v-col cols="12" lg="10" class="px-5">
                    <v-card
                      height="100%"
                      class="bg-transparent elevation-0 pb-5 px-5"
                      v-if="schools.length > 0"
                    >
                      <v-simple-table dark class="bg-transparent">
                        <template v-slot:default>
                          <tbody>
                            <tr v-for="(x, k) in schools" :key="k">
                              <!-- <td class="py-2">{{k+1}}</td> -->
                              <td class="py-2">{{ x.name }}</td>
                              <td class="py-2">{{ x.date }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card>

                    <div class="my-0 py-0 text-center" v-else>
                      <h4 class="subtitre">-- No school --</h4>
                    </div>
                  </v-col>
                </v-row>
              </v-col>

              <!-- Job -->
              <v-col cols="12" class="px-5">
                <h5 class="my-0 font-weight-bold text-uppercase titre">
                  Job
                </h5>

                <v-row>
                  <v-col cols="12" lg="10" class="px-5">
                    <v-card
                      height="100%"
                      class="bg-transparent elevation-0 pb-5 px-5"
                      v-if="jobs.length > 0"
                    >
                      <v-simple-table dark class="bg-transparent">
                        <template v-slot:default>
                          <tbody>
                            <tr v-for="(x, k) in jobs" :key="k">
                              <!-- <td class="py-2">{{k+1}}</td> -->
                              <td class="py-2">{{ x.name }}</td>
                              <td class="py-2">{{ x.date }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card>

                    <div class="my-0 py-0 text-center" v-else>
                      <h4 class="subtitre">-- No Job --</h4>
                    </div>
                  </v-col>
                </v-row>
              </v-col>

              <!-- Business -->
              <v-col cols="12" class="px-5">
                <h5 class="my-0 font-weight-bold text-uppercase titre">
                  Business
                </h5>

                <v-row>
                  <v-col cols="12" lg="10" class="px-5">
                    <v-card
                      height="100%"
                      class="bg-transparent elevation-0 pb-5 px-5"
                      v-if="businesses.length > 0"
                    >
                      <v-simple-table dark class="bg-transparent">
                        <template v-slot:default>
                          <tbody>
                            <tr v-for="(x, k) in businesses" :key="k">
                              <!-- <td class="py-2">{{k+1}}</td> -->
                              <td class="py-2">{{ x.name }}</td>
                              <td class="py-2">{{ x.adresse }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-card>

                    <div class="my-0 py-0 text-center" v-else>
                      <h4 class="subtitre">-- No business --</h4>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <!-- Family -->
            <v-row
              justify="center"
              class="py-5"
              v-for="(y, kf) in $store.state.family"
              :key="kf"
            >
              <v-col cols="12" class="px-5">
                <v-divider class="grey"></v-divider>
                <h5
                  class="white--text my-2 subtitre font-weight-bold text-uppercase"
                >
                  {{ y.name }}
                </h5>

                <!-- People -->
                <v-simple-table dark style="background-color: rgb(35, 36, 39);">
                  <template v-slot:default>
                    <tbody>
                      <tr v-for="(x, k) in family" :key="k">
                        <td class="py-2" v-if="y.id == x.familyId">
                          <v-avatar>
                            <v-img :src="x.image" class="img-fluid" />
                          </v-avatar>
                        </td>
                        <td class="py-2" v-if="y.id == x.familyId">
                          <h6 class="my-2 font-weight-bold grey--text">
                            {{ x.fullname }}
                          </h6>
                        </td>
                        <td
                          class="py-2"
                          v-if="y.id == x.familyId && x.phone != ''"
                        >
                          <v-btn
                            color="red"
                            small
                            class="white--text"
                            :href="'tel' + x.phone"
                            >Call</v-btn
                          >
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
                <!-- end People -->
              </v-col>
            </v-row>

            <!-- Roomate -->
            <v-row justify="center" class="py-5">
              <!-- <span class="white--text">{{mesRoommate}}</span> -->
              <v-col cols="12" class="px-5">
                <h5
                  class="my-2 subtitre font-weight-bold text-uppercase"
                  v-if="roommate.length > 0"
                >
                  Roomate(s):
                </h5>

                <!-- People -->
                <v-row justify="centers">
                  <v-col
                    cols="6"
                    md="4"
                    lg="3"
                    class="text-center"
                    v-for="(x, k) in roommate"
                    :key="k"
                  >
                    <v-card
                      class="p-3 elevation-0 grey darken-3"
                      style="height:100%"
                    >
                      <v-card-text class="p-0">
                        <v-avatar size="100">
                          <v-img :src="x.image" class="img-fluid" /> </v-avatar
                        ><br />
                        <h6 class="my-2 font-weight-bold titre">
                          {{ x.fullname }}
                        </h6>
                        <!-- <span>{{ x.relation }}</span> -->
                      </v-card-text>
                      <v-card-actions class="p-0">
                        <div class="text-center w-100">
                          <v-btn
                            small
                            color="red"
                            class="elevation-0 m-1 white--text"
                            :href="'tel:' + x.phone"
                            v-if="x.phone"
                            >Call</v-btn
                          >
                        </div>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
                <!-- end People -->
              </v-col>
            </v-row>
          </div>
        </div>
      </transition>
    </v-main>
    <div class="d-none">{{ ouvrirTransition }}</div>
  </v-app>
</template>

<script>
// import ServiceApi from "../ServicesApi.js";
import db from "../../db.js";
import firebase from "firebase";

export default {
  data() {
    return {
      email: "kerene@example.com",
      show: false,
      password: "plus1234",
      erreur: "",
      dialogErreur: false,
      place: "",
      valeur: 50,
      family: [],
      roommate: [],
      mesRoommate: [],
      schools: [],
      jobs: [],
      users: [],
      businesses: [],
      automobiles: [],
      familyName: "",
      familyId: "",
      assurances: [],
      documents: [],
      mydocuments: [
        "Green Card",
        "ID Card",
        "Driver License",
        "Social Security",
        "Work Permit",
        "Passport",
        "Lease"
      ],
      insurancesPick: [],
      documentsPick: [],
      insurances: [
        "Life Insurance",
        "Car Insurance",
        "Health Insurance",
        "Home Insurance"
      ]
    };
  },
  created() {
    this.$store.state.dialogDeath = false;
    var that = this;
    this.$store.state.family = [];
    // this.$store.state.ye = this.$store.state.ngayi;
    var user = firebase.auth().currentUser;
    if (
      this.$store.state.ngayi.email == "" ||
      this.$store.state.ngayi.email == undefined ||
      user.email == "" ||
      user.email == undefined
    )
      this.$router.push("/menu");
    else {
      /********************************************************/
      db.collection("users").onSnapshot(snapshot => {
        that.users = [];
        snapshot.forEach(doc => {
          that.users.push({
            id: doc.data().id,
            image: doc.data().image,
            phone: doc.data().phone,
            fullname:
              doc.data().nom +
              " " +
              doc.data().postnom +
              " " +
              doc.data().prenom,
            email: doc.data().email
          });
        });
      });
      /*******************************************************/
      /********************************************************/
      that.$store.state.family = [];
      /********************************************************/
      db.collection("family")
        .where("id", "==", that.$store.state.ye.id)
        .onSnapshot(snapshot => {
          that.$store.state.family = [];
          snapshot.forEach(doc => {
            that.$store.state.family.push({
              id: doc.data().familyId,
              name: doc.data().familyName
            });
          });
          console.log(that.$store.state.family);
          this.getFamily();
        });
      /*******************************************************/

      /********************************************************/
      that.$store.state.roommate = [];
      /********************************************************/
      db.collection("roommate")
        .where("id", "==", that.$store.state.ye.id)
        .onSnapshot(snapshot => {
          that.roommate = [];
          that.roomId = "";
          snapshot.forEach(doc => {
            console.log(doc.id);
            that.roomId = doc.data().roomid;
            that.$store.state.roommate = doc.data().roomid;
            that.mesRoommate.push({
              id: doc.id
            });
          });
          this.getRoomate();
        });
      /*******************************************************/

      this.getSchool();
      this.getJob();
      this.getBusiness();
      this.getAuto();
    }
  },
  methods: {
    theFormat(number) {
      return number.toFixed(0);
    },
    /*******************************************************/
    /*******************************************************/
    getFamily() {
      var that = this;
      that.family = [];
      var uniqstate = this.$store.state.family.filter(
        (v, i, a) => a.findIndex(t => t.id === v.id) === i
      );
      this.$store.state.family = uniqstate;

      this.$store.state.family.forEach(element => {
        console.log(element.id);
        /********************************************************/
        db.collection("family")
          .where("familyId", "==", element.id)
          .onSnapshot(snapshot => {
            snapshot.forEach(doc => {
              console.log(doc.id);
              that.family.push({
                originVariable: doc.id,
                id: doc.data().id,
                familyId: doc.data().familyId,
                fullname: "",
                image: "",
                phone: "",
                email: ""
              });
            });
            var uniq = this.family.filter(
              (v, i, a) =>
                a.findIndex(t => t.id === v.id && t.familyId === v.familyId) ===
                i
            );
            this.family = uniq;
            console.log("family");
            console.log(this.family);
            console.log("family");
            that.prendUsers();
          });
        /*******************************************************/
      });
    },
    /*******************************************************/
    getRoomate() {
      var that = this;

      // alert(this.$store.state.roommate);
      /********************************************************/
      db.collection("roommate")
        .where("roomid", "==", that.roomId)
        .onSnapshot(snapshot => {
          that.roommate = [];
          snapshot.forEach(doc => {
            that.roommate.push({
              originVariable: doc.id,
              id: doc.data().id,
              fullname: "",
              image: "",
              phone: "",
              email: ""
            });
          });
          that.prendUsersForRoom();
        });
      /*******************************************************/
    },
    prendUsers() {
      for (let i = 0; i < this.family.length; i++) {
        var familleId = this.family[i].id;
        this.users.forEach(element => {
          if (familleId == element.id) {
            this.family[i].fullname = element.fullname;
            this.family[i].image = element.image;
            this.family[i].phone = element.phone;
            this.family[i].email = element.email;
          }
        });
      }

      // var tab = this.family;

      // for (let i = 0; i < tab.length; i++) {
      //   if(tab[i].id == this.$store.state.ngayi.id){
      //     this.family.splice(i,1);
      //   }
      // }
      // this.family = tab;
    },
    prendUsersForRoom() {
      for (let i = 0; i < this.roommate.length; i++) {
        var roomId = this.roommate[i].id;
        this.users.forEach(element => {
          if (roomId == element.id) {
            this.roommate[i].fullname = element.fullname;
            this.roommate[i].image = element.image;
            this.roommate[i].phone = element.phone;
            this.roommate[i].email = element.email;
          }
        });
      }
      var uniqstate = this.roommate.filter(
        (v, i, a) => a.findIndex(t => t.id === v.id) === i
      );
      this.roommate = uniqstate;
    },

    getSchool() {
      var that = this;
      this.part = "school";
      /********************************************************/
      db.collection("school").onSnapshot(snapshot => {
        that.schools = [];
        snapshot.forEach(doc => {
          if (doc.data().email == that.$store.state.ye.email) {
            that.schools.push({
              id: doc.id,
              date: doc.data().date,
              name: doc.data().name,
              email: doc.data().email
            });
          }
        });
        console.log(that.schools);
      });
    },
    getJob() {
      var that = this;
      this.part = "job";
      /********************************************************/
      db.collection("job").onSnapshot(snapshot => {
        that.jobs = [];
        snapshot.forEach(doc => {
          if (doc.data().email == that.$store.state.ye.email) {
            that.jobs.push({
              id: doc.id,
              name: doc.data().name,
              date: doc.data().date,
              email: doc.data().email
            });
          }
        });
        console.log(that.jobs);
      });
    },
    getBusiness() {
      var that = this;
      this.part = "business";
      /********************************************************/
      db.collection("business").onSnapshot(snapshot => {
        that.businesses = [];
        snapshot.forEach(doc => {
          if (doc.data().email == that.$store.state.ye.email) {
            that.businesses.push({
              id: doc.id,
              name: doc.data().name,
              adresse: doc.data().adresse,
              email: doc.data().email
            });
          }
        });
        console.log(that.businesses);
      });
    },
    getAuto() {
      var that = this;
      this.part = "automobile";
      /********************************************************/
      db.collection("automobile").onSnapshot(snapshot => {
        that.automobiles = [];
        snapshot.forEach(doc => {
          if (doc.data().email == that.$store.state.ye.email) {
            that.automobiles.push({
              id: doc.id,
              name: doc.data().name,
              model: doc.data().model,
              color: doc.data().color,
              year: doc.data().year,
              email: doc.data().email
            });
          }
        });
        console.log(that.automobiles);
      });
    },

    /********************************** */
    validate() {
      this.$store.state.mutuYeyo = this.User;
      var d = new Date();
      d.setTime(d.getTime() + 5 * (365 * 24 * 60 * 60 * 1000));
      var expires = "expires=" + d.toUTCString();
      document.cookie = "mutuYango" + "=" + this.yanoyo + ";" + expires + ";";
      document.cookie = "bomba" + "=" + this.password + ";" + expires + ";";
    },
    leTemps() {
      setTimeout(() => {
        this.changedValue();
        return false;
      }, 100);
      return 0;
    },
    changedValue() {
      this.show = true;
      return false;
    }
  },
  computed: {
    ouvrirTransition() {
      this.leTemps();
      return false;
    }
  }
};
</script>

<style scoped>
.card {
  border: none;
}
</style>
