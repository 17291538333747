<template>
  <!-- App.vue -->
  <!--  -->
  <v-app>
    <v-app-bar
      app
      style="background-color:rgb(255, 255, 255);"
      class="elevation-0"
    >
      <v-btn class="white--text" text onclick="window.history.back()">
        <font-awesome-icon
          :icon="['fas', 'chevron-left']"
          class="fa-x black--text mr-2"
        />
      </v-btn>
    </v-app-bar>
    <!-- Sizes your content based upon application components -->

    <v-main class="p-0 py-5" style="background-color: rgb(235, 236, 239);">
      <section class="bg-col-image">
        <div
          style="height: 100%;width: 100%;background: rgba(11,18,81,0.66);padding: 80px 0px;"
        >
          <div class="container py-0">
            <div class="row">
              <div
                class="col-12 d-flex justify-content-center align-items-center"
                style="text-align: center;"
              >
                <h1 class="white--text display-3 font-weight-bold pt-5">
                  waKongo
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div class="container my-5">
        <!-- Settings -->
        <v-row class="py-5" justify="center">
          <v-col cols="12" lg="10" class="px-5">
            <v-card height="100%" class="elevation-0 pb-5 px-5 bg-transparent">
              <h3 class="text-center mb-5 black--text">Réglages</h3>
              <v-divider></v-divider>
              <v-simple-table class="bg-transparent">
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td class="py-2 black--text">Deconnection</td>
                      <td class="py-2">
                        <v-btn
                          small
                          @click="logout()"
                          color="red"
                          class="white--text my-2 elevation-0"
                          >Deconnecter</v-btn
                        >
                      </td>
                    </tr>
                    <tr>
                      <td>Google Analytics</td>
                      <td>
                        <p>
                          Click <a href="#" @click.prevent="disableTracking">here</a>,
                          to disable the tracking through Google Analytics.
                        </p>
                      </td>
                    </tr>
                    <!-- <tr>
                      <td class="py-2 white--text">Light Mode:</td>
                      <td class="py-2 white--text">
                        <v-switch v-model="switch1"></v-switch>
                      </td>
                    </tr> -->
                    <tr>
                      <td class="py-2 black--text">Enregitrer:</td>
                      <td class="py-2 black--text">
                        {{ $store.state.ngayi.date }}
                      </td>
                    </tr>
                    <tr>
                      <td class="py-2 black--text">Nom de la plateforme:</td>
                      <td class="py-2 black--text"><b>waKongo</b></td>
                    </tr>
                    <tr>
                      <td class="py-2 black--text">Version:</td>
                      <td class="py-2 black--text"><b>1.0</b></td>
                    </tr>
                  </tbody>
                </template> </v-simple-table
              ><br /><br />

              <span class="py-2 title black--text"
                ><b>Created By Richero</b></span
              ><br />
              <v-btn small href="tel:3195193008" color="red" class="white--text"
                >Call</v-btn
              >
              <br /><br />
              <img :src="require('@/images/DSC02395.jpg')" height="300px" />
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-main>
  </v-app>
</template>

<script>
// import ServiceApi from "../ServicesApi.js";
import firebase from "firebase";

export default {
  data() {
    return {
      switch1: false,

      direction: "bottom",
      fab: false,
      fling: false,
      hover: false,
      tabs: null,
      top: false,
      right: false,
      bottom: true,
      left: true,
      transition: "slide-y-reverse-transition"
    };
  },
  watch: {
    top(val) {
      this.bottom = !val;
    },
    right(val) {
      this.left = !val;
    },
    bottom(val) {
      this.top = !val;
    },
    left(val) {
      this.right = !val;
    }
  },
  created() {
    // alert(user.email);
    var user = firebase.auth().currentUser;
    if (
      this.$store.state.ngayi.email == "" ||
      this.$store.state.ngayi.email == undefined ||
      user.email == "" ||
      user.email == undefined
    )
      alert(0);
    // this.$router.push("/menu");
  },
  methods: {
    getSetting() {
      var that = this;
      that.part = "setting";
    },
    disableTracking: function() {
      this.$ga.disable();
      alert('Tracking disabled');
    },
    logout() {
      var that = this;
      firebase
        .auth()
        .signOut()
        .then(function() {
          // Sign-out successful.
          document.cookie = "mutuYango=" + ";";
          document.cookie = "codeYango=" + ";";

          //Reinstallation
          that.$store.state.ngayi = {};
          that.$store.state.ye = {};
          that.$store.state.email = "";
          that.$store.state.psw = "";
          that.$store.state.news = {};
          that.$store.state.city = {};
          that.$store.state.users = [];
          that.$store.state.familyNotification = [];
          that.$store.state.family = [];
          that.$store.state.roommate = [];

          that.$router.push("/");
        })
        .catch(function(error) {
          // An error happened.
          alert(error.message);
        });
    }
  }
};
</script>

<style scoped>
tr td {
  cursor: pointer;
}

.card {
  border: none;
}

.card:hover,
.curseur {
  background-color: rgb(248, 207, 194);
  cursor: pointer;
}

.masthead {
  /* background: url('../../images/pexels-alexander-mils-2068975.jpg') no-repeat;
  background-size: cover; */
  background-color: #2962ff;
  height: 100px;
}
</style>
