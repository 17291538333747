<template>
  <!-- App.vue -->
  <v-app>
    <v-app-bar class="elevation-0" app>
      <v-btn text onclick="window.history.back()" v-if="lakisa == true">
        <font-awesome-icon
          :icon="['fas', 'arrow-left']"
          class="fa-x black--text mr-2"
        />
      </v-btn>
      <v-btn
        text
        v-else
        @click="
          lakisa = true;
          enfants = [];
          nombreEnfant = 0;
        "
      >
        <font-awesome-icon
          :icon="['fas', 'arrow-left']"
          class="fa-x black--text mr-2"
        />
      </v-btn>

      <!-- <v-spacer></v-spacer>
      <h4 class="black--text">Family</h4> -->
      <v-spacer></v-spacer>
      <!-- <v-btn icon href="#/menu">
        <font-awesome-icon :icon="['fas', 'bars']" class="fa-x black--text" />
      </v-btn> -->

      <v-btn text small @click="signUp()" v-if="montrer == 3">
        <font-awesome-icon
          :icon="['fas', 'bookmark']"
          class="fa-x black--text"
        />
        <span class="m-1">Enregistrer</span>
      </v-btn>

      <v-btn icon @click="dialog4 = true" v-else>
        <font-awesome-icon :icon="['fas', 'search']" class="fa-x black--text" />
      </v-btn>
    </v-app-bar>
    <!-- Sizes your content based upon application components -->

    <v-main class="p-0" style="background-color:rgb(235, 236, 239)">
      <transition name="slide-fade">
        <div v-if="show">
          <div
            class="container py-5"
            style="margin-top:70px;"
            v-if="lakisa == true"
          >
            <!-- {{people}} -->
            <h4 class="text-center mt-5 py-5 black--text">
              Qui voulez-vous ajouter?
            </h4>
            <v-row justify="center">
              <!-- Famille -->
              <v-col cols="12" lg="6" class="text-center">
                <v-row justify="center">
                  <!-- Famille -->
                  <v-col
                    cols="12"
                    sm="6"
                    lg="6"
                    xl="5"
                    class="d-flex justify-center"
                  >
                    <v-card
                      @click="whoCreer(0)"
                      height="100%"
                      width="200px"
                      class="card elevation-0 py-5 px-5 text-center grey lighten-2"
                    >
                      <div class="text-center">
                        <v-avatar size="100">
                          <img
                            :src="require('../../images/family.png')"
                            class="img-fluids curseur"
                          /> </v-avatar
                        ><br />
                        <span class="title my-1">Adulte (+18 ans)</span>
                      </div>
                    </v-card>
                  </v-col>

                  <!-- Roomate -->
                  <v-col
                    cols="12"
                    sm="6"
                    lg="6"
                    xl="5"
                    class="d-flex justify-center"
                  >
                    <v-card
                      @click="whoCreer(1)"
                      height="100%"
                      width="200px"
                      class="card elevation-0 py-5 px-5 text-center grey lighten-2"
                    >
                      <div class="text-center">
                        <v-avatar size="100">
                          <img
                            :src="require('../../images/roomate.png')"
                            class="img-fluids curseur"
                          /> </v-avatar
                        ><br />
                        <span class="title my-1">Enfant (-18 ans)</span>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>

          <div class="py-5 my-5" v-else>
            <!-- <h1 class="red--text display-3">asdplkjhv</h1> -->

            <div class="container py-5">
              <!-- {{people}} -->
              <div
                class="p-3 mt-5 headline text-center grey--text"
                v-if="showPerson"
              >
                <span class="titre">Cette personne n'existe pas</span><br />
                <font-awesome-icon
                  :icon="['fas', 'exclamation-circle']"
                  class="fa-2x grey--text"
                />
              </div>

              <div class="grey lighten-2 my-5">
                <v-chip class="p-0" v-for="(x, k) in naponi" :key="k" close>
                  <v-avatar>
                    <v-img :src="x.image" />
                  </v-avatar>
                  <span class="mx-3">{{ x.fullname }}</span>
                  <v-btn icon @click="longola(k)">
                    <font-awesome-icon
                      :icon="['fas', 'times']"
                      class="fa-x black--text"
                    />
                  </v-btn>
                </v-chip>
              </div>
              <div class="my-3" v-if="naponi.length > 0">
                <v-btn small color="orange white--text" @click="dialog = true"
                  >Ajouter ce(s) membre(s)</v-btn
                >
              </div>

              <v-row justify="center">
                <v-col
                  cols="8"
                  md="4"
                  lg="3"
                  class="text-center"
                  v-for="(x, k) in peopleSearch"
                  :key="k"
                >
                  <v-card class="p-3 elevation-0" style="height:100%">
                    <v-card-text class="p-0">
                      <v-avatar size="100">
                        <v-img :src="x.image" class="img-fluid" /> </v-avatar
                      ><br />
                      <h6 class="my-2 font-weight-bold">{{ x.fullname }}</h6>
                      <br />
                      <v-btn color="primary" @click="pona(x)">Add</v-btn>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </div>

            <div class="container py-5" v-if="montrer == 2">
              <!-- {{people}} -->

              <div
                class="p-3 mt-5 headline text-center grey--text"
                v-if="showPerson"
              >
                <span class="titre">Cette personne n'existe pas</span><br />
                <font-awesome-icon
                  :icon="['fas', 'exclamation-circle']"
                  class="fa-2x grey--text"
                />
              </div>

              <div class="grey lighten-2 my-5">
                <v-chip class="p-0" v-for="(x, k) in naponi" :key="k" close>
                  <v-avatar>
                    <v-img :src="x.image" />
                  </v-avatar>
                  <span class="mx-3">{{ x.fullname }}</span>
                  <v-btn icon @click="longola(k)">
                    <font-awesome-icon
                      :icon="['fas', 'times']"
                      class="fa-x black--text"
                    />
                  </v-btn>
                </v-chip>
              </div>
              <div class="my-3" v-if="naponi.length > 0">
                <v-btn small color="orange black--text" @click="dialog2 = true"
                  >Ajouter ce(s) membre(s)</v-btn
                >
              </div>

              <v-row justify="center">
                <v-col
                  cols="8"
                  md="4"
                  lg="3"
                  class="text-center"
                  v-for="(x, k) in peopleSearch"
                  :key="k"
                >
                  <v-card class="p-3 elevation-0" style="height:100%">
                    <v-card-text class="p-0">
                      <v-avatar size="100">
                        <v-img :src="x.image" class="img-fluid" /> </v-avatar
                      ><br />
                      <h6 class="my-2 font-weight-bold">{{ x.fullname }}</h6>
                      <br />
                      <v-btn color="primary" @click="pona(x)">Add</v-btn>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </div>

            <!-- Add child -->
            <div class="container py-5" v-if="montrer == 3">
              <v-row class="d-flex justify-center">
                <v-col
                  cols="8"
                  v-for="(x, k) in enfants"
                  :key="k"
                  class="white p-5 my-5"
                >
                  <span class="titles h3 font-weight-bold text-primary"
                    >Child {{ k + 1 }}</span
                  ><br />
                  <form>
                    <input
                      class="form-control my-5 subtitle-2 font-weight-normal"
                      type="text"
                      name="Prenom"
                      placeholder="Prénom"
                      v-model="x.prenom"
                      style="border-radius: 10px;color: black;"
                    />

                    <input
                      class="form-control my-5 subtitle-2 font-weight-normal"
                      type="text"
                      name="Postnom"
                      placeholder="Postnom"
                      v-model="x.postnom"
                      style="border-radius: 10px;color: black;"
                    />

                    <input
                      class="form-control my-5 subtitle-2 font-weight-normal"
                      type="text"
                      name="Nom"
                      placeholder="Nom"
                      v-model="x.nom"
                      style="border-radius: 10px;color: black;"
                    />

                    <input
                      class="form-control my-5 subtitle-2 font-weight-normal"
                      type="text"
                      name="Naissance"
                      v-model="x.lieu_de_naissance"
                      placeholder="Lieu de naissance"
                      style="border-radius: 10px;color: black;"
                    />

                    <input
                      class="form-control my-5 subtitle-2 font-weight-normal"
                      type="date"
                      v-model="x.date_de_naissance"
                      style="border-radius: 10px;color: black;"
                    />

                    <v-btn-toggle color="orange" mandatory>
                      <v-btn small class="elevation-0" @click="tiaSex(0, k)">
                        Masculin
                      </v-btn>
                      <v-btn small class="elevation-0" @click="tiaSex(1, k)"
                        >Feminin
                      </v-btn>
                    </v-btn-toggle>
                    <br />
                    <br />

                    <v-btn
                      small
                      color="primary"
                      class="elevation-0"
                      @click="sameAddres(k, 0)"
                      >Mon adresse</v-btn
                    >

                    <textarea
                      class="form-control my-5 subtitle-2 font-weight-normal"
                      placeholder="Adresse"
                      v-model="x.adresse"
                      style="color: black;border-radius: 10px;"
                    ></textarea>

                    <!-- <span>City:</span> -->
                    <select
                      class="form-control my-5 subtitle-2 font-weight-normal p-0 pl-3"
                      v-model="x.city"
                      style="border-radius: 10px;color: black;"
                    >
                      <optgroup label="Select a city">
                        <option value="City">City</option>
                        <option value="Coralville">Coralville</option>
                        <option value="Iowa City">Iowa City</option>
                        <option value="North Liberty">North Liberty</option>
                        <option value="Cedar Rapids">Cedar Rapids</option>
                        <option value="Des moines">Des moines</option>
                      </optgroup>
                    </select>

                    <input
                      class="form-control my-5 subtitle-2 font-weight-normal"
                      type="text"
                      name="Zipcode"
                      v-model="x.zipcode"
                      placeholder="Zipcode"
                      style="border-radius: 10px;color: black;"
                    /><br />

                    <v-btn
                      small
                      color="primary"
                      class="elevation-0"
                      @click="sameAddres(k, 1)"
                      >Ma date d'immigration</v-btn
                    >
                    <input
                      class="form-control my-5 subtitle-2 font-weight-normal"
                      type="date"
                      v-model="x.date_a_iowa"
                      style="border-radius: 10px;color: black;"
                    />

                    <!-- <div class="text-center">
                    <v-btn
                      large
                      rounded
                      color="primary darken-1"
                      class="elevation-0"
                      >Enregistrer</v-btn
                    >
                  </div> -->
                  </form>
                </v-col>
              </v-row>
            </div>
          </div>
        </div>
      </transition>
    </v-main>

    <!-- Dialog -->
    <v-dialog v-model="dialog" max-width="400">
      <v-card class="elevation-0">
        <v-row justify="center">
          <v-col
            cols="12"
            v-for="(x, k) in naponi"
            :key="k"
            class="text-center py-5"
          >
            <v-avatar size="60"> <v-img :src="x.image" /> </v-avatar><br />
            <span class="mx-3 subtitle-1">{{ x.fullname }}</span>
            <br /><br />
            <v-btn small color="red" @click="longola(k)">
              <font-awesome-icon
                :icon="['fas', 'trash']"
                class="fa-x white--text"
              />
            </v-btn>
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <div class="text-center pb-3">
          <v-btn
            class="elevation-0"
            small
            color="orange white--text"
            @click="
              dialogWait = true;
              addMePerson();
              addThisPerson();
              dialog = false;
            "
            >Créer la famille maintenant</v-btn
          >
        </div>
      </v-card>
    </v-dialog>

    <!-- Dialog 2 -->
    <v-dialog v-model="dialog2" max-width="400">
      <v-card class="elevation-0">
        <v-row justify="center">
          <v-col
            cols="12"
            v-for="(x, k) in naponi"
            :key="k"
            class="text-center py-5"
          >
            <v-avatar size="60"> <v-img :src="x.image" /> </v-avatar><br />
            <span class="mx-3 subtitle-1">{{ x.fullname }}</span>
            <br /><br />
            <v-btn small color="red" @click="longola(k)">
              <font-awesome-icon
                :icon="['fas', 'trash']"
                class="fa-x black--text"
              />
            </v-btn>
            <v-divider></v-divider>
          </v-col>
        </v-row>
        <div class="text-center pb-3">
          <v-btn
            class="elevation-0"
            small
            color="orange black--text"
            @click="
              dialogWait = true;
              addThisPerson();
              dialog = false;
            "
            >Create family now</v-btn
          >
        </div>
      </v-card>
    </v-dialog>

    <!-- Dialog 3 -->
    <v-dialog persistent v-model="dialog3" max-width="400">
      <v-card class="elevation-0 py-3 px-5">
        <span class="my-3">Combien d'enfant voulez-vous ajouter?</span
        ><br /><br />

        <v-text-field dense v-model="nombreEnfant" />

        <div class="text-center pb-3">
          <v-btn
            class="elevation-0 m-1"
            small
            color="red white--text"
            @click="
              montrer = 0;
              lakisa = true;
              dialog3 = false;
            "
            >Annuler</v-btn
          >
          <v-btn
            class="elevation-0 m-1"
            small
            color="primary white--text"
            @click="
              creerEnfant();
              dialog3 = false;
            "
            >Valider</v-btn
          >
        </div>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="dialogWait" max-width="200">
      <v-card class="text-center py-3">
        <v-progress-circular
          :size="30"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <span class="ml-2">Please Wait</span>
      </v-card>
    </v-dialog>

    <!-- Dialog 4 -->
    <v-dialog v-model="dialog4" max-width="400">
      <v-card class="elevation-0 px-5 py-5" v-if="par == 'rien'">
        <div class="w-100 text-center">
          <span class="headline">Recherche</span><br /><br />
          <v-btn
            large
            blocks
            class="elevation-0 white--text m-1"
            color="orange"
            @click="par = 'id'"
            >Par ID</v-btn
          >
          <v-btn
            large
            blocks
            class="elevation-0 white--text m-1"
            color="orange"
            @click="par = 'nom'"
            >Par Noms</v-btn
          >
        </div>
      </v-card>

      <!-- ID  -->
      <v-card class="elevation-0 px-5 py-5" v-else-if="par == 'id'">
        <v-btn icon @click="par = 'rien'">
          <font-awesome-icon
            :icon="['fas', 'arrow-left']"
            class="fa-x black--text"
        /></v-btn>
        <div class="w-100 text-center">
          <span class="headline">Recherche par ID</span>
        </div>
        <v-text-field v-model="koluka[0].id" label="ID Number" />
        <v-btn
          class="elevation-0 white--text"
          color="orange"
          @click="chercher(0)"
          >Chercher</v-btn
        >
      </v-card>

      <!-- Noms  -->
      <v-card class="elevation-0 px-5 py-5" v-else-if="par == 'nom'">
        <v-btn icon @click="par = 'rien'">
          <font-awesome-icon
            :icon="['fas', 'arrow-left']"
            class="fa-x black--text"
        /></v-btn>
        <div class="w-100 text-center">
          <span class="headline">Recherche par noms</span>
        </div>

        <v-text-field v-model="koluka[0].nom" label="Nom" />
        <v-text-field v-model="koluka[0].postnom" label="Postnom" />
        <v-text-field v-model="koluka[0].prenom" label="Prenom" />

        <v-btn
          class="elevation-0 white--text"
          color="orange"
          @click="chercher(1)"
          >Chercher</v-btn
        >
      </v-card>
    </v-dialog>
    <div class="d-none">{{ ouvrirTransition }}</div>
  </v-app>
</template>

<script>
// import ServiceApi from "../ServicesApi.js";
import firebase from "firebase";
import db from "../../db.js";

export default {
  data() {
    return {
      email: "kerene@example.com",
      show: false,
      password: "plus1234",
      erreur: "",
      showPerson: false,
      par: "rien",
      koluka: [
        {
          id: "",
          nom: "",
          postnom: "",
          prenom: ""
        }
      ],
      place: "",
      valeur: 50,
      search: "",
      dialog: false,
      dialog2: false,
      dialog3: false,
      dialog4: true,
      lakisa: true,
      familyId: "",
      familyName: "",
      dialogWait: false,
      nombreEnfant: 1,
      peopleSearch: [],

      enfants: [
        {
          nom: "",
          postnom: "",
          prenom: "",
          sex: "",
          lieu_de_naissance: "",
          date_de_naissance: "",
          adresse: "",
          city: "",
          zipcode: "",
          date_a_iowa: ""
        }
      ],
      people: [],
      naponi: [],
      family: [],
      montrer: 3,
      male:
        "https://firebasestorage.googleapis.com/v0/b/databaste-51ebd.appspot.com/o/Pictures%2F3.jpg?alt=media&token=29d212de-77cc-4385-9600-d58c22342678",
      female:
        "https://firebasestorage.googleapis.com/v0/b/databaste-51ebd.appspot.com/o/Pictures%2F4.jpg?alt=media&token=bf53b876-a8c8-497b-88cd-c67465a5e5e0"
    };
  },
  created() {
    var user = firebase.auth().currentUser;
    if (
      this.$store.state.ngayi.email == "" ||
      this.$store.state.ngayi.email == undefined ||
      user.email == "" ||
      user.email == undefined
    )
      this.$router.push("/menu");

    if (this.$store.state.familyId == "") {
      alert("Vous devez créer la famille d'abord");
      this.$router.push("/link_with_family");
    } else {
      this.montrer = 0;
      this.dialog4 = false;
    }
  },
  mounted() {
    this.$store.state.family = [];
    /*******************************************************/

    /***************************************/
    db.collection("users").onSnapshot(snapshot => {
      this.people = [];
      snapshot.forEach(doc => {
        // console.log(doc.id);
        this.people.push({
          id: doc.data().id,
          nom: doc.data().nom,
          postnom: doc.data().postnom,
          prenom: doc.data().prenom,
          phone: doc.data().phone,
          date_de_naissance: doc.data().date_de_naissance,
          date_a_iowa: doc.data().date_a_iowa,
          lieu_de_naissance: doc.data().lieu_de_naissance,
          sex: doc.data().sex,
          adresse: doc.data().adresse,
          image: doc.data().image,
          city: doc.data().city,
          zipcode: doc.data().zipcode,
          state: doc.data().state,
          email: doc.data().email,
          privacy: doc.data().privacy,
          type: doc.data().type,
          year: "",
          date: new Date(doc.data().cretedAd.toDate()).toDateString(),
          heure:
            " at " + new Date(doc.data().cretedAd.toDate()).toLocaleTimeString()
        });
      });
    });
  },
  methods: {
    whoCreer(value) {
      if (value == 0) {
        this.montrer = 0;
        this.dialog4 = true;
      } else {
        this.montrer = 3;
        this.dialog3 = true;
        this.dialog4 = false;
      }
      this.lakisa = false;
    },
    creerEnfant() {
      this.enfants = [];
      for (let i = 0; i < this.nombreEnfant; i++) {
        this.enfants.push({
          id: this.madeId(),
          nom: "",
          postnom: "",
          prenom: "",
          sex: "",
          image: this.male,
          lieu_de_naissance: "",
          date_de_naissance: "",
          adresse: "",
          city: "",
          zipcode: "",
          date_a_iowa: ""
        });
      }
    },
    theFormat(number) {
      return number.toFixed(0);
    },
    openPeople(people) {
      console.log(people);
      //Duree
      console.log(people.date_a_iowa);
      var duree = people.date_a_iowa.slice(0, 4);
      console.log(duree);
      var d = new Date();
      var annee = d.getFullYear();
      console.log("age == " + (Number(annee) - Number(duree)));
      this.$store.state.ye = people;
      this.$store.state.ye.year = Number(annee) - Number(duree);

      this.$router.push("/person_profile");
    },

    /********************************** */
    addThisPerson() {
      var that = this;
      that.naponi.forEach(element => {
        try {
          db.collection("family").add({
            familyId: that.$store.state.familyId,
            familyName: "Famille " + that.$store.state.familyName,
            id: element.id,
            cretedAd: firebase.firestore.FieldValue.serverTimestamp()
          });
          that.dialogWait = false;
          alert("Ajout de " + element.fullname + " avec succès");
        } catch (error) {
          alert(error.message);
        }
      });
      that.$router.push("/menu");
    },
    /********************************** */
    addMePerson() {
      var that = this;
      try {
        db.collection("family").add({
          familyId: that.$store.state.familyId,
          familyName: "Famille " + that.$store.state.familyName,
          id: that.$store.state.ngayi.id,
          cretedAd: firebase.firestore.FieldValue.serverTimestamp()
        });
      } catch (error) {
        alert(error.message);
      }
    },
    longola(k) {
      this.naponi.splice(k, 1);
      if (this.naponi.length == 0) this.dialog = false;
    },
    pona(x) {
      this.naponi.push({
        id: x.id,
        fullname: x.fullname,
        image: x.image
      });
    },

    createId() {
      var result = "FAM";
      var keep = false;

      do {
        var characters =
          "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        var charactersLength = characters.length;
        for (var i = 0; i < 40; i++) {
          result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
          );
        }
        this.family.forEach(element => {
          if (element.originVariable == result) keep = true;
        });
      } while (keep);
      this.familyId = result;
      // alert(this.familyId);
      this.dialog4 = true;
      this.montrer = 1;
    },
    madeId() {
      var result = "CON";
      var keep = false;

      do {
        var characters = "0123456789";
        var charactersLength = characters.length;
        for (var i = 0; i < 7; i++) {
          result += characters.charAt(
            Math.floor(Math.random() * charactersLength)
          );
        }
        this.people.forEach(element => {
          if (element.id == result) keep = true;
        });
      } while (keep);
      console.log("result " + result);
      return result;
    },
    signUp() {
      console.log(this.enfants);
      try {
        this.enfants.forEach(element => {
          var image = "";
          if (element[3] == "M") {
            image =
              "https://firebasestorage.googleapis.com/v0/b/databaste-51ebd.appspot.com/o/Pictures%2F3.jpg?alt=media&token=29d212de-77cc-4385-9600-d58c22342678";
          } else {
            image =
              "https://firebasestorage.googleapis.com/v0/b/databaste-51ebd.appspot.com/o/Pictures%2F4.jpg?alt=media&token=bf53b876-a8c8-497b-88cd-c67465a5e5e0";
          }

          db.collection("users").add({
            id: element.id,
            nom: element.nom,
            postnom: element.postnom,
            prenom: element.prenom,
            fullname:
              element.nom + " " + element.postnom + " " + element.prenom,
            phone: this.$store.state.ngayi.phone,
            date_de_naissance: element.date_de_naissance,
            lieu_de_naissance: element.lieu_de_naissance,
            sex: element.sex,
            privacy: false,
            date_a_iowa: element.date_a_iowa,
            image: image,
            adresse: element.adresse,
            city: element.city,
            zipcode: element.zipcode,
            state: "Iowa",
            type: "",
            green: "",
            email: "",
            cretedAd: firebase.firestore.FieldValue.serverTimestamp()
          });
        });
        this.naponi = this.enfants;
        this.dialogWait = true;
        this.addThisPerson();
      } catch (error) {
        alert(error.message);
      }
    },
    tiaSex(sex, k) {
      if (sex == 0) {
        this.enfants[k].sex = "M";
        this.enfants[k].image = this.male;
      } else {
        this.enfants[k].sex = "F";
        this.enfants[k].image = this.female;
      }
      console.log(this.enfants[k].image);
    },
    sameAddres(k, d) {
      console.log(this.$store.state.ngayi);

      if (d == 0) {
        this.enfants[k].adresse = this.$store.state.ngayi.adresse;
        this.enfants[k].city = this.$store.state.ngayi.city;
        this.enfants[k].zipcode = this.$store.state.ngayi.zipcode;
      } else {
        var date = new Date(this.$store.state.ngayi.date_a_iowa);
        this.enfants[k].date_a_iowa = date.toISOString().slice(0, 10);
        console.log(date);
        console.log(this.$store.state.ngayi.date_a_iowa);
        console.log(date.toISOString().slice(0, 10));
      }
    },
    chercher(value) {
      console.log(this.people);
      this.peopleSearch = [];
      var keepId = false;
      var keepNom = false;
      // par ID
      if (value == 0) {
        this.people.forEach(element => {
          console.log(
            element.id.toLowerCase() + "--" + this.koluka[0].id.toLowerCase()
          );
          if (element.id.toLowerCase() == this.koluka[0].id.toLowerCase()) {
            keepId = true;
            this.showPerson = false;
            this.peopleSearch.push({
              id: element.id,
              image: element.image,
              fullname:
                element.nom + " " + element.postnom + " " + element.prenom
            });
          }
        });
        console.log(this.peopleSearch);
        if (keepId == false) this.showPerson = true;
      }
      // par Noms
      else {
        this.people.forEach(element => {
          console.log(element.nom);
          if (
            element.nom.toLowerCase() == this.koluka[0].nom.toLowerCase() &&
            element.postnom.toLowerCase() ==
              this.koluka[0].postnom.toLowerCase() &&
            element.prenom.toLowerCase() == this.koluka[0].prenom.toLowerCase()
          ) {
            keepNom = true;
            this.showPerson = false;
            this.peopleSearch.push({
              id: element.id,
              image: element.image,
              fullname:
                element.nom + " " + element.postnom + " " + element.prenom
            });
          }
        });
        console.log(this.peopleSearch);
        if (keepNom == false) this.showPerson = true;
      }
      this.dialog4 = false;
      this.koluka = [
        {
          id: "",
          nom: "",
          postnom: "",
          prenom: ""
        }
      ];
      this.par = "rien";
      console.log("test");
    },
    leTemps() {
      setTimeout(() => {
        this.changedValue();
        return false;
      }, 100);
      return 0;
    },
    changedValue() {
      this.show = true;
      return false;
    }
  },
  computed: {
    ouvrirTransition() {
      this.leTemps();
      return false;
    }
  }
};
</script>

<style scoped>
.card:hover {
  border: 2px solid rgb(4, 61, 134);
  cursor: pointer;
}
</style>
