<template>
    <!-- App.vue -->
    <v-app>
        <v-app-bar class="elevation-0" app style="background-color:rgb(35, 36, 39)">
            <v-btn text color="white" onclick="window.history.back()">
                <font-awesome-icon :icon="['fas', 'arrow-left']" class="fa-x white--text mr-2" />
                Retour
            </v-btn>
        </v-app-bar>
        <!-- Sizes your content based upon application components -->

        <v-main class="main p-0">
            <transition name="slide-fade">
                <div v-if="show">
                    <div class="container py-5 my-5">
                        <v-row class="mt-5 pt-5">
                            <v-col cols="12">
                                <h1>Privacy Policy</h1>
                            </v-col>

                            <v-col cols="12" class="text-left" style="padding: 0px 50px;">
                                <p>
                                    Protecting your private information is our priority. This Statement of Privacy
                                    applies to waKongo, and waKongo and governs data collection and usage. For the
                                    purposes of this Privacy
                                    Policy, unless otherwise noted, all references to waKongo include
                                    otilogiciel@gmail.com.
                                </p>
                                <p>
                                    The waKongo application is a Community Software application. By using the waKongo
                                    application,
                                    you consent to the data practices described in this statement.
                                </p>

                                <p>
                                    Collection of your Personal Information In order to better provide you with products
                                    and services offered, waKongo may
                                    collect personally identifiable information, and may also collect anonymous
                                    demographic information, which is not unique to
                                    you, such as your:
                                </p>

                                <div>
                                    <h5 class="font-weight-bold">
                                        We do not collect any personal information about you unless you voluntarily
                                        provide it to us.
                                    </h5>
                                    <p class="mt-3">
                                        However, you may be required to provide certain personal information to us when
                                        you select to use certain products or services. <br /><br />These may include:
                                    </p>
                                    <ul>
                                        <li>Registering for an account;</li>
                                        <li>Rentering a sweepstakes or contest sponsored by us or one of our
                                            partners;</li>
                                        <li>Signing up for special offers from selected third parties;</li>
                                        <li>Sending us an email message;</li>
                                        <li>Submitting your credit card or other payment information when ordering
                                            and purchasing products and services. </li>
                                    </ul>
                                </div>

                                <p>
                                    To with, we will use your information for, but not limited to, communicating with
                                    you in relation to
                                    services and/or products you have requested from us. We also may gather additional
                                    personal or
                                    non-personal information in the future.
                                </p>

                                <p>

                                    Use of your Personal Information waKongo collects and uses your personal information
                                    to operate
                                    and deliver the services you have requested.</p>

                                <p>
                                    waKongo may also use your personally identifiable information to inform you of other
                                    products or services available from waKongo and its affiliates.</p>

                                <h5 class="font-weight-bold">We do not share your information with Third Parties</h5>
                                <p>
                                    waKongo does not sell, rent or lease its customer lists to third parties.
                                </p>
                                <ul>
                                    <li>First and Last Name</li>
                                    <li>E-mail Address</li>
                                    <li>Phone Number</li>
                                    <li>Work and school status</li>
                                    <li>Age</li>
                                    <li>Gender</li>
                                    <li>Etc.</li>
                                </ul>

                                <p>
                                    waKongo may share data with trusted partners to help perform statistical analysis,
                                    send you email or postal mail, provide customer support, or arrange for deliveries.
                                    All such third
                                    parties are prohibited from using your personal information except to provide these
                                    services to
                                    waKongo, and they are required to maintain the confidentiality of your information.
                                </p>
                                <p>
                                    waKongo may disclose your personal information, without notice, if required to do so
                                    by law or in the good faith belief that such action is necessary to:
                                    <ul>
                                        <li>Conform to the edicts of the law or comply
                                            with legal process served on waKongo or the site;</li>
                                        <li>Protect and defend the rights
                                            or property of waKongo;</li>
                                        <li>Act under exigent circumstances to protect the personal safety
                                            of users of waKongo, or the public.</li>
                                    </ul>
                                </p>

                                <h5 class="font-weight-bold">
                                    Security of your Personal Information waKongo</h5>
                                <p>secures your personal information from unauthorized access, use, or disclosure.
                                    waKongo uses the following methods for this purpose:</p>


                                <p>We strive to take appropriate security measures to protect against unauthorized
                                    access to or alteration of your personal information. Unfortunately, no data
                                    transmission over
                                    the Internet or any wireless network can be guaranteed to be 100% secure. As a
                                    result, while we strive
                                    to protect your personal information, you acknowledge that: </p>

                                <!-- <ul class="red--textss">

                                    <li>There are security and privacy limitations
                                        inherent to the Internet which are beyond our control;</li>
                                    <li>Security, integrity, and privacy of any and all information and data exchanged
                                        between you and us through this Site cannot be guaranteed.</li>
                                </ul> -->

                                <h5 class="font-weight-bold">Right to Deletion</h5>
                                <p>
                                    Subject to certain exceptions set out below, on receipt of a verifiable request from
                                    you, we will:</p>

                                <p>Please note that we may not be able to comply with requests to delete your personal
                                    information if it is necessary to:</p>
                                <ul>
                                    <li>All user information is private and confidential</li>
                                    <li>User data will never be passed on to a third party</li>
                                    <li>Delete your personal information from our records;</li>
                                    <li>Direct any service providers to delete your personal information from their
                                        records.</li>
                                    <li>Complete the transaction for which the personal information was collected,
                                        fulfill
                                        the terms of a written warranty or product recall conducted in accordance with
                                        federal
                                        law, provide a good or service requested by you, or reasonably anticipated
                                        within the
                                        context of our ongoing business relationship with you, or otherwise perform a
                                        contract between you and us;</li>
                                    <li>
                                        Detect security incidents, protect against malicious, deceptive, fraudulent, or
                                        illegal
                                        activity; or prosecute those responsible for that activity;
                                    </li>
                                    <li>Debug to identify and repair errors that impair existing intended functionality
                                    </li>
                                    <li>Exercise free speech, ensure the right of another consumer to exercise his or
                                        her
                                        right of free speech, or exercise another right provided for by law;</li>
                                    <li>Comply with the California Electronic Communications Privacy Act;</li>
                                </ul>

                                <h5 class="font-weight-bold">Children Under Eighteen</h5>
                                <p>
                                    waKongo does not knowingly collect personally identifiable information from children
                                    under the age of eighteen. If you are under the age of eighteen, you must ask your
                                    parent or
                                    guardian for permission to use this application.
                                </p>

                                <h5 class="font-weight-bold">E-mail Communications</h5>
                                <p>
                                    From time to time, waKongo may contact you via email for the purpose of providing
                                    announcements, promotional offers, alerts, confirmations, surveys, and/or other
                                    general communication.
                                </p>

                                <h5 class="font-weight-bold">Changes to this Statement</h5>
                                <p>
                                    waKongo reserves the right to change this Privacy Policy from time to time. We will
                                    notify you about significant changes in the way we treat personal information by
                                    sending a
                                    notice to the primary email address or a text to your phone number specified in your
                                    account,
                                    by placing a prominent notice on our application, and/or by updating any privacy
                                    information.
                                </p>

                                <p>
                                    Your continued use of the application and/or Services
                                    available after such modifications will constitute your:
                                </p>

                                <ul>
                                    <li>Acknowledgment of the modified Privacy
                                        Policy;</li>
                                    <li>Agreement to abide and be bound by that Policy.</li>
                                </ul>

                                <h5 class="font-weight-bold">Contact Information</h5>
                                <p>
                                    waKongo welcomes your questions or comments regarding this Statement of Privacy. If
                                    you
                                    believe that waKongo has not adhered to this Statement, please contact waKongo at:
                                </p>

                                <p>
                                    waKongo<br />
                                    1560 Cook Cir<br />
                                    North Liberty, Iowa 52317</p>

                                <p>
                                    Email Address:
                                    otilogiciel@gmail.com
                                    <br />
                                    Telephone number:
                                    3195193008
                                </p>
                                <p>
                                    Effective as of January 14, 2023
                                    <ul>
                                    <li>Engage in public or peer-reviewed scientific, historical, or statistical research
                                    in the public interest that adheres to all other applicable ethics and privacy laws, when
                                    our deletion of the information is likely to render impossible or seriously impair the
                                    achievement of such research, provided we have obtained your informed consent;</li>
                                    <li>
                                    • Enable solely internal uses that are reasonably aligned with your expectations
                                    based on your relationship with us;</li>
                                    <li>Comply with an existing legal obligation;</li>
                                    <li>Otherwise use your personal information, internally, in a lawful manner that is
                                    compatible with the context in which you provided the information.</li>
                                    </ul>
                                </p>
                            </v-col>

                        </v-row>
                    </div>
                </div>
            </transition>
        </v-main>

        <div class="d-none">{{ ouvrirTransition }}</div>
    </v-app>
</template>

<script>
    // import ServiceApi from "../ServicesApi.js";

    export default {
        data() {
            return {
                show: false
            };
        },
        methods: {
            leTemps() {
                setTimeout(() => {
                    this.changedValue();
                    return false;
                }, 100);
                return 0;
            },
            changedValue() {
                this.show = true;
                return false;
            }
        },
        computed: {
            ouvrirTransition() {
                this.leTemps();
                return false;
            }
        }
    };
</script>

<style scoped>
    .card:hover {
        border: 2px solid rgb(4, 61, 134);
        cursor: pointer;
    }
</style>