<template>
  <!-- App.vue -->
  <v-app>
    <v-app-bar class="elevation-0 white" app>
      <v-btn text color="white" onclick="window.history.back()">
        <font-awesome-icon
          :icon="['fas', 'arrow-left']"
          class="fa-x black--text mr-2"
        />
        Retour
      </v-btn>
      <!-- <v-spacer></v-spacer>
      <h4 class="white--text">Creation</h4> -->
      <v-spacer></v-spacer>
    </v-app-bar>
    <!-- Sizes your content based upon application components -->

    <v-main class="main p-0" style="background-color:rgb(235, 236, 239)">
      <transition name="slide-fade">
        <div v-if="show">
          <section class="bg-col-image">
            <div
              style="height: 100%;width: 100%;background: rgba(11,18,81,0.66);padding: 80px 0px 60px;"
            >
              <div class="container py-0">
                <div class="row">
                  <div
                    class="col-12 d-flex justify-content-center align-items-center flex-column"
                    style="text-align: center;"
                  >
                    <h1
                      class="white--text display-1 font-weight-bold pt-5 text-uppercase"
                    >
                      Ajouter un membre
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div class="container py-5">
            <!-- {{people}} -->
            <h4 class="text-center mt-5 py-5 black--text">
              Que voulez-vous créer?
            </h4>
            <v-row justify="center">
              <!-- Famille -->
              <v-col cols="12" lg="6" class="text-center">
                <v-row justify="center">
                  <!-- Famille -->
                  <v-col
                    cols="12"
                    sm="6"
                    lg="6"
                    xl="5"
                    class="d-flex justify-center"
                  >
                    <v-card
                      @click="dialogQuestion = true"
                      height="100%"
                      width="200px"
                      class="card elevation-0 py-5 px-5 text-center grey lighten-2"
                    >
                      <div class="text-center">
                        <v-avatar size="100">
                          <img
                            :src="require('../../images/family.png')"
                            class="img-fluids curseur"
                          /> </v-avatar
                        ><br />
                        <span class="title my-1">Famille</span>
                      </div>
                    </v-card>
                  </v-col>

                  <!-- Roomate -->
                  <v-col
                    cols="12"
                    sm="6"
                    lg="6"
                    xl="5"
                    class="d-flex justify-center"
                  >
                    <v-card
                      href="#/roommate"
                      height="100%"
                      width="200px"
                      class="card elevation-0 py-5 px-5 text-center grey lighten-2"
                    >
                      <div class="text-center">
                        <v-avatar size="100">
                          <img
                            :src="require('../../images/roomate.png')"
                            class="img-fluids curseur"
                          /> </v-avatar
                        ><br />
                        <span class="title my-1">Roommate</span>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </div>
        </div>
      </transition>
    </v-main>

    <v-dialog persistents v-model="dialogQuestion" max-width="400">
      <v-card class="text-center py-3 px-3">
        <h6 class="py-5">Dans quelle famille voulez-vous ajouter?</h6>

        <v-list shaped class="bg-transparent">
          <v-list-item-group v-model="familleId">
            <template v-for="(item, i) in $store.state.family">
              <v-divider v-if="!item" :key="`divider-${i}`"></v-divider>

              <v-list-item
                v-else
                :key="`item-${i}`"
                :value="item"
                active-class="blue lighten-3 white--text my-1"
              >
                <template v-slot:default="{ active }">
                  <v-list-item-content>
                    <v-list-item-title
                      class="black--text"
                      v-text="item.name"
                    ></v-list-item-title>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-checkbox
                      :input-value="active"
                      color="primary darken-2"
                    ></v-checkbox>
                  </v-list-item-action>
                </template>
              </v-list-item>
            </template>
          </v-list-item-group>
        </v-list>

        <v-btn
          small
          color="primary swhite--text"
          class="elevation-0 m-2"
          @click="openFamily()"
          :disabled="familleIdEzali"
          >Continue</v-btn
        >
      </v-card>
    </v-dialog>
    <div class="d-none">{{ ouvrirTransition }}</div>
  </v-app>
</template>

<script>
import firebase from "firebase";
// import ServiceApi from "../ServicesApi.js";
export default {
  data() {
    return {
      dialogQuestion: false,
      show: false,
      familleId: {}
    };
  },
  computed: {
    familleIdEzali() {
      var id = true;
      try {
        if (this.familleId.id) id = false;
      } catch (error) {
        id = true;
      }
      return id;
    },
    ouvrirTransition() {
      this.leTemps();
      return false;
    }
  },
  created() {
    var user = firebase.auth().currentUser;
    if (
      this.$store.state.ngayi.email == "" ||
      this.$store.state.ngayi.email == undefined ||
      user.email == "" ||
      user.email == undefined
    )
      this.$router.push("/menu");
  },
  methods: {
    openFamily() {
      this.$store.state.familyId = this.familleId.id;
      var name = this.familleId.name;
      name = name.replace(/Famille/g, "");
      name = name.replace(/Famile/g, "");
      name = name.replace(/Family/g, "");
      this.$store.state.familyName = name;
      this.$router.push("/family");
    },
    leTemps() {
      setTimeout(() => {
        this.changedValue();
        return false;
      }, 100);
      return 0;
    },
    changedValue() {
      this.show = true;
      return false;
    }
  }
};
</script>

<style scoped>
.card:hover {
  border: 2px solid rgb(0, 110, 255);
  cursor: pointer;
}
</style>
