import firebase from "firebase";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDgmfiC8RscsVVEfrCeIQuke7RuwdftzNk",
  authDomain: "databaste-51ebd.firebaseapp.com",
  databaseURL: "https://databaste-51ebd.firebaseio.com",
  projectId: "databaste-51ebd",
  storageBucket: "databaste-51ebd.appspot.com",
  messagingSenderId: "516905734640",
  appId: "1:516905734640:web:46b24376eba769631f4d35",
  measurementId: "G-3HG3N3NRF5"
};
// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);
export default firebaseApp.firestore();
