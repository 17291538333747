<template>
  <v-app>
    <v-app-bar
      app
      style="background-color:rgb(35, 36, 39);"
      class="elevation-0"
    >
      <v-btn class="white--text" text onclick="window.history.back()">
        <font-awesome-icon
          :icon="['fas', 'chevron-left']"
          class="fa-x white--text mr-2"
        />
      </v-btn>
    </v-app-bar>

    <v-main class="bg">
      <transition name="slide-fade">
        <div v-if="show">
          <div style="height: 100vh;">
            <div
              class="d-flex flex-column justify-content-around align-items-center"
              style="background-color: rgba(0,0,0,.0);height: 100%;"
            >
              <div class="container text-center"></div>
              <div class="container text-center p-0">
                <img :src="require('../../images/myLogo.png')" class="w-25" />
                <h3 class="display-1 white--text">waKongo messenger</h3>
                <h3 class="overline white--text">version 1.0</h3>
              </div>
              <div class="container text-center p-0">
                <v-progress-linear
                  v-if="montrer == 0"
                  indeterminate
                  color="white"
                  height="15"
                ></v-progress-linear>
              </div>
              <div class="container text-center p-0"></div>
            </div>
          </div>
        </div>
      </transition>
    </v-main>

    <v-dialog persistent v-model="dialog" max-width="600">
      <!-- Politique -->
      <v-card class="text-center py-3 px-5" v-if="part == 0">
        <h5 class="py-5">Mon message n'est pas à caractère politique</h5>
        <v-btn color="success" class="elevation-0 m-2" @click="mettre(1)"
          >Oui</v-btn
        >
        <v-btn
          color="red white--text"
          class="elevation-0 m-2"
          onclick="window.history.back()"
          >Non</v-btn
        >
      </v-card>

      <!-- Offensif -->
      <v-card class="text-center py-3 px-5" v-else-if="part == 1">
        <h5 class="py-5">Mon message n'est pas offensif</h5>
        <v-btn color="success" class="elevation-0 m-2" @click="mettre(2)"
          >Oui</v-btn
        >
        <v-btn
          color="red white--text"
          class="elevation-0 m-2"
          onclick="window.history.back()"
          >Non</v-btn
        >
      </v-card>

      <!-- Agree -->
      <v-card class="text-center py-3 px-5" v-else>
        <div class="text-left">
          <v-simple-table dense light>
            <template v-slot:default>
              <tbody>
                <!-- Apolitique -->
                <tr>
                  <td>
                    <font-awesome-icon
                      :icon="['fas', 'check']"
                      class="fa-x green--text mr-2"
                    />
                  </td>
                  <td>Apolitique</td>
                </tr>
                <!-- Inoffensif -->
                <tr>
                  <td>
                    <font-awesome-icon
                      :icon="['fas', 'check']"
                      class="fa-x green--text mr-2"
                    />
                  </td>
                  <td>Inoffensif</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
        <h5 class="py-5">
          J'atteste les décisions ci-haut, au cas ccontraire
          <span class="font-weight-bold black--text"
            >je n'aurais plus accès à waKongo messenger</span
          >
        </h5>
        <v-btn color="success" class="elevation-0 m-2" @click="ouvrir()"
          >J'accepte</v-btn
        >
        <v-btn
          color="red white--text"
          class="elevation-0 m-2"
          onclick="window.history.back()"
          >Annuler</v-btn
        >
      </v-card>
    </v-dialog>
    <div class="d-none">{{ ouvrirTransition }}</div>
  </v-app>
</template>

<script>
import db from "../../db.js";
export default {
  name: "Home",
  data() {
    return {
      montrer: 0,
      show: false,
      part: 0,
      dialog: false,
      agree: false,
      lois: [
        "1. Mon message n'est pas politique",
        "2. Mon message est Inoffensif"
      ],
      jaccepte: []
    };
  },
  created() {
    /********************************************************/
    try {
      var that = this;
      var kadena = [];
      db.collection("kadena")
        .where("email", "==", that.$store.state.email)
        .onSnapshot(snapshot => {
          kadena = [];
          snapshot.forEach(doc => {
            kadena.push({
              email: doc.data().email
            });
          });
          // alert(kadena.length);
          if (kadena.length > 0) that.$router.push("/menu");
        });
    } catch (error) {
      alert(error.message);
    }
    /********************************************************/
  },
  mounted() {
    setTimeout(() => {
      this.montrer = 1;
      this.dialog = true;
    }, 2000);
  },
  methods: {
    ouvrir() {
      this.dialog = false;
      this.montrer = 0;
      setTimeout(() => {
        this.montrer = 1;
        this.$router.push("/chat");
      }, 2000);
    },
    mettre(value) {
      this.dialog = false;
      setTimeout(() => {
        this.part = value;
        this.dialog = true;
      }, 300);
    },
    leTemps() {
      setTimeout(() => {
        this.changedValue();
        return false;
      }, 100);
      return 0;
    },
    changedValue() {
      this.show = true;
      return false;
    }
  },
  computed: {
    ouvrirTransition() {
      this.leTemps();
      return false;
    }
  }
};
</script>
