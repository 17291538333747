<template>
  <!-- App.vue -->
  <v-app>
    <v-app-bar class="elevation-0 white" app>
      <v-btn text color="white" onclick="window.history.back()">
        <font-awesome-icon
          :icon="['fas', 'arrow-left']"
          class="fa-x black--text mr-2"
        />
        Retour
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn icon n @click="montrer = 2">
        <font-awesome-icon :icon="['fas', 'table']" class="fa-x black--text" />
      </v-btn>
      <v-btn icon n @click="montrer = 0">
        <font-awesome-icon :icon="['fas', 'users']" class="fa-x black--text" />
      </v-btn>
      <!-- <v-btn
        icon
        @click="
          dialog4 = true;
          montrer = 1;
        "
      >
        <font-awesome-icon :icon="['fas', 'search']" class="fa-x black--text" />
      </v-btn> -->
    </v-app-bar>
    <!-- Sizes your content based upon application components -->

    <v-main class="main p-0" style="background-color:rgb(235, 236, 239)">
      <transition name="slide-fade">
        <div v-if="show">
          <section class="bg-col-image">
            <div
              style="height: 100%;width: 100%;background: rgba(11,18,81,0.66);padding: 80px 0px 60px;"
            >
              <div class="container py-0">
                <div class="row">
                  <div
                    class="col-12 d-flex justify-content-center align-items-center flex-column"
                    style="text-align: center;"
                  >
                    <h1
                      class="white--text display-1 font-weight-bold pt-5 text-uppercase"
                    >
                      Population
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <!-- <span class="display-4 red--text white p-3 my-5">{{montrer}}</span> -->
          <div class="container py-5 my-5" v-if="montrer == 1">
            <!-- {{people}} -->
            <div
              class="p-3 mt-5 headline text-center black--text"
              v-if="showPerson"
            >
              <span class="py-5">Cette personne n'existe pas</span><br />
              <font-awesome-icon
                :icon="['fas', 'exclamation-circle']"
                class="fa-2x black--text"
              />
            </div>
            <v-row>
              <v-col
                cols="8"
                md="4"
                lg="3"
                class="text-center"
                v-for="(x, k) in peopleSearch"
                :key="k"
              >
                <v-card class="p-3 elevation-0 white" style="height:100%">
                  <v-card-text class="p-0">
                    <v-avatar size="100">
                      <v-img :src="x.image" class="img-fluid" /> </v-avatar
                    ><br />
                    <h6 class="my-2 font-weight-bold black--text">
                      {{ x.fullname }}
                    </h6>
                    <br />
                    <v-btn
                      color="primary"
                      small
                      class="white--text"
                      @click="openPeople(x)"
                      >Ouvrir</v-btn
                    >
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </div>

          <div class="container py-5 my-5" v-else-if="montrer == 2">
            <v-row>
              <v-col cols="12">
                <v-card class="bg-transparent elevation-0">
                  <v-card-title>
                    <v-text-field
                      v-model="recherche"
                      append-icon="mdi-magnify"
                      label="Search"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-card-title>
                  <v-data-table
                    dense
                    class="bg-transparent"
                    :items-per-page="-1"
                    :headers="headers"
                    :items="people"
                    :search="recherche"
                  >
                    <template v-slot:item.image="{ item }">
                      <div class="py-1">
                        <v-avatar>
                          <v-img
                            :src="item.image"
                            :alt="'Image de ' + item.fullname"
                            class="img-fluids"
                          ></v-img>
                        </v-avatar>
                      </div>
                    </template>
                    <template v-slot:item.setting="{ item }">
                      <span class="d-none">{{ item.setting }}</span>
                      <v-btn
                        small
                        color="blue accent-3"
                        class="white--text"
                        @click="openPeople(item)"
                        >Ouvrir</v-btn
                      >
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>
          </div>

          <div class="container py-5 my-5" v-else>
            <v-row>
              <v-col
                cols="6"
                md="4"
                lg="3"
                class="text-center"
                v-for="(x, k) in people"
                :key="k"
              >
                <v-card class="p-3 elevation-0 white" style="height:100%">
                  <v-card-text class="p-0">
                    <v-avatar size="100">
                      <v-img :src="x.image" class="img-fluid" /> </v-avatar
                    ><br />
                    <h6 class="my-2 font-weight-bold black--text">
                      {{ x.fullname }}
                    </h6>
                    <br />
                    <v-btn
                      color="primary"
                      class="white--text"
                      @click="openPeople(x)"
                      >Ouvrir</v-btn
                    >
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </div>
      </transition>
    </v-main>

    <v-dialog persistent v-model="dialogWait" max-width="200">
      <v-card class="text-center py-3">
        <v-progress-circular
          :size="30"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <span class="ml-2">Please Wait</span>
      </v-card>
    </v-dialog>

    <!-- Dialog 4 -->
    <v-dialog v-model="dialog4" max-width="400">
      <v-card class="elevation-0 px-5 py-5" v-if="par == 'rien'">
        <div class="w-100 text-center">
          <span class="headline">Recherche</span><br /><br />
          <v-btn
            large
            blocks
            class="elevation-0 white--text m-1"
            style="background: rgb(11,18,81);"
            @click="par = 'id'"
            >Par ID</v-btn
          >
          <v-btn
            large
            blocks
            class="elevation-0 white--text m-1"
            style="background: rgb(11,18,81);"
            @click="par = 'nom'"
            >Par Noms</v-btn
          >
        </div>
      </v-card>

      <!-- ID  -->
      <v-card class="elevation-0 px-5 py-5" v-else-if="par == 'id'">
        <v-btn icon @click="par = 'rien'">
          <font-awesome-icon
            :icon="['fas', 'arrow-left']"
            class="fa-x black--text"
        /></v-btn>
        <div class="w-100 text-center">
          <span class="headline">Recherche par ID</span>
        </div>
        <v-text-field v-model="koluka[0].id" label="ID Number" />
        <v-btn
          class="elevation-0 white--text"
          style="background: rgb(11,18,81);"
          :disabled="koluka[0].id == ''"
          @click="chercher(0)"
          >Chercher</v-btn
        >
      </v-card>

      <!-- Noms  -->
      <v-card class="elevation-0 px-5 py-5" v-else-if="par == 'nom'">
        <v-btn icon @click="par = 'rien'">
          <font-awesome-icon
            :icon="['fas', 'arrow-left']"
            class="fa-x black--text"
        /></v-btn>
        <div class="w-100 text-center">
          <span class="headline">Recherche par noms</span>
        </div>

        <v-text-field v-model="koluka[0].nom" label="Nom" />
        <v-text-field v-model="koluka[0].postnom" label="Postnom" />
        <v-text-field v-model="koluka[0].prenom" label="Prenom" />

        <v-btn
          class="elevation-0 white--text"
          :disabled="koluka[0].nom == '' || koluka[0].prenom == ''"
          style="background: rgb(11,18,81);"
          @click="chercher(1)"
          >Chercher</v-btn
        >
      </v-card>
    </v-dialog>
    <div class="d-none">{{ ouvrirTransition }}</div>
  </v-app>
</template>

<script>
// import ServiceApi from "../ServicesApi.js";
import firebase from "firebase";
import db from "../../db.js";

export default {
  data() {
    return {
      email: "kerene@example.com",
      show: false,
      password: "plus1234",
      erreur: "",
      showPerson: false,
      par: "rien",
      koluka: [
        {
          id: "",
          nom: "",
          postnom: "",
          prenom: ""
        }
      ],
      headers: [
        { text: "Image", value: "image" },
        { text: "Fullname", value: "fullname" },
        { text: "Phone", value: "phone" },
        { text: "Action", value: "setting" }
      ],
      recherche: "",
      place: "",
      valeur: 50,
      search: "",
      dialog: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      roomid: "",
      dialogWait: false,
      nombreEnfant: 1,
      peopleSearch: [],

      enfants: [
        {
          nom: "",
          postnom: "",
          prenom: "",
          sex: "",
          lieu_de_naissance: "",
          date_de_naissance: "",
          adresse: "",
          city: "",
          zipcode: "",
          date_a_iowa: ""
        }
      ],
      people: [],
      naponi: [],
      roomate: [],
      montrer: 2,
      makabo: true,
      o: {
        id: "",
        email: "",
        yesa: ""
      }
    };
  },
  created() {
    var that = this;
    var user = firebase.auth().currentUser;
    if (
      this.$store.state.ngayi.email == "" ||
      this.$store.state.ngayi.email == undefined ||
      user.email == "" ||
      user.email == undefined
    )
      this.$router.push("/menu");
    else {
      if (
        this.$store.state.ngayi.yesa == process.env.VUE_APP_FBT_2023 ||
        this.$store.state.ngayi.yesa == process.env.VUE_APP_KONGOMANI
      )
        console.log("ok ok ok");
      else {
        // alert(0);
        try {
          db.collection("challenge").add({
            id: that.$store.state.ngayi.id,
            email: that.$store.state.ngayi.email,
            access: "access au menu pre",
            cretedAd: firebase.firestore.FieldValue.serverTimestamp()
          });
          this.$router.push("/menu");
        } catch (error) {
          alert(error.message);
        }
      }
    }
  },
  mounted() {
    var that = this;
    console.log(this.$store.state.email + " from menu");
    /********************************************************/
    if (this.$store.state.email == "") this.$router.push("/menu");
    else {
      /********************************************************/
      db.collection("charge")
        .orderBy("email", "asc")
        .onSnapshot(snapshot => {
          this.o = {
            id: "",
            email: "",
            yesa: ""
          };
          this.makabo = true;
          snapshot.forEach(doc => {
            if (
              doc.data().yesa == that.$store.state.ngayi.yesa &&
              doc.data().email.toLowerCase() ==
                that.$store.state.ngayi.email.toLowerCase() &&
              doc.data().id.toLowerCase() ==
                that.$store.state.ngayi.id.toLowerCase()
            ) {
              this.o = {
                id: doc.data().id,
                email: doc.data().email,
                yesa: doc.data().yesa
              };
              this.makabo = false;
            }
          });
          if (this.makabo == true) {
            if (
              this.$store.state.ngayi.yesa != process.env.VUE_APP_KomngoMani ||
              this.$store.state.ngayi.yesa != process.env.VUE_APP_FBT_2023
            ) {
              // alert("block account");
              try {
                db.collection("challenge").add({
                  id: that.$store.state.ngayi.id,
                  email: that.$store.state.ngayi.email,
                  access: "access a tout le monde",
                  cretedAd: firebase.firestore.FieldValue.serverTimestamp()
                });
              } catch (error) {
                alert(error.message);
              }
            }
          }
        });
    }
    // /*******************************************************/

    /***************************************/
    db.collection("users")
      .orderBy("nom", "asc")
      .onSnapshot(snapshot => {
        this.people = [];
        snapshot.forEach(doc => {
          // console.log(doc.id);
          this.people.push({
            originalId: doc.id,
            id: doc.data().id,
            postnom: doc.data().postnom,
            nom: doc.data().nom,
            prenom: doc.data().prenom,
            fullname:
              doc.data().nom +
              " " +
              doc.data().postnom +
              " " +
              doc.data().prenom,
            phone: doc.data().phone,
            date_de_naissance: doc.data().date_de_naissance,
            date_a_iowa: doc.data().date_a_iowa,
            lieu_de_naissance: doc.data().lieu_de_naissance,
            sex: doc.data().sex,
            adresse: doc.data().adresse,
            image: doc.data().image,
            city: doc.data().city,
            zipcode: doc.data().zipcode,
            state: doc.data().state,
            email: doc.data().email,
            privacy: doc.data().privacy,
            type: doc.data().type,
            year: new Date(doc.data().cretedAd.toDate()).toDateString().year,
            date: new Date(doc.data().cretedAd.toDate()).toDateString(),
            heure:
              " at " +
              new Date(doc.data().cretedAd.toDate()).toLocaleTimeString()
          });
        });
      });
  },
  methods: {
    creerEnfant() {
      this.enfants = [];
      for (let i = 0; i < this.nombreEnfant; i++) {
        this.enfants.push({
          id: this.madeId(),
          nom: "Future",
          postnom: "Prestige",
          prenom: "Prestige",
          sex: "M",
          image: this.male,
          lieu_de_naissance: "Kinshasa",
          date_de_naissance: "2007-02-02",
          adresse: "",
          city: "",
          zipcode: "",
          date_a_iowa: ""
        });
      }
    },
    theFormat(number) {
      return number.toFixed(0);
    },
    openPeople(people) {
      console.log(people);
      //Duree
      console.log(people.date_a_iowa);
      var duree = people.date_a_iowa.slice(0, 4);
      console.log(duree);
      var d = new Date();
      var annee = d.getFullYear();
      console.log("age == " + (Number(annee) - Number(duree)));
      this.$store.state.ye = people;
      this.$store.state.ye.year = Number(annee) - Number(duree);

      this.$router.push("/person_profile");
    },
    // chercher(value) {
    //   // console.log(this.people);
    //   this.peopleSearch = [];
    //   var keepId = false;
    //   var keepNom = false;
    //   // par ID
    //   if (value == 0) {
    //     this.people.forEach(element => {
    //       console.log(
    //         element.id.toLowerCase() + "--" + this.koluka[0].id.toLowerCase()
    //       );
    //       if (element.id.toLowerCase() == this.koluka[0].id.toLowerCase()) {
    //         keepId = true;
    //         this.showPerson = false;
    //         this.peopleSearch.push({
    //           id: element.id,
    //           image: element.image,
    //           fullname: element.fullname
    //         });
    //       }
    //     });
    //     console.log(this.peopleSearch);
    //     if (keepId == false) this.showPerson = true;
    //   }
    //   // par Noms
    //   else {
    //     this.people.forEach(element => {
    //       console.log(element.nom);
    //       if (
    //         element.nom.toLowerCase() == this.koluka[0].nom.toLowerCase() &&
    //         element.postnom.toLowerCase() ==
    //           this.koluka[0].postnom.toLowerCase() &&
    //         element.prenom.toLowerCase() == this.koluka[0].prenom.toLowerCase()
    //       ) {
    //         keepNom = true;
    //         this.showPerson = false;
    //         this.peopleSearch.push({
    //           id: element.id,
    //           image: element.image,
    //           fullname:
    //             element.nom + " " + element.postnom + " " + element.prenom
    //         });
    //       }
    //     });
    //     console.log(this.peopleSearch);
    //     if (keepNom == false) this.showPerson = true;
    //   }
    //   this.dialog4 = false;
    //   this.koluka = [
    //     {
    //       id: "",
    //       nom: "",
    //       postnom: "",
    //       prenom: ""
    //     }
    //   ];
    //   this.par = "rien";
    //   console.log("test");
    // },
    leTemps() {
      setTimeout(() => {
        this.changedValue();
        return false;
      }, 100);
      return 0;
    },
    changedValue() {
      this.show = true;
      return false;
    }
  },
  computed: {
    ouvrirTransition() {
      this.leTemps();
      return false;
    }
  }
};
</script>

<style scoped>
.card:hover {
  border: 2px solid rgb(4, 61, 134);
  cursor: pointer;
}
</style>
