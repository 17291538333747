<template>
    <div class="row bg-secondary d-flex align-items-center justify-center" style="min-height: 100vh;">


        <!-- Account -->
        <v-col cols="6" class="white p-3 px-5 mt-5">
            <v-progress-linear v-model="knowledge" color="primary darken-4" style="color:white;" height="35">
                <strong>{{knowledge}}%</strong>
            </v-progress-linear>

            <h3 class="my-5 ">Create your Password</h3>
            <form class="form">
                <!-- <div class="form-group">
                    <v-text-field v-model="$store.state.ngayi.email" :disabled="true" class="mb-2" clearable label="Email">
                    </v-text-field>
                </div> -->
                <div class="form-group">
                    <v-text-field v-model="password" :type="show" name="input-10-1" label="Password"
                        hint="At least 8 characters" counter></v-text-field>

                    <v-text-field v-model="cfrPassword" :type="show" name="input-10-1" label="Confirm Password"
                        hint="At least 8 characters" counter></v-text-field>
                        <span class="red--text" v-if="password!=cfrPassword">Les mots de passe doivent être identiques!</span>
                </div>

                <v-btn class="mt-5 mx-2" color="success" @click="show = (show=='password'?'':'password')">
                    <span v-if="show=='password'">Montrer le mot de passe</span>
                    <span v-else>Cacher le mot de passe</span>
                </v-btn>
            </form>

            <v-btn large class="mt-5 mx-2" color="red white--text" href="#/setMyProfile">Retour</v-btn>
            <v-btn large class="mt-5" color="primary" @click="signupCreate()"
                v-if="(password.length>7 && password==cfrPassword)">
                Continuer
            </v-btn>
        </v-col>
    </div>
</template>

<script>
    import firebase from "firebase";
    import db from "../../db.js";

    export default {
        data() {
            return {
                knowledge: 90,
                cfrPassword: '',
                password: '',
                erreur: '',
                show: "password"
            }
        },
        created(){
            this.$store.state.ngayi.email = this.$store.state.ngayi.id.toUpperCase() +"@wakongo.com";
        },
        methods: {
            save() {
                var d = new Date();
                d.setTime(d.getTime() + 5 * (365 * 24 * 60 * 60 * 1000));
                var expires = "expires=" + d.toUTCString();
                document.cookie = "mutuYango=" + this.$store.state.ngayi.email + ";" + expires + "; path=/";
                document.cookie = "codeYango=" + this.password + ";" + expires + "; path=/";
                this.updateAccount();
            },
            updateAccount() {
                try {
                    db.collection("users")
                        .doc(this.$store.state.ngayi.originalId)
                        .update({
                            email: this.$store.state.ngayi.email
                        });
                        alert("Votre compte a été créer!");
                } catch {
                    alert("Un problem est survenu. Veuillez contacter le +1 (319-519-3008)");
                }
            },

            signupCreate() {
                var that = this;

                try {
                    if (that.$store.state.ngayi.email != undefined && that.$store.state.ngayi.email != "" &&
                        that.password != undefined && that.password != "") {

                        // logout
                        firebase
                            .auth()
                            .signOut()
                            .then(function () {
                                // Sign-out successful.
                                document.cookie = "mutuYango=" + ";";
                                document.cookie = "codeYango=" + ";";
                            })
                            .catch(function (error) {
                                // An error happened.
                                alert(error.message);
                            });

                        /*****************************/
                        firebase
                            .auth()
                            .createUserWithEmailAndPassword(that.$store.state.ngayi.email, that.password)
                            .then(
                                elt => {
                                    console.log(elt.user.email);
                                    that.save();
                                },
                                error => {
                                    that.erreur = error.message;
                                    
                                    var d = new Date();
                                    d.setTime(d.getTime() + 5 * (365 * 24 * 60 * 60 * 1000));
                                    var expires = "expires=" + d.toUTCString();
                                    document.cookie = "mutuYango=" + that.$store.state.ngayi.email + ";" + expires + ";";
                                    document.cookie = "codeYango=" + that.password + ";" + expires + ";";

                                    alert("Votre compte a été mis a jour!");
                                }
                            );
                        /*****************************/
                        
                        that.$router.push("/setMyAddress");
                    }
                } catch {
                    alert("Un problem est survenu. Veuillez contacter le +1 (319-519-3008)");
                }
            },
        }
    }
</script>

<style>

</style>