<template>
    <div></div>
</template>
<script>
    export default {
        data() {
            return {
                lien: "CON001"
            }
        },
        mounted() {
            this.$store.state.email = "demo";
            this.getKeys();
        },
        methods: {
            getKeys() {
                var location = window.location.hash.slice(25);
                this.$store.state.ngayi.id = location;
                // alert(this.$store.state.ngayi.id);
                if(this.$store.state.ngayi.id!='') this.$router.push("/cjmgbEssJpxTTycUeGk0rp1BqmiIvMqmjWLxAP/u7BU=");
                else this.$router.push("/");
            }
        }
    }
</script>

<style>

</style>