<template>
  <!-- App.vue -->
  <v-app>
    <v-app-bar app class="elevation-0 white">
      <v-btn
        class="black--text"
        text
        onclick="window.history.back()"
        v-if="montre == 1"
      >
        <font-awesome-icon
          :icon="['fas', 'arrow-left']"
          class="fa-x black--text mr-2"
        />
        Retour
      </v-btn>
      <v-btn text color="white" @click="closeTotal()" v-else>
        <font-awesome-icon
          :icon="['fas', 'arrow-left']"
          class="fa-x black--text mr-2"
        />
        Retour
      </v-btn>
      <!-- <v-spacer></v-spacer>
      <h4 class="black--text">Finance Pro</h4> -->
      <v-spacer></v-spacer>
    </v-app-bar>
    <!-- Sizes your content based upon application components -->

    <v-main class="main p-0" style="background-color:rgb(235, 236, 239)">
      <transition name="slide-fade">
        <div v-if="show">
          <section class="bg-col-image">
            <div
              style="height: 100%;width: 100%;background: rgba(11,18,81,0.66);padding: 80px 0px 60px;"
            >
              <div class="container py-0">
                <div class="row">
                  <div
                    class="col-12 d-flex justify-content-center align-items-center flex-column"
                    style="text-align: center;"
                  >
                    <h3
                      class="white--text font-weight-bold pt-5 text-uppercase"
                    >
                      <!-- assistance -->
                      Request from the community ({{ users.length }})
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <div class="container">
            <v-row justify="center" class="py-5">
              <v-col cols="12" class="py-2 px-5" v-if="montre == 1">
                <v-card height="100%" class="bg-transparent elevation-0 py-5">
                  <!-- <h5 class="text-center my-5 black--text">
                Request from the community ({{ users.length }})
              </h5> -->
                  <v-divider class="grey"></v-divider>

                  <v-simple-table class="bg-transparent">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            Image
                          </th>
                          <th class="text-left">
                            Noms
                          </th>
                          <!-- <th class="text-left">
                        Date
                      </th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(x, k) in users"
                          :key="k"
                          @click="openUser(x)"
                        >
                          <td class="py-2">
                            <v-avatar>
                              <v-img :src="x.image" class="img-fluids" />
                            </v-avatar>
                          </td>
                          <td>
                            <h6 class="font-weight-bold">{{ x.fullname }}</h6>
                          </td>
                          <!-- <td>
                        <v-btn color="primary" href="#/person_profile"
                          >Open</v-btn
                        >
                      </td> -->
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </v-col>

              <v-col cols="12" class="py-5 px-5" v-else>
                <!-- list -->
                <v-card class="bg-transparent elevation-0 py-5">
                  <div class="w-100 text-center">
                    <v-avatar size="150">
                      <v-img :src="nani.src" class="img-fluids" /> </v-avatar
                    ><br />
                    <h5 class="mt-2 black--text">{{ nani.fullname }}</h5>
                    <v-btn
                      v-if="nani.phone"
                      small
                      text
                      color="red"
                      class="elevation-0 m-1"
                      :href="'tel:' + nani.phone"
                      >Appeler</v-btn
                    >
                    <v-btn
                      v-if="nani.email"
                      small
                      text
                      color="primary"
                      class="elevation-0 m-1"
                      :href="'mailto:' + nani.email"
                      >Email</v-btn
                    >
                  </div>

                  <v-divider class="grey"></v-divider>

                  <v-simple-table class="bg-transparent">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            Request
                          </th>
                          <th class="text-left">
                            Price
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(x, k) in nani.request"
                          :key="k"
                          @click="openRequest(x)"
                        >
                          <td class="py-2">{{ x.name }}</td>
                          <!-- <td>
                            <h6 class="font-weight-bold black--text">
                              {{ "$" + x.price }}
                            </h6>
                          </td> -->
                          <td>
                          <v-btn
                            icon
                            @click="
                              deleteAssistance(k);
                            "
                          >
                            <font-awesome-icon
                              :icon="['fas', 'trash']"
                              class="fa-2x red--text"
                            />
                          </v-btn>
                        </td>
                        </tr>
                        <!-- <tr>
                          <td class="title black--text"><b>Total</b></td>
                          <td class="title black--text">
                            <b>{{ "$" + total }}</b>
                          </td>
                        </tr> -->
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </div>
      </transition>
    </v-main>

    <v-dialog persistent v-model="dialogWait" max-width="200">
      <v-card class="text-center py-3">
        <v-progress-circular
          :size="30"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <span class="ml-2">Please Wait</span>
      </v-card>
    </v-dialog>
    <div class="d-none">{{ ouvrirTransition }}</div>
  </v-app>
</template>

<script>
// import ServiceApi from "../ServicesApi.js";
import firebase from "firebase";
import db from "../../db.js";

export default {
  data() {
    return {
      email: "kerene@example.com",
      show: false,
      password: "plus1234",
      dialogWait: false,
      dialogRequest: false,
      place: "",
      total: 0,
      montre: 1,
      users: [],
      people: [],
      switch: false,
      nani: {
        fullname: "=",
        src: "",
        email: "",
        phone: "",
        request: []
      },
      makabo: true,
      o: {
        id: "",
        email: "",
        yesa: ""
      }
    };
  },
  created() {
    var user = firebase.auth().currentUser;
    if (
      this.$store.state.ngayi.email == "" ||
      this.$store.state.ngayi.email == undefined ||
      user.email == "" ||
      user.email == undefined
    )
      this.$router.push("/menu");
  },
  mounted() {
    var that = this;
    /***************************************/
    db.collection("assistance").onSnapshot(snapshot => {
      that.users = [];
      snapshot.forEach(doc => {
        that.users.push({
          assistanceID: doc.id,
          id: doc.data().id,
          fullname: doc.data().fullname,
          image: "",
          phone: doc.data().phone,
          email: doc.data().email,
          request: doc.data().request
        });
      });
      this.zuaPeople();
    });
    /***************************************/

    /********************************************************/
    if (this.$store.state.email == "") this.$router.push("/menu");
    else {
      /********************************************************/
      db.collection("charge")
        .orderBy("email", "asc")
        .onSnapshot(snapshot => {
          this.o = {
            id: "",
            email: "",
            yesa: ""
          };
          this.makabo = true;
          snapshot.forEach(doc => {
            if (
              doc.data().yesa == that.$store.state.ngayi.yesa &&
              doc.data().email.toLowerCase() ==
                that.$store.state.ngayi.email.toLowerCase() &&
              doc.data().id.toLowerCase() ==
                that.$store.state.ngayi.id.toLowerCase()
            ) {
              this.o = {
                id: doc.data().id,
                email: doc.data().email,
                yesa: doc.data().yesa
              };
              this.makabo = false;
            }
          });
          if (this.makabo == true) {
            // alert("block account");
            try {
              db.collection("challenge").add({
                id: that.$store.state.ngayi.id,
                email: that.$store.state.ngayi.email,
                access: "access au menu pro",
                cretedAd: firebase.firestore.FieldValue.serverTimestamp()
              });
            } catch (error) {
              alert(error.message);
            }
          }
        });
    }
    // /*******************************************************/
  },
  methods: {
    theFormat(number) {
      return number.toFixed(0);
    },
    zuaPeople() {
      var that = this;
      /***************************************/
      db.collection("users").onSnapshot(snapshot => {
        that.people = [];
        snapshot.forEach(doc => {
          that.people.push({
            id: doc.data().id,
            image: doc.data().image,
            email: doc.data().email
          });
        });
        this.tiaImage();
      });
      /***************************************/
    },
    tiaImage() {
      this.users.forEach(assistance => {
        this.people.forEach(element => {
          if (element.id.toLowerCase() == assistance.id.toLowerCase()) {
            assistance.image = element.image;
          }
        });
      });
    },
    openUser(x) {
      this.nani.fullname = x.fullname;
      this.nani.src = x.image;
      this.nani.phone = x.phone;
      this.nani.email = x.email;
      this.nani.request = x.request;
      this.nani.id = x.id;
      this.nani.assistanceID = x.assistanceID;
      this.montre = 0;
      this.calculerTotal();
    },
    deleteAssistance(value){
      // alert(this.nani.assistanceID);

      this.nani.request.splice(value, 1);
      // console.log("this.nani.request");
      console.log(this.nani.request.length);
      
      if(this.nani.request.length>1){
        try {
        db.collection("assistance")
          .doc(this.nani.assistanceID)
          .update({
            request: this.nani.request
          });
        } catch (error) {
          console.log(error.message);
        }
      }else{
        try {
        db.collection("assistance")
          .doc(this.nani.assistanceID)
          .delete();
        } catch (error) {
          console.log(error.message);
        }
      }

    },
    calculerTotal() {
      this.total = 0;
      this.nani.request.forEach(element => {
        this.total = -(-this.total - Number(element.price));
      });
    },
    closeTotal() {
      this.total = 0;
      this.montre = 1;
      this.nani = {
        fullname: "",
        src: "",
        email: "",
        phone: "",
        request: []
      };
    },
    openRequest(x) {
      console.log(x);
      this.dialogRequest = true;
    },
    leTemps() {
      setTimeout(() => {
        this.changedValue();
        return false;
      }, 100);
      return 0;
    },
    changedValue() {
      this.show = true;
      return false;
    }
  },
  computed: {
    ouvrirTransition() {
      this.leTemps();
      return false;
    }
  }
};
</script>
