<template>
  <!-- App.vue -->
  <v-app>
    <v-app-bar class="elevation-0 white" app>
      <v-btn text color="white" onclick="window.history.back()">
        <font-awesome-icon
          :icon="['fas', 'chevron-left']"
          class="fa-x black--text mr-2"
        />
      </v-btn>
    </v-app-bar>
    <!-- Sizes your content based upon application components -->

    <v-main class="main p-0" style="background-color:rgb(235, 236, 239)">
      <transition name="slide-fade">
        <div v-if="show">
          <div
            class="py-5"
            style="background: rgba(0,0,0,.0);height: 100%;"
            v-if="part == 0"
          >
            <section class="bg-col-image">
              <div
                style="height: 100%;width: 100%;background: rgba(11,18,81,0.66);padding: 80px 0px 60px;"
              >
                <div class="container py-0">
                  <div class="row">
                    <div
                      class="col-12 d-flex justify-content-center align-items-center flex-column"
                      style="text-align: center;"
                    >
                      <h1
                        class="white--text display-1 font-weight-bold pt-5 text-uppercase"
                      >
                        Finance
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <v-row justify="center" class="py-5">
              <v-col cols="12" class="p-0">
                <v-card
                  height="250px"
                  class="elevation-0 bg-transparent py-5 px-5 d-flex justify-center align-items-center flex-column"
                >
                  <span class="pb-3 black--text">Budget actuel</span>
                  <number
                    class="display-3 black--text"
                    ref="number1"
                    :from="0"
                    :to="total"
                    :format="theFormat"
                    :duration="1"
                    :delay="0.5"
                    easing="Power1.easeOut"
                  />
                </v-card>
                <v-divider class="grey"></v-divider>
              </v-col>
            </v-row>

            <div class="container py-5">
              <v-row justify="center">
                <v-col cols="6">
                  <v-card
                    height="100%"
                    class="elevation-0 bg-transparent py-2 px-5 d-flex justify-center align-items-center flex-column"
                  >
                    <span class="mb-2 black--text">Savings</span>
                    <h2 class="black--text font-weight-bold">
                      {{ "$" + gain }}
                    </h2>
                  </v-card>
                </v-col>

                <v-col cols="6">
                  <v-card
                    height="100%"
                    class="elevation-0 bg-transparent py-2 px-5 d-flex justify-center align-items-center flex-column"
                  >
                    <span class="mb-2 black--text">Expenses</span>
                    <h2 class="erreur font-weight-bold">
                      {{ "$" + depenses }}
                    </h2>
                  </v-card>
                </v-col>

                <!-- <v-col cols="6" md="4" lg="3">
                  <v-card
                    height="100%"
                    class="elevation-0 bg-transparent py-2 px-5 d-flex justify-center align-items-center flex-column"
                  >
                    <span class="mb-2 black--text">Users</span>
                    <h3 class="headline black--text font-weight-bold">
                      {{ users.length }}
                    </h3>
                  </v-card>
                </v-col> -->

                <!-- <v-col cols="6" md="4" lg="3">
              <v-card
                height="100%"
                class="elevation-0 bg-transparent py-2 px-5 d-flex justify-center align-items-center flex-column"
              >
                <span class="mb-2 black--text">Maintenace</span>
                <h3 class="headline erreur font-weight-bold">
                  {{ "$" + maintenance }}
                </h3>
              </v-card>
            </v-col> -->

                <v-col cols="12">
                  <v-divider class="grey"></v-divider>
                </v-col>

                <v-col cols="12" class="py-2 px-5">
                  <v-card height="100%" class="elevation-0 py-5 bg-transparent">
                    <h2 class="text-center my-5 black--text">Balance</h2>

                    <v-simple-table class="bg-transparent">
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              Motif
                            </th>
                            <th class="text-left">
                              Montant
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(x, k) in motif"
                            :key="k"
                            @click="openMoney(x)"
                          >
                            <td>
                              <h6>{{ x.motif }}</h6>
                            </td>
                            <td class="py-5">
                              <h6
                                class="font-weight-bold erreur"
                                v-if="x.type == false"
                              >
                                {{ "$" + x.montant }}
                              </h6>
                              <h6 class="font-weight-bold black--text" v-else>
                                {{ "$" + x.montant }}
                              </h6>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card>
                </v-col>

                <v-col cols="12">
                  <v-card
                    height="100%"
                    class="elevation-0 p-0 py-5 bg-transparent"
                  >
                    <apexchart
                      type="bar"
                      height="350"
                      class="w-100"
                      :options="chartOptionsCollum"
                      :series="seriesCollum"
                    ></apexchart>
                  </v-card>
                </v-col>

                <!-- <v-col cols="12" lg="6" class="black py-5 px-5"></v-col> -->
              </v-row>
            </div>
          </div>

          <div
            class="py-5 px-0 my-5"
            style="background: rgba(0,0,0,.0);height: 100%;"
            v-else
          >
            <section class="bg-col-image">
              <div
                style="height: 100%;width: 100%;background: rgba(11,18,81,0.66);padding: 80px 0px 60px;"
              >
                <div class="container py-0">
                  <div class="row">
                    <div
                      class="col-12 d-flex justify-content-center align-items-center flex-column"
                      style="text-align: center;"
                    >
                      <h2
                        class="white--text font-weight-bold pt-5 text-uppercase"
                      >
                        Transaction en cours de validation
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <div class="container my-5 py-5 px-5">
              <!-- <h3 class="text-center black--text">Transaction en cours de validation</h3> -->
              <v-simple-table class="bg-transparent">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Motif
                      </th>
                      <th class="text-left">
                        Montant
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(x, k) in attente"
                      :key="k"
                      @click="openAttente(x)"
                    >
                      <td>
                        <h6>{{ x.motif }}</h6>
                      </td>
                      <td class="py-5">
                        <h6
                          class="font-weight-bold erreur"
                          v-if="x.type == false"
                        >
                          {{ "$" + x.montant }}
                        </h6>
                        <h6 class="font-weight-bold black--text" v-else>
                          {{ "$" + x.montant }}
                        </h6>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </div>
        </div>
      </transition>
    </v-main>

    <v-dialog persistent v-model="dialogWait" max-width="200">
      <v-card class="text-center py-3">
        <v-progress-circular
          :size="30"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <span class="ml-2">Please Wait</span>
      </v-card>
    </v-dialog>

    <!-- Dialog 4 -->
    <v-dialog v-model="dialogAdd" max-width="400">
      <v-card class="elevation-0 px-5 py-5">
        <h4 class="text-center mb-3">Financial Transaction</h4>
        <form class="form">
          <input
            class="form-control"
            v-model="Money[0].motif"
            type="text"
            placeholder="Name"
          /><br />
          <input
            class="form-control"
            v-model="Money[0].montant"
            type="number"
            placeholder="Amount"
          /><br />
          <textarea
            class="form-control"
            v-model="Money[0].descriptions"
            placeholder="Descriptions"
          ></textarea
          ><br />
          <input
            class="form-control"
            v-model="Money[0].ladate"
            type="date"
            placeholder="Date"
          /><br />
          <input
            class="form-control"
            v-model="Money[0].forWho"
            type="text"
            placeholder="Agent Name"
          /><br />
        </form>
        <v-btn class="elevation-0" small :color="saving" @click="tiaType(1)">
          Saving
        </v-btn>
        <v-btn class="elevation-0" small :color="expenses" @click="tiaType(0)">
          Expenses
        </v-btn>
        <v-divider></v-divider>
        <v-img
          @click="SelecFile()"
          v-if="Money[0].image == ''"
          :src="require('../../images/noPic.png')"
          class="img-fluids curseur"
        ></v-img>
        <v-img
          v-else
          @click="SelecFile()"
          name="myImage"
          :src="Money[0].image"
          class="img-fluids curseur"
        ></v-img
        ><br />
        <input
          type="file"
          id="file"
          @change="baPoniImages($event)"
          accept="image/png, image/gif, image/jpeg, image/jpg"
          style="display:none;"
        />

        <v-card-actions>
          <v-divider></v-divider>
          <div class="w-100 pt-3 text-right">
            <v-btn small color="red" text @click="closeYango()">
              Close
            </v-btn>
            <v-btn small color="green" text @click="postYango()">
              Add
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>

    
    <v-dialog v-model="dialogDetails" max-width="600">
      <v-card class="elevation-0 px-5 py-5">
        <div class="text-center w-100">
          <h4>Details</h4>
          <v-btn
            small
            color="red darken-3"
            class="white--text"
            :href="details.image"
            target="_blank"
            v-if="details.type == 0"
          >
            Voir le reçu
          </v-btn>
          <v-btn
            small
            color="green darken-3"
            class="white--text"
            :href="details.image"
            target="_blank"
            v-else
          >
            Voir le reçu
          </v-btn>
        </div>
        <v-divider></v-divider>

        <!-- Image -->
        <v-simple-table light>
          <template v-slot:default>
            <tbody>
              <!-- Motif -->
              <tr v-if="details.motif">
                <td class="py-2 black--text">Motif:</td>
                <td>{{ details.motif }}</td>
              </tr>
              <!-- Date -->
              <tr v-if="details.ladate">
                <td class="py-2 black--text">Date:</td>
                <td>{{ details.ladate }}</td>
              </tr>
              <!-- Montant -->
              <tr v-if="details.montant">
                <td class="py-2 black--text">Montant:</td>
                <td class="red--text" v-if="details.type == 0">
                  {{ "$" + details.montant }}
                </td>
                <td class="font-weight-bold black--text" v-else>
                  {{ "$" + details.montant }}
                </td>
              </tr>
              <!-- ForWho -->
              <tr v-if="details.forWho">
                <td class="py-2 black--text">Agent:</td>
                <td>{{ details.forWho }}</td>
              </tr>
              <!-- Descriptions -->
              <tr v-if="details.descriptions">
                <td class="py-2 black--text">Descriptions:</td>
                <td>{{ details.descriptions }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <div class="py-5 mt-5" v-if="part == 1">
          <h4 class="pt-5">AGREEMENTS</h4>

          <v-simple-table light>
            <template v-slot:default>
              <tbody>
                <tr
                  v-for="(x, k) in details.permission"
                  :key="k"
                  @dblclick="openPermission(x)"
                >
                  <td
                    v-if="x.email == $store.state.ngayi.email"
                    class="grey lighten-1 font-weight-bold"
                  >
                    {{ x.name }}
                  </td>
                  <td v-else>{{ x.name }}</td>
                  <td
                    v-if="x.email == $store.state.ngayi.email"
                    class="grey lighten-1 font-weight-bold"
                  >
                    <span v-if="x.value == 'yes'">Accepté</span>
                    <span v-else-if="x.value == 'non'">Refusé</span>
                    <span v-else>N/A</span>
                  </td>
                  <td v-else>
                    <span v-if="x.value == 'yes'">Accepté</span>
                    <span v-else-if="x.value == 'non'">Refusé</span>
                    <span v-else>N/A</span>
                  </td>
                  <td
                    v-if="x.email == $store.state.ngayi.email"
                    class="grey lighten-1"
                  >
                    <span v-if="x.value == 'yes'">
                      <font-awesome-icon
                        :icon="['fas', 'circle']"
                        class="fa-x green--text mr-2"
                      />
                    </span>
                    <span v-else-if="x.value == 'non'">
                      <font-awesome-icon
                        :icon="['fas', 'circle']"
                        class="fa-x red--text mr-2"
                      />
                    </span>
                    <span v-else>
                      <font-awesome-icon
                        :icon="['fas', 'circle']"
                        class="fa-x black--text mr-2"
                      />
                    </span>
                  </td>
                  <td v-else>
                    <span v-if="x.value == 'yes'">
                      <font-awesome-icon
                        :icon="['fas', 'circle']"
                        class="fa-x green--text mr-2"
                      />
                    </span>
                    <span v-else-if="x.value == 'non'">
                      <font-awesome-icon
                        :icon="['fas', 'circle']"
                        class="fa-x red--text mr-2"
                      />
                    </span>
                    <span v-else>
                      <font-awesome-icon
                        :icon="['fas', 'circle']"
                        class="fa-x black--text mr-2"
                      />
                    </span>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>

        <v-card-actions>
          <v-divider></v-divider>
          <div class="w-100 pt-3 text-right">
            <v-btn small color="red" text @click="dialogDetails = false">
              Close
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div class="d-none">{{ ouvrirTransition }}</div>
  </v-app>
</template>

<script>
// import ServiceApi from "../ServicesApi.js";
import firebase from "firebase";
import db from "../../db.js";

export default {
  data() {
    return {
      email: "kerene@example.com",
      show: false,
      password: "plus1234",
      dialogWait: false,
      dialogAdd: false,
      dialog: false,
      dialogDetails: false,
      entrer_pour_presenter: false,
      place: "",
      valeur: 50,
      details: {},
      users: [],
      attente: [],

      motif: [],

      //   AppCHart

      series: [76],
      chartOptions: {
        chart: {
          type: "radialBar",
          offsetY: -20,
          sparkline: {
            enabled: true
          }
        },
        plotOptions: {
          radialBar: {
            startAngle: -90,
            endAngle: 90,
            track: {
              background: "#CCCCCC",
              strokeWidth: "97%",
              margin: 5, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: 2,
                left: 0,
                color: "#000000",
                opacity: 1,
                blur: 2
              }
            },
            dataLabels: {
              name: {
                show: false
              },
              value: {
                offsetY: -2,
                fontSize: "22px"
              }
            }
          }
        },
        grid: {
          padding: {
            top: -10
          }
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "light",
            shadeIntensity: 0.4,
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 53, 91]
          }
        },
        labels: ["Average Results"]
      },

      // end AppChart

      seriesCollum: [
        {
          data: []
        }
      ],
      chartOptionsCollum: {
        chart: {
          height: 350,
          type: "bar",
          events: {
            click: function(chart, w, e) {
              console.log(chart, w, e);
            }
          }
        },
        colors: ["#00E396", "#FF4560"],
        // colors: this.colors,
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          show: false
        },
        xaxis: {
          categories: [["Savings"], ["Expenses"]],
          labels: {
            style: {
              colors: "#000000",
              fontSize: "12px"
            }
          }
        },
        yaxis: {
          labels: {
            style: {
              colors: "#000000",
              fontSize: "12px"
            }
          }
        }
      },
      denied: 0,
      part: 0,
      gain: 0,
      depenses: 0,
      maintenance: 0,
      total: 0,
      permission: {},
      Money: [
        {
          motif: "",
          montant: 0,
          image: "",
          ladate: "",
          forWho: "",
          byWho: "",
          descriptions: "",
          type: true
        }
      ],

      saving: "blue px-5",
      expenses: "red lighten-3 px-5",
      makabo: true,
      o: {
        id: "",
        email: "",
        yesa: ""
      },
      akoki: false
    };
  },
  created() {
    var user = firebase.auth().currentUser;
    if (
      this.$store.state.ngayi.email == "" ||
      this.$store.state.ngayi.email == undefined ||
      user.email == "" ||
      user.email == undefined
    )
      this.$router.push("/menu");
  },
  mounted() {
    /********************************************************/
    if (this.$store.state.email == "") this.$router.push("/menu");
    this.getMoney();
    /***************************************/
  },
  methods: {
    theFormat(number) {
      return number.toFixed(0);
    },
    openMoney(mbongo) {
      this.details = mbongo;
      this.dialogDetails = true;
    },
    openAttente(mbongo) {
      console.log("mbongo");
      console.log(mbongo);
      console.log("mbongo");
      this.details = mbongo;
      this.dialogDetails = true;
    },
    getMoney() {
      var that = this;
      /***************************************/
      db.collection("baba")
        .orderBy("ladate", "desc")
        .onSnapshot(snapshot => {
          that.motif = [];
          that.attente = [];
          that.seriesCollum = [];
          that.depenses = 0;
          that.denied = 0;
          that.gain = 0;
          snapshot.forEach(doc => {
            if (
              doc.data().permission.pre == "yes" &&
              doc.data().permission.vp == "yes" &&
              doc.data().permission.sg == "yes" &&
              doc.data().permission.tre == "yes" &&
              doc.data().permission.fem == "yes" &&
              doc.data().permission.cul == "yes" &&
              doc.data().permission.jeu == "yes"
            ) {
              that.motif.push({
                id: doc.id,
                motif: doc.data().motif,
                montant: doc.data().montant,
                image: doc.data().image,
                permission: doc.data().permission,
                ladate: doc.data().ladate,
                forWho: doc.data().forWho,
                byWho: doc.data().byWho,
                descriptions: doc.data().descriptions,
                type: doc.data().type
              });

              // Calcul
              if (doc.data().type == false)
                that.depenses += Number(doc.data().montant);
              else that.gain += Number(doc.data().montant);

              that.total = Number(that.gain) - Number(that.depenses);
              var data = [];
              data.push(that.gain);
              data.push(that.depenses);

              that.seriesCollum.push({
                data: data
              });

              console.log("--- that.seriesCollum ---");
              console.log(that.seriesCollum);
            }
          });

          that.total = Number(that.gain) - Number(that.depenses);
          var data = [];
          data.push(that.gain);
          data.push(that.depenses);

          that.seriesCollum.push({
            data: data
          });

          console.log("--- that.seriesCollum ---");
          console.log(that.seriesCollum);
        });
      /***************************************/
    },
    postYango() {
      var that = this;
      that.dialogWait = true;
      if (
        that.Money[0].motif != "" &&
        that.Money[0].montant != 0 &&
        that.Money[0].forWho != "" &&
        that.Money[0].descriptions != ""
      ) {
        try {
          var obj = {
            pre: "",
            vp: "",
            sg: "",
            tre: "yes",
            fem: "",
            cul: "",
            jeu: ""
          };
          db.collection("baba").add({
            motif: that.Money[0].motif,
            montant: Number(that.Money[0].montant),
            ladate: that.Money[0].ladate,
            forWho: that.Money[0].forWho,
            image: that.Money[0].image,
            byWho: that.$store.state.ngayi.id,
            descriptions: that.Money[0].descriptions,
            type: that.Money[0].type,
            permission: obj,
            cretedAd: firebase.firestore.FieldValue.serverTimestamp()
          });
          that.dialogWait = false;
          that.closeYango();
          alert("Successfully");
        } catch (error) {
          alert(error.message);
        }
      } else {
        that.dialogWait = false;
        that.$store.state.dialogErreur = true;
        that.$store.state.erreur = "Please, complete every field!";
      }
    },
    tiaType(t) {
      if (t == 1) {
        this.saving = "blue px-5";
        this.expenses = "red lighten-3 px-5";
        this.Money[0].type = true;
      } else {
        this.saving = "grey lighten-3 px-5";
        this.expenses = "red black--text px-5";
        this.Money[0].type = false;
      }
    },
    closeYango() {
      this.dialogAdd = false;
      this.Money = [
        {
          motif: "",
          montant: 0,
          image: "",
          ladate: "",
          forWho: "",
          byWho: "",
          descriptions: "",
          type: true
        }
      ];

      var d = new Date().toISOString();
      console.log(d);
      this.Money[0].ladate = d.slice(0, 10);
    },

    /********************************** */
    validate() {
      this.$store.state.mutuYeyo = this.User;
      var d = new Date();
      d.setTime(d.getTime() + 5 * (365 * 24 * 60 * 60 * 1000));
      var expires = "expires=" + d.toUTCString();
      document.cookie = "mutuYango" + "=" + this.yanoyo + ";" + expires + ";";
      document.cookie = "bomba" + "=" + this.password + ";" + expires + ";";
    },
    SelecFile() {
      this.$store.state.ImagesUpload = [];
      document.getElementById("file").click();
    },
    baPoniImages(e) {
      document.getElementById("file");
      
      var that = this;
      e.target.files.forEach(element => {
        that.dialogWait = true;
        var filename = element.name;
        var tableau = "";

        var storageRef = firebase.storage().ref("Finance/" + filename);
        var metadata = {
          contentType: "image/jpeg"
        };

        storageRef.put(element, metadata).then(function(downloadURL) {
          downloadURL.ref.getDownloadURL().then(function(url) {
            url = String(url);
            if (url !== undefined) {
              var b = url;
              tableau = b;
              console.log(tableau);
              that.dialogWait = false;
              that.Money[0].image = tableau;
            }
          });
        });
      });
    },
    leTemps() {
      setTimeout(() => {
        this.changedValue();
        return false;
      }, 100);
      return 0;
    },
    changedValue() {
      this.show = true;
      return false;
    }
  },
  computed: {
    ouvrirTransition() {
      this.leTemps();
      return false;
    }
  }
};
</script>
