var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-app-bar',{staticClass:"elevation-0",attrs:{"app":""}},[_c('v-btn',{attrs:{"text":"","color":"white","onclick":"window.history.back()"}},[_c('font-awesome-icon',{staticClass:"fa-x black--text mr-2",attrs:{"icon":['fas', 'arrow-left']}})],1),_c('v-spacer')],1),_c('v-main',{staticClass:"main p-0",staticStyle:{"background-color":"rgb(235, 236, 239)"}},[_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.show)?_c('div',[_c('section',{staticClass:"bg-col-image"},[_c('div',{staticStyle:{"height":"100%","width":"100%","background":"rgba(11,18,81,0.66)","padding":"80px 0px 60px"}},[_c('div',{staticClass:"container py-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 d-flex justify-content-center align-items-center flex-column",staticStyle:{"text-align":"center"}},[_c('h1',{staticClass:"white--text display-1 font-weight-bold pt-5 text-uppercase"},[_vm._v(" Création d'une famille ")])])])])])]),_c('div',{staticClass:"container py-5"},[_c('v-row',[_c('v-col',{staticClass:"py-5",attrs:{"cols":"12","md":"8","lg":"6"}},[_c('form',[_c('span',{staticClass:"my-5 title font-weight-normal black--text"},[_vm._v("Quel est le nom de la famille?")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.familyName),expression:"familyName"}],staticClass:"form-control mt-5 mb-3 subtitle-2 font-weight-normal",staticStyle:{"border-radius":"10px","color":"black"},attrs:{"type":"text","name":"Name","placeholder":"Name"},domProps:{"value":(_vm.familyName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.familyName=$event.target.value}}}),(
                    _vm.familyName.toLowerCase().includes('famille') ||
                      _vm.familyName.toLowerCase().includes('famile') ||
                      _vm.familyName.toLowerCase().includes('family')
                  )?_c('span',{staticClass:"py-1 px-3 my-0 white red--text"},[_vm._v(" Le nom de la famille ne doit pas contenir le mot \"Famille\" ")]):_vm._e(),_c('br'),_c('br'),_c('v-btn',{staticClass:"elevation-0",attrs:{"disabled":_vm.familyName == '' ||
                      _vm.familyName.toLowerCase().includes('famille') ||
                      _vm.familyName.toLowerCase().includes('famile') ||
                      _vm.familyName.toLowerCase().includes('family'),"small":"","color":"orange black--text"},on:{"click":function($event){return _vm.createId()}}},[_vm._v("Commencer")])],1)])],1)],1)]):_vm._e()])],1),_c('div',{staticClass:"d-none"},[_vm._v(_vm._s(_vm.ouvrirTransition))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }