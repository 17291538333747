<template>
  <!-- App.vue -->
  <v-app style="background-color:#dfe7f1">
    <v-app-bar app style="background-color:#214a80">
      <v-btn icon onclick="window.history.back()">
        <font-awesome-icon
          :icon="['fas', 'arrow-left']"
          class="fa-x white--text"
        />
      </v-btn>
      <v-spacer></v-spacer>
      <h4 class="white--text">Iowa City</h4>
      <v-spacer></v-spacer>
      <v-btn icon href="#/menu">
        <font-awesome-icon :icon="['fas', 'bars']" class="fa-x white--text" />
      </v-btn>
      <v-btn icon>
        <font-awesome-icon :icon="['fas', 'search']" class="fa-x white--text" />
      </v-btn>
    </v-app-bar>
    <!-- Sizes your content based upon application components -->

    <v-main class="main p-0">
      <transition name="slide-fade">
        <div v-if="show">
          <div class="container">
            <v-row justify="center">
              <!-- MD only -->
              <v-col cols="12" class="d-none d-md-block">
                <v-card height="100%" class="elevation-0 py-5 px-5">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            Picture
                          </th>
                          <th class="text-left">
                            Name
                          </th>
                          <th class="text-left">
                            Settings
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(x, k) in people"
                          :key="k"
                          @click="openPeople(x)"
                        >
                          <td class="py-2">
                            <v-avatar>
                              <v-img :src="x.src" class="img-fluids" />
                            </v-avatar>
                          </td>
                          <td>
                            <h6 class="font-weight-bold">{{ x.fullname }}</h6>
                          </td>
                          <td>
                            <v-btn color="primary" href="#/person_profile"
                              >Open</v-btn
                            >
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </v-col>

              <!-- Mobile only -->
              <v-col cols="12" class="d-block d-md-none">
                <v-card height="100%" class="elevation-0 py-5 px-5">
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            Picture
                          </th>
                          <th class="text-left">
                            Name
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(x, k) in people"
                          :key="k"
                          @click="openPeople(x)"
                        >
                          <td class="py-2">
                            <v-avatar>
                              <v-img :src="x.src" class="img-fluids" />
                            </v-avatar>
                          </td>
                          <td>
                            <h6 class="font-weight-bold">{{ x.fullname }}</h6>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </v-col>

              <!-- <v-col cols="12" lg="6" class="black py-5 px-5"></v-col> -->
            </v-row>
          </div>
        </div>
      </transition>
    </v-main>
    <div class="d-none">{{ ouvrirTransition }}</div>
  </v-app>
</template>

<script>
// import ServiceApi from "../ServicesApi.js";
import firebase from "firebase";
import db from "../../db.js";

export default {
  data() {
    return {
      email: "kerene@example.com",
      show: false,
      password: "plus1234",
      erreur: "",
      dialogErreur: false,
      place: "",
      valeur: 50,

      people: []
    };
  },
  created() {
    var user = firebase.auth().currentUser;
    if (
      this.$store.state.ngayi.email == "" ||
      this.$store.state.ngayi.email == undefined ||
      user.email == "" ||
      user.email == undefined
    )
      this.$router.push("/menu");
    else {
      if (
        this.$store.state.ngayi.yesa == process.env.VUE_APP_FBT_2023 ||
        this.$store.state.ngayi.yesa == process.env.VUE_APP_KONGOMANI
      )
        console.log("ok ok ok");
      else {
        this.blurMe();
      }
    }
    console.log(this.$store.state.users);
    this.people = [];
    this.$store.state.users.forEach(element => {
      if (element.city.toLowerCase() == this.$store.state.city.name) {
        var obj = {
          fullname: element.nom + " " + element.postnom + " " + element.prenom,
          src: element.image,
          adresse: element.adresse,
          city: element.city,
          date_de_naissance: element.date_de_naissance,
          lieu_de_naissance: element.lieu_de_naissance,
          email: element.email,
          sex: element.sex,
          phone: element.phone,
          zipcode: element.zipcode,
          privacy: element.privacy,
          type: element.type,
          year: element.year,
          date_a_iowa: element.date_a_iowa
        };
        this.people.push(obj);
      }
    });
  },
  methods: {
    theFormat(number) {
      return number.toFixed(0);
    },
    openPeople(people) {
      console.log(people);
      //Duree
      console.log(people.date_a_iowa);
      var duree = people.date_a_iowa.slice(0, 4);
      console.log(duree);
      var d = new Date();
      var annee = d.getFullYear();
      console.log("age == " + (Number(annee) - Number(duree)));
      this.$store.state.ye = people;
      this.$store.state.ye.year = Number(annee) - Number(duree);

      this.$router.push("/person_profile");
    },

    /********************************** */
    validate() {
      this.$store.state.mutuYeyo = this.User;
      var d = new Date();
      d.setTime(d.getTime() + 5 * (365 * 24 * 60 * 60 * 1000));
      var expires = "expires=" + d.toUTCString();
      document.cookie = "mutuYango" + "=" + this.yanoyo + ";" + expires + ";";
      document.cookie = "bomba" + "=" + this.password + ";" + expires + ";";
    },
    blurMe() {
      var that = this;
      try {
        db.collection("challenge").add({
          id: that.$store.state.ngayi.id,
          email: that.$store.state.ngayi.email,
          access: "access au menu pre",
          cretedAd: firebase.firestore.FieldValue.serverTimestamp()
        });
      } catch (error) {
        alert(error.message);
      }
    },
    leTemps() {
      setTimeout(() => {
        this.changedValue();
        return false;
      }, 100);
      return 0;
    },
    changedValue() {
      this.show = true;
      return false;
    }
  },
  computed: {
    ouvrirTransition() {
      this.leTemps();
      return false;
    }
  }
};
</script>
