<template>
    <div class="bg-secondary d-flex align-items-center justify-center" style="min-height: 100vh;">
        <v-row class="pt-5" justify="center">

            <v-col cols="12" lg="8" class="white">
                <v-progress-linear v-model="knowledge" color="primary lighten-4" height="35">
                    <strong>{{knowledge}}%</strong>
                </v-progress-linear>
            </v-col>

            <v-col cols="12" lg="8" class="white d-flex align-items-center justify-center p-0" style="height:150px;">

                <div class="d-flex align-items-center justify-center p-0 w-100" style="height:100%">
                    <h3 style="color: rgba(0,0,0,.79);" class="text-center">
                        Is this information correct?
                    </h3>
                </div>
            </v-col>

            <v-col cols="12" lg="8" class="bg px-5 white">
                <v-card height="100%" class="bg-transparent elevation-0 pb-5 px-5">
                    <v-simple-table>
                        <template v-slot:default>
                            <tbody>
                                <!-- Prénom -->
                                <tr>
                                    <td class="py-2 black--text">First Name:<br /><span
                                            class="grey--text">(Prénom)</span></td>
                                    <td>
                                        <v-text-field label="Prenom" v-model="prenom" />
                                    </td>
                                </tr>
                                <!-- Nom -->
                                <tr>
                                    <td class="py-2 black--text">Last Name:<br /><span class="grey--text">(Nom)</span>
                                    </td>
                                    <td>
                                        <v-text-field label="Nom" v-model="nom" />
                                    </td>
                                </tr>
                                <!-- Postnom -->
                                <tr>
                                    <td class="py-2 black--text">Middle Name:<br /><span
                                            class="grey--text">(Postnom)</span></td>
                                    <td>
                                        <v-text-field label="Postnom" v-model="postnom" />
                                    </td>
                                </tr>
                                <!-- Phone -->
                                <tr>
                                    <td class="py-2 black--text">Phone Number:<br /><span
                                            class="grey--text">(Téléphone)</span></td>
                                    <td>
                                        <v-text-field label="Phone Number" v-model="phone" />
                                    </td>
                                </tr>
                                <!-- Email -->
                                <!-- <tr>
                                    <td class="py-2 black--text">Email Address:<br /><span class="grey--text">(Adresse
                                            Email)</span></td>
                                    <td>
                                        <v-text-field label="Email" disabled v-model="email" />
                                    </td>
                                </tr> -->
                                <!-- Sex -->
                                <tr>
                                    <td class="py-2 black--text">Sex:<br /><span class="grey--text">(Sexe)</span></td>
                                    <td>
                                        <v-select :items="['M', 'F']" label="Sex" v-model="sex"></v-select>
                                    </td>
                                </tr>
                                <!-- City -->
                                <tr>
                                    <td class="py-2 black--text">City:<br /><span class="grey--text">(Ville)</span></td>
                                    <td>
                                        <v-select :items="cities" label="Cities" v-model="city" />
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>

                    <div class="pt-5">
                    </div>
                    <div class="text-center">
                        <v-btn color="success" large @click="postUsers()" v-if="showBtn">Enregistrer</v-btn>
                        <v-btn large class="mt-5" color="primary" href="#/setMyJob" v-else>
                            Continuer</v-btn>
                    </div>
                </v-card>
            </v-col>
        </v-row>
        <span class="d-none">{{ trackChanges }}</span>
    </div>
</template>

<script>
    import db from "../../db.js";

    export default {
        data() {
            return {
                knowledge: 10,
                prenom: "",
                postnom: "",
                nom: "",
                phone: "",
                email: "",
                city: "",
                sex: "",
                cities: [
                    "Cedar Rapids",
                    "Coralville",
                    "Iowa City",
                    "North Liberty",
                    "Tiffin",
                    "Autres"
                ],
                showBtn: false
            }
        },
        created() {
            this.setUp();
            setTimeout(() => {
                this.setUp();
            }, 2000);
        },
        computed: {
            trackChanges() {
                this.shawButton();
                return 0;
            }
        },
        methods: {
            setUp() {
                
                this.$store.state.ngayi.email = this.$store.state.ngayi.id.toUpperCase() +"@wakongo.com";

                this.prenom = this.$store.state.ngayi.prenom;
                this.postnom = this.$store.state.ngayi.postnom;
                this.nom = this.$store.state.ngayi.nom;
                this.email = this.$store.state.ngayi.email;
                this.city = this.$store.state.ngayi.city;
                this.sex = this.$store.state.ngayi.sex;
                this.phone = this.$store.state.ngayi.phone;
                
                // alert("dsa");
            },
            postUsers() {
                var that = this;
                db.collection("users")
                    .doc(that.$store.state.ngayi.originalId)
                    .update({
                        prenom: that.prenom,
                        postnom: that.postnom,
                        nom: that.nom,
                        sex: that.sex,
                        phone: that.phone,
                        email: that.email,
                        city: that.city
                    });
                //if update on one field, save first then next
                alert("Enregistrer avec success!");
                this.$store.state.ngayi.prenom = this.prenom;
                this.$store.state.ngayi.postnom = this.postnom;
                this.$store.state.ngayi.nom = this.nom;
                this.$store.state.ngayi.email = this.email;
                this.$store.state.ngayi.city = this.city;
                this.$store.state.ngayi.sex = this.sex;
                this.$store.state.ngayi.phone = this.phone;
            },
            shawButton() {
                if (this.prenom != this.$store.state.ngayi.prenom ||
                    this.postnom != this.$store.state.ngayi.postnom ||
                    this.nom != this.$store.state.ngayi.nom ||
                    this.email != this.$store.state.ngayi.email ||
                    this.city != this.$store.state.ngayi.city ||
                    this.phone != this.$store.state.ngayi.phone) {
                    this.showBtn = true;
                } else {
                    this.showBtn = false;
                }
            }

        }
    }
</script>

<style>

</style>