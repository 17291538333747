<template>
  <!-- App.vue -->
  <v-app>
    <v-app-bar class="elevation-0" app style="background-color:rgb(35, 36, 39)">
      <v-btn text color="white" onclick="window.history.back()">
        <font-awesome-icon
          :icon="['fas', 'arrow-left']"
          class="fa-x white--text mr-2"
        />
        Retour
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn icon @click="montrer = 2">
        <font-awesome-icon :icon="['fas', 'table']" class="fa-x white--text" />
      </v-btn>
      <v-btn icon @click="montrer = 0">
        <font-awesome-icon :icon="['fas', 'users']" class="fa-x white--text" />
      </v-btn>
      <v-btn
        icon
        @click="
          dialog4 = true;
          montrer = 1;
        "
      >
        <font-awesome-icon :icon="['fas', 'search']" class="fa-x white--text" />
      </v-btn>
    </v-app-bar>
    <!-- Sizes your content based upon application components -->

    <v-main class="main p-0">
      <transition name="slide-fade">
        <div v-if="show">
          <div class="container py-5 my-5">
            <v-row>
              <v-col cols="12">
                <v-card class="bg-transparent elevation-0">
                  <v-card-title>
                    <v-text-field
                      v-model="recherche"
                      append-icon="mdi-magnify"
                      label="Search"
                      single-line
                      dark
                      hide-details
                    ></v-text-field>
                  </v-card-title>
                  <v-data-table
                    light
                    dense
                    class="bg-transparent"
                    :items-per-page="-1"
                    :headers="headers"
                    :items="people"
                    :search="recherche"
                  >
                    <template v-slot:item.image="{ item }">
                      <div class="py-1">
                        <v-avatar>
                          <v-img
                            :src="item.image"
                            :alt="'Image de ' + item.fullname"
                            class="img-fluids"
                          ></v-img>
                        </v-avatar>
                      </div>
                    </template>
                    <template v-slot:item.setting="{ item }">
                      <span class="d-none">{{ item.setting }}</span>
                      <v-btn
                        small
                        color="blue accent-3"
                        class="white--text"
                        @click="
                          message = item;
                          dialogMessage = true;
                        "
                        >Ouvrir</v-btn
                      >
                    </template>
                  </v-data-table>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </div>
      </transition>
    </v-main>

    <v-dialog persistent v-model="dialogWait" max-width="200">
      <v-card class="text-center py-3">
        <v-progress-circular
          :size="30"
          color="primary"
          indeterminate
        ></v-progress-circular>
        <span class="ml-2">Please Wait</span>
      </v-card>
    </v-dialog>

    <!-- Dialog 4 -->
    <v-dialog v-model="dialogMessage" max-width="600">
      <v-card class="elevation-0 px-5 py-5" v-if="par == 'rien'">
        <div class="w-100 text-center">
          <span class="headline font-weight-bold">Signaler</span><br /><br />

          <table class="table">
            <tr>
              <td>originalID</td>
              <td>{{ message.originalID }}</td>
            </tr>
            <tr>
              <td>messageID</td>
              <td>{{ message.messageID }}</td>
            </tr>
            <tr>
              <td>accuserFullname</td>
              <td>{{ message.accuserFullname }}</td>
            </tr>
            <tr>
              <td>email</td>
              <td>{{ message.email }}</td>
            </tr>
            <tr>
              <td>parFullname</td>
              <td>{{ message.parFullname }}</td>
            </tr>
            <tr>
              <td>ladate</td>
              <td>{{ message.ladate }}</td>
            </tr>
            <tr>
              <td>nbreFois</td>
              <td>{{ message.nbreFois }}</td>
            </tr>
          </table>

          <p class="p-3 mb-5 grey lighten-3">{{ message.message }}</p>
        </div>

        <v-btn
          class="elevation-0 white--text m-1"
          color="red"
          block
          @dblclick="
            bloquerMF(message.accuseId, message.email, message.messageID)
          "
          >Bloquer MF</v-btn
        >

        <v-btn
          class="elevation-0 white--text m-1"
          color="green"
          block
          @dblclick="cancelSignaler(message.messageID)"
          >Cancel signaler</v-btn
        >
      </v-card>
    </v-dialog>
    <div class="d-none">{{ ouvrirTransition }}</div>
  </v-app>
</template>

<script>
// import ServiceApi from "../ServicesApi.js";
import firebase from "firebase";
import db from "../../db.js";

export default {
  data() {
    return {
      dialogMessage: false,
      show: false,
      message: {},
      email: "kerene@example.com",
      password: "plus1234",
      erreur: "",
      showPerson: false,
      par: "rien",
      koluka: [
        {
          id: "",
          nom: "",
          postnom: "",
          prenom: ""
        }
      ],
      headers: [
        { text: "ID", value: "accuseId" },
        { text: "Fullname", value: "accuserFullname" },
        { text: "message", value: "message" },
        { text: "Length", value: "nbreFois" },
        { text: "Action", value: "setting" }
      ],
      place: "",
      valeur: 50,
      search: "",
      recherche: "",
      dialog: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      roomid: "",
      dialogWait: false,
      nombreEnfant: 1,
      peopleSearch: [],
      people: [],
      naponi: [],
      roomate: [],
      montrer: 2
    };
  },
  created() {
    var that = this;
    var user = firebase.auth().currentUser;
    if (
      this.$store.state.ngayi.email == "" ||
      this.$store.state.ngayi.email == undefined ||
      user.email == "" ||
      user.email == undefined
    )
      this.$router.push("/menu");
    else {
      if (this.$store.state.ngayi.yesa == process.env.VUE_APP_KONGOMANI)
        console.log("ok ok ok");
      else {
        // alert(0);
        try {
          db.collection("challenge").add({
            id: that.$store.state.ngayi.id,
            email: that.$store.state.ngayi.email,
            access: "access au menu pre",
            cretedAd: firebase.firestore.FieldValue.serverTimestamp()
          });
          this.$router.push("/menu");
        } catch (error) {
          alert(error.message);
        }
      }
    }
  },
  mounted() {
    var that = this;
    console.log(this.$store.state.email + " from menu");
    /********************************************************/
    if (this.$store.state.email == "") this.$router.push("/menu");
    else {
      /********************************************************/
      db.collection("charge")
        .orderBy("email", "asc")
        .onSnapshot(snapshot => {
          this.o = {
            id: "",
            email: "",
            yesa: ""
          };
          this.makabo = true;
          snapshot.forEach(doc => {
            if (
              doc.data().yesa == that.$store.state.ngayi.yesa &&
              doc.data().email.toLowerCase() ==
                that.$store.state.ngayi.email.toLowerCase() &&
              doc.data().id.toLowerCase() ==
                that.$store.state.ngayi.id.toLowerCase()
            ) {
              this.o = {
                id: doc.data().id,
                email: doc.data().email,
                yesa: doc.data().yesa
              };
              this.makabo = false;
            }
          });
          if (this.makabo == true) {
            if (
              this.$store.state.ngayi.yesa != process.env.VUE_APP_KomngoMani ||
              this.$store.state.ngayi.yesa != process.env.VUE_APP_FBT_2023
            ) {
              // alert("block account");
              try {
                db.collection("challenge").add({
                  id: that.$store.state.ngayi.id,
                  email: that.$store.state.ngayi.email,
                  access: "access a tout le monde",
                  cretedAd: firebase.firestore.FieldValue.serverTimestamp()
                });
              } catch (error) {
                alert(error.message);
              }
            }
          }
        });
    }
    // /*******************************************************/

    /***************************************/
    db.collection("signaler")
      .orderBy("id", "asc")
      .onSnapshot(snapshot => {
        this.people = [];
        snapshot.forEach(doc => {
          this.people.push({
            originalID: doc.id,
            email: doc.data().email,
            messageID: doc.data().messageID,
            accuserFullname: doc.data().fullname,
            accuseId: doc.data().id,
            parId: doc.data().parId,
            parFullname: doc.data().parFullname,
            ladate: doc.data().ladate,
            message: doc.data().message,
            nbreFois: 0
          });
        });
        console.log("this.people");
        console.log(this.people);
        console.log("this.people");
        try {
          this.talaCombiendeFois();
        } catch (error) {
          console.log(error);
        }
      });
  },
  methods: {
    talaCombiendeFois() {
      var nbre = 0;
      var who = "";
      console.log(this.people);

      for (let i = 0; i < this.people.length; i++) {
        who = this.people[i].messageID;
        nbre = 0;
        for (let j = 0; j < this.people.length; j++) {
          console.log(this.people[j].messageID + " -- " + who);
          if (this.people[j].messageID == who) {
            nbre++;
            this.people[i].nbreFois = nbre;
          } else {
            this.people[i].nbreFois = nbre;
            nbre = 0;
          }
        }
      }
      var uniqstate = this.people.filter(
        (v, i, a) => a.findIndex(t => t.messageID === v.messageID) === i
      );
      //  console.log("------- uniqstate -------");
      //  console.log(uniqstate);
      this.people = uniqstate;

      // this.people = this.groupBy(this.people, 'messageID');
    },
    bloquerMF(accuseId, email, messageID) {
      try {
        db.collection("kadena").add({
          id: accuseId,
          email: email,
          cretedAd: firebase.firestore.FieldValue.serverTimestamp()
        });
        this.cancelSignaler(messageID);
      } catch (error) {
        alert(error.message);
      }
    },
    cancelSignaler(messageID) {
      var messageYango = db
        .collection("signaler")
        .where("messageID", "==", messageID);
      messageYango.get().then(function(querySnapshot) {
        querySnapshot.forEach(function(doc) {
          doc.ref.delete();
        });
      });

      this.dialogMessage = false;
    },
    leTemps() {
      setTimeout(() => {
        this.changedValue();
        return false;
      }, 100);
      return 0;
    },
    changedValue() {
      this.show = true;
      return false;
    }
  },
  computed: {
    ouvrirTransition() {
      this.leTemps();
      return false;
    }
  }
};
</script>

<style scoped>
.card:hover {
  border: 2px solid rgb(4, 61, 134);
  cursor: pointer;
}
</style>
