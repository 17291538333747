<template>
  <!-- App.vue -->
  <v-app>
    <v-app-bar class="elevation-0" app style="background-color:rgb(35, 36, 39)">
      <v-btn text color="white" onclick="window.history.back()">
        <font-awesome-icon :icon="['fas', 'arrow-left']" class="fa-x white--text mr-2" />
        Retour
      </v-btn>
      <v-spacer></v-spacer>
    </v-app-bar>
    <!-- Sizes your content based upon application components -->

    <v-main class="main p-0">
      <transition name="slide-fade">
        <div v-if="show">
          <div class="containers px-5 py-5" style="margin-top:65px;">
            <h3 class="text-center font-weight-bold">People</h3>

            <v-divider></v-divider>

            <input type="file" @change="putIt()" id="input" class="d-nones" />

            <v-btn small color="primary" class="m-1" @click="createIt()">create people</v-btn>
            <v-btn small color="warning" class="m-1" @click="hasJob()">create Job</v-btn>
            <v-divider></v-divider>

            <v-btn small color="warning" class="m-1" @click="exportToCsv()">Make it perfect</v-btn>
            <table class="table my-5">
              <tr>
                <th>Num</th>
                <th>ID</th>
                <th>Nom</th>
                <th>Postnom</th>
                <th>Prenom</th>
                <th>Sex</th>
                <th>Phone</th>
                <th>Email</th>
                <th>City</th>
                <th>School</th>
                <th>Job</th>
              </tr>
              <tr v-for="(x, k) in people" :key="k">
                <td>{{ k + 1 }}</td>
                <td>{{ x.id }}</td>
                <td>{{ x.nom }}</td>
                <td>{{ x.postnom }}</td>
                <td>{{ x.prenom }}</td>
                <td>{{ x.sex }}</td>
                <td>{{ x.tel }}</td>
                <td>{{ x.email }}</td>
                <td>{{ x.city }}</td>
                <td>{{ x.school }}</td>
                <td>{{ x.job }}</td>
              </tr>
            </table>
          </div>
        </div>
      </transition>
    </v-main>

    <v-dialog persistent v-model="dialogWait" max-width="200">
      <v-card class="text-center py-3">
        <v-progress-circular :size="30" color="primary" indeterminate></v-progress-circular>
        <span class="ml-2">Please Wait</span>
      </v-card>
    </v-dialog>

    <!-- Dialog 4 -->
    <v-dialog v-model="dialogMessage" max-width="600">
      <v-card class="elevation-0 px-5 py-5" v-if="par == 'rien'">
        <div class="w-100 text-center">
          <span class="headline font-weight-bold">Signaler</span><br /><br />

          <table class="table">
            <tr>
              <td>originalID</td>
              <td>{{ message.originalID }}</td>
            </tr>
            <tr>
              <td>messageID</td>
              <td>{{ message.messageID }}</td>
            </tr>
            <tr>
              <td>accuserFullname</td>
              <td>{{ message.accuserFullname }}</td>
            </tr>
            <tr>
              <td>email</td>
              <td>{{ message.email }}</td>
            </tr>
            <tr>
              <td>parFullname</td>
              <td>{{ message.parFullname }}</td>
            </tr>
            <tr>
              <td>ladate</td>
              <td>{{ message.ladate }}</td>
            </tr>
            <tr>
              <td>nbreFois</td>
              <td>{{ message.nbreFois }}</td>
            </tr>
          </table>

          <p class="p-3 mb-5 grey lighten-3">{{ message.message }}</p>
        </div>

        <v-btn class="elevation-0 white--text m-1" color="red" block @dblclick="
            bloquerMF(message.accuseId, message.email, message.messageID)
          ">Bloquer MF</v-btn>

        <v-btn class="elevation-0 white--text m-1" color="green" block @dblclick="cancelSignaler(message.messageID)">
          Cancel signaler</v-btn>
      </v-card>
    </v-dialog>
    <div class="d-none">{{ ouvrirTransition }}</div>
  </v-app>
</template>

<script>
  // import ServiceApi from "../ServicesApi.js";
  import firebase from "firebase";
  import db from "../../db.js";
  import readXlsxFile from "read-excel-file";
  import ObjectsToCsv from "objects-to-csv";

  export default {
    data() {
      return {
        dialogMessage: false,
        show: false,
        message: {},
        email: "kerene@example.com",
        password: "plus1234",
        erreur: "",
        showPerson: false,
        par: "rien",
        koluka: [{
          id: "",
          nom: "",
          postnom: "",
          prenom: ""
        }],
        place: "",
        valeur: 50,
        search: "",
        recherche: "",
        dialog: false,
        dialog2: false,
        dialog3: false,
        dialog4: false,
        roomid: "",
        dialogWait: false,
        nombreEnfant: 1,
        people: [],
        users: [],
        naponi: [],
        montrer: 2
      };
    },
    created() {
      var that = this;
      var user = firebase.auth().currentUser;
      if (
        this.$store.state.ngayi.email == "" ||
        this.$store.state.ngayi.email == undefined ||
        user.email == "" ||
        user.email == undefined
      )
        this.$router.push("/menu");
      else {
        if (this.$store.state.ngayi.yesa == process.env.VUE_APP_KONGOMANI) {
          console.log("ok ok ok");
          this.getUsers();
        } else {
          // alert(0);
          try {
            db.collection("challenge").add({
              id: that.$store.state.ngayi.id,
              email: that.$store.state.ngayi.email,
              access: "access au menu pre",
              cretedAd: firebase.firestore.FieldValue.serverTimestamp()
            });
            this.$router.push("/menu");
          } catch (error) {
            alert(error.message);
          }
        }
      }
    },
    mounted() {
      var that = this;
      console.log(this.$store.state.email + " from menu");
      /********************************************************/
      if (this.$store.state.email == "") this.$router.push("/menu");
      else {
        /********************************************************/
        db.collection("charge")
          .orderBy("email", "asc")
          .onSnapshot(snapshot => {
            this.o = {
              id: "",
              email: "",
              yesa: ""
            };
            this.makabo = true;
            snapshot.forEach(doc => {
              if (
                doc.data().yesa == that.$store.state.ngayi.yesa &&
                doc.data().email.toLowerCase() ==
                that.$store.state.ngayi.email.toLowerCase() &&
                doc.data().id.toLowerCase() ==
                that.$store.state.ngayi.id.toLowerCase()
              ) {
                this.o = {
                  id: doc.data().id,
                  email: doc.data().email,
                  yesa: doc.data().yesa
                };
                this.makabo = false;
              }
            });
            if (this.makabo == true) {
              if (
                this.$store.state.ngayi.yesa != process.env.VUE_APP_KomngoMani ||
                this.$store.state.ngayi.yesa != process.env.VUE_APP_FBT_2023
              ) {
                // alert("block account");
                try {
                  db.collection("challenge").add({
                    id: that.$store.state.ngayi.id,
                    email: that.$store.state.ngayi.email,
                    access: "access a tout le monde",
                    cretedAd: firebase.firestore.FieldValue.serverTimestamp()
                  });
                } catch (error) {
                  alert(error.message);
                }
              }
            }
          });
      }
      // /*******************************************************/

      /***************************************/
    },
    computed: {
      ouvrirTransition() {
        this.leTemps();
        return false;
      }
    },
    methods: {
      getUsers() {
        db.collection("users").onSnapshot(snapshot => {
          this.users = [];
          snapshot.forEach(doc => {
            this.users.push({
              id: doc.data().id,
              nom: doc.data().nom, 
              postnom: doc.data().postnom,
              prenom: doc.data().prenom,
              phone: "1"+doc.data().phone
            });
          });
          // alert(this.users.length);
        });
      },
      putIt() {
        this.people = [];

        try {
          const input = document.getElementById("input");
          readXlsxFile(input.files[0]).then(rows => {
            // `rows` is an array of rows
            for (let i = 1; i < rows.length; i++) {
              const element = rows[i];
              // if (element[0] && element[2] && element[3] && element[4]) {
              var image = "";
              if (element[3] == "M") {
                image =
                  "https://firebasestorage.googleapis.com/v0/b/databaste-51ebd.appspot.com/o/Pictures%2F1.jpg?alt=media&token=0026894d-ef6f-4e08-a40b-ab38213e68ad";
              } else {
                image =
                  "https://firebasestorage.googleapis.com/v0/b/databaste-51ebd.appspot.com/o/Pictures%2F2.jpg?alt=media&token=5c4dd362-04b1-40e0-9369-ba000d28d525";
              }
              this.people.push({
                id: this.madeId(),
                nom: element[1],
                postnom: element[2],
                prenom: element[0],
                sex: element[3],
                tel: element[4],
                email: element[5],
                city: element[6],
                image: image,
                school: element[7],
                job: element[8]
              });
              // }
            }
            console.log(this.people);
            // each row being an array of cells.
          });
        } catch (error) {
          alert(error);
        }
      },
      createIt() {
        var erreur = false;
        this.people.forEach(element => {
          console.log(element);
          try {
            db.collection("users").add({
              id: element.id,
              nom: element.nom,
              postnom: element.postnom,
              prenom: element.prenom,
              phone: element.tel,
              date_de_naissance: "",
              lieu_de_naissance: "",
              sex: element.sex,
              privacy: false,
              date_a_iowa: "",
              image: element.image,
              adresse: "",
              city: element.city,
              zipcode: "",
              state: "Iowa",
              type: "e",
              green: "",
              email: element.email,
              day: "",
              mois: "",
              access: "",
              didi: "",
              cretedAd: firebase.firestore.FieldValue.serverTimestamp()
            });
          } catch (error) {
            erreur = true;
            alert(error.message);
          }
        });


        if (erreur == false) {
          alert("successfull");
          // this.createAcount();
          // setTimeout(() => {
          // this.hasJob();
          // }, 5000);
        }
      },
      hasJob() {
        this.people.forEach(element => {
          var musala = false;
          console.log("element.job = " + element.job);
          if (element.job == 'Y') musala = true;
          else musala = false;
          console.log("musala = " + musala);

          try {
            db.collection("job").add({
              id: element.id,
              email: element.email,
              job: musala,
              city: element.city,
              cretedAd: firebase.firestore.FieldValue.serverTimestamp()
            });
          } catch (error) {
            alert(error.message);
          }
        });
        alert("done job");
      },
      createAcount() {
        this.people.forEach(element => {
          if (element.email != null && element.email != "") {
            /*****************************/
            firebase
              .auth()
              .createUserWithEmailAndPassword(element.email, element.id)
              .then(
                elt => {
                  console.log(elt.user.email);
                },
                error => {
                  alert(error.message + "\n\n" + element.email);
                }
              );
            /*****************************/
          }
        });
        alert("done");
      },
      madeId() {
        var result = "CON";
        var keep = false;
        do {
          var characters = "0123456789";
          var charactersLength = characters.length;
          for (var i = 0; i < 4; i++) {
            result += characters.charAt(
              Math.floor(Math.random() * charactersLength)
            );
          }
          this.users.forEach(element => {
            if (element.id == result) keep = true;
          });
        } while (keep);
        // alert("result " + result);
        return result;
      },
      leTemps() {
        setTimeout(() => {
          this.changedValue();
          return false;
        }, 100);
        return 0;
      },
      changedValue() {
        this.show = true;
        return false;
      },
      async exportToCsv() {
        var tab = await new ObjectsToCsv(this.users).toString();
        this.exportToCsv_2("wakongoUsers", tab);
      },
      exportToCsv_2(filename, rows) {
        var csvFile = rows;

        var blob = new Blob([csvFile], {
          type: 'text/csv;charset=utf-8;'
        });
        if (navigator.msSaveBlob) { // IE 10+
          navigator.msSaveBlob(blob, filename);
        } else {
          var link = document.createElement("a");
          if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", filename);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      }
    }
  };
</script>

<style scoped>
  .card:hover {
    border: 2px solid rgb(4, 61, 134);
    cursor: pointer;
  }
</style>